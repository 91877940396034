import React from 'react'
import { Translate } from '../../../../../utils'
import { BtnAction, DeletSvg, DragSvg, CheckBox } from '../../../../_Common'
import './dragstyle.css'
const initialDnDState = {
    draggedFrom: null,
    draggedTo: null,
    isDragging: false,
    originalOrder: [],
    updatedOrder: []
}

const DragToReorderList = (props) => {

    const [dragAndDrop, setDragAndDrop] = React.useState(initialDnDState);


    // onDragStart fires when an element
    // starts being dragged
    const onDragStart = (event) => {
        const initialPosition = Number(event.currentTarget.dataset.position);

        setDragAndDrop({
            ...dragAndDrop,
            draggedFrom: initialPosition,
            isDragging: true,
            originalOrder: props.items
        });


        // Note: this is only for Firefox.
        // Without it, the DnD won't work.
        // But we are not using it.
        event.dataTransfer.setData("text/html", '');
    }

    // onDragOver fires when an element being dragged
    // enters a droppable area.
    // In this case, any of the items on the list
    const onDragOver = (event) => {

        // in order for the onDrop
        // event to fire, we have
        // to cancel out this one
        event.preventDefault();

        let newList = dragAndDrop.originalOrder;

        // index of the item being dragged
        const draggedFrom = dragAndDrop.draggedFrom;

        // index of the droppable area being hovered
        const draggedTo = Number(event.currentTarget.dataset.position);

        const itemDragged = newList[draggedFrom];
        const remainingItems = newList.filter((item, index) => index !== draggedFrom);

        newList = [
            ...remainingItems.slice(0, draggedTo),
            itemDragged,
            ...remainingItems.slice(draggedTo)
        ];

        if (draggedTo !== dragAndDrop.draggedTo) {
            setDragAndDrop({
                ...dragAndDrop,
                updatedOrder: newList,
                draggedTo: draggedTo
            })
        }

    }

    const onDrop = (event) => {
        props.setList(dragAndDrop.updatedOrder);

        setDragAndDrop({
            ...dragAndDrop,
            draggedFrom: null,
            draggedTo: null,
            isDragging: false
        });
    }

    const onDragLeave = () => {
        setDragAndDrop({
            ...dragAndDrop,
            draggedTo: null
        });

    }
    return (
        <ul>
            {props.items.map((item, index) => {
                return (
                    <li
                        key={index}
                        data-position={index}
                        draggable
                        onDragStart={onDragStart}
                        onDragOver={onDragOver}
                        onDrop={onDrop}
                        onDragLeave={onDragLeave}
                        className={(dragAndDrop && dragAndDrop.draggedTo === Number(index) ? "dropArea" : "") + " pos-relative"}
                    >
                        <textarea data-index={index} name="qestions" placeholder={Translate('selfgrile', 'qstText')} onChange={props.handleChangeQestion} className="sizeF-1em" style={{ height: "23px", width: "600px" }} value={item.text}></textarea>
                        <div className="dis-flex leftM-auto">
                            {props.currentTab === "candidate" &&
                                <CheckBox className="rightM-8 dis-flex" id={'justify' + index} name={index} other={{ checked: item.needJustify }} labelText="Avec justification" onChange={props.handleChecked} />
                            }
                            <span onClick={() => props.handleDelete(index)}>
                                <BtnAction className="" iconBtn={DeletSvg} />
                            </span>
                            <span>
                                <BtnAction className="leftM-8 curs-move" iconBtn={DragSvg} />
                            </span>
                        </div>
                    </li>
                )
            })}
        </ul >

    )
};
export { DragToReorderList }
/*<li
                    key={index}
                    data-position={index}
                    draggable
                    onDragStart={onDragStart}
                    onDragOver={onDragOver}
                    onDrop={onDrop}

                    onDragLeave={onDragLeave}
                    className={dragAndDrop && dragAndDrop.draggedTo=== Number(index) ? "dropArea" : ""}
                >
                    <textarea data-index={item.index} name="qestions" placeholder="La description de question" onChange={props.handleChangeQestion} className="sizeF-1em" style={{height:"23px",width:"600px"}} value={item.text}></textarea>
                    <i className="sTcrmvL">{CloseSvg}</i>
                </li>*/