import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Translate,FormatDate} from '../../../../../utils'
import {testActions,alertActions} from '../../../../../actions'
import {testsConstants} from '../../../../../constants'
import {InputForm,BtnClick,SelectSearch,InlineElemnt,ImageViewer,CloseSvg, Loader,InfoTable} from '../../../../_Common'
class PreviewEvaluator extends Component{
    constructor(props){
        super(props)
        this.handleNext=this.handleNext.bind(this)
    }
    componentWillReceiveProps(nextProps){
        const {StoreStep,addTesteval,dataTestEval,close,actions}=nextProps
        if(this.props.addTesteval==="0" && addTesteval==="1" && dataTestEval){
            this.props.SendAlert("Opération réussie","success")
            StoreStep("none",testsConstants.REMOVE_PROCESS_TEST)
            actions()
            close(undefined)
        }
        if(this.props.addTesteval==="0" && addTesteval==="2"){
            this.props.SendAlert("Erreur lors de la création de test , esseyer plus tard","error")
        }
    }
    handleNext(){
        const {dataGrille,dataTime,dataUsers}=this.props
        if(dataGrille && dataTime && Array.isArray(dataUsers)){
            var evalua=dataUsers.map(evalu=>{return {evaluator_id:evalu.evaluator.id,tablette_id:evalu.tablette.tablette_id}})
            let test={
                dateautotest:dataTime,
                auto_evaluation_id:dataGrille.auto_evaluation_id,
                evaluators:evalua
            }
            this.props.TestEvaluateur(test)
        } 
    }
    render(){
        const {dataGrille,dataTime,dataUsers,addTesteval}=this.props
        let evalu=[]
        if(Array.isArray(dataUsers)){
            evalu=dataUsers.map(user=>{return [user.evaluator.fullname,user.tablette.color]})
        }
        return(
            <div className="hg-100-200 leftM-auto righttM-auto" style={{width:"68%"}}>
                {addTesteval==="0" && <Loader/>}
                <div>
                    <strong className="linhgth sizeF-12em lineHg-35">Date</strong>
                    <div>{FormatDate(dataTime)}</div>
                    <hr/>
                    <strong className="linhgth sizeF-12em lineHg-35">Formulaire</strong>
                    <div>{dataGrille.title_auto_evaluation}</div>
                    <hr/>
                    <strong className="linhgth sizeF-12em lineHg-35">Évaluateur</strong>
                    <div>
                    <InfoTable
                        thead={[Translate('test','nmevalu'),Translate('tablette','color')]}
                        tbody={evalu}
                    />   
                    </div> 
                </div>
                <div className="dis-flex content-center topM-22">
                    <BtnClick
                        onClick={this.handleNext}
                        TxtBtn="Créer"
                        Color="bdg-onrg clr-white leftM-8"
                    />
                </div>
            </div>
        )
    }
}
function mapState(state){
    const {dataGrille,dataTime,dataUsers,addTesteval,dataTestEval}=state.tests
    return {dataGrille,dataTime,dataUsers,addTesteval,dataTestEval}
}
const Actions={
    StoreStep:testActions.StoreStep,
    TestEvaluateur:testActions.TestEvaluateur,
    SendAlert:alertActions.SendAlert
}
const ConnectedClass=connect(mapState,Actions)(PreviewEvaluator)
export {ConnectedClass as PreviewEvaluator}