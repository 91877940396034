import { evaluatorConstants } from '../constants';
export function evaluator(state , action) {
    switch (action.type) {
        case evaluatorConstants.ADD_EVALUATOR_REQUEST:
            return {
                ...state,
                addingevalu:"0",
                dataevalu: action.payload
            };
        case evaluatorConstants.ADD_EVALUATOR_SUCCESS:
            return {
                addingevalu:"1",
                dataevalu: action.payload
            };
        case evaluatorConstants.ADD_EVALUATOR_FAILURE:
            return {
                ...state,
                addingevalu:"2",
                dataevalu: action.payload
            };
        case evaluatorConstants.UPDATE_EVALUATOR_REQUEST:
            return {
                ...state,
                updatingeval:"0",
                dataevalu: action.payload
            };
        case evaluatorConstants.UPDATE_EVALUATOR_SUCCESS:
            return {
                updatingeval:"1",
                dataevalu: action.payload
            };
        case evaluatorConstants.UPDATE_EVALUATOR_FAILURE:
            return {
                ...state,
                updatingeval:"2",
                dataevalu: action.payload
            };
        case evaluatorConstants.DELETE_EVALUATOR_REQUEST:
            return {
                ...state,
                deletingEvalu:"0",
                dataDeleteEval: action.payload
            };
        case evaluatorConstants.DELETE_EVALUATOR_SUCCESS:
            return {
                deletingEvalu:"1",
                dataDeleteEval: action.payload
            };
        case evaluatorConstants.DELETE_EVALUATOR_FAILURE:
            return {
                ...state,
                deletingEvalu:"2",
                dataDeleteEval: action.payload
            };
        case evaluatorConstants.GET_EVALUATOR_REQUEST:
            return {
                getiEvalu:"0",
                dataEvalu: action.payload
            };
        case evaluatorConstants.GET_EVALUATOR_SUCCESS:
            return {
                getiEvalu:"1",
                dataEvalu: action.payload
            };
        case evaluatorConstants.GET_EVALUATOR_FAILURE:
            return {
                getiEvalu:"2",
                dataEvalu: action.payload
            };
        default:
            return {...state}
        }
}