import React ,{ Component, Fragment } from 'react'
import {InlineElemnt} from './InlineElemnt'
import {DownArrowSvg} from './IconSvg'
export class DropList extends Component{
    constructor(props){
        super(props)
        this.state={
            viewElem:false,
            selected:this.props.default
        }
        this.handleDown=this.handleDown.bind(this);
        this.handleOutside=this.handleOutside.bind(this)
        this.outListRef=React.createRef();
    }
    componentWillUnmount(){
        document.removeEventListener("mousedown",this.handleOutside)
    }
    handleDown(){
        let current=this.state.viewElem
        if(current){
            document.removeEventListener("mousedown",this.handleOutside)
        }else{
            document.addEventListener("mousedown",this.handleOutside)
        }
        this.setState({
            viewElem:!current
        })
    }
    setValue(elem){
        this.props.onChange(elem)
        this.setState({
            selected:elem
        })
        this.handleDown()
    }
    handleOutside(e){
        if (this.outListRef && !this.outListRef.current.contains(e.target)) {
            this.handleDown()
         }
    }
    render(){
        return (
            <div style={this.props.style} className={(this.props.className?this.props.className :"")+" box-border pos-relative"} ref={this.outListRef}>
                <div className="pd-10-15 curs-pointer onHvr brd-equ rad-5" onClick={this.handleDown}>
                    <InlineElemnt
                        first={this.state.selected ?this.state.selected.text :this.props.placeholder}
                        secend={DownArrowSvg}   
                        classSecend="leftM-auto" 
                    />
                </div>
                <div className={(this.state.viewElem ? "box-def rad-5 top-null brd-equ" : "")+" index-5 trans-cub pos-absolute width-100 HdOvrfl bdg-white"}>
                {this.state.viewElem && this.props.items && this.props.items.map((item,key)=>{
                    return <div key={key} className="pd-10-15 onHvr curs-pointer brd-btm" onClick={() => this.setValue(item)}>{item.text}</div>
                })}
                </div>
            </div>
        )
    }
}