export const testsConstants = {
    SET_TIME_TEST: "SET_TIME_TEST_SELF",
    SET_TIME_TEST_EVAL: "SET_TIME_TEST_EVAL",
    SET_SELF_TIME_TEST: "SET_SELF_TIME_TEST",
    SET_SELF_GRILLE_TEST: "SET_SELF_GRILLE_TEST",
    SET_GRILE_TEST: "SET_GRILE_TEST_SELF",
    SET_USERS_TEST: "SET_USERS_TEST_SELF",
    SET_EVALUATOR_TABLETTE_TEST: "SET_EVALUATOR_TABLETTE_TEST",
    REMOVE_PROCESS_TEST: "REMOVE_PROCESS_TEST_SELF",
    STORE_AFFECTATION_EVALUATOR:"STORE_AFFECTATION_EVALUATOR",
    GO_TO_STEP: "GO_TO_STEP_TEST_SELF"
}