import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Translate} from '../../../../../utils'
import {testActions,testknowActions} from '../../../../../actions'
import {testsConstants} from '../../../../../constants'
import {InputForm,BtnClick,SelectSearch,InlineElemnt,ImageViewer,CloseSvg} from '../../../../_Common'
class SelectParticipant extends Component{
    constructor(props){
        super(props)
        this.state={
            griletitle:null,
            selectedUser:[]
        }
        this.HandleChange=this.HandleChange.bind(this)
        this.handleNext=this.handleNext.bind(this)
    }
    componentWillReceiveProps(nextProps){
        const {affecTest,dataAffect}=nextProps
        if(this.props.affecTest==="0" && affecTest==="1" && dataAffect && dataAffect.data && dataAffect.data.message==="affectation succsessful"){
            this.props.StoreStep(this.state.selectedUser,testsConstants.SET_USERS_TEST)
        }
    }
    HandleChange(user){
        let current=this.state.selectedUser
        if(!current.find(use=>use.id===user.id)){
            current.push(user)
            this.setState({
                selectedUser:current
            })
        }
        
    }
    removeSelect(key){
        let crntelem=this.state.selectedUser
        crntelem.splice(key,1)
        this.setState({
            selectedUser:crntelem
        })
    }
    handleNext(){
        if(this.state.selectedUser.length>0){
            const {addingTest,dataTest,AffUserTest}=this.props
            if(addingTest==="1" && dataTest){
                let candidate=this.state.selectedUser.map(user=>user.id)
                AffUserTest({candidatIds:candidate},dataTest.data.toAdd.id)
            }
        } 
    }
    render(){
        let griles=[{id:4,fullName:"zino khedri",picture:null},{id:5,fullName:"hello khedri",picture:null},{id:6,fullName:"test candidate",picture:null},{id:6,fullName:"just a candidate",picture:null},{id:7,fullName:"candidate test",picture:null},{id:8,fullName:"candidate moi",picture:null},{id:9,fullName:"le candidate numéro 9",picture:null}]
        if(this.state.selectedUser.length>0){
            griles=griles.filter(user=>!this.state.selectedUser.find(tmp=>tmp.id==user.id))
        }
        return(
            <div className="hg-100-200 leftM-auto righttM-auto" style={{width:"68%"}}>
                <div>
                    <span className="linhgth">{Translate('test','cndSelect')}</span>
                    <div className="dis-flex stMrgv-tb flx-warp">
                        {this.state.selectedUser.length>0 ? this.state.selectedUser.map((user,key)=>{
                            return <InlineElemnt
                                        key={key}
                                        className="brd-equ rad-5 sm-padding mr-2 mtb-5"
                                        first={<ImageViewer picture={user.picture} size={30} name={user.fullName}/>}
                                        secend={user.fullName}
                                        more={<i className="dis-flex leftM-8 curs-pointer" onClick={() =>this.removeSelect(key)}>{CloseSvg}</i>}
                                    />
                        }) :
                        <strong className="color-org">Selectioner des participant</strong>}
                    </div>
                    <hr/>
                    <span className="linhgth">{Translate('test','serchuser')}</span>
                    <div className="dis-flex topM-8">
                        <SelectSearch field="fullName" maxHieght="100%" placeholder="Rechercher un utilisateur selon le nom ..." hideOnChose={false} value={this.state.griletitle} datalist={griles} handleSelect={this.HandleChange}/>
                    </div>
                </div>
                <div className="dis-flex content-center topM-22">
                    <BtnClick
                        onClick={this.handleNext}
                        TxtBtn={Translate('test','nextstep')}
                        Color="bdg-onrg clr-white leftM-8"
                    />
                </div>
            </div>
        )
    }
}
function mapState(state){
    const {dataGrile}=state.tests
    const {affecTest,dataAffect,addingTest,dataTest}=state.testKnow
    return {dataGrile,affecTest,dataAffect,addingTest,dataTest}
}
const Actions={
    StoreStep:testActions.StoreStep,
    AffUserTest:testknowActions.AffUserTest
}
const ConnectedClass=connect(mapState,Actions)(SelectParticipant)
export {ConnectedClass as SelectParticipant}