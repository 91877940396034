import {messagingConstants} from "../constants";

export function messaging(state,action) {
    switch(action.type) {
        case messagingConstants.FETCH_LIST_USERS_REQESUT:
            return {
                ...state,
                getingUsers: 0
            }
        case messagingConstants.FETCH_LIST_USERS_SUCCESS:
            const {users=[]}=action.payload
            return {
                ...state,
                getingUsers: 1,
                dataUsersList: users
            }
        case messagingConstants.FETCH_LIST_USERS_FAILEUR:
            return {
                ...state,
                getingUsers: 2
            }
        /************************** */
        case messagingConstants.FETCH_NOTIFICATION_MSGS_SUCCESS:
            return {
                ...state,
                notificationall: action.payload.notifcationchannelsall,
                notifcationchannelsone: action.payload.notifcationchannelsone
            }
        case messagingConstants.FETCH_NOTIFICATION_MSGS_FAILEUR:
            return {
                ...state,
                notificationall:0,
                notifcationchannelsone:[]
            }
        /************************** */
        case messagingConstants.SYNC_MESSAGES_CHANNEL_REQESUT:
            return {
                ...state,
                syncMessageChannel: 0
            }
        case messagingConstants.SYNC_MESSAGES_CHANNEL_SUCCESS:
            const {messages=[],user: {picture_path,full_name}}=action.payload
            return {
                ...state,
                syncMessageChannel: 1,
                messagesChannel: messages,
                pictureChannel: picture_path||"/assets/img/default.png",
                nameChannel: full_name
            }
        case messagingConstants.SYNC_MESSAGES_CHANNEL_FAILEUR:
            return {
                ...state,
                syncMessageChannel: 2
            }
        /**************** */
        case messagingConstants.SET_MESSAGES_CHANNEL:
            const {message}=action.payload
            return {
                ...state,
                messagesChannel: [...state.messagesChannel,message],
            }
        /******************* */
        default:
            return {
                dataUsersList: [],
                messagesChannel: [],
                ...state
            }
    }
}