import React, { Component } from 'react'
import { connect } from 'react-redux'
import { DocsSvg } from '../../../_Common'
import { FormatDate } from '../../../../utils'
import { ElementGrille } from '../utils'
class ListGrille extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            this.props.dateGrilles.map((grille, key) => {
                let user = {}
                if (grille.user) {
                    user = grille.user
                }
                return <ElementGrille key={key} duplicateGrille={() => this.props.duplicateGrille(key)} actionSHow={() => this.props.onViewDetail(grille.auto_evaluation_id)} iconDoc={DocsSvg} titleGrile={grille.title_auto_evaluation} lables={grille.label_auto_test} date={FormatDate(grille.created_at, true)} firstName={user.firstname} lastName={user.lastname} deleteGrille={() => this.props.deleteGrille(key)} />
            })
        )
    }
}
function mapState(state) {
    return {}
}
const connectedListGrille = connect(mapState)(ListGrille);
export { connectedListGrille as ListGrille };