import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate } from '../../../utils'
import { candidateActions, alertActions } from '../../../actions'
import { PopTab, InputForm, BtnSubmit, Loader } from '../../_Common'
import XLSX from 'xlsx';
class ImportCandidate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataFile: null
        }
        this.readxlsfile = this.readxlsfile.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    componentWillReceiveProps(nextProps) {
        const { importingCand, dataImport } = nextProps
        if (this.props.importingCand === "0" && importingCand === "1" && dataImport) {
            this.props.SendAlert('Opération réussie', "success")
            this.props.getCandidate(nextProps.filters)
            this.props.close(undefined)
        }
        if (this.props.importingCand === "0" && importingCand === "2") {
            this.props.SendAlert("échec de l'importation réessayer plus tard", "error")
        }
    }
    handleSubmit(e) {
        e.preventDefault()
        let list = {
            list: this.state.dataFile
        }
        this.props.importCandidates(list)
    }
    readxlsfile(e) {
        var reader = new FileReader();
        var bindthis = this
        reader.readAsArrayBuffer(e.target.files[0]);
        reader.onload = function () {
            var data = new Uint8Array(reader.result);
            var arr = new Array();
            for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
            var DataBook = arr.join("");
            var workbook = XLSX.read(DataBook, {
                type: "binary"
            });
            var first_sheet_name = workbook.SheetNames[0];

            var worksheet = workbook.Sheets[first_sheet_name];
            var JsonData = XLSX.utils.sheet_to_json(worksheet)
            bindthis.setState({
                dataFile: JsonData
            })
        }
    }
    render() {
        const { importingCand, dataImport } = this.props
        return (
            <PopTab isActive={true} TabTitle="Importer les candidats" close={() => this.props.close(undefined)} width="250px">
                {importingCand === "0" && <Loader />}
                <p>Pour importer des candidats veuillez suivre <a href="/model_candidat_excel.xlsx" className="color-org" download>ce modèle.</a></p>
                <form onSubmit={this.handleSubmit}>
                    <InputForm
                        placeholder={Translate('tablette', 'number')}
                        name="File_Import_excel"
                        id="File_Import_excel"
                        type="file"
                        onChange={this.readxlsfile}
                    />
                    <div className="dis-flex leftM-auto sTrgPdn sTmr-si">
                        <BtnSubmit
                            TxtBtn={"Importer"}
                            Color="width-100 bdg-onrg clr-white"
                        />
                    </div>
                </form>
            </PopTab>
        )
    }
}
function mapState(state) {
    const { importingCand, dataImport } = state.candidate
    return { importingCand, dataImport }
}
const actionsClass = {
    importCandidates: candidateActions.importCandidates,
    getCandidate: candidateActions.getCandidate,
    SendAlert: alertActions.SendAlert
}
const connectedClass = connect(mapState, actionsClass)(ImportCandidate);
export { connectedClass as ImportCandidate };