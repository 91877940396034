import React, { Component, createRef } from 'react'
import { Editor } from '@tinymce/tinymce-react';
import {BtnClick, InputForm, Loader, PopTab} from '../../../_Common'
import { Translate } from '../../../../utils';
import { toast } from 'react-toastify';
import { mailsActions,mediaActions } from '../../../../actions';
class MailEditor extends Component {
    state={
        refEditor:createRef(null),
        content:'',
        email:'',
        loading:false,
        handleTest:false,
        variables:[
            {name:'firstname',value:'Prénom (firstname)'},
            {name:'lastname',value:'Nom (lastname)'},
            {name:'email',value:'Email (email)'},
            {name:'password',value:'Mot de pass (password)'},
            {name:'date',value:'Date JTA (date)'},
            {name:'start',value:'Heure début JTA (start)'},
            {name:'end',value:'Heure find JTA (end)'},
            {name:'meet',value:'Lien distance (meet)'}
        ],
        menuBtns:[
            {name:'varaibles',value:'Insérer variable',content:(data)=>{
                return `{{$${data}}}`
            }},
            {name:'conditions',value:'Insérer condition',content:(data)=>{
                return `@if($${data}) <div></div> @endif`
            }}
        ]
    }
    componentDidUpdate(prevProps){
        if(prevProps.typeMail!==this.props.typeMail){
            this.fetchEmailContent()
        }
    }
    componentDidMount(){
        this.fetchEmailContent()
    }
    fetchEmailContent = (e) =>{
        this.setState({
            loading:true
        })
        mailsActions.FetchMailTemplate(this.props.typeMail)
        .then(({data={}})=>{
            if(data.status==='done'){
                this.state.refEditor.current.setContent(data.content)
            }
        })
        .catch(err=>{

        })
        .finally(e=>{
            this.setState({
                loading:false
            })
        })
    }
    
    handleSubmit = () =>{
        this.setState({
            loading:true
        })
        mailsActions.SaveTemplate({content:this.state.refEditor.current.getContent()},this.props.typeMail)
        .then(({data={}})=>{
            if(data.status==='done'){
                toast.success('La template a été modifié avec succès')
            }
        })
        .catch(err=>{

        })
        .finally(e=>{
            this.setState({
                loading:false
            })
        })
    }
    handleTestTemplate = () =>{
        this.setState({
            handleTest:!this.state.handleTest
        })
    }
    handleSubmitTest = (e) =>{
        this.setState({
            handleTest:false
        })
        mailsActions.TestTemplate({email:this.state.email},this.props.typeMail)
    }
    handleChange = (e) =>{
        const {name,value}=e.target
        this.setState({
            [name]:value
        })
    }
    render(){
        return(
            <>
                {this.state.loading && <Loader/>}
                <div className="dis-flex item-center content-btwn" style={{marginBottom:'14px'}}>
                    <h3 className="margin-null">Modifier l'{Translate('setting', this.props.typeMail)}</h3>
                    <div>
                        <BtnClick
                            TxtBtn="Tester le template"
                            Color="bdg-onrg-op color-org"
                            onClick={this.handleTestTemplate}
                        />
                        <BtnClick
                            TxtBtn="Sauvegarder"
                            Color="bdg-onrg clr-white leftM-8"
                            onClick={this.handleSubmit}
                        />
                    </div>
                </div>
                <Editor
                    onInit={(evt, editor) => this.state.refEditor.current = editor}
                    initialValue={this.state.content}
                    apiKey={process.env.REACT_APP_API_KEY_EDITOR}
                    textareaName="body"
                    init={{
                        height: 650,
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar: ['undo redo | image | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                        'varaibles conditions styleselect fontselect fontsizeselect'],
                        image_title: true,
                        automatic_uploads: true,
                        file_picker_types: 'image',
                        file_picker_callback: (cb, value, meta) => {
                            var input = document.createElement('input');
                            input.setAttribute('type', 'file');
                            input.setAttribute('accept', 'image/*');
                            input.onchange = function() {
                                var file = this.files[0];
                                const data = new FormData() 
                                data.append('media', file)
                                mediaActions.UploadFile(data,'mails')
                                .then(({data={}})=>{
                                    if(data.status==="done"){
                                        cb(data.image, { title: file.name }); 
                                    }else{
                                        cb('', { title: file.name }); 
                                    }
                                })
                                .catch(err=>{
                                    cb('', { title: file.name });
                                })
                            };
                            input.click();
                        },
                        content_style: 'body { font-family:Poppins,sans-serif; font-size:14px }',
                        language:'fr_FR',
                        setup:  (editor) => {
                            this.state.menuBtns.forEach(menu => {
                                editor.ui.registry.addMenuButton(menu.name, {
                                    text: menu.value,
                                    fetch: (callback) => {
                                        var items=this.state.variables.map(element => {
                                            return {
                                                type: 'menuitem',
                                                text: element.value,                                    
                                                onAction: function () {
                                                    editor.insertContent(menu.content(element.name));
                                                }
                                            }
                                        });
                                        callback(items);
                                    }
                                });
                            });
                        }
                    }}
                    onChange={this.handleChange}
                />
                {this.state.handleTest && <PopTab isActive={this.state.handleTest} TabTitle={`Envoyer un ${Translate('setting', this.props.typeMail)}`} close={this.handleTestTemplate} width="500px">
                    <div>
                        <InputForm
                            className="rightM-8"
                            placeholder={Translate('fields','email')}
                            name="email"
                            type="email"
                            onChange={this.handleChange}
                            value={this.state.email}
                        />
                    </div>
                    <div className="dis-flex content-center topM-15" style={{marginBottom:8}}>
                        <BtnClick
                            onClick={this.handleTestTemplate}
                            TxtBtn="Annuler"
                            style={{ width: "35%" }}
                            Color="leRgM-15 bdg-onrg-op color-org"
                        />
                        <BtnClick
                            onClick={this.handleSubmitTest}
                            style={{ width: "35%" }}
                            TxtBtn="Envoyer"
                            Color="leRgM-15 width-100 bdg-onrg clr-white"
                        />
                    </div>
                </PopTab>}
            </>
        )
    }
}
export {MailEditor}