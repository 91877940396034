export const userConstants = {
    LOGIN_SUCCESS: 'LOGIN_USER_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_USER_FAILURE',
    LOGIN_REQUEST: 'LOGIN_USER_REQUEST',

    UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_USER_SUCCESS',
    UPDATE_PASSWORD_FAILURE: 'UPDATE_PASSWORD_USER_FAILURE',
    UPDATE_PASSWORD_REQUEST: 'UPDATE_PASSWORD_USER_REQUEST',

    LOGOUT_SUCCESS: 'LOGOUT_USER_SUCCESS',
    LOGOUT_FAILURE: 'LOGOUT_USER_FAILURE',
    LOGOUT_REQUEST: 'LOGOUT_USER_REQUEST',

    GET_USER_SUCCESS: 'GET_USER_SUCCESS',
    GET_USER_FAILURE: 'GET_USER_FAILURE',
    GET_USER_REQUEST: 'GET_USER_REQUEST',
};