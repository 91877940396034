import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import {createLogger } from 'redux-logger'
import rootReducer from '../reducers';
const LogerModdleware=createLogger()
const midlleware= process.env.NODE_ENV!=="production"? applyMiddleware(
    thunkMiddleware,
    LogerModdleware
):applyMiddleware(
    thunkMiddleware
)
export const store = createStore(
    rootReducer,
    midlleware
);
