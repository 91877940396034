import React, { Component } from 'react'
import { connect } from 'react-redux'
import {adminActions,alertActions} from '../../../actions'
import {Translate} from '../../../utils'
import {PopTab,InputForm,BtnSubmit,SelectForm,Loader} from '../../_Common'
class AddAdmin extends Component{
    constructor(props){
        super(props)
        this.state={
            firstname:props.default.firstname,
            lastname:props.default.lastname,
            email:props.default.email,
            phone:props.default.phone
        }
        this.HandleChange=this.HandleChange.bind(this)
        this.handleSubmit=this.handleSubmit.bind(this)
    }
    componentWillReceiveProps(nextProps){
        const {dataadm,addingadm,close,getAdmin,updatingAdmin}=nextProps
        if(this.props.addingadm==="0" &&  addingadm==="1" && dataadm){
            getAdmin()
            close(undefined)
            this.props.SendAlert("Opération réussie","success")
        }
        if(this.props.updatingAdmin==="0" &&  updatingAdmin==="1" && dataadm){
            getAdmin()
            close(undefined)
            this.props.SendAlert("Opération réussie","success")
        }
        if(this.props.addingadm==="0" && addingadm==="2" && dataadm){
            this.props.SendAlert("Vérifier les informations de l'admin ou esseyer plus tard","error")
        }
    }
    HandleChange(e){
        const {name,value}=e.target
        this.setState({
            [name]:value
        })
    }
    handleSubmit(e){
        e.preventDefault()
        let admin={
            email:this.state.email,
            firstname:this.state.firstname,
            lastname:this.state.lastname,
            phone:this.state.phone,
        }
        if(this.props.active==="add"){
            this.props.AddAdmin(admin)
        }else{
            admin.iduser=this.props.active
            this.props.updateAdmin(admin)
        }
        
    }
    render(){
        const {addingadm,updatingAdmin}=this.props
        let error=false
        return(
            <PopTab isActive={this.props.active} TabTitle={Translate('admin','newadmintext')} close={() => this.props.close(undefined)} width="550px">
                {(addingadm==="0" || updatingAdmin==="0") && <Loader/>}
                <form onSubmit={this.handleSubmit}>
                    <h4 className="clr-grey sTmr-si">{Translate('evaluateur','accountinfo')}</h4>
                    <div className="dis-flex">
                        <InputForm
                            className="rightM-8"
                            placeholder={Translate('fields','lastname')}
                            name="firstname"
                            type="text"
                            onChange={this.HandleChange}
                            value={this.state.firstname}
                        />
                        <InputForm
                            placeholder={Translate('fields','firstname')}
                            name="lastname"
                            type="text"
                            onChange={this.HandleChange}
                            value={this.state.lastname}
                        />
                    </div>
                    <div className="dis-flex topM-8">
                        <InputForm
                            className="rightM-8"
                            placeholder={Translate('fields','email')}
                            name="email"
                            type="email"
                            onChange={this.HandleChange}
                            value={this.state.email}
                        />
                        <InputForm
                            placeholder={Translate('fields','phone')}
                            name="phone"
                            type="phone"
                            onChange={this.HandleChange}
                            value={this.state.phone}
                        />
                    </div>
                    <div className="dis-flex leftM-auto sTrgPdn sTmr-si">
                        <BtnSubmit
                            TxtBtn={this.props.active==="add" ? Translate('admin','ceerladmin'):Translate('admin','updatadmin')}
                            Color="width-100 bdg-onrg clr-white"
                        />
                    </div>
                </form>
            </PopTab>
        )
    }
}
function mapState(state){
    const {dataadm,addingadm,updatingAdmin}=state.admin
    return {dataadm,addingadm,updatingAdmin}
}
const Actions={
    AddAdmin:adminActions.AddAdmin,
    getAdmin:adminActions.getAdmin,
    updateAdmin:adminActions.updateAdmin,
    SendAlert:alertActions.SendAlert
}
const connectedClass=connect(mapState,Actions)(AddAdmin)
export {connectedClass as AddAdmin}