import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate } from '../../../../../utils'
import { testActions, evaluTestActions, evaluatorActions, tabletActions, candidateActions, evalGrilleActions, groupeActions } from '../../../../../actions'
import { testsConstants } from '../../../../../constants'
import { BtnClick, SelectSearch, Loader } from '../../../../_Common'
import { SelectEvaluatorTablete } from './EvaluationSteps'
class AffectationEvaluateur extends Component {
    constructor(props) {
        super(props)
        this.state = {
            step: 0,
            dataevaluation: Array.isArray(props.dataEvaluator) ? props.dataEvaluator : [],
            boards: { columns: [] }
        }
        if (props.getEvalEva !== "1") {
            props.getEvaGrilleEva()
        }
        if (props.getiEvalu !== "1") {
            props.getEvaluator()
        }
        if (props.getiTablet !== "1") {
            props.getTablet()
        }
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.affectingGrp === 0 && nextProps.affectingGrp === 1 && nextProps.dataAffectation.ok) {
            console.log(nextProps.dataAffectation.affectations)
            this.setState({
                step: 1,
                boards: { columns: nextProps.dataAffectation.affectations }
            })
        }
    }
    handleAffect = (evalu, tablete) => {
        if (evalu && tablete) {
            let current = this.props.dataAffectationEvalu
            current.push({
                evaluator_id: evalu.id,
                tablette_id: tablete.tablette_id,
                evalu_name: evalu.fullname,
                table_color: tablete.color,
                color_code: tablete.color_code
            })
            this.props.StoreStep(current, testsConstants.STORE_AFFECTATION_EVALUATOR)
            this.setState(this.state)
        }
    }
    handleDeleteItem = (key) => {
        var current = this.props.dataAffectationEvalu
        current.splice(key, 1);
        this.props.StoreStep(current, testsConstants.STORE_AFFECTATION_EVALUATOR)
        this.setState(this.state)
    }
    HandleChangeGride = (grid) => {
        this.setState({
            grille: grid
        })
    }
    gobackStep = () => {
        this.setState({
            step: 0
        })
    }
    handleNext = () => {
        this.props.StoreStep(2, testsConstants.GO_TO_STEP)
    }
    render(){
        return(
            <div className="hg-100-200 leftM-auto righttM-auto" style={{ width: "90%" }}>
                <SelectEvaluatorTablete AddJta={this.props.AddJta} handleDeleteItem={this.handleDeleteItem} dataevaluation={this.props.dataAffectationEvalu} HandleChangeEvalu={this.HandleChangeEvalu} handleAffect={this.handleAffect} getiEvalu={this.props.getiEvalu} dataEvalu={this.props.dataEvalu} getiTablet={this.props.getiTablet} dataTabelt={this.props.dataTabelt} handleNext={this.handleNext} />
            </div>
        )
    }
}
function mapState(state) {
    const { dataGrile, dataTime, datagrileEval, dataEvaluator, dataGroupe,dataAffectationEvalu } = state.tests
    const { addingTest, dataTest } = state.evaluTest
    const { getiTablet, dataTabelt } = state.tablet
    const { getiEvalu, dataEvalu } = state.evaluator
    const { getEvalEva, dataEvalEva } = state.grilleEvalu
    const { affectingGrp, dataAffectation } = state.groupe
    return { dataGrile, addingTest, dataTabelt, dataEvalu, dataTest, dataTime, datagrileEval, dataEvaluator, getiEvalu, getiTablet, dataGroupe, getEvalEva, dataEvalEva, affectingGrp, dataAffectation,dataAffectationEvalu }
}
const Actions = {
    StoreStep: testActions.StoreStep,
    getGroupeDetail: candidateActions.getGroupeDetail,
    getEvaGrilleEva: evalGrilleActions.getEvaGrilleEva,
    confirmCandidate: candidateActions.confirmCandidate,
    getEvaluator: evaluatorActions.getEvaluator,
    getTablet: tabletActions.getTablet,
    requestAffectation: groupeActions.requestAffectation
}
const ConnectedClass = connect(mapState, Actions)(AffectationEvaluateur)
export { ConnectedClass as AffectationEvaluateur }