import React,{Component} from 'react'
import {LoginPage} from './LoginPage'
import {
    Route,
    Switch,
    Redirect
  } from 'react-router-dom';

class IndexAuth extends Component{
    render(){
        return(
            <div className="pos-fixed width-100 height-100 top-0 left-0 bdg-default">
                <div className="dis-flex item-center content-center width-100 height-100">
                    <Switch>
                        <Route path="/login" component={LoginPage}/>
                        <Redirect from="*" to="/login"/>
                    </Switch>
                </div>
            </div>
        )
    }
}
export {IndexAuth}