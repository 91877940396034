import React, { Component } from 'react'
import { connect } from 'react-redux'
import { evaluatorActions } from '../../../actions'
import { Translate, FormatDate } from '../../../utils'
import { ContainerPages, ButtonLink, InfoTable, Loader, DeleteSvg, EditSvg, BtnActions, RefreshSvg, ButtonCLick, PopTab, BtnClick } from '../../_Common'
import PropTypes from "prop-types";
import {
    addUrlProps,
    UrlQueryParamTypes
} from "react-url-query";
import { AddEvaluator } from './AddEvaluator'
const urlPropsQueryConfig = {
    tab: { type: UrlQueryParamTypes.string }
};
class Evaluator extends Component {
    constructor(props) {
        super(props)
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            username: "",
            password: "",
            birth: null,
            handleTab: false
        }
        if (this.props.getiEvalu !== "1") {
            this.props.getEvaluator()
        }
        this.handleDelet = this.handleDelet.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleRefresh = this.handleRefresh.bind(this)
    }
    static defaultProps = {
        tab: ''
    }
    static propTypes = {
        tab: PropTypes.string,
        /********************** */
        onChangeTab: PropTypes.func,
    };
    componentDidMount() {
        document.title = Translate('titles', 'app') + " - " + Translate('sidebar', 'evaluateur')
    }
    componentWillReceiveProps(nextProps) {
        const { deletingEvalu, dataDeleteEval } = nextProps
        if (this.props.deletingEvalu === "0" && deletingEvalu === "1" && dataDeleteEval) {
            this.state.handleTab = false
            this.props.getEvaluator()
        }
    }
    handleRefresh() {
        this.props.getEvaluator()
    }
    handleDelet(idEvalu) {
        if (!this.state.handleTab) {
            this.setState({
                handleTab: idEvalu
            })
        } else {
            let evalu = {
                idUser: this.state.handleTab
            }
            this.props.deleteEvalu(evalu)
        }
    }
    handleClose() {
        this.setState({
            handleTab: false
        })
    }
    render() {
        const { getiEvalu, dataEvalu, deletingEvalu } = this.props
        var evaluators = []
        var evalu = {}
        var todelete = {}
        var lengthData = ''
        if (getiEvalu === "1" && dataEvalu && Array.isArray(dataEvalu.evaluators)) {
            evaluators = dataEvalu.evaluators.map(evalu => {
                return [evalu.lastname, evalu.firstname, evalu.phone, evalu.email, evalu.usename, evalu.evaluator_status, evalu.initpassword ? evalu.initpassword : Translate('fields', 'passwupda'), FormatDate(evalu.created_at, true), FormatDate(evalu.updated_at, true), <BtnActions btns={[{ icon: EditSvg, action: () => this.props.onChangeTab(evalu.id) }, { icon: DeleteSvg, action: () => this.handleDelet(evalu.id) }]} />]
            })
            lengthData = ' (' + dataEvalu.evaluators.length + ')'
            if (this.state.handleTab) {
                todelete = dataEvalu.evaluators.find(evalu => evalu.id == this.state.handleTab)
            }
            evalu = dataEvalu.evaluators.find(evalu => evalu.id == this.props.tab)
            if (!evalu) {
                evalu = {}
            }
        }
        return (
            <ContainerPages titlePage={Translate('sidebar', 'evaluateur') + lengthData} className="evaluateur_bdg" contentLeft={<div className="dis-flex">
                <ButtonCLick TextBottun={RefreshSvg} action={this.handleRefresh} />
                <ButtonLink TextBottun={Translate('evaluateur', 'addnew')} pathName="?tab=add" />
            </div>} StyleChildren={{ height: "calc(100% - 75px)" }}>
                {getiEvalu === "0" ? <Loader style={{ minHeight: "250px" }} /> : getiEvalu === "1" ?
                    <InfoTable
                        classSticky="fix_elem_top"
                        styleTop={{ height: "100%" }}
                        styleBody={{ background: "#ffffff9e" }}
                        thead={[Translate('fields', 'lastname'), Translate('fields', 'firstname'), Translate('fields', 'phone'), Translate('fields', 'email'), Translate('fields', 'username'), Translate('evaluateur', 'status'), Translate('fields', 'initpassw'), Translate('fields', 'datecreat'), Translate('fields', 'datemodif'), Translate('fields', 'action')]}
                        tbody={evaluators}
                    /> : ""}
                {this.props.tab && <AddEvaluator active={this.props.tab} default={evalu} close={this.props.onChangeTab} />}
                {this.state.handleTab && <PopTab isActive={this.state.handleTab} TabTitle={Translate('evaluateur', 'supevalu')} close={this.handleClose} width="470px">
                    {deletingEvalu === "0" && <Loader />}
                    <p>Vous êtes sur le point de supprimer <strong className="sizeF-12em txt-dng">{todelete.firstname + " " + todelete.lastname}</strong>. Cette action est irréversible ! </p>
                    <div className="dis-flex leftM-auto sTrgPdn sTmr-si">
                        <BtnClick
                            onClick={this.handleClose}
                            TxtBtn="Annuler"
                            style={{ width: "35%" }}
                            Color="leRgM-15 bdg-onrg-op color-org"
                        />
                        <BtnClick
                            onClick={this.handleDelet}
                            style={{ width: "55%" }}
                            TxtBtn="Confirmer la suppression"
                            Color="leRgM-15 width-100 bdg-dng clr-white"
                        />
                    </div>
                </PopTab>}
            </ContainerPages>
        )
    }
}
function mapState(state) {
    const { getiEvalu, dataEvalu, deletingEvalu, dataDeleteEval } = state.evaluator
    return { getiEvalu, dataEvalu, deletingEvalu, dataDeleteEval }
}
const Actions = {
    getEvaluator: evaluatorActions.getEvaluator,
    deleteEvalu: evaluatorActions.deleteEvalu
}
const connectedEvaluator = connect(mapState, Actions)(Evaluator);
const QueryProps = addUrlProps({ urlPropsQueryConfig })(connectedEvaluator)
export { QueryProps as Evaluator };