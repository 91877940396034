import React, { Component } from 'react'
import {Translate} from '../../../../utils'
import {connect} from 'react-redux'
import {testActions} from '../../../../actions'
import {testsConstants} from '../../../../constants'
import {PresonSvg,TestCSvg,CheckSvg,CalendarSvg} from '../../../_Common'
import {Link} from 'react-router-dom'
class TopTabCheck extends Component{
    handleStep(step){
        if(this.props.stepNumber>step){
            this.props.StoreStep(step,testsConstants.GO_TO_STEP)
        }
    }
    render(){
        const {stepNumber}=this.props
        return(
            <div className="pos-sticky bdg-white" style={{top:"22px"}}>
                <div className="dis-flex text-center content-center topM-22 tranFrAll">
                    <div className="st_prcid">
                        <div className="leftM-auto righttM-auto dis-flex pos-relative item-center width-mxcn">
                            <div onClick={()=>this.handleStep(0)} className={(stepNumber===0 ?  "activ_pr" : stepNumber>0 && "bdg-valid curs-pointer")+" prc_Wd-8 dis-flex content-center item-center rad-100 bdg-light"}>
                                <span className="st_Scal dis-flex">{CalendarSvg}</span>
                            </div>
                            <hr className="brd-none hg_lin_v st_Lf_vl wid-50 pos-absolute bdg-light"/>
                        </div>
                        <span className="paddTB-10 dis-block">{Translate('test','Planification')}</span>
                    </div>
                    <div className="st_prcid">
                        <div className="leftM-auto righttM-auto dis-flex pos-relative item-center width-mxcn">
                            <div onClick={()=>this.handleStep(1)} className={(stepNumber===1 ?  "activ_pr" : stepNumber>1 && "bdg-valid curs-pointer")+" prc_Wd-8 dis-flex content-center item-center rad-100 bdg-light"}>
                                <span className="dis-flex" style={{transform:'scale(1.3)'}}>{TestCSvg}</span>
                            </div>
                            <hr className="brd-none hg_lin_v st_Lf_vl wid-50 pos-absolute bdg-light"/>
                        </div>
                        <span className="paddTB-10 dis-block">{Translate('test','grileselect')}</span>
                    </div>
                    <div className="st_prcid">
                        <div className="leftM-auto righttM-auto dis-flex pos-relative item-center width-mxcn">
                            <div onClick={()=>this.handleStep(2)} className={(stepNumber===2 ?  "activ_pr" : stepNumber>2 && "bdg-valid curs-pointer")+" prc_Wd-8 dis-flex content-center item-center rad-100 bdg-light"}>
                                <span className="dis-flex">{PresonSvg}</span>
                            </div>
                            <hr className="brd-none hg_lin_v st_Lf_vl wid-50 pos-absolute bdg-light"/>
                        </div>
                        <span className="paddTB-10 dis-block">{Translate('test','partiSelect')}</span>
                    </div>
                    <div className="st_prcid">
                        <div className="leftM-auto righttM-auto dis-flex pos-relative item-center width-mxcn">
                            <div onClick={()=>this.handleStep(4)} className={(stepNumber===3 ?  "activ_pr" : stepNumber>3 && "bdg-valid curs-pointer")+" prc_Wd-8 dis-flex content-center item-center rad-100 bdg-light"}>
                                <span className="st_Scal dis-flex">{CheckSvg}</span>
                            </div>
                        </div>
                        <span className="paddTB-10 dis-block">{Translate('test','confirmtest')}</span>
                    </div>
                </div>
                <hr className="brd-none hg_lin_v bdg-light botM-25" style={{width:"55%"}}/>
            </div>
        )
    }
}
function mapState(state){
    const {dataGrile,stepNumber}=state.tests
    return {dataGrile,stepNumber}
}
const Actions={
    StoreStep:testActions.StoreStep
}
const ConnectedClass=connect(mapState,Actions)(TopTabCheck)
export {ConnectedClass as TopTabCheck}