import React, { Component } from 'react'
import { Translate, FormatDate } from '../../../utils'
import { exportsActions } from '../../../actions/exports.actions'
import { TableRows, BtnActions, ShowSvg, PDFSvg, DeletSvg } from '../../_Common'
class GroupePage extends Component {
    render() {
        // const cols = [
        //     { name: Translate('test', 'groupede'), rowKey: "groupename" },
        //     { name: Translate('fields', 'dateCreate'), rowKey: "created_at", render: created_at => FormatDate(created_at, true) },
        //     {
        //         name: Translate('fields', 'affichgroupe'), rowKey: "groupe_candidate_id", render: groupe_candidate_id => <BtnActions btns={
        //             [{ icon: ShowSvg, action: () => this.props.action(groupe_candidate_id) },
        //             { icon: PDFSvg, action: () => exportsActions.PresenceFiche({ groupe: groupe_candidate_id }) }
        //             ]} />
        //     },
        //     {
        //         name: 'Supprimer', rowKey: "remove", renderRemove: (groupData, Disabled) => <BtnActions btns={
        //             [
        //                 { icon: DeletSvg, Disabled, action: () => this.props.deleteGroupe(groupData) },
        //             ]} />
        //     }
        // ]
        const cols =
            this.props.showDeleteGroup ?
                [
                    { name: Translate('test', 'groupede'), rowKey: "groupename" },
                    { name: Translate('fields', 'dateCreate'), rowKey: "created_at", render: created_at => FormatDate(created_at, true) },
                    {
                        name: Translate('fields', 'affichgroupe'), rowKey: "groupe_candidate_id", render: groupe_candidate_id => <BtnActions btns={
                            [{ icon: ShowSvg, action: () => this.props.action(groupe_candidate_id) },
                            { icon: PDFSvg, action: () => exportsActions.PresenceFiche({ groupe: groupe_candidate_id }) }
                            ]} />
                    },
                    {
                        name: 'Supprimer', rowKey: "remove", renderRemove: (groupData, Disabled) => <BtnActions btns={
                            [
                                { icon: DeletSvg, Disabled, action: () => this.props.deleteGroupe(groupData) },
                            ]} />
                    }
                ] :
                [
                    { name: Translate('test', 'groupede'), rowKey: "groupename" },
                    { name: Translate('fields', 'dateCreate'), rowKey: "created_at", render: created_at => FormatDate(created_at, true) },
                    {
                        name: Translate('fields', 'affichgroupe'), rowKey: "groupe_candidate_id", render: groupe_candidate_id => <BtnActions btns={
                            [{ icon: ShowSvg, action: () => this.props.action(groupe_candidate_id) },
                            { icon: PDFSvg, action: () => exportsActions.PresenceFiche({ groupe: groupe_candidate_id }) }
                            ]} />
                    },
                ]
        return (
            <TableRows
                classSticky="fix_elem_top"
                styleTop={{ height: "calc(100% - 50px)" }}
                columns={cols}
                data={this.props.dataGroupe && this.props.dataGroupe.groupes[this.props.currentShow]}
                key_table="groupe_candidate_id"
            />

        )
    }
}
export { GroupePage }