import Dictionary from "./dictionary";
let Dict = Dictionary()
export function Translate(type, children) {
  try {
    const languageDb = Dict[0]

    if (children in languageDb[type]) {
      return languageDb[type][children];
    }

    return children;
  } catch (err) {
    console.error("Error while translating::translateWord", err);

    // If something goes wrong return the word as it is.
    return children;
  }
}
export function FormatDate(data, detail) {
  return (new Date(data)).toLocaleDateString('fr-FR', detail ? { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' } : { year: 'numeric', month: 'short', day: 'numeric' })
}