import React from 'react'
export function BtnActions(props){
    return(
        <div className="dis-flex width-mxcn">
            {Array.isArray(props.btns) && props.btns.map((btn,key)=>{
                if (btn.Disabled !== true) {
                    return <div key={key} className="dis-flex pdnVall rad-5 brd-equ curs-pointer onHvr" style={{marginInline:3}} onClick={btn.action}>{btn.icon}</div>
                } 
                return <div key={key} className="dis-flex rad-5 rad-5 cur-not-allowed brd-equ curs-pointer opc-5" style={{marginInline:3}}>{btn.icon}</div>
            })}
        </div>
    )
}