import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate } from '../../../utils'
import { ContainerPages, NavTab, Loader, ArrowSvg, TableRows, ImportSvg, ButtonCLick, FilterSvg, CostumDrop, CheckBox,MaleSvg,FemaleSvg } from '../../_Common'
import { candidateActions } from '../../../actions'
import { ImSearch } from 'react-icons/im'
import PropTypes from "prop-types";
import {
    addUrlProps,
    UrlQueryParamTypes
} from "react-url-query";
import { ImportCandidate } from './ImportCandidate'
import ReactPaginate from 'react-paginate';
const urlPropsQueryConfig = {
    currentTab: { type: UrlQueryParamTypes.string },
    import: { type: UrlQueryParamTypes.string }
};
class Candidate extends Component {
    constructor(props) {
        super(props)
        let state = {
            last_page: 0,
            current_page: 1,
            total: 0,
            filters: {
                status: props.currentTab,
                page: 1,
                sorter: null,
                sort: null
            },
            includeSearch: {
                lastname: true,
                firstname: true
            },
            search: ''
        }
        this.formSearch = React.createRef()
        this.state = state
        props.getCandidate(state.filters)
        this.handleImport = this.handleImport.bind(this)
        this.handleSearchCandi = this.handleSearchCandi.bind(this)
        this.handlePageCLick = this.handlePageCLick.bind(this)
        this.handleSorter = this.handleSorter.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.callSearch = this.callSearch.bind(this)
        this.candidates = []
        this.isvalidData = false
    }
    componentDidMount() {
        document.title = Translate('titles', 'app') + " - " + Translate('sidebar', 'candidate')
    }
    static defaultProps = {
        currentTab: 'new',
    }
    static propTypes = {
        currentTab: PropTypes.string,
        import: PropTypes.string,
        /********************** */
        onChangeCurrentTab: PropTypes.func,
        onChangeImport: PropTypes.func,
    };
    componentWillReceiveProps(nextProps) {
        const { getingcandi, datacandi, currentTab } = nextProps
        if (this.props.getingcandi === "0" && getingcandi === "1" && datacandi && datacandi.candidates && Array.isArray(datacandi.candidates.data)) {
            this.setState({
                last_page: datacandi.candidates.last_page,
                current_page: datacandi.candidates.current_page,
                from: datacandi.candidates.from,
                to: datacandi.candidates.to,
                total: datacandi.candidates.total
            })
            this.candidates = datacandi.candidates.data
            this.isvalidData = true
        }
        if (this.isvalidData && this.props.currentTab !== currentTab) {
            let state = {
                filters: {
                    ...this.state.filters,
                    status: currentTab,
                    page: 1
                }
            }
            this.setState(state)
            this.props.getCandidate(state.filters)
        }
    }
    handleImport() {

    }
    handleSorter(sorter) {
        let prevfilter = this.state.filters
        prevfilter.sort = sorter.value
        prevfilter.sorter = sorter.current
        this.props.getCandidate(prevfilter)
        this.setState({
            filters: prevfilter
        })
    }
    handleSearchCandi(e) {
        const { name, value } = e.target
        if (!value) {
            this.state.search = value
            this.formSearch.dispatchEvent(new Event("submit"));
        } else {
            this.setState({
                [name]: value
            })
        }
    }
    callSearch(e) {
        e.preventDefault()
        let prevSate = this.state
        for (const [key, checked] of Object.entries(prevSate.includeSearch)) {
            if (checked) {
                prevSate.filters[key] = this.state.search
            } else {
                prevSate.filters[key] = null
            }
        }
        this.state = prevSate
        this.props.getCandidate(prevSate.filters)
    }
    handleChange(e) {
        const { name, checked } = e.target
        let prevData = this.state.includeSearch
        prevData[name] = checked
        this.setState({
            includeSearch: prevData
        })
    }
    handlePageCLick(page) {
        let state = {
            filters: {
                ...this.state.filters,
                page: page.selected + 1
            }
        }
        this.setState(state)
        this.props.getCandidate(state.filters)
    }
    render() {
        const { getingcandi } = this.props
        let ActionDo = []
        switch (this.props.currentTab) {
            case "new":
                ActionDo.push(<ButtonCLick TextBottun={ImportSvg} action={() => this.props.onChangeImport('new')} />)
                break;
            default:
                break;
        }
        return (
            <ContainerPages className="candidate_bdg" titlePage={"Candidats (" + this.state.total + ')'} contentTop={
                <div className="dis-flex item-center">
                    <CostumDrop dropClick={FilterSvg} className="rightM-15">
                        <h4 style={{ paddingBottom: "6px", marginBottom: "10px" }} className="brd-btm margin-null">Champes de recherche</h4>
                        <div className="px-8">
                            <CheckBox id="lastname" name="lastname" onChange={this.handleChange} className="my-10" labelText={Translate('fields', 'lastname')} other={{
                                checked: this.state.includeSearch.lastname
                            }} />
                            <CheckBox id="firstname" name="firstname" onChange={this.handleChange} className="my-10" labelText={Translate('fields', 'firstname')} other={{
                                checked: this.state.includeSearch.firstname
                            }} />
                            <CheckBox id="phone" name="phone" onChange={this.handleChange} className="my-10" labelText={Translate('fields', 'phone')} other={{
                                checked: this.state.includeSearch.phone
                            }} />
                            <CheckBox id="email" name="email" onChange={this.handleChange} className="my-10" labelText={Translate('fields', 'email')} other={{
                                checked: this.state.includeSearch.email
                            }} />
                            <CheckBox id="city" name="city" onChange={this.handleChange} className="my-10" labelText={Translate('candidate', 'city')} other={{
                                checked: this.state.includeSearch.city
                            }} />
                            <CheckBox id="civility" name="civility" onChange={this.handleChange} className="my-10" labelText={Translate('candidate', 'civility')} other={{
                                checked: this.state.includeSearch.civility
                            }} />
                            <CheckBox id="age" name="age" onChange={this.handleChange} className="my-10" labelText={Translate('candidate', 'age')} other={{
                                checked: this.state.includeSearch.age
                            }} />
                            <CheckBox id="fitgeneral" name="fitgeneral" onChange={this.handleChange} className="my-10" labelText={Translate('candidate', 'fitgeneral')} other={{
                                checked: this.state.includeSearch.fitgeneral
                            }} />
                        </div>

                    </CostumDrop>
                    <form ref={refForm => (this.formSearch = refForm)} onSubmit={this.callSearch}>
                        <input type="text" autoComplete="off" style={{ width: "350px" }} name="search" onChange={this.handleSearchCandi} placeholder="Rechercher un candidat" value={this.state.search} className="sizeF-1em " />
                        <ImSearch className="icon_color curs-pointer mr-2" onClick={this.callSearch} />
                    </form>
                </div>
            } contentLeft={ActionDo} StyleChildren={{ height: "calc(100% - 110px)" }}>
                {getingcandi === "0" && <Loader />}
                <div className="dis-flex item-center content-btwn">
                    <NavTab
                        ElemeList={[{ text: <div className="curs-pointer" onClick={() => this.props.onChangeCurrentTab("new")}>{Translate('candidate', 'nvcandidate')}</div>, active: this.props.currentTab === "new" }, { text: <div className="curs-pointer" onClick={() => this.props.onChangeCurrentTab("progress")}>{Translate('candidate', 'cdencours')}</div>, active: this.props.currentTab === "progress" }, { text: <div className="curs-pointer" onClick={() => this.props.onChangeCurrentTab("returned")}>{Translate('candidate', 'cdvalider')}</div>, active: this.props.currentTab === "returned" }]}
                    />
                </div>
                <hr className="brd-none paddTB-3" style={{ borderBottom: "1px solid #e1dfdd" }} />
                <TableRows
                    classSticky="fix_elem_top"
                    styleTop={{ height: "calc(100% - 38px)" }}
                    columns={[
                        { name: Translate('fields', 'lastname'), rowKey: "lastname", sorter: true },
                        { name: Translate('fields', 'firstname'), rowKey: "firstname", sorter: true },
                        { name: Translate('fields', 'phone'), rowKey: "phone", sorter: true },
                        { name: Translate('fields', 'email'), rowKey: "email", sorter: true },
                        { name: Translate('fields', 'username'), rowKey: "usename", sorter: true },
                        { name: Translate('fields', 'initpassw'), rowKey: "initpassword", render: initpassword => initpassword ? initpassword : <strong>Fait lui connexion</strong> },
                        { name: Translate('candidate', 'civility'), rowKey: "civility", sorter: true,render: civility => <div className="dis-flex item-center"> {civility==="Madame" ? FemaleSvg : MaleSvg} <span style={{paddingInline:8}}>{civility}</span></div> },
                        { name: Translate('candidate', 'city'), rowKey: "city", sorter: true },
                        { name: Translate('candidate', 'age'), rowKey: "age", sorter: true },
                        { name: Translate('candidate', 'codepostale'), rowKey: "codepostale", sorter: true },
                        { name: Translate('candidate', 'fitgeneral'), rowKey: "fitgenerale", sorter: true },
                        { name: Translate('fields', 'lindustreet_id'), rowKey: "lindustreet_id", sorter: true }
                    ]}
                    data={this.candidates}
                    callBackSort={this.handleSorter}
                    key_table="candidate_id"
                />
                <div className="dis-flex topM-8 pos-sticky bottom-null">
                    <div className="bdg-white dis-flex item-center def_big_shadow rad-5 sTrgPdn">
                        <span>Affichage des éléments : <strong className="color-org">{this.state.from}</strong> à <strong className="color-org">{this.state.to}</strong> sur <strong className="color-org">{this.state.total}</strong> éléments</span>
                    </div>
                    <div className="dis-inline-block leftM-auto">
                        <ReactPaginate
                            previousLabel={<i className="dis-flex arrowPage arrowPageprev">{ArrowSvg}</i>}
                            nextLabel={<i className="dis-flex arrowPage arrowPagenext">{ArrowSvg}</i>}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            disableInitialCallback={true}
                            pageCount={this.state.last_page}
                            initialPage={this.state.current_page - 1}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            disabledClassName="hidde_nextPagi"
                            onPageChange={this.handlePageCLick}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName='active_page'
                        />
                    </div>
                </div>
                {this.props.import && <ImportCandidate close={this.props.onChangeImport} filters={this.state.filters} />}
            </ContainerPages>
        )
    }
}
function mapState(state) {
    const { getingcandi, datacandi } = state.candidate
    return { getingcandi, datacandi }
}
const actionsClass = {
    getCandidate: candidateActions.getCandidate
}
const connectedCandidate = connect(mapState, actionsClass)(Candidate);
const QueryProps = addUrlProps({ urlPropsQueryConfig })(connectedCandidate)
export { QueryProps as Candidate };