import React , {Component} from 'react'
import {connect} from 'react-redux'
import {evalGrilleActions,exportsActions} from '../../../../actions'
import {Qestionview} from './Qestionview'
import {InlineElemnt,PopTab,BtnClick, Loader} from '../../../_Common'
class GrilleViewer extends Component{
    constructor(props){
        super(props)
        this.props.getDetailGrille(this.props.isActive)
    }
    render(){
        const {getDetailgri,dataDetail}=this.props
        let title="Chargement de grille ..."
        let qestions=[]
        let idGrid=null
        if(getDetailgri==="1" && dataDetail && dataDetail.grid){
            title=dataDetail.grid.titlegrid
            idGrid=dataDetail.grid.evaluation_grid_id
            qestions=Array.isArray(dataDetail.grid.questions)?dataDetail.grid.questions:[]
        }
        return(
                <PopTab isActive={this.props.isActive} TabTitle={<InlineElemnt first={this.props.iconGrille} secend={title}/>} close={() => this.props.Close(undefined)} width="90%" height="90%">
                    {getDetailgri==="0" && <Loader/>}
                    <div className="pd-10-15 brd-equ rad-5 box-border height-100 OvfAtoY">
                        {qestions.map((qst,key)=>{
                            return <Qestionview key={key} qst={qst} index={key+1}/>
                        })}
                        <div className="pos-absolute bdg-white dis-flex width-mxcn leftM-auto" style={{zIndex:2,paddingTop:5,bottom:8,right:10}}>
                            <BtnClick onClick={()=>exportsActions.ExportGrid({grid:idGrid,type:'Blank'},'candidate')} TxtBtn="Exporter grille de réponse" Color="bdg-onrg clr-white select-non" />
                            <BtnClick onClick={()=>exportsActions.ExportGrid({grid:idGrid,type:'Answer'},'candidate')} TxtBtn="Exporter grille de correction" Color="bgd-pdf clr-white select-non leftM-8" />
                        </div>
                    </div>
                    
                </PopTab>
        )
    }
}
function mapState(state) {
    const {getDetailgri,dataDetail}=state.grilleEvalu
    return {getDetailgri,dataDetail}
}
const actionsClass={
    getDetailGrille:evalGrilleActions.getDetailGrille
}
const connectedGrilleViewer = connect(mapState,actionsClass)(GrilleViewer);
export { connectedGrilleViewer as GrilleViewer };