import {toast} from 'react-toastify';
import {messagingConstants} from '../constants';
import {FunctionService,RequestService} from '../services';
export const messagingActions={
    GetListUsers,
    getChannelMessages,
    SendMessage,
    AddMessage,
    GetListAsync,
    ArchiveChannel,
    GetNotificationall,
    readallmasgsactivechannel
}
function GetListUsers() {
    return dispatch => {
        dispatch(FunctionService.request(messagingConstants.FETCH_LIST_USERS_REQESUT))
        RequestService.GetRequest(`messages/list-users`,true)
            .then(({data={}}) => {
                dispatch(FunctionService.succes(data,messagingConstants.FETCH_LIST_USERS_SUCCESS))
                // console.log('GetListUsers is : ',data)
            })
            .catch(err => {
                dispatch(FunctionService.failure(err,messagingConstants.FETCH_LIST_USERS_FAILEUR))
            })
        }
    }
    function GetNotificationall() {
        return dispatch => {
            RequestService.GetRequest(`messages/notification-count`,true)
            .then(({data={}}) => {
                dispatch(FunctionService.succes(data.notifcationchannels,messagingConstants.FETCH_NOTIFICATION_MSGS_SUCCESS))
                // console.log('GetNotificationall is : ',data)
                // console.log('GetNotificationall is : ',data)
            })
            .catch(err => {
                dispatch(FunctionService.failure(err,messagingConstants.FETCH_NOTIFICATION_MSGS_FAILEUR))
            })
    }
}
function readallmasgsactivechannel(message) {
    return dispatch => {
        RequestService.PostRequest(`messages/readread`,message,true)
        .then(({data={}}) => {
                console.log('readallmasgsactivechannel is : yes')
            })
            .catch(err => {
                console.log('readallmasgsactivechannel is : No',err)
            })
    }
}
function GetListAsync() {
    return dispatch => {
        RequestService.GetRequest(`messages/list-users`,true)
            .then(({data={}}) => {
                dispatch(FunctionService.succes(data,messagingConstants.FETCH_LIST_USERS_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err,messagingConstants.FETCH_LIST_USERS_FAILEUR))
            })
    }
}
function getChannelMessages(idChannel) {
    return dispatch => {
        dispatch(FunctionService.request(messagingConstants.SYNC_MESSAGES_CHANNEL_REQESUT))
        RequestService.GetRequest(`messages/channel/${idChannel}`,true)
            .then(({data={}}) => {
                // console.log('getChannelMessages is : ',data)
                dispatch(FunctionService.succes(data,messagingConstants.SYNC_MESSAGES_CHANNEL_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err,messagingConstants.SYNC_MESSAGES_CHANNEL_FAILEUR))
            })
    }
}
function SendMessage(message) {
    return dispatch => {
        dispatch(FunctionService.request(messagingConstants.SYNC_MESSAGES_CHANNEL_REQESUT))
        RequestService.PostRequest(`messages/send`,message,true)
            .then(({data={}}) => {
                dispatch(FunctionService.succes(data,messagingConstants.SYNC_MESSAGES_CHANNEL_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err,messagingConstants.SYNC_MESSAGES_CHANNEL_FAILEUR))
            })
    }
}

function AddMessage(message) {
    return dispatch => {
        dispatch(FunctionService.succes({message},messagingConstants.SET_MESSAGES_CHANNEL))
    }
}

function ArchiveChannel(channel) {
    const request=RequestService.PostRequest(`messages/archive-channel`,{channel},true);
    toast.promise(
        request,
        {
            pending: "L'archivage est en cours de traitement",
            success: "Votre questionaire est archiver",
            error: "Une erreur s'est produite lors de l'archivage"
        }
    )
    return request
}