import React from 'react'
import { Component } from 'react';
class CostumDrop extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isDroped: false
        }
        this.refList = React.createRef()
        this.handleDrop = this.handleDrop.bind(this)
        this.handleOutside = this.handleOutside.bind(this)
    }
    handleDrop() {
        let prevSate = this.state.isDroped
        if (prevSate) {
            document.removeEventListener("mousedown", this.handleOutside)
        } else {
            document.addEventListener("mousedown", this.handleOutside)
        }
        this.setState({
            isDroped: !prevSate
        })
    }
    handleOutside(e) {
        if (this.refList && this.refList.current && !this.refList.current.contains(e.target)) {
            this.handleDrop()
        }
    }
    render() {
        return (
            <div ref={this.refList} className={this.props.className}>
                <div onClick={this.handleDrop} className="curs-pointer dis-flex">{this.props.dropClick}</div>
                {this.state.isDroped && <div className="pos-absolute bdg-white rad-5 box-def topM-5">
                    <div className="pd-10-15">
                        {this.props.children}
                    </div>
                </div>}
            </div>
        )
    }
}
export { CostumDrop }