import React, { Component } from 'react'
import { connect } from 'react-redux'
import { adminActions } from '../../../actions'
import { Translate, FormatDate } from '../../../utils'
import { ContainerPages, ButtonLink, InfoTable, Loader, BtnActions, EditSvg, DeleteSvg, ButtonCLick, RefreshSvg, PopTab, BtnClick } from '../../_Common'
import PropTypes from "prop-types";
import {
    addUrlProps,
    UrlQueryParamTypes
} from "react-url-query";
import { AddAdmin } from './AddAdmin'
const urlPropsQueryConfig = {
    tab: { type: UrlQueryParamTypes.string }
};
class Admin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            handleTab: false
        }
        if (this.props.getiadimn !== "1") {
            this.props.getAdmin()
        }
        this.handleDelet = this.handleDelet.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleRefresh = this.handleRefresh.bind(this)
    }
    static defaultProps = {
        tab: ''
    }
    static propTypes = {
        tab: PropTypes.string,
        /********************** */
        onChangeTab: PropTypes.func,
    };
    componentDidMount() {
        document.title = Translate('titles', 'app') + " - " + Translate('sidebar', 'admin')
    }
    componentWillReceiveProps(nextProps) {
        const { dataDelete, deletingAdmin } = nextProps
        if (this.props.deletingAdmin === "0" && deletingAdmin === "1" && dataDelete && dataDelete.message === "admin deleted") {
            this.props.getAdmin()
            this.state.handleTab = false
        }
    }
    handleDelet(idadmin) {
        if (!this.state.handleTab) {
            this.setState({
                handleTab: idadmin
            })
        } else {
            let admin = {
                idUser: this.state.handleTab
            }
            this.props.deletAdmin(admin)
        }
    }
    handleClose() {
        this.setState({
            handleTab: false
        })
    }
    handleRefresh() {
        this.props.getAdmin()
    }
    render() {
        const { getiadimn, dataAdmin, deletingAdmin } = this.props
        var admins = []
        var admin = {}
        var todelete = {}
        var lengthData = ''
        if (getiadimn === "1" && dataAdmin && Array.isArray(dataAdmin.admins)) {
            admins = dataAdmin.admins.map(admin => {
                return [admin.firstname, admin.lastname, admin.phone, admin.email, admin.usename, admin.initpassword ? admin.initpassword : Translate('fields', 'passwupda'), FormatDate(admin.created_at, true), FormatDate(admin.updated_at, true), <BtnActions btns={[{ icon: EditSvg, action: () => this.props.onChangeTab(admin.id) }, { icon: DeleteSvg, action: () => this.handleDelet(admin.id) }]} />]
            })
            lengthData = ' (' + dataAdmin.admins.length + ')'
            if (this.state.handleTab) {
                todelete = dataAdmin.admins.find(admin => admin.id == this.state.handleTab)
            }
            if (this.props.tab !== "add") {
                admin = dataAdmin.admins.find(admin => admin.id == this.props.tab)
                if (!admin) {
                    admin = {}
                }
            }
        }
        return (
            <ContainerPages titlePage={Translate('sidebar', 'admin') + lengthData} className="admin_bdg" contentLeft={<div className="dis-flex">
                <ButtonCLick TextBottun={RefreshSvg} action={this.handleRefresh} />
                <ButtonLink TextBottun={Translate('admin', 'addadmin')} pathName="?tab=add" />
            </div>} StyleChildren={{ height: "calc(100% - 75px)" }}>
                {getiadimn === "0" && <Loader style={{ height: "200px" }} />}
                <InfoTable
                    classSticky="fix_elem_top"
                    styleTop={{ height: "100%" }}
                    styleBody={{ background: "#ffffff9e" }}
                    thead={[Translate('fields', 'lastname'), Translate('fields', 'firstname'), Translate('fields', 'phone'), Translate('fields', 'email'), Translate('fields', 'username'), Translate('fields', 'initpassw'), Translate('fields', 'datecreat'), Translate('fields', 'datemodif'), Translate('fields', 'action')]}
                    tbody={admins}
                />
                {this.props.tab && <AddAdmin active={this.props.tab} default={admin} close={this.props.onChangeTab} />}
                {this.state.handleTab && <PopTab isActive={this.state.handleTab} TabTitle={Translate('admin', 'supadmin')} close={this.handleClose} width="470px">
                    {deletingAdmin === "0" && <Loader />}
                    <p>Vous êtes sur le point de supprimer <strong className="sizeF-12em txt-dng">{todelete.firstname + " " + todelete.lastname}</strong>. Cette action est irréversible !</p>
                    <div className="dis-flex leftM-auto sTrgPdn sTmr-si">
                        <BtnClick
                            onClick={this.handleClose}
                            TxtBtn="Annuler"
                            style={{ width: "35%" }}
                            Color="leRgM-15 bdg-onrg-op color-org"
                        />
                        <BtnClick
                            onClick={this.handleDelet}
                            style={{ width: "55%" }}
                            TxtBtn="Confirmer la suppression"
                            Color="leRgM-15 width-100 bdg-dng clr-white"
                        />
                    </div>
                </PopTab>}
            </ContainerPages>
        )
    }
}
function mapState(state) {
    const { getiadimn, dataAdmin, deletingAdmin, dataDelete } = state.admin
    return { getiadimn, dataAdmin, deletingAdmin, dataDelete }
}
const Actions = {
    getAdmin: adminActions.getAdmin,
    deletAdmin: adminActions.deletAdmin
}
const connectedClass = connect(mapState, Actions)(Admin);
const QueryProps = addUrlProps({ urlPropsQueryConfig })(connectedClass)
export { QueryProps as Admin };