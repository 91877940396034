export const tabletConstants = {
    ADD_TABLET_REQUEST:"ADD_TABLET_REQUEST",
    ADD_TABLET_SUCCESS:"ADD_TABLET_SUCCESS",
    ADD_TABLET_FAILURE:"ADD_TABLET_FAILURE",

    UPDATE_TABLET_REQUEST:"UPDATE_TABLET_REQUEST",
    UPDATE_TABLET_SUCCESS:"UPDATE_TABLET_SUCCESS",
    UPDATE_TABLET_FAILURE:"UPDATE_TABLET_FAILURE",

    GET_TABLET_REQUEST:"GET_TABLET_REQUEST",
    GET_TABLET_SUCCESS:"GET_TABLET_SUCCESS",
    GET_TABLET_FAILURE:"GET_TABLET_FAILURE",

    DELETE_TABLET_REQUEST:"DELETE_TABLET_REQUEST",
    DELETE_TABLET_SUCCESS:"DELETE_TABLET_SUCCESS",
    DELETE_TABLET_FAILURE:"DELETE_TABLET_FAILURE"
};