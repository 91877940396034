import React, { useState } from 'react'
import { qestionsType } from '../../../../../constants'
import { InlineElemnt, DownArrowSvg, InfoTable } from '../../../../_Common'
export function Qestionview(props) {
    const initialState = {
        showDetail: false
    }
    const [state, setstate] = useState(initialState);
    function handleChange() {
        setstate({
            ...state,
            showDetail: !state.showDetail
        })
    }
    return (
        <div className="botM-25">
            <span className="curs-pointer" onClick={handleChange}>
                <InlineElemnt className="sizeF-11em" first={<strong className={"dis-flex brd-equ select-non item-center content-center mm-widthg rad-100 " + (props.qst.typequestion === qestionsType.text ? "clr-grey" : props.qst.isCorrect ? "accpt_clr" : "txt-dng")}>{props.index}</strong>} more={<div className="leftM-auto trans-cub" style={state.showDetail ? { transform: 'scale(1.4)' } : { transform: 'rotate(-90deg) scale(1.4)' }}>{DownArrowSvg}</div>} classSecend={props.qst.typequestion === qestionsType.text ? "clr-grey" : props.qst.isCorrect ? "accpt_clr" : "txt-dng"} secend={props.qst.text}
                />
            </span>
            {state.showDetail && props.qst.typequestion === qestionsType.bool &&
                <div className="leftM-40 topM-8 dis-flex">
                    <InlineElemnt
                        className="brd-equ rad-5 pd-10-15"
                        classSecend=""
                        first={<input disabled={true} checked={(props.qst.details[0].detailanswer === 1 && props.qst.details[0].answerCandi) || (props.qst.details[0].detailanswer === 0 && props.qst.details[0].answerCandi === false)} type="radio" />}
                        secend={"Oui"}
                    />
                    <InlineElemnt
                        className="leftM-8 brd-equ rad-5 pd-10-15"
                        classSecend=""
                        first={<input disabled={true} checked={(props.qst.details[0].detailanswer === 0 && props.qst.details[0].answerCandi) || (props.qst.details[0].detailanswer === 1 && props.qst.details[0].answerCandi === false)} type="radio" />}
                        secend={"Non"}
                    />
                </div>}
            {state.showDetail && props.qst.typequestion === qestionsType.qcm &&
                <div className="dis-block leftM-40 topM-8 paddTB-3">
                    <InfoTable
                        thead={["Réponse correct", "Text d'option", "Réponse du candidat"]}
                        tbody={props.qst.details.map((detail, key) => {
                            return [
                                <><input disabled={true} checked={detail.detailanswer === 1} type="checkbox" /><label></label></>,
                                <span className={detail.answerCandi ? "accpt_clr" : "txt-dng"}>{detail.detailtext}</span>,
                                <><input disabled={true} checked={detail.answerChossed === 1} type="checkbox" /><label></label></>
                            ]
                        })}
                    />
                </div>}
            {state.showDetail && props.qst.typequestion === qestionsType.rank &&
                <div className="dis-block leftM-40 topM-8 paddTB-3">
                    <InfoTable
                        thead={["Classement correct", "Text d'option", "Classement de candidate"]}
                        tbody={props.qst.details.map((detail, key) => {
                            return [
                                <strong>{detail.detailanswer}</strong>,
                                <span className={detail.answerCandi ? "accpt_clr" : "txt-dng"}>{detail.detailtext}</span>,
                                <strong>{detail.answerChossed}</strong>
                            ]
                        })}
                    />
                </div>}
            {state.showDetail && props.qst.typequestion === qestionsType.text &&
                <div className="dis-block leftM-40 topM-8">{props.qst.details[0].answerCandi}</div>}
        </div>
    )
}