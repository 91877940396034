import React, { Component } from 'react'
import { Translate } from '../../../utils'
import { TableRows } from '../../_Common'
class NoGroupeCanddidate extends Component {
    render() {
        return (
            <TableRows
                classSticky="fix_elem_top"
                styleTop={{ height: "calc(100% - 50px)" }}
                columns={[
                    { name: Translate('fields', 'lastname'), rowKey: "lastname", sorter: true },
                    { name: Translate('fields', 'firstname'), rowKey: "firstname", sorter: true },
                    { name: Translate('fields', 'phone'), rowKey: "phone", sorter: true },
                    { name: Translate('fields', 'email'), rowKey: "email", sorter: true },
                    { name: Translate('fields', 'username'), rowKey: "usename", sorter: true },
                    { name: Translate('fields', 'initpassw'), rowKey: "initpassword", render: initpassword => initpassword ? initpassword : <strong>Fait lui connexion</strong> },
                    { name: Translate('candidate', 'civility'), rowKey: "civility", sorter: true },
                    { name: Translate('candidate', 'city'), rowKey: "city", sorter: true },
                    { name: Translate('candidate', 'age'), rowKey: "age", sorter: true },
                    { name: Translate('candidate', 'codepostale'), rowKey: "codepostale", sorter: true },
                    { name: Translate('candidate', 'fitgeneral'), rowKey: "fitgenerale", sorter: true },
                    { name: Translate('fields', 'lindustreet_id'), rowKey: "lindustreet_id", sorter: true }
                ]}
                data={this.props.dataGroupe}
                rowSelect={{
                    onChange: this.props.onChange
                }}
                callBackSort={this.props.handleSorter}
                key_table="candidate_id"
            />
        )
    }
}
export { NoGroupeCanddidate }
