import { testKnowConstants } from '../constants';
import { RequestService,FunctionService  } from '../services';
export const testknowActions = {
    AddTestKnow,
    AffUserTest
};
function AddTestKnow(test){
    return dispatch => {
        dispatch(FunctionService.request(testKnowConstants.ADD_TEST_REQUEST))
        RequestService.PostRequest('knowleage-test',test,true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,testKnowConstants.ADD_TEST_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,testKnowConstants.ADD_TEST_FAILURE))
        })
    }
}
function AffUserTest(candidat,idtest){
    return dispatch => {
        dispatch(FunctionService.request(testKnowConstants.AFFECT_TEST_REQUEST))
        RequestService.PostRequest('knowleage-test/'+idtest,candidat,true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,testKnowConstants.AFFECT_TEST_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,testKnowConstants.AFFECT_TEST_FAILURE))
        })
    }
}
