import React, { Component } from 'react'
import { PopTab } from '../../../_Common'
import { TopTabCheck } from './TopTabCheck'
import { Translate } from '../../../../utils'
import { connect } from 'react-redux'
import { SelectTime, SelectGrile, SelectParticipant, PreviewTest } from './utils'
class AddKnowTest extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    handleSetup() {
        switch (this.props.stepNumber) {
            case 1:
                return <SelectGrile user={this.props.userType} />
            case 2:
                return <SelectParticipant user={this.props.userType} />
            case 3:
                return <PreviewTest user={this.props.userType} />
            default:
                return <SelectTime />
        }
    }
    render() {
        return (
            <PopTab isActive={this.props.isActive} TabTitle={Translate('test', 'addknowtest')} close={() => this.props.Close(undefined)} width="90%" height="90%" classChild="OvfAtoY">
                <TopTabCheck />
                {this.handleSetup()}
            </PopTab>
        )
    }
}
function mapState(state) {
    const { stepNumber } = state.tests
    return { stepNumber }
}
const ConnectedClass = connect(mapState)(AddKnowTest)
export { ConnectedClass as AddKnowTest }