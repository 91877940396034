import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate } from '../../../utils'
import { ContainerPages, Loader, ArrowSvg } from '../../_Common'
import { candidateActions } from '../../../actions'
import { ElementGalerie } from './utils'
import { ImSearch } from 'react-icons/im'
import ReactPaginate from 'react-paginate';
class GalleryPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            search: ""
        }
        this.refContainer = React.createRef()
        props.getGallery("")
        this.handlePageCLick = this.handlePageCLick.bind(this)
        this.handleDownloadImage = this.handleDownloadImage.bind(this)
        this.handleSearchImage = this.handleSearchImage.bind(this)
    }
    componentWillReceiveProps(nextProps) {
        const { getingGallery, dataGallery } = nextProps
        if (this.props.getingGallery === "0" && getingGallery === "1" && dataGallery && dataGallery.status === "done" && dataGallery.list) {
            this.refContainer.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            this.state = { ...this.state, ...dataGallery.list }
        }
    }
    componentDidMount() {
        document.title = Translate('titles', 'app') + " - Galerie candidats"
    }
    handlePageCLick(page) {
        this.props.getGallery("?page=" + (page.selected + 1) + "&search=" + this.state.search)
    }
    handleDownloadImage(idIterop) {
        window.open('https://jta-selection.fr/download/' + idIterop)
    }
    handleSearchImage(e) {
        const { value } = e.target
        this.state.search = value
        this.props.getGallery("?page=1&search=" + value)
    }
    render() {
        const { getingGallery, searchingCand } = this.props
        return (
            <ContainerPages titlePage="Galerie candidats" contentTop={
                <div className="dis-flex item-center">
                    <ImSearch className="icon_color mr-2" />
                    <input type="text" autoComplete="off" style={{ width: "300px" }} name="search" value={this.state.search} onChange={this.handleSearchImage} placeholder="Rechercher l'image d'un candidate" className="sizeF-1em " />
                </div>
            } className="galery_bdg" StyleChildren={{ height: "calc(100% - 60px)", padding: "0" }}>
                <div className="height-100 OvfAtoY" ref={this.refContainer}>
                    <div className="dis-flex flx-warp content-between width-100">
                        {(getingGallery === "0" || searchingCand === "0") && <Loader />}
                        {Array.isArray(this.state.data) && this.state.data.map((cand, key) => {
                            return <ElementGalerie key={key} iterop={cand.iditerop} fullName={cand.firstname + " " + cand.lastname} image={cand.picture} downloadLink={cand.downloadLink} downloadImage={this.handleDownloadImage} />
                        })}
                    </div>
                    {this.state.last_page &&
                        <div className="dis-flex topM-8 pos-sticky bottom-null">
                            <div className="bdg-white dis-flex item-center def_big_shadow rad-5 sTrgPdn">
                                <span>Affichage des éléments : <strong className="color-org">{this.state.from}</strong> à <strong className="color-org">{this.state.to}</strong> sur <strong className="color-org">{this.state.total}</strong> éléments</span>
                            </div>
                            <div className="dis-inline-block leftM-auto">
                                <ReactPaginate
                                    previousLabel={<i className="dis-flex arrowPage arrowPageprev">{ArrowSvg}</i>}
                                    nextLabel={<i className="dis-flex arrowPage arrowPagenext">{ArrowSvg}</i>}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    disableInitialCallback={true}
                                    pageCount={this.state.last_page}
                                    initialPage={this.state.current_page - 1}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    disabledClassName="hidde_nextPagi"
                                    onPageChange={this.handlePageCLick}
                                    containerClassName={'pagination'}
                                    subContainerClassName={'pages pagination'}
                                    activeClassName='active_page'
                                />
                            </div>
                        </div>
                    }
                </div>
            </ContainerPages>
        )
    }
}
function mapState(state) {
    const { getingGallery, dataGallery } = state.candidate
    return { getingGallery, dataGallery }
}
const actionsClass = {
    getGallery: candidateActions.getGallery
}
const connectedClass = connect(mapState, actionsClass)(GalleryPage);
export { connectedClass as GalleryPage };