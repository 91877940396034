import React from 'react'
import { Translate } from '../../../utils'
import { Component } from 'react';
import { InlineElemnt } from '../../_Common'
import { BsFillPersonLinesFill } from 'react-icons/bs';
import { SiGooglescholar } from 'react-icons/si'
import { IoPersonSharp, IoDocumentText } from "react-icons/io5"
import { HiDeviceTablet } from 'react-icons/hi'
import { IoIosArrowForward, IoMdToday } from 'react-icons/io'
import { RiGroupFill, RiFileEditFill, RiFileList3Fill, RiHistoryLine } from 'react-icons/ri'
import { FaImages, FaBookReader, FaUserSlash } from 'react-icons/fa'
import { GiFiles } from 'react-icons/gi'
import { IoIosPerson, IoIosChatboxes } from 'react-icons/io'
import { AiFillSetting } from 'react-icons/ai'
import { connect } from 'react-redux'
import { messagingActions } from '../../../actions'

import ElementSide from './ELementSide'
class SideBarV2 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            groupes_candi: true,
            jtaSection: true,
            // jtaSection: ['/candidate?currentTab=returned', '/test/evaluation'].indexOf(window.location.pathname) !== -1,
            settings: ['/grille/evaluation', '/settings', '/tablet', '/evaluator', '/admin'].indexOf(window.location.pathname) !== -1,
            notUsed: ['/test/self', '/grille/test', '/grille/self', '/candidate/reportes', '/candidate/chat', '/candidate/pictures', '/reporters'].indexOf(window.location.pathname) !== -1,
            pathname: this.props.dataUser.user.role !== "reporter" ? window.location.pathname === '/' ? `/candidate` : window.location.pathname : '/candidate/reportes'
        }
        this.fetchListUsers()
    }
    componentDidMount() {
        window.Echo.channel(`messaging-adminsJta`)
            .listen('RecordChannelEvent', (e) => {
                if (e.asyncList === "async") {
                    this.props.GetNotificationall()
                    if (this.props.notificationall > 0) {
                        var audio = new Audio('/sounds/notfication.mp3');
                        audio.play();
                    }
                }
            })
    }
    fetchListUsers = () => {
        this.props.GetNotificationall()
    }
    handleContent(name) {
        let initState = this.state[name]
        this.setState({
            [name]: !initState
        })
    }
    handleContentpathname(name) {
        this.setState({
            ...this.state,
            pathname: name
        })
    }
    render() {
        var pathname = this.state.pathname
        const { notificationall } = this.props
        return (
            <div className="side-width height-100 rightP-5 brd-left box-border bottomP-15 box-border OvfAtoY" style={{ background: "#1e1e2d" }}>
                {/* <h1 className='clr-white text-center mb-20'>ADMIN APP</h1> */}
                <div className='width-100 text-center' style={{ background: '#181824', padding: '10px 0' }}>
                    <img alt="logo_industreet" width="200" src="/assets/img/logo_white.png" className='' />
                </div>
                <div className="clr-white">
                    {/* THe groups */}
                    <>
                        <>
                            {this.props.user.role !== "reporter" &&
                                <div onClick={() => this.handleContent("groupes_candi")}>
                                    <InlineElemnt
                                        className={"font-600 elemS-height curs-pointer leftP-12 onHvr topM-0 " + (this.state.groupes_candi ? "sideSmbdg" : "")}
                                        first={<IoIosArrowForward className={"size_icon icon_color " + (this.state.groupes_candi ? "rotat-90" : "")} />}
                                        classFirst="scale-7"
                                        secend={Translate('sidebar', 'candidate')}
                                    />
                                </div>}
                        </>
                        <div className="HdOvrfl trans-cub" style={this.state.groupes_candi ? { height: 155 } : { height: 0 }}>
                            {this.props.user.role !== "reporter" && <>
                                <ElementSide
                                    className=""
                                    icon={<SiGooglescholar className="size_icon icon_color" />}
                                    TextSvg="Candidats"
                                    pathName="/candidate"
                                    active={pathname === "/candidate"}
                                    onClick={() => this.handleContentpathname("/candidate")}
                                />
                                <ElementSide
                                    className=""
                                    icon={<RiGroupFill className="size_icon icon_color" />}
                                    TextSvg={Translate('sidebar', 'candidateRepa')}
                                    pathName="/candidate/groupe"
                                    onClick={() => this.handleContentpathname("/candidate/groupe")}
                                    active={pathname === "/candidate/groupe"}
                                />
                                <ElementSide
                                    className=""
                                    icon={<FaUserSlash className="size_icon icon_color" />}
                                    TextSvg={Translate('sidebar', 'abandon')}
                                    pathName="/candidate/abandon"
                                    onClick={() => this.handleContentpathname("/candidate/abandon")}
                                    active={pathname === "/candidate/abandon"}
                                />

                            </>}
                        </div>
                    </>
                    {/* JTA */}
                    <>
                        <>
                            {this.props.user.role !== "reporter" &&
                                <div onClick={() => this.handleContent("jtaSection")}>
                                    <InlineElemnt
                                        className={"font-600 elemS-height curs-pointer leftP-12 border-t onHvr " + (this.state.jtaSection ? "sideSmbdg" : "")}
                                        first={<IoIosArrowForward className={"size_icon icon_color " + (this.state.jtaSection ? "rotat-90" : "")} />}
                                        classFirst="scale-7"
                                        secend={Translate('sidebar', 'jta')}
                                    />
                                </div>}
                        </>
                        <div className="HdOvrfl trans-cub" style={this.state.jtaSection ? { height: 110 } : { height: 0 }}>
                            {this.props.user.role !== "reporter" && <>
                                <ElementSide
                                    className=""
                                    icon={<IoMdToday className="size_icon icon_color" />}
                                    TextSvg={Translate('sidebar', 'jtaeval')}
                                    pathName="/test/evaluation"
                                    active={pathname === "/test/evaluation"}
                                    onClick={() => this.handleContentpathname("/test/evaluation")}
                                />
                                <ElementSide
                                    className=""
                                    icon={<RiHistoryLine className="size_icon icon_color" />}
                                    TextSvg={Translate('candidate', 'cdvalider')}
                                    pathName="/test/historique"
                                    active={pathname === "/test/historique"}
                                    onClick={() => this.handleContentpathname("/test/historique")}
                                />
                            </>}
                        </div>
                    </>
                    {/* Settings */}
                    <>
                        <>
                            {this.props.user.role !== "reporter" &&
                                <div onClick={() => this.handleContent("settings")}>
                                    <InlineElemnt
                                        className={"font-600 elemS-height curs-pointer leftP-12 border-t onHvr " + (this.state.settings ? "sideSmbdg" : "")}
                                        first={<IoIosArrowForward className={"size_icon icon_color " + (this.state.settings ? "rotat-90" : "")} />}
                                        classFirst="scale-7"
                                        secend={Translate('sidebar', 'settings')}
                                    />
                                </div>}
                        </>
                        <div className="HdOvrfl trans-cub" style={this.state.settings ? { height: 250 } : { height: 0 }}>
                            {this.props.user.role === "supadmin" && <>
                                <ElementSide
                                    className=""
                                    onClick={() => this.handleContentpathname("/admin")}
                                    icon={<IoPersonSharp className="size_icon icon_color" />}
                                    TextSvg={Translate('sidebar', 'admin')}
                                    pathName="/admin"
                                    active={pathname === "/admin"}
                                />
                            </>}
                            {this.props.user.role !== "reporter" && <>
                                <ElementSide
                                    className=""
                                    icon={<BsFillPersonLinesFill className="size_icon icon_color" />}
                                    TextSvg={Translate('sidebar', 'evaluateur')}
                                    onClick={() => this.handleContentpathname("/evaluator")}
                                    pathName="/evaluator"
                                    active={pathname === "/evaluator"}
                                />
                                <ElementSide
                                    className=""
                                    icon={<HiDeviceTablet className="size_icon icon_color" />}
                                    TextSvg={Translate('sidebar', 'tablette')}
                                    onClick={() => this.handleContentpathname("/tablet")}
                                    pathName="/tablet"
                                    active={pathname === "/tablet"}
                                />
                                <ElementSide
                                    className=""
                                    icon={<AiFillSetting className="size_icon icon_color" />}
                                    TextSvg={Translate('sidebar', 'emails')}
                                    onClick={() => this.handleContentpathname("/settings")}
                                    pathName="/settings"
                                    active={pathname === "/settings"}
                                />
                                <ElementSide
                                    className=""
                                    icon={<RiFileEditFill className="size_icon icon_color" />}
                                    TextSvg={Translate('sidebar', 'grilevaluation')}
                                    pathName="/grille/evaluation"
                                    onClick={() => this.handleContentpathname("/grille/evaluation")}
                                    active={pathname === "/grille/evaluation"}
                                />
                            </>}
                        </div>
                    </>
                    {/* Not used */}
                    <>
                        <>
                            {this.props.user.role !== "reporter" &&
                                <div onClick={() => this.handleContent("notUsed")}>
                                    <InlineElemnt
                                        className={"font-600 elemS-height curs-pointer leftP-10 border-t onHvr " + (this.state.notUsed ? "sideSmbdg" : "")}
                                        first={<IoIosArrowForward className={"size_icon icon_color " + (this.state.notUsed ? "rotat-90" : "")} />}
                                        classFirst="scale-7"
                                        secend={Translate('sidebar', 'not_used')}
                                    />
                                </div>}
                        </>
                        <div className="HdOvrfl trans-cub" style={this.state.notUsed ? { height: 340 } : { height: 0 }}>
                            {this.props.user.role === "supadmin" && <>
                                <ElementSide
                                    className=""
                                    onClick={() => this.handleContentpathname("/reporters")}
                                    icon={<IoIosPerson className="size_icon icon_color" />}
                                    TextSvg={Translate('sidebar', 'reports')}
                                    pathName="/reporters"
                                    active={pathname === "/reporters"}
                                />
                            </>}
                            {this.props.user.role !== "reporter" && <>
                                <ElementSide
                                    className=""
                                    // TextSvg={Translate('sidebar', 'chat')}
                                    icon={<FaImages className="size_icon icon_color" />}
                                    TextSvg="Galerie candidats"
                                    pathName="/candidate/pictures"
                                    active={pathname === "/candidate/pictures"}
                                    onClick={() => this.handleContentpathname("/candidate/pictures")}
                                />
                                <ElementSide
                                    className=""
                                    icon={<IoIosChatboxes className="size_icon icon_color" />}
                                    TextSvg={Translate('sidebar', 'chat')}
                                    // TextSvg='ok'
                                    pathName="/candidate/chat"
                                    onClick={() => this.handleContentpathname("/candidate/chat")}
                                    notification={notificationall}
                                    active={pathname.indexOf("/candidate/chat") > -1}
                                />
                            </>}
                            {(this.props.user.role === "reporter" || this.props.user.role === "supadmin") &&
                                <ElementSide
                                    className=""
                                    icon={<IoDocumentText className="size_icon icon_color" />}
                                    TextSvg={Translate('sidebar', 'titleRap')}
                                    pathName="/candidate/reportes"
                                    active={pathname === "/candidate/reportes"}
                                    onClick={() => this.handleContentpathname("/candidate/reportes")}
                                />}
                            {this.props.user.role !== "reporter" && <>
                                <ElementSide
                                    className=""
                                    icon={<GiFiles className="size_icon icon_color" />}
                                    TextSvg={Translate('sidebar', 'grileauto')}
                                    pathName="/grille/self"
                                    onClick={() => this.handleContentpathname("/grille/self")}
                                    active={pathname === "/grille/self"}
                                />
                                <ElementSide
                                    className=""
                                    icon={<RiFileList3Fill className="size_icon icon_color" />}
                                    TextSvg={Translate('sidebar', 'grilletest')}
                                    pathName="/grille/test"
                                    onClick={() => this.handleContentpathname("/grille/test")}
                                    active={pathname === "/grille/test"}
                                />
                                <ElementSide
                                    className=""
                                    icon={<FaBookReader className="size_icon icon_color" />}
                                    TextSvg={Translate('sidebar', 'testauto')}
                                    pathName="/test/self"
                                    onClick={() => this.handleContentpathname("/test/self")}
                                    active={pathname === "/test/self"}
                                />
                            </>}
                        </div>
                    </>
                </div>
            </div>
        )
    }
}
function mapState(state) {
    const { dataUser } = state.user
    const { notificationall } = state.messaging
    return { notificationall, dataUser }
}
const connectedClass = connect(mapState, messagingActions)(SideBarV2);
export { connectedClass as SideBarV2 };
// export {SideBarV2};
