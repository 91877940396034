import React, { Component } from 'react'
class ContainerPages extends Component {
    render() {
        if (document.getElementById("title_nav_page")) {
            document.getElementById("title_nav_page").innerHTML = this.props.titlePage
        }
        return (
            <div className={"width-100 height-100 pos-relative box-border OvfAtoY HdOvFlwX " + this.props.className} style={this.props.style}>
                {!this.props.hideSubHeader && <div className="dis-flex item-center pos-sticky sTrgPdnperc box-def box-border top-null st_border_top bdg-blue index-5" style={{ marginBottom: "10px", paddingBlock: "5px", height: "60px" }}>
                    {this.props.contentTop}
                    {this.props.contentLeft &&
                        <div className="leftM-auto dis-flex item-center">
                            {this.props.contentLeft}
                        </div>}
                </div>}
                <div className="width-100 sTrgPdnperc sTrgPdn pos-relative box-border bottomP-15" style={this.props.StyleChildren}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}
export { ContainerPages }