import React, { Component } from 'react';
import { connect } from "react-redux";
import {
  Router
} from 'react-router-dom';
import { configureUrlQuery } from 'react-url-query';
import {history} from '../utils'
import {IndexApp,IndexAuth} from '../components'
import PrivateRoute from './privateRoute';
configureUrlQuery({ history });
class App extends Component {
  render() {
    return (
      <Router history={history}>
          <PrivateRoute 
            private={IndexApp}
            public={IndexAuth}
          />
      </Router>
    );
  }
}
export default connect()(App);
