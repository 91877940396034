import React from 'react';
import { Route } from 'react-router-dom';
import Cookies from 'js-cookie'
var authUser = Cookies.get('auth_user')
// if (authUser) {
//   Cookies.remove('auth_user')
// } else{
//   console.log('--- No need to clear the cookies')
// }
const PrivateRoute = ({ private: Private, public: Public, ...rest }) => (
  <Route {...rest} render={props => (
    authUser ?
      <Private {...props} />
      : <Public />
  )} />
);

export default PrivateRoute;