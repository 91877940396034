import React, { useState, Component } from 'react'
import { EmojiSVG } from './IconSvg'
import Picker from 'emoji-picker-react'

export class EmojiViewers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false
        }
        this.handleDown = this.handleDown.bind(this)
        this.onEmojiClick = this.onEmojiClick.bind(this)
        this.handleOutside = this.handleOutside.bind(this)
        this.outListRef = React.createRef();
    }
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleOutside)
    }

    showEmoji() {
        this.setState({
            show: !this.state.show
        })
    }
    onEmojiClick(event, emojiObject) {
        this.props.onEmojiClick(event, emojiObject)
    }
    handleOutside(e) {
        if (this.outListRef && !this.outListRef.current.contains(e.target)) {
            this.handleDown()
        }
    }
    handleDown() {
        let current = this.state.show
        if (current) {
            document.removeEventListener("mousedown", this.handleOutside)
        } else {
            document.addEventListener("mousedown", this.handleOutside)
        }
        this.setState({
            show: !current
        })
    }
    render() {
        return (
            <div className="pos-relative dis-inline-block" ref={this.outListRef} >
                <button onClick={this.handleDown} >{EmojiSVG}</button>
                { this.state.show && <div className="emoji_div pos-absolute index-5" style={{ top: "30px" }}><Picker
                    groupNames={{
                        smileys_people: 'yellow faces',
                        animals_nature: 'cute dogs and also trees',
                        food_drink: 'milkshakes and more',
                        travel_places: 'I love trains',
                        activities: 'lets play a game',
                        objects: 'stuff',
                        symbols: 'more stuff',
                        flags: 'fun with flags',
                        recently_used: 'did I really use those?!',
                    }}
                    onEmojiClick={this.onEmojiClick} /></div>}
            </div>
        )
    }
}