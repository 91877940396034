import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate } from '../../../utils'
import { ContainerPages, BtnClick, Loader, ArrowSvg, TableRows, FilterSvg, CostumDrop, CheckBox } from '../../_Common'
import { candidateActions } from '../../../actions'
import { ImSearch } from 'react-icons/im'
import PropTypes from "prop-types";
import {
    addUrlProps,
    UrlQueryParamTypes
} from "react-url-query";
import ReactPaginate from 'react-paginate';
import { ListRapport } from './ListRapport'
const urlPropsQueryConfig = {
    currentTab: { type: UrlQueryParamTypes.string }
};
class CandidateRapport extends Component {
    constructor(props) {
        super(props)
        let state = {
            last_page: 0,
            current_page: 1,
            total: 0,
            filters: {
                page: 1,
                sorter: null,
                sort: null
            },
            includeSearch: {
                lastname: true,
                firstname: true
            },
            search: '',
            candidateInQueue: null
        }
        this.formSearch = React.createRef()
        this.state = state
        props.getCandidate(state.filters)
        this.handleSearchCandi = this.handleSearchCandi.bind(this)
        this.handlePageCLick = this.handlePageCLick.bind(this)
        this.handleSorter = this.handleSorter.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.callSearch = this.callSearch.bind(this)
        this.candidates = []
        this.isvalidData = false
    }
    componentDidMount() {
        document.title = Translate('titles', 'app') + " - " + Translate('sidebar', 'titleRap')
    }
    static defaultProps = {
        currentTab: null,
    }
    static propTypes = {
        currentTab: PropTypes.string,
        /********************** */
        onChangeCurrentTab: PropTypes.func,
    };
    componentWillReceiveProps(nextProps) {
        const { getingcandi, datacandi, currentTab } = nextProps
        if (this.props.getingcandi === "0" && getingcandi === "1" && datacandi && datacandi.candidates && Array.isArray(datacandi.candidates.data)) {
            this.setState({
                last_page: datacandi.candidates.last_page,
                current_page: datacandi.candidates.current_page,
                from: datacandi.candidates.from,
                to: datacandi.candidates.to,
                total: datacandi.candidates.total
            })
            this.candidates = datacandi.candidates.data
            this.isvalidData = true
        }
    }
    handleSorter(sorter) {
        let prevfilter = this.state.filters
        prevfilter.sort = sorter.value
        prevfilter.sorter = sorter.current
        this.props.getCandidate(prevfilter)
        this.setState({
            filters: prevfilter
        })
    }
    handleSearchCandi(e) {
        const { name, value } = e.target
        if (!value) {
            this.state.search = value
            this.formSearch.dispatchEvent(new Event("submit"));
        } else {
            this.setState({
                [name]: value
            })
        }
    }
    callSearch(e) {
        e.preventDefault()
        let prevSate = this.state
        for (const [key, checked] of Object.entries(prevSate.includeSearch)) {
            if (checked) {
                prevSate.filters[key] = this.state.search
            } else {
                prevSate.filters[key] = null
            }
        }
        this.state = prevSate
        this.props.getCandidate(prevSate.filters)
    }
    handleChange(e) {
        const { name, checked } = e.target
        let prevData = this.state.includeSearch
        prevData[name] = checked
        this.setState({
            includeSearch: prevData
        })
    }
    handlePageCLick(page) {
        let state = {
            filters: {
                ...this.state.filters,
                page: page.selected + 1
            }
        }
        this.setState(state)
        this.props.getCandidate(state.filters)
    }
    handleRapport(row) {
        this.state.candidateInQueue = row
        this.props.onChangeCurrentTab(row.iditerop)
    }
    render() {
        const { getingcandi } = this.props
        return (
            <ContainerPages className="candidate_bdg" titlePage={Translate('sidebar', 'titleRap')} contentTop={
                <div className="dis-flex item-center">
                    <CostumDrop dropClick={FilterSvg} className="rightM-15">
                        <h4 style={{ paddingBottom: "6px", marginBottom: "10px" }} className="brd-btm margin-null">Champes de recherche</h4>
                        <div className="px-8">
                            <CheckBox id="lastname" name="lastname" onChange={this.handleChange} className="my-10" labelText={Translate('fields', 'lastname')} other={{
                                checked: this.state.includeSearch.lastname
                            }} />
                            <CheckBox id="firstname" name="firstname" onChange={this.handleChange} className="my-10" labelText={Translate('fields', 'firstname')} other={{
                                checked: this.state.includeSearch.firstname
                            }} />
                            <CheckBox id="phone" name="phone" onChange={this.handleChange} className="my-10" labelText={Translate('fields', 'phone')} other={{
                                checked: this.state.includeSearch.phone
                            }} />
                            <CheckBox id="email" name="email" onChange={this.handleChange} className="my-10" labelText={Translate('fields', 'email')} other={{
                                checked: this.state.includeSearch.email
                            }} />
                        </div>

                    </CostumDrop>
                    <form ref={refForm => (this.formSearch = refForm)} onSubmit={this.callSearch}>
                        <input type="text" autoComplete="off" style={{ width: "350px" }} name="search" onChange={this.handleSearchCandi} placeholder="Rechercher un candidat" value={this.state.search} className="sizeF-1em " />
                        <ImSearch className="icon_color mr-2" onClick={this.callSearch} className="curs-pointer" />
                    </form>
                </div>
            } StyleChildren={{ height: "calc(100% - 100px)" }}>
                {getingcandi === "0" && <Loader />}
                <TableRows
                    classSticky="fix_elem_top"
                    styleTop={{ height: "100%" }}
                    columns={[
                        { name: Translate('fields', 'lastname'), rowKey: "lastname", sorter: true },
                        { name: Translate('fields', 'firstname'), rowKey: "firstname", sorter: true },
                        { name: Translate('fields', 'phone'), rowKey: "phone", sorter: true },
                        { name: Translate('fields', 'email'), rowKey: "email", sorter: true },
                        { name: Translate('fields', 'username'), rowKey: "usename", sorter: true },
                        {
                            name: Translate('fields', 'action'), rowKey: "iditerop", render: (iditerop, none, row) => <BtnClick
                                onClick={() => this.handleRapport(row)}
                                TxtBtn={Translate("raportCandi", "showRepports")}
                                Color="bdg-onrg-op color-org"
                            />
                        }
                    ]}
                    data={this.candidates}
                    callBackSort={this.handleSorter}
                    key_table="candidate_id"
                />
                <div className="dis-flex topM-8 pos-sticky bottom-null">
                    <div className="bdg-white dis-flex item-center def_big_shadow rad-5 sTrgPdn">
                        <span>Affichage des éléments : <strong className="color-org">{this.state.from}</strong> à <strong className="color-org">{this.state.to}</strong> sur <strong className="color-org">{this.state.total}</strong> éléments</span>
                    </div>
                    <div className="dis-inline-block leftM-auto">
                        <ReactPaginate
                            previousLabel={<i className="dis-flex arrowPage arrowPageprev">{ArrowSvg}</i>}
                            nextLabel={<i className="dis-flex arrowPage arrowPagenext">{ArrowSvg}</i>}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            disableInitialCallback={true}
                            pageCount={this.state.last_page}
                            initialPage={this.state.current_page - 1}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            disabledClassName="hidde_nextPagi"
                            onPageChange={this.handlePageCLick}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName='active_page'
                        />
                    </div>
                </div>
                {this.props.currentTab && <ListRapport candidate={this.state.candidateInQueue} activeTab={this.props.currentTab} close={this.props.onChangeCurrentTab} />}
            </ContainerPages>
        )
    }
}
function mapState(state) {
    const { getingcandi, datacandi } = state.candidate
    return { getingcandi, datacandi }
}
const actionsClass = {
    getCandidate: candidateActions.getCandidate
}
const connectedCandidate = connect(mapState, actionsClass)(CandidateRapport);
const QueryProps = addUrlProps({ urlPropsQueryConfig })(connectedCandidate)
export { QueryProps as CandidateRapport };
