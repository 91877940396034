import { RequestService  } from '../services';
export const rapportCandidateActions = {
    fetchRapports,
    downloadRapport
};
function fetchRapports(idCandidate){
    return RequestService.GetRequest('report/candidate/fetch/'+idCandidate,true,)
}
function downloadRapport(idCandidate,params){
    return RequestService.GetRequest('report/candidate/download/'+idCandidate,true,params,{ responseType: 'blob'})
}