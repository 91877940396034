import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate } from '../../../../../utils'
import { testActions, evaluTestActions, evaluatorActions, tabletActions, candidateActions, evalGrilleActions, groupeActions } from '../../../../../actions'
import { testsConstants } from '../../../../../constants'
import { BtnClick, SelectSearch, Loader } from '../../../../_Common'
import { SelectEvaluatorTablete, AffectationGroupe } from './EvaluationSteps'
class Evaluation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            step: 0,
            dataevaluation: Array.isArray(props.dataEvaluator) ? props.dataEvaluator : [],
            boards: { columns: [] }
        }
        this.HandleChangeGride = this.HandleChangeGride.bind(this)
        this.handleNext = this.handleNext.bind(this)
        this.handleAffect = this.handleAffect.bind(this)
        this.handleEditSelect = this.handleEditSelect.bind(this)
        this.requestGroupe = this.requestGroupe.bind(this)
        this.gobackStep = this.gobackStep.bind(this)
        if (props.getEvalEva !== "1") {
            props.getEvaGrilleEva()
        }
        if (props.getiEvalu !== "1") {
            props.getEvaluator()
        }
        if (props.getiTablet !== "1") {
            props.getTablet()
        }
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.affectingGrp === 0 && nextProps.affectingGrp === 1 && nextProps.dataAffectation.ok) {
            this.setState({
                step: 1,
                boards: { columns: nextProps.dataAffectation.affectations }
            })
        }
    }
    handleEditSelect() {
        this.setState({
            dataevaluation: []
        })
    }
    handleAffect = (evalu, tablete) => {
        if (evalu && tablete) {
            let current = this.props.dataAffectationEvalu
            current.push({
                evaluator_id: evalu.id,
                tablette_id: tablete.tablette_id,
                evalu_name: evalu.fullname,
                table_color: tablete.color,
                color_code: tablete.color_code
            })
            this.props.StoreStep(current, testsConstants.STORE_AFFECTATION_EVALUATOR)
            this.setState(this.state)
        }
    }
    handleDeleteItem = (key) => {
        var current = this.props.dataAffectationEvalu
        current.splice(key, 1);
        this.props.StoreStep(current, testsConstants.STORE_AFFECTATION_EVALUATOR)
        this.setState(this.state)
    }
    requestGroupe() {
        this.props.requestAffectation({ evaluators: this.props.dataAffectationEvalu, groupe: this.props.dataGroupe.groupe_candidate_id })
    }
    HandleChangeGride(grid) {
        this.setState({
            grille: grid
        })
    }
    gobackStep() {
        this.setState({
            step: 0
        })
    }
    getStepData() {
        switch (this.state.step) {
            case 0: return <SelectEvaluatorTablete handleDeleteItem={this.handleDeleteItem} handleEditSelect={this.handleEditSelect} dataevaluation={this.props.dataAffectationEvalu} HandleChangeEvalu={this.HandleChangeEvalu} handleAffect={this.handleAffect} getiEvalu={this.props.getiEvalu} dataEvalu={this.props.dataEvalu} getiTablet={this.props.getiTablet} dataTabelt={this.props.dataTabelt} handleNext={this.requestGroupe} />
            case 1: return <AffectationGroupe dataBoards={this.state.boards} goBack={this.gobackStep} handleNext={this.handleNext} getEvalEva={this.props.getEvalEva} dataEvalEva={this.props.dataEvalEva} grille={this.props.datagrileEval} />
            default:
                return ''
        }
    }
    handleNext(dataBoard, grille) {
        if (grille && dataBoard) {
            let boards = dataBoard.columns.map(board => {
                return {
                    evaluator_id: board.id,
                    tablette_id: board.tablete,
                    table_color: board.table_color,
                    evalu_name: board.evalu_name,
                    candidates: board.cards.map(card => card.id)
                }
            })
            this.props.StoreStep({ girle: grille, affect: boards }, testsConstants.SET_EVALUATOR_TABLETTE_TEST)
        }
    }
    render() {
        const { getDetGroupe, getiEvalu, getiTablet, affectingGrp, confirmingCandi } = this.props
        return (
            <div className="hg-100-200 leftM-auto righttM-auto" style={{ width: "90%" }}>
                {(getDetGroupe === "0" || getiEvalu === "0" || getiTablet === "0" || confirmingCandi === "0" || affectingGrp === 0) && <Loader />}
                {this.getStepData()}
            </div>
        )
    }
}
function mapState(state) {
    const { dataGrile, dataTime, datagrileEval, dataEvaluator, dataGroupe,dataAffectationEvalu } = state.tests
    const { addingTest, dataTest } = state.evaluTest
    const { getiTablet, dataTabelt } = state.tablet
    const { getiEvalu, dataEvalu } = state.evaluator
    const { getEvalEva, dataEvalEva } = state.grilleEvalu
    const { affectingGrp, dataAffectation } = state.groupe
    return { dataGrile, addingTest, dataTabelt, dataEvalu, dataTest, dataTime, datagrileEval, dataEvaluator, getiEvalu, getiTablet, dataGroupe, getEvalEva, dataEvalEva, affectingGrp, dataAffectation,dataAffectationEvalu }
}
const Actions = {
    StoreStep: testActions.StoreStep,
    getGroupeDetail: candidateActions.getGroupeDetail,
    getEvaGrilleEva: evalGrilleActions.getEvaGrilleEva,
    confirmCandidate: candidateActions.confirmCandidate,
    getEvaluator: evaluatorActions.getEvaluator,
    getTablet: tabletActions.getTablet,
    requestAffectation: groupeActions.requestAffectation
}
const ConnectedClass = connect(mapState, Actions)(Evaluation)
export { ConnectedClass as Evaluation }