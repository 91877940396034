import { tabletConstants } from '../constants';
export function tablet(state , action) {
    switch (action.type) {
        case tabletConstants.ADD_TABLET_REQUEST:
            return {
                ...state,
                adingTablet:"0",
                dataAddTabelt: action.payload
            };
        case tabletConstants.ADD_TABLET_SUCCESS:
            return {
                ...state,
                adingTablet:"1",
                dataAddTabelt: action.payload
            };
        case tabletConstants.ADD_TABLET_FAILURE:
            return {
                ...state,
                adingTablet:"2",
                dataAddTabelt: action.payload
            };
        case tabletConstants.UPDATE_TABLET_REQUEST:
            return {
                ...state,
                updatingTablet:"0",
                dataAddTabelt: action.payload
            };
        case tabletConstants.UPDATE_TABLET_SUCCESS:
            return {
                ...state,
                updatingTablet:"1",
                dataAddTabelt: action.payload
            };
        case tabletConstants.UPDATE_TABLET_FAILURE:
            return {
                ...state,
                updatingTablet:"2",
                dataAddTabelt: action.payload
            };
        case tabletConstants.DELETE_TABLET_REQUEST:
            return {
                ...state,
                deletingTablet:"0",
                dataDeletetabl: action.payload
            };
        case tabletConstants.DELETE_TABLET_SUCCESS:
            return {
                ...state,
                deletingTablet:"1",
                dataDeletetabl: action.payload
            };
        case tabletConstants.DELETE_TABLET_FAILURE:
            return {
                ...state,
                deletingTablet:"2",
                dataDeletetabl: action.payload
            };
        case tabletConstants.GET_TABLET_REQUEST:
            return {
                ...state,
                getiTablet:"0",
                dataTabelt: action.payload
            };
        case tabletConstants.GET_TABLET_SUCCESS:
            return {
                getiTablet:"1",
                dataTabelt: action.payload
            };
        case tabletConstants.GET_TABLET_FAILURE:
            return {
                ...state,
                getiTablet:"2",
                dataTabelt: action.payload
            };
        default:
            return {...state}
        }
}