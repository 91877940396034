import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate } from '../../../../utils'
import { testActions } from '../../../../actions'
import { ContainerPages, NavTab, ButtonCLick, Loader, RefreshSvg } from '../../../_Common'
import { ElementLabel } from '../utils'
import PropTypes from "prop-types";
import {
    addUrlProps,
    UrlQueryParamTypes
} from "react-url-query";
import { AddSelfTest } from './AddSelfTest'
import { DetailTest } from './DetailTest'
import { DetailEvaluTest } from './DetailEvaluTest'
const urlPropsQueryConfig = {
    currentTab: { type: UrlQueryParamTypes.string },
    tab: { type: UrlQueryParamTypes.string },
    viewtest: { type: UrlQueryParamTypes.string }
};
class SelfTest extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showDetail: false
        }
        if (props.getTesteval !== "1") {
            props.getTestEvaluateur()
        }
        if (props.getTestCad !== "1") {
            props.getTestCandidate()
        }
        this.handleRefresh = this.handleRefresh.bind(this)
        this.handleTest = this.handleTest.bind(this)
    }
    static defaultProps = {
        currentTab: 'candidate',
        tab: '',
        viewtest: ''
    }
    static propTypes = {
        currentTab: PropTypes.string,
        tab: PropTypes.string,
        viewtest: PropTypes.string,
        /********************** */
        onChangeCurrentTab: PropTypes.func,
        onChangeTab: PropTypes.func,
        onChangeViewtest: PropTypes.func,
    };
    componentDidMount() {
        document.title = Translate('titles', 'app') + " - " + Translate('sidebar', 'testauto')
    }
    componentWillReceiveProps(nextProps) {
        const { updatStatus, dataUpdate } = nextProps
        if (this.props.updatStatus === "0" && updatStatus === "1" && dataUpdate) {
            if (this.props.currentTab === "candidate") {
                this.props.getTestCandidate()
            } else {
                this.props.getTestEvaluateur()
            }
        }
    }
    handleTest(action, idTest, status) {
        if (action === "start" || action === "end") {
            let newStatus = {
                test_auto_id: idTest,
                status_test_auto_eval: action
            }
            this.props.updateStatusAuto(this.props.currentTab === "candidate" ? "autoTestCand" : "autoTestEval", newStatus)
        } else {
            this.state.showDetail = (status !== "attent")
            this.props.onChangeViewtest(idTest)
        }
    }
    handleRefresh() {
        this.props.getTestEvaluateur()
        this.props.getTestCandidate()
    }
    render() {
        const { getTesteval, dataTestGet, getTestCad, dataTestCan, updatStatus, dataUpdate } = this.props
        var attent = []
        var start = []
        var end = []
        let ActionDo = ""
        let detail = ""
        switch (this.props.currentTab) {
            case "candidate":
                if (getTestCad === "1" && dataTestCan && dataTestCan.tests) {
                    attent = Array.isArray(dataTestCan.tests.attent) ? dataTestCan.tests.attent : []
                    start = Array.isArray(dataTestCan.tests.start) ? dataTestCan.tests.start : []
                    end = Array.isArray(dataTestCan.tests.end) ? dataTestCan.tests.end : []
                }
                ActionDo = <div className="dis-flex">
                    <ButtonCLick TextBottun={RefreshSvg} action={this.handleRefresh} />
                </div>
                detail = <DetailTest showDetail={this.state.showDetail} isActive={this.props.viewtest} Close={this.props.onChangeViewtest} />
                break;
            case "evaluator":
                if (getTesteval === "1" && dataTestGet && dataTestGet.tests) {
                    attent = Array.isArray(dataTestGet.tests.attent) ? dataTestGet.tests.attent : []
                    start = Array.isArray(dataTestGet.tests.start) ? dataTestGet.tests.start : []
                    end = Array.isArray(dataTestGet.tests.end) ? dataTestGet.tests.end : []
                }
                ActionDo = <div className="dis-flex">
                    <ButtonCLick TextBottun={RefreshSvg} action={this.handleRefresh} />

                </div>
                detail = <DetailEvaluTest showDetail={this.state.showDetail} isActive={this.props.viewtest} Close={this.props.onChangeViewtest} />
                break;
            default:
                break;
        }
        return (
            <ContainerPages titlePage={Translate('sidebar', 'testauto')} contentTop={<NavTab
                ElemeList={[{ text: <div className="curs-pointer" onClick={() => this.props.onChangeCurrentTab('candidate')}>{Translate('test', 'tstcndt')}</div>, active: this.props.currentTab === "candidate" }, { text: <div className="curs-pointer" onClick={() => this.props.onChangeCurrentTab('evaluator')}>{Translate('test', 'tstevl')}</div>, active: this.props.currentTab === "evaluator" }]}
            />} contentLeft={ActionDo}>
                
                {(getTesteval === "0" || getTestCad === "0" || updatStatus === "0") && <Loader style={{ minHeight: "300px" }} />}
                <div>
                    {start.length > 0 && <ElementLabel updateStatus={this.handleTest} bdg_st="activ_pr" TestStatus="start" textLabel={Translate('test', 'tstencr')} testList={start} />}
                    {attent.length > 0 && <ElementLabel updateStatus={this.handleTest} bdg_st="bdg-pend" TestStatus="attent" className="topM-15" textLabel={Translate('test', 'pentest')} testList={attent} />}
                    {end.length > 0 && <ElementLabel bdg_st="bdg-valid" updateStatus={this.handleTest} TestStatus="end" className="topM-15" textLabel={Translate('test', 'tremtest')} testList={end} />}
                </div>
                <AddSelfTest isActive={this.props.tab === "add"} Close={this.props.onChangeTab} userType={this.props.currentTab} />
                {this.props.viewtest && detail}
            </ContainerPages>
        )
    }
}
function mapState(state) {
    const { getTesteval, dataTestGet, getTestCad, dataTestCan, updatStatus, dataUpdate } = state.tests
    return { getTesteval, dataTestGet, getTestCad, dataTestCan, updatStatus, dataUpdate }
}
const actionsClass = {
    getTestEvaluateur: testActions.getTestEvaluateur,
    getTestCandidate: testActions.getTestCandidate,
    updateStatusAuto: testActions.updateStatusAuto

}
const connectedSelfTest = connect(mapState, actionsClass)(SelfTest);
const QueryProps = addUrlProps({ urlPropsQueryConfig })(connectedSelfTest)
export { QueryProps as SelfTest };