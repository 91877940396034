import { reporterConstants } from '../constants';
export function reporter(state, action) {
    switch (action.type) {
        /********** edit or create an reporter here *************/
        case reporterConstants.HANDLE_REPORTER_REQUEST:
            return {
                ...state,
                handlingReporter:0
            }
        case reporterConstants.HANDLE_REPORTER_SUCCESS:
            return {
                ...state,
                handleData:action.payload,
                handlingReporter:1
            }
        case reporterConstants.HANDLE_REPORTER_FAILURE:
            return {
                ...state,
                handleErrors:action.payload,
                handlingReporter:2
            }
        /********** delete an reporter here *************/
        case reporterConstants.DELETE_REPORTER_REQUEST:
            return {
                ...state,
                deletingReporter:0
            }
        case reporterConstants.DELETE_REPORTER_SUCCESS:
            return {
                ...state,
                dataDelete:action.payload,
                deletingReporter:1
            }
        case reporterConstants.DELETE_REPORTER_FAILURE:
            return {
                ...state,
                errorsDelete:action.payload,
                deletingReporter:2
            }
        /********** fetch list  of reporters here *************/
        case reporterConstants.FETCH_REPORTER_REQUEST:
            return {
                ...state,
                fetchingReporters:0
            }
        case reporterConstants.FETCH_REPORTER_SUCCESS:
            return {
                ...state,
                reportersList:action.payload,
                fetchingReporters:1
            }
        case reporterConstants.FETCH_REPORTER_FAILURE:
            return {
                ...state,
                fetchErrors:action.payload,
                fetchingReporters:2
            }
        default:
            return {
                ...state
            }
    }
}