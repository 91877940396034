import React from 'react'
import { MdPictureAsPdf } from 'react-icons/md'
import { IoMdDownload } from 'react-icons/io'
import { IoEyeSharp } from 'react-icons/io5'
import { InlineElemnt, AvaterDef, DuplicateSvg } from '../../../_Common'
export function ElementRapport(props) {
    return (
        <div className="dis-flex item-center parent_to_hover box-border width-100 hover-shadwh curs-pointer brd-btm content-btwn" style={{ padding: "18px 10px" }}>
            <div className="dis-flex item-center width-80" onClick={props.actionSHow}>
                <span className="rightM-8 dis-flex"><MdPictureAsPdf className="icon_color" style={{ width: 27, height: 27 }} /></span>
                <div className="width-46per space-nowarp rightM-15">
                    <span className="sizeF-1em txt-ellips HdOvrfl dis-block">{props.nameRapport}</span>
                </div>
                <div style={{ width: "20%" }} className="sizeF-9em clr-grey">
                    <span>{props.date}</span>
                </div>
            </div>
            <div className="dis-flex item-center">
                <div className="dis-flex cntrl_show_hover stHvrscal rightM-15" onClick={() => props.downloadFile(props.urlFile, props.nameRapport)}><IoMdDownload className="icon_color" style={{ width: 23, height: 23 }} /></div>
            </div>
        </div>
    )
}