import { testsConstants, selfTestCanstants,evaluTestConstants } from '../constants';
export function tests(state, action) {
    switch (action.type) {
        case testsConstants.SET_TIME_TEST:
            return {
                ...state,
                grilleAuto: action.payload
            }
        case testsConstants.SET_TIME_TEST_EVAL:
            return {
                ...state,
                grilleAutoEval: action.payload
            }
        case testsConstants.SET_SELF_TIME_TEST:
            return {
                ...state,
                stepNumber: 1,
                dataTime: action.payload
            }
        case testsConstants.SET_SELF_GRILLE_TEST:
            return {
                ...state,
                stepNumber: 2,
                dataGrille: action.payload
            }
        case testsConstants.SET_EVALUATOR_TABLETTE_TEST:
            return {
                ...state,
                stepNumber: 4,
                datagrileEval: action.payload.girle,
                dataEvaluator: action.payload.affect
            }
        case testsConstants.STORE_AFFECTATION_EVALUATOR:
            return {
                ...state,
                dataAffectationEvalu: action.payload,
            }
        case testsConstants.SET_GRILE_TEST:
            return {
                ...state,
                ...action.payload
            }
        case testsConstants.SET_USERS_TEST:
            return {
                ...state,
                stepNumber: 3,
                dataUsers: action.payload
            }
        case testsConstants.GO_TO_STEP:
            return {
                ...state,
                stepNumber: action.payload
            }
        case evaluTestConstants.SET_DATA_JTA_EDIT:
            return {
                ...state,
                dataTime: action.payload.dataTime,
                datagrileEval: action.payload.datagrileEval,
                dataGroupe: action.payload.dataGroupe,
                dataGrille: action.payload.dataGrille,
                stepNumber: 0,
                dataAffectationEvalu:action.payload.dataAffectationEvalu
            };
        /********************************** */
        case selfTestCanstants.ADD_TEST_REQUEST:
            return {
                ...state,
                addSelfTest: "0",
                dataSelfTest: action.payload
            };
        case selfTestCanstants.ADD_TEST_SUCCESS:
            return {
                ...state,
                addSelfTest: "1",
                dataSelfTest: action.payload
            };
        case selfTestCanstants.ADD_TEST_FAILURE:
            return {
                ...state,
                addSelfTest: "2",
                dataSelfTest: action.payload
            };
        /************************************** */
        case selfTestCanstants.ADD_TEST_EVAL_REQUEST:
            return {
                ...state,
                addTesteval: "0",
                dataTestEval: action.payload
            };
        case selfTestCanstants.ADD_TEST_EVAL_SUCCESS:
            return {
                ...state,
                addTesteval: "1",
                dataTestEval: action.payload
            };
        case selfTestCanstants.ADD_TEST_EVAL_FAILURE:
            return {
                ...state,
                addTesteval: "2",
                dataTestEval: action.payload
            };
        /********************************* */
        case selfTestCanstants.GET_TEST_EVAL_REQUEST:
            return {
                ...state,
                getTesteval: "0",
                dataTestGet: action.payload
            };
        case selfTestCanstants.GET_TEST_EVAL_SUCCESS:
            return {
                ...state,
                getTesteval: "1",
                dataTestGet: action.payload
            };
        case selfTestCanstants.GET_TEST_EVAL_FAILURE:
            return {
                ...state,
                getTesteval: "2",
                dataTestGet: action.payload
            };
        /************************************** */
        case selfTestCanstants.GET_TEST_CANDI_REQUEST:
            return {
                ...state,
                getTestCad: "0",
                dataTestCan: action.payload
            };
        case selfTestCanstants.GET_TEST_CANDI_SUCCESS:
            return {
                ...state,
                getTestCad: "1",
                dataTestCan: action.payload
            };
        case selfTestCanstants.GET_TEST_CANDI_FAILURE:
            return {
                ...state,
                getTestCad: "2",
                dataTestCan: action.payload
            };
        /****************************************** */
        case selfTestCanstants.UPDATE_AUTO_TEST_REQUEST:
            return {
                ...state,
                updatStatus: "0",
                dataUpdate: action.payload
            };
        case selfTestCanstants.UPDATE_AUTO_TEST_SUCCESS:
            return {
                ...state,
                updatStatus: "1",
                dataUpdate: action.payload
            };
        case selfTestCanstants.UPDATE_AUTO_TEST_FAILURE:
            return {
                ...state,
                updatStatus: "2",
                dataUpdate: action.payload
            };
        /***************************************/
        case selfTestCanstants.GET_DETAIL_TEST_REQUEST:
            return {
                ...state,
                getingDetail: "0",
                dataDetailselft: action.payload
            };
        case selfTestCanstants.GET_DETAIL_TEST_SUCCESS:
            return {
                ...state,
                getingDetail: "1",
                dataDetailselft: action.payload
            };
        case selfTestCanstants.GET_DETAIL_TEST_FAILURE:
            return {
                ...state,
                getingDetail: "2",
                dataDetailselft: action.payload
            };
        /*************************************** */
        case selfTestCanstants.GET_ANSWER_TEST_REQUEST:
            return {
                ...state,
                getingAnswers: "0",
                dataAnswers: action.payload
            };
        case selfTestCanstants.GET_ANSWER_TEST_SUCCESS:
            return {
                ...state,
                getingAnswers: "1",
                dataAnswers: action.payload
            };
        case selfTestCanstants.GET_ANSWER_TEST_FAILURE:
            return {
                ...state,
                getingAnswers: "2",
                dataAnswers: action.payload
            };
        /*********  for detail evaluateur ***********/
        case selfTestCanstants.GET_DETAIL_TEST_EVALU_REQUEST:
            return {
                ...state,
                getingEvalDetail: "0",
                dataEvalDetailselft: action.payload
            };
        case selfTestCanstants.GET_DETAIL_TEST_EVALU_SUCCESS:
            return {
                ...state,
                getingEvalDetail: "1",
                dataEvalDetailselft: action.payload
            };
        case selfTestCanstants.GET_DETAIL_TEST_EVALU_FAILURE:
            return {
                ...state,
                getingEvalDetail: "2",
                dataEvalDetailselft: action.payload
            };
        /************ geting answers */
        case selfTestCanstants.GET_ANSWER_TEST_EVALU_REQUEST:
            return {
                ...state,
                getingEvalAns: "0",
                dataEvalAnswers: action.payload
            };
        case selfTestCanstants.GET_ANSWER_TEST_EVALU_SUCCESS:
            return {
                ...state,
                getingEvalAns: "1",
                dataEvalAnswers: action.payload
            };
        case selfTestCanstants.GET_ANSWER_TEST_EVALU_FAILURE:
            return {
                ...state,
                getingEvalAns: "2",
                dataEvalAnswers: action.payload
            };
        /***** fin de section ******/
        case testsConstants.REMOVE_PROCESS_TEST:
            return {
                ...state,
                dataTime: null,
                datagrileEval: null,
                dataEvaluator: null,
                dataGroupe: null,
                dataGrille: null,
                dataUsers: null,
                stepNumber: 0,
                grilleAutoEval: null,
                grilleAuto: null,
                dataUsers: null,
                dataAffectationEvalu:[]

            }
        default:
            return {
                stepNumber: 0,
                dataAffectationEvalu:[],
                ...state
            }
    }
}