import React, { Component } from 'react'
import { connect } from 'react-redux'
import {FormatDate, Translate} from '../../../../../utils'
import {testActions} from '../../../../../actions'
import {testsConstants} from '../../../../../constants'
import {InputForm,BtnClick,SelectSearch,InlineElemnt,ImageViewer,CloseSvg} from '../../../../_Common'
class PreviewTest extends Component{
    constructor(props){
        super(props)
        this.state={
            griletitle:null,
            selectedUser:[]
        }
        this.HandleChange=this.HandleChange.bind(this)
        this.handleNext=this.handleNext.bind(this)
    }
    HandleChange(user){
        let current=this.state.selectedUser
        if(!current.find(use=>use.id===user.id)){
            current.push(user)
            this.setState({
                selectedUser:current
            })
        }
        
    }
    removeSelect(key){
        let crntelem=this.state.selectedUser
        crntelem.splice(key,1)
        this.setState({
            selectedUser:crntelem
        })
    }
    handleNext(){
        this.props.StoreStep(0,testsConstants.REMOVE_PROCESS_TEST)
    }
    render(){
        const {dataTime,dataGrile,dataUsers}=this.props
        let griles=[{id:124,fullName:"zino khedri",picture:null},{id:14,fullName:"nacero khedri",picture:null},{id:58,fullName:"hello khedri",picture:null},{id:54,fullName:"me khedri",picture:null},{id:1222,fullName:"me khedri",picture:null},{id:4487,fullName:"me khedri",picture:null}]
        if(this.state.selectedUser.length>0){
            griles=griles.filter(user=>!this.state.selectedUser.find(tmp=>tmp.id==user.id))
        }
        return(
            <div className="hg-100-200 leftM-auto righttM-auto" style={{width:"68%"}}>
                <div>
                    <InlineElemnt
                        className="sm-padding mtb-5"
                        first={<h3 className="margin-null">Date de test : </h3>}
                        secend={FormatDate(dataTime,true)}
                    />
                    <InlineElemnt
                        className="sm-padding mtb-5"
                        first={<h3 className="margin-null">Grille de test : </h3>}
                        secend={<span>{dataGrile.title_grid}</span>}
                    />
                    <div className="sm-padding mtb-5">
                        <h3>Participant de test</h3>
                        <div className="dis-flex stMrgv-tb flx-warp">
                            {dataUsers.length>0 && dataUsers.map((user,key)=>{
                                return <InlineElemnt
                                            key={key}
                                            className="brd-equ rad-5 sm-padding mr-2 mtb-5"
                                            first={<ImageViewer picture={user.picture} size={30} name={user.fullName}/>}
                                            secend={user.fullName}
                                            more={<i className="dis-flex leftM-8 curs-pointer" onClick={() =>this.removeSelect(key)}>{CloseSvg}</i>}
                                        />
                            })}
                        </div>
                    </div>
                    
                </div>
                <div className="dis-flex content-center topM-22">
                    <BtnClick
                        onClick={this.handleNext}
                        TxtBtn={Translate('test','submit')}
                        Color="bdg-onrg clr-white leftM-8"
                    />
                </div>
            </div>
        )
    }
}
function mapState(state){
    const {dataGrile,dataTime,dataUsers}=state.tests
    const {affecTest,dataAffect,addingTest,dataTest}=state.testKnow
    return {dataGrile,dataTime,dataUsers}
}
const Actions={
    StoreStep:testActions.StoreStep
}
const ConnectedClass=connect(mapState,Actions)(PreviewTest)
export {ConnectedClass as PreviewTest}