import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reporterActions, alertActions } from '../../../actions'
import { Translate } from '../../../utils'
import { PopTab, InputForm, BtnSubmit, SelectForm, Loader } from '../../_Common'
class AddReporter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            firstname: props.default.firstname,
            lastname: props.default.lastname,
            email: props.default.email,
            phone: props.default.phone
        }
        this.HandleChange = this.HandleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    componentWillReceiveProps(nextProps) {
        const { handleData, handleErrors, close, fetchReporters, handlingReporter } = nextProps
        if (this.props.handlingReporter === 0 && handlingReporter === 1 && handleData) {
            fetchReporters()
            close(undefined)
            this.props.SendAlert("Opération réussie", "success")
        }
        if (this.props.handlingReporter === 0 && handlingReporter === 2 && handleErrors) {
            this.props.SendAlert("Vérifier les informations de l'admin ou esseyer plus tard", "error")
        }
    }
    HandleChange(e) {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }
    handleSubmit(e) {
        e.preventDefault()
        let reporter = {
            email: this.state.email,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            phone: this.state.phone,
        }
        if (this.props.active === "add") {
            this.props.AddReporter(reporter)
        } else {
            reporter.iduser = this.props.active
            this.props.updateReporter(reporter)
        }

    }
    render() {
        const { handlingReporter } = this.props
        return (
            <PopTab isActive={this.props.active} TabTitle={Translate('report', 'addTitleRep')} close={() => this.props.close(undefined)} width="550px">
                {handlingReporter === 0 && <Loader />}
                <form onSubmit={this.handleSubmit}>
                    <h4 className="clr-grey sTmr-si">{Translate('evaluateur', 'accountinfo')}</h4>
                    <div className="dis-flex">
                        <InputForm
                            className="rightM-8"
                            placeholder={Translate('fields', 'firstname')}
                            name="firstname"
                            type="text"
                            onChange={this.HandleChange}
                            value={this.state.firstname}
                        />
                        <InputForm
                            placeholder={Translate('fields', 'lastname')}
                            name="lastname"
                            type="text"
                            onChange={this.HandleChange}
                            value={this.state.lastname}
                        />
                    </div>
                    <div className="dis-flex topM-8">
                        <InputForm
                            className="rightM-8"
                            placeholder={Translate('fields', 'email')}
                            name="email"
                            type="email"
                            onChange={this.HandleChange}
                            value={this.state.email}
                        />
                        <InputForm
                            placeholder={Translate('fields', 'phone')}
                            name="phone"
                            type="phone"
                            onChange={this.HandleChange}
                            value={this.state.phone}
                        />
                    </div>
                    <div className="dis-flex leftM-auto sTrgPdn sTmr-si">
                        <BtnSubmit
                            TxtBtn={this.props.active === "add" ? Translate('report', 'submitReporter') : Translate('report', 'updatedSubmit')}
                            Color="width-100 bdg-onrg clr-white"
                        />
                    </div>
                </form>
            </PopTab>
        )
    }
}
function mapState(state) {
    const { handlingReporter, handleData, handleErrors } = state.reporter
    return { handlingReporter, handleData, handleErrors }
}
const Actions = {
    AddReporter: reporterActions.AddReporter,
    fetchReporters: reporterActions.fetchReporters,
    updateReporter: reporterActions.updateReporter,
    SendAlert: alertActions.SendAlert
}
const connectedClass = connect(mapState, Actions)(AddReporter)
export { connectedClass as AddReporter }