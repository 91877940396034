import React,{useState,Component} from "react";
import {CheckBox} from "./CheckBox";
import {SorterSvg} from "./IconSvg";
class TableRowsG extends Component {
  constructor(props) {
    super(props);
    this.state={
      allchecked: 0,
      datahere: this.props.data,
      sorter: {
        value: null,
        current: null,
      },
      selectedRow:
        props.rowSelect&&Array.isArray(props.rowSelect.selectedRow)
          ? props.rowSelect.selectedRow
          :[],
    };
    this.handleSelectRow=this.handleSelectRow.bind(this);
  }
  handleSelectRow=(e) => {
    const {
      dataset: {rowtable},
    }=e.target;
    let prevRow=this.state.selectedRow;
    let allCheck=this.state.allchecked;
    if(rowtable==="all") {
      if(allCheck!==0) {
        prevRow=[];
        allCheck=0;
      } else {
        prevRow=this.props.data.map((row) => row[this.props.key_table]);
        allCheck=1;
      }
    } else {
      allCheck=2;
      let intvalRow=parseInt(rowtable);
      let indexValu=prevRow.indexOf(intvalRow);
      if(indexValu>-1) {
        prevRow=[...prevRow.filter((elem) => elem!=intvalRow)];
        allCheck=prevRow.length===0? 0:2;
      } else {
        prevRow=[...prevRow,intvalRow];
      }
    }
    this.setState({
      allchecked: allCheck,
      selectedRow: prevRow,
    });
    if(this.props.rowSelect.onChange) {
      this.props.rowSelect.onChange(prevRow);
    }
  };
  handleSorter(srt) {
    let sorter={
      value: null,
      current: null,
    };
    if(!this.state.sorter.current) {
      sorter={
        value: "ASC",
        current: srt,
      };
    } else if(this.state.sorter.current===srt) {
      if(this.state.sorter.value==="ASC") {
        sorter={
          value: "DESC",
          current: srt,
        };
      }
    } else {
      sorter={
        value: "ASC",
        current: srt,
      };
    }

    this.setState({
      sorter: sorter,
    });
    this.callBackSort(sorter,srt);
  }
  componentDidUpdate(prevProps) {
    if(this.props.data!==prevProps.data) {
      this.setState({
        datahere: this.props.data,
      });
    }
  }
  callBackSort(sorter,name) {
    const ss=[...this.props.data]
    if(sorter.value) {
      if(sorter.value==="ASC") {
        if(Array.isArray(this.props.data)) {
          if(name==='image') {
            ss.sort(function(a,b) {
              if(a.candidate.accept_condition<b.candidate.accept_condition) {
                return -1;
              }
              if(a.candidate.accept_condition>b.candidate.accept_condition) {
                return 1;
              }
              return 0;
            });
          } else if(name==='statusInvit') {

            ss.sort(function(a,b) {
              if(a.status_invitation<b.status_invitation) {
                return -1;
              }
              if(a.status_invitation>b.status_invitation) {
                return 1;
              }
              return 0;
            });
          } else {
            ss.sort(function(a,b) {
              if(a.candidate.user[name]<b.candidate.user[name]) {
                return -1;
              }
              if(a.candidate.user[name]>b.candidate.user[name]) {
                return 1;
              }
              return 0;
            });

          }
        }
      } else {
        if(Array.isArray(this.props.data)) {
          if(name==='image') {
            ss.sort(function(a,b) {
              if(a.candidate.accept_condition>b.candidate.accept_condition) {
                return -1;
              }
              if(a.candidate.accept_condition<b.candidate.accept_condition) {
                return 1;
              }
              return 0;
            });
          } else if(name==='statusInvit') {

            ss.sort(function(a,b) {
              if(a.status_invitation>b.status_invitation) {
                return -1;
              }
              if(a.status_invitation<b.status_invitation) {
                return 1;
              }
              return 0;
            });
          } else {
            ss.sort(function(a,b) {
              if(a.candidate.user[name]>b.candidate.user[name]) {
                return -1;
              }
              if(a.candidate.user[name]<b.candidate.user[name]) {
                return 1;
              }
              return 0;
            });
          }
        }
      }
      this.setState({
        datahere: ss,
      });
    } else {
      this.setState({
        datahere: this.props.data,
      });
    }
  }

  render() {
    return (
      <div
        className="width-100 OvfAtoX pos-relative paddTB-3"
        style={this.props.styleTop}
      >
        <div className="width-100 rad-10" style={{overflow: ""}}>
          <table
            className="text-left pad-table space-nowarp"
            style={{minWidth: "100%"}}
          >
            <thead className="index-1">
              <tr
                className={
                  "color-org RglCh-cntn "+
                  (this.props.classSticky? this.props.classSticky:"")
                }
              >
                {this.props.rowSelect&&(
                  <th className="bdg-blue" style={{zIndex: 2}}>
                    <CheckBox
                      onChange={this.handleSelectRow}
                      id="selet_all_candidate"
                      other={{
                        checked: this.state.allchecked,
                        "data-rowtable": "all",
                        className:
                          this.state.allchecked===2? "checkbox_middle":"",
                      }}
                    />
                  </th>
                )}
                {this.props.columns.map((head,key) => {
                  return (
                    <th className={"bdg-blue controleSorter "} key={key}>
                      {head.sorter? (
                        <div className="dis-flex content-btwn">
                          <span
                            className="rightP-5 curs-pointer userselectnone"
                            onClick={() =>
                              this.handleSorter(head.srt)
                            }
                          >
                            {head.name}
                          </span>
                          <span
                            className={
                              "dis-flex curs-pointer sorter sorter_svg "+
                              (this.state.sorter.current===head.srt&&
                                this.state.sorter.value)
                            }
                            onClick={() =>
                              this.handleSorter(head.srt)
                            }
                          >
                            {SorterSvg}
                          </span>
                        </div>
                      ):(
                        <span
                          className="userselectnone"
                        >
                          {head.name}
                        </span>
                      )}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody
              className={this.props.classBody}
              style={this.props.styleBody}
            >
              {Array.isArray(this.state.datahere)&&
                this.state.datahere.map((tr,key1) => {
                  return (
                    <tr
                      key={key1}
                      className="brd-btm curs-pointer hover-shadwh RglCh-cntn"
                    >
                      {this.props.rowSelect&&(
                        <th className="sticky_row fixed_left_row1">
                          <CheckBox
                            onChange={this.handleSelectRow}
                            id={
                              "selet_all_candidate"+tr[this.props.key_table]
                            }
                            other={{
                              checked:
                                this.state.selectedRow.indexOf(
                                  tr[this.props.key_table]
                                )>-1,
                              "data-rowtable": tr[this.props.key_table],
                            }}
                          />
                        </th>
                      )}
                      {tr&&
                        this.props.columns.map((clmn,key2) => {
                          if(clmn.render) {
                            return (
                              <td key={key2} style={clmn.style}>
                                {clmn.render(
                                  tr[clmn.rowKey],
                                  tr[clmn.rowParams],
                                  tr
                                )}
                              </td>
                            );
                          } else if(clmn.renderKey) {
                            return (
                              <td key={key2} style={clmn.style}>
                                {clmn.renderKey(key1)}
                              </td>
                            );
                          } else {
                            if(tr[clmn.rowKey]) {
                              return (
                                <td
                                  key={key2}
                                  style={clmn.style}
                                  title={tr[clmn.rowKey]}
                                >
                                  {tr[clmn.rowKey]}
                                </td>
                              );
                            }
                            return <td key={key2}></td>;
                          }
                        })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
export {TableRowsG};
