import React, { Component } from 'react'
import { Loader, PopTab } from '../../../_Common'
import { TopTabCheck } from './TopTabCheck'
import { Translate } from '../../../../utils'
import { connect } from 'react-redux'
import { SelectTime, TestKnow, Evaluation, PreviewTest, ConfirmPresence,AffectationEvaluateur } from './utils'
import { alertActions, evaluTestActions, testActions } from '../../../../actions'
import { testsConstants } from '../../../../constants'
class AddEvaluTest extends Component {
    constructor(props){
        super(props)
        this.state={
            id_jta: props.idJta!=='add' ? props.idJta :null
        }
        if(props.idJta!=='add'){
            props.editJta(props.idJta)
            
        }
    }
    componentDidUpdate(prevProps){
        const {SendAlert,StoreStep,getJta,Close,addingTest,dataTest}=this.props
        if(prevProps.addingTest==="0" && addingTest==="1" && dataTest){
            SendAlert("Opération réussie", "success")
            StoreStep("none", testsConstants.REMOVE_PROCESS_TEST)
            getJta()
            Close(undefined)
        }
        if(prevProps.addingTest==="0" && addingTest==="2"){
            SendAlert("Erreur lors de la création de test , esseyer plus tard", "error")
        }
    }
    handleAddJta = () => {
        const { dataGrille, dataEvaluator, datagrileEval, dataTime, dataGroupe, dataAffectationEvalu } = this.props
        let jta = {
            date_evaluation: dataTime,
            test_conaissance: {
                groupe_candidate_id:dataGroupe && dataGroupe.groupe_candidate_id,
                groupe_grid_id:dataGrille && dataGrille.evaluation_grid_id
            },
            test_evaluation: {
                evaluation_grid_id:datagrileEval && datagrileEval.evaluation_grid_id,
                affectation: dataEvaluator
            },
            affectations:dataAffectationEvalu
        }
        if(this.state.id_jta){
            jta.id_jta=this.state.id_jta
            this.props.UpdateJta(jta)
        }else{
            this.props.AddJta(jta)
        }
    }
    handleSetup() {
        switch (this.props.stepNumber) {
            case 1:
                return <AffectationEvaluateur AddJta={this.handleAddJta}/>
            case 2:
                return <ConfirmPresence AddJta={this.handleAddJta}/>
            case 3:
                return <Evaluation AddJta={this.handleAddJta}/>
            // case 4:
                // return <SelectTime AddJta={this.handleAddJta}/>
            case 4:
                return <PreviewTest AddJta={this.handleAddJta}/>
            default:
                return <TestKnow AddJta={this.handleAddJta}/>
        }
    }
    render() {
        return (
            <PopTab isActive={this.props.isActive} TabTitle={Translate('test', 'testjta')} close={() => this.props.Close(undefined)} width="94%" height="94%" classChild="OvfAtoY">
                <div className="dis-flex flex-direction item-center height-100">
                    {this.props.addingTest === "0" && <Loader/>}
                    <TopTabCheck />
                    {this.handleSetup()}
                </div>
            </PopTab>
        )
    }
}
function mapState(state) {
    const { stepNumber,dataEvaluator, datagrileEval, dataTime, dataGroupe, dataGrille, grilleAuto, grilleAutoEval, addSelfTest, dataSelfTest,dataAffectationEvalu } = state.tests
    const { addingTest, dataTest } = state.evaluTest
    return { stepNumber,dataEvaluator, datagrileEval, dataTime, dataGroupe, dataGrille, grilleAuto, grilleAutoEval, addSelfTest, dataSelfTest,dataAffectationEvalu, addingTest, dataTest }
}
const Actions = {
    AddJta:evaluTestActions.AddJta,
    UpdateJta:evaluTestActions.UpdateJta,
    getJta:evaluTestActions.getJta,
    editJta:evaluTestActions.editJta,
    StoreStep: testActions.StoreStep,
    SendAlert: alertActions.SendAlert
}
const ConnectedClass = connect(mapState,Actions)(AddEvaluTest)
export { ConnectedClass as AddEvaluTest }