import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate, FormatDate } from '../../../../utils'
import { testActions } from '../../../../actions'
import { InlineElemnt, PopTab, BtnClick, InfoTable, Loader, CheckClrSvg, MissingSvg } from '../../../_Common'
class DetailEvaluTest extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showDetail: false
        }
        props.getSelftTestEvalu(props.isActive)
        this.showResponse = this.showResponse.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }
    showResponse(idtest, idEvalu, evaluTab) {
        this.state.showDetail = evaluTab
        this.props.detailResponseEvalu(idtest, idEvalu)
    }
    handleClose() {
        this.setState({
            showDetail: null
        })
    }
    render() {
        const { getingEvalDetail, dataEvalDetailselft, getingEvalAns, dataEvalAnswers } = this.props
        let title = "Chargement du test ..."
        let list = []
        let labels = []
        let qestions = []
        let titleresponse = "Chargement de reponse ..."
        let titleGrille = ""
        let noAnswer = true
        if (getingEvalDetail === "1" && dataEvalDetailselft && dataEvalDetailselft.test) {
            title = " Le: " + FormatDate(dataEvalDetailselft.test.dateautotest)
            list = Array.isArray(dataEvalDetailselft.test.evaluators) ? dataEvalDetailselft.test.evaluators.map(evalu => {
                return [evalu.user.lastname, evalu.user.firstname, evalu.tablette.color, this.props.showDetail ? <BtnClick onClick={() => this.showResponse(dataEvalDetailselft.test.test_auto_id, evalu.user.id, { fullname: evalu.user.lastname + " " + evalu.user.firstname, color: evalu.tablette.color })}
                    TxtBtn="Voir la réponse"
                    Color="bdg-onrg-op color-org select-non" /> : "En attent"]
            }) : []
        }
        if (this.state.showDetail && getingEvalAns === "1" && dataEvalAnswers && dataEvalAnswers.answers) {
            labels = dataEvalAnswers.answers.label_auto_test ? dataEvalAnswers.answers.label_auto_test.split(";") : []
            qestions = Array.isArray(dataEvalAnswers.answers.questions) ? dataEvalAnswers.answers.questions : []
            titleresponse = <span className="clr-grey">Réponse d'évaluateur: <strong className="setFntDefault">{this.state.showDetail.fullname}</strong> sur la tabllette : <strong className="setFntDefault">{this.state.showDetail.color}</strong></span>
            titleGrille = dataEvalAnswers.answers.title_auto_evaluation
            noAnswer = (qestions.length !== 0)
        }
        return (
            <PopTab isActive={this.props.isActive} TabTitle={title} close={() => this.props.Close(undefined)} width="92%" height="92%">
                {getingEvalDetail === "0" && <Loader />}
                <div className="height-100 OvfAtoY">
                    <InfoTable
                        thead={[Translate('fields', 'lastname'), Translate('fields', 'firstname'), Translate('tablette', 'color'), Translate('fields', 'action')]}
                        tbody={list}
                    />
                </div>
                {this.state.showDetail &&
                    <PopTab isActive={this.state.showDetail} TabTitle={titleresponse} close={this.handleClose} width="90%" height="90%">
                        {getingEvalAns === "0" && <Loader />}
                        <h3>{titleGrille}</h3>
                        {noAnswer ?
                            <table className="width-100 setTablw">
                                <thead>
                                    <tr className="brd-btm">
                                        <th className="" style={{ width: "550px" }}></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {qestions.map((qst, key) => {
                                        return <tr key={key} className="brd-btm">
                                            <td>
                                                <div>
                                                    <strong>{(key + 1) + "/ "}</strong>{qst.textquestionautoeval}
                                                </div>
                                            </td>
                                            <td>{qst.answer}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table> :
                            <div className="dis-flex flex-direction content-center item-center width-100 height-100">
                                {MissingSvg}
                                <h2>Accune réponse de ce candidate</h2>
                            </div>}
                    </PopTab>}
            </PopTab>
        )
    }
}
function mapState(state) {
    const { getingEvalDetail, dataEvalDetailselft, getingEvalAns, dataEvalAnswers } = state.tests
    return { getingEvalDetail, dataEvalDetailselft, getingEvalAns, dataEvalAnswers }
}
const actionsClass = {
    getSelftTestEvalu: testActions.getSelftTestEvalu,
    detailResponseEvalu: testActions.detailResponseEvalu
}
const connectedClass = connect(mapState, actionsClass)(DetailEvaluTest);
export { connectedClass as DetailEvaluTest };