import React,{Component} from "react";
import {IoArchiveSharp,IoSend} from "react-icons/io5";
import {connect} from "react-redux";
import {messagingActions} from "../../../actions";
import {InlineElemnt,Loader} from "../../_Common";
import {MessageItem} from "./utils";

class MessageContent extends Component {
    constructor(props) {
        super(props)
        this.state={
            message: ""
        }
        this.scroleBottom=false
        this.messageContainerRef=React.createRef(null)
        window.activeChannel=props.match.params.idChannel
        this.fetchMessages()
    }
    componentDidMount() {
        window.Echo.channel(`messaging-adminsJta`)
            .listen('MessagingEvent',(e) => {
                if(e.message&&e.source) {
                    if(e.source===this.props.match.params.idChannel) {
                        // console.log('888888888888888888888888888888888888888888888 hey hey')
                        this.scroleBottom=true
                        this.props.AddMessage(e.message)
                        this.props.readallmasgsactivechannel({idchannel: this.props.match.params.idChannel})
                        var audio=new Audio('/sounds/newmsg.mp3');
                        audio.play();
                        // console.log('----- -----MessagingEvent listening --------------- 2222')
                        // console.log('channel here is :', this.props.match.params.idChannel)
                        // console.log('e.source is :', e.source)
                    }
                }
            })
    }
    componentWillUnmount() {
        window.Echo.leaveChannel('messaging-adminsJta');
        // console.log('bye bye channel Admin ==')
    }
    // componentDidMount() {

    //     window.Echo.channel(`messaging-adminsJta`)
    // //         .listen('MessagingEvent',(e) => {
    // //             if(e.message&&e.source) {
    // //                 // console.log('channel is :', e.channel)
    // //                 if(e.source===this.props.match.params.idChannel) {
    // //                     this.scroleBottom=true
    // //                     this.props.AddMessage(e.message)
    // //                     // this.props.readallmasgsactivechannel(this.props.match.params.idChannel)
    // //                     setTimeout(() => {
    // //                         this.props.GetNotificationall()
    // //                     }, 1000);
    // //                 }
    // //             }
    // //         })
    // }
    componentDidUpdate(prevProps) {
        if(prevProps.match.params.idChannel!==this.props.match.params.idChannel) {
            window.activeChannel=this.props.match.params.idChannel
            this.fetchMessages()
        }
        if(prevProps.syncMessageChannel===0&&this.props.syncMessageChannel===1) {
            this.scrollToBottom()
        }
        if(this.scroleBottom) {
            this.scroleBottom=false
            this.scrollToBottom()
        }
    }
    scrollToBottom=() => {
        if(this.messageContainerRef&&this.messageContainerRef.current) {
            this.messageContainerRef.current.scrollIntoView({behavior: 'smooth'})
        }
    }
    fetchMessages=() => {
        this.props.getChannelMessages(this.props.match.params.idChannel)
    }
    handleChange=(e) => {
        const {name,value}=e.target
        this.setState({
            [name]: value
        })
    }
    sendMessage=(e) => {
        e.preventDefault()
        if(!this.state.message) return
        this.props.SendMessage({message: this.state.message,idChannel: this.props.match.params.idChannel})
        this.state.message=""
    }
    archiveChannel=(e) => {
        messagingActions.ArchiveChannel(this.props.match.params.idChannel)
            .then(({data: {status}}) => {
                if(status==="done") {
                    this.props.history.push('/candidate/chat')
                }
            })
            .catch(err => {})
    }
    render() {
        const {syncMessageChannel,messagesChannel,pictureChannel,nameChannel}=this.props
        // console.log('messagesChannel : ', messagesChannel)
        return (
            <div className="dis-flex flex-direction height-100">
                {syncMessageChannel===0&&<Loader />}
                <div style={{paddingBottom: 10,paddingTop: 4,borderBottom: "1px solid #00000020"}}>
                    <InlineElemnt
                        first={<img src={pictureChannel}
                            onError={e => {e.currentTarget.src="/assets/img/default.png";}}
                            alt='pic' style={{borderRadius: "100%"}} className="width-100 height-100" />}
                        classFirst="sm-widhg"
                        secend={<h3 className="margin-null">{nameChannel}</h3>}
                        classSecend=""
                        more={<div className="leftM-auto rightM-15">
                            <IoArchiveSharp onClick={this.archiveChannel} className="mm-widthg curs-pointer icon_color" />
                        </div>}
                    />
                </div>
                <div className="height-100 OvfAtoY paddTB-10 pos-relative">
                    {messagesChannel.map(({message_text,is_me,created_at}={},key) => {
                        return <MessageItem key={key} isMe={is_me} message={message_text} picture={pictureChannel} datamsg={created_at} />
                    })}
                    <div ref={this.messageContainerRef}></div>
                </div>
                <form onSubmit={this.sendMessage} className="dis-flex item-center brd-equ rad-5 padding-def" style={{borderWidth: 2}}>
                    <input type="text" autoComplete="off" name="message" onChange={this.handleChange} placeholder="Écrire votre réponse ..." value={this.state.message} className="sizeF-1em width-100" />
                    <IoSend className="curs-pointer" onClick={this.sendMessage} style={{width: 25,height: 25}} />
                </form>
            </div>
        )
    }
}
function mapState(state) {
    const {syncMessageChannel,messagesChannel,pictureChannel,nameChannel}=state.messaging
    return {syncMessageChannel,messagesChannel,pictureChannel,nameChannel}
}
const connectedClass=connect(mapState,messagingActions)(MessageContent);
export {connectedClass as MessageContent};