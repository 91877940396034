import { userConstants } from '../constants';
export function auth(state , action) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                loging:"0",
                dataLogin: action.payload
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                loging:"1",
                dataLogin: action.payload
            };
        case userConstants.LOGIN_FAILURE:
            return {
                loging:"2",
                dataLogin: action.payload
            };
        case userConstants.UPDATE_PASSWORD_REQUEST:
            return {
                ...state,
                updatinPassword:"0",
                dataPassword: action.payload
            };
        case userConstants.UPDATE_PASSWORD_SUCCESS:
            return {
                ...state,
                updatinPassword:"1",
                dataPassword: action.payload
            };
        case userConstants.UPDATE_PASSWORD_FAILURE:
            return {
                ...state,
                updatinPassword:"2",
                dataPassword: action.payload
            };
        default:
            return {...state}
        }
}