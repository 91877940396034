import { userConstants } from '../constants';
import { RequestService,FunctionService  } from '../services';
export const userActions = {
    GetUser
};
function GetUser(){
    return dispatch => {
        dispatch(FunctionService.request(userConstants.GET_USER_REQUEST))
        RequestService.GetRequest('user',true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,userConstants.GET_USER_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,userConstants.GET_USER_FAILURE))
        })
    }
}

