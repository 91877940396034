import React, { Component } from "react";
import { Translate } from '../../../../../../utils';
import { BtnClick, SelectSearch } from '../../../../../_Common'
import Board from '@lourenci/react-kanban'
import '@lourenci/react-kanban/dist/styles.css'
import { exportsActions } from "../../../../../../actions";
class AffectationGroupe extends Component {
    constructor(props) {
        super(props)
        this.state = {
            grille: props.grille
        }
        this.boards = props.dataBoards
        this.handleBoardUpdate = this.handleBoardUpdate.bind(this)
        this.handleGrille = this.handleGrille.bind(this)
        this.handleExportExcel = this.handleExportExcel.bind(this)
        this.nextStep = this.nextStep.bind(this)
    }
    handleBoardUpdate(board) {
        this.boards = board
    }
    handleExportExcel() {
        exportsActions.ExportAffectations({colomns:this.boards.columns});
    }
    handleGrille(grille) {
        this.setState({
            grille: grille
        })
    }
    nextStep() {
        this.props.handleNext(this.boards, this.state.grille)
    }
    render() {
        const { getEvalEva, dataEvalEva } = this.props
        var grilles = []
        if (getEvalEva === "1" && dataEvalEva && Array.isArray(dataEvalEva.grids)) {
            grilles = dataEvalEva.grids
        }
        return (
            <div className="dis-flex flex-direction">
                <h3 className="margin-null">Répartition des groupes</h3>
                <div style={{ zoom: ".9" }}>
                    <Board 
                    renderCard={({ lastname, firstname, photo, fit, age, civility, city }, { dragging }) => (
                        <div style={{paddingInline:15}} dragging={dragging}>
                            <div className="bdg-white width-100 rad-10 padding-def mtb-5 box-def box-border" >
                                <div className="dis-flex item-center">
                                    <img alt="Image" src={photo ? photo :civility==='Madame'? "/assets/img/default_women.png": "/assets/img/default.png"} className="rad-100 image_board" />
                                    <h4 className="margin-null leftM-8">{lastname + " " + firstname}</h4>
                                </div>
                                <div className="dis-flex flex-direction topM-5 st_border_top" style={{ paddingTop: "7px" }}>
                                    <div className="dis-flex content-btwn">
                                        <div><strong>Age : </strong><span>{age}</span></div>
                                        <div className="leftM-8"><strong>Fit général : </strong><span>{fit}</span></div>
                                    </div>
                                    <div><strong>Civilité : </strong><span>{civility}</span></div>
                                    <div><strong>Ville : </strong><span>{city}</span></div>
                                </div>
                            </div>
                        </div>
                    )}
                    renderColumnHeader={({title,color_code})=>{
                        return <h4 className="dis-block margin-null" style={{backgroundColor:color_code+'20',padding:'12px 25px',border:'4px solid '+color_code,borderBottomWidth:0}}>{title}</h4>
                    }}
                    initialBoard={this.props.dataBoards} 
                    onColumnDragEnd={this.handleBoardUpdate} 
                    onCardDragEnd={this.handleBoardUpdate} />
                </div>
                <div>
                    <div className="stMrgv-tb leftM-auto righttM-auto" style={{ width: "70%" }}>
                        <h3 className="linhgth" style={{ marginTop: "0px" }}>{Translate('test', 'grrildeval')}</h3>
                        <div className="dis-flex">
                            <SelectSearch field="titlegrid" placeholder="Choisir la grille de test ..." hideOnChose={true} value={this.state.grille} datalist={grilles} maxHieght="250px" handleSelect={this.handleGrille} />
                        </div>
                    </div>
                    <div className="dis-flex content-btwn topM-22">
                        <BtnClick
                            onClick={this.props.goBack}
                            TxtBtn="Retourner"
                            Color="bdg-onrg-op color-org"
                        />
                        <div>
                            <BtnClick
                                onClick={this.handleExportExcel}
                                TxtBtn="Exporter les groupes"
                                Color="bdg-onrg-op color-org"
                            />
                            <BtnClick
                                onClick={this.nextStep}
                                TxtBtn={Translate('test', 'nextstep')}
                                Color="bdg-onrg clr-white leftM-8"
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export { AffectationGroupe }