import { adminConstants } from '../constants';
export function admin(state , action) {
    switch (action.type) {
        case adminConstants.ADD_ADMIN_REQUEST:
            return {
                ...state,
                addingadm:"0",
                dataadm: action.payload
            };
        case adminConstants.ADD_ADMIN_SUCCESS:
            return {
                addingadm:"1",
                dataadm: action.payload
            };
        case adminConstants.ADD_ADMIN_FAILURE:
            return {
                ...state,
                addingadm:"2",
                dataadm: action.payload
            };
        case adminConstants.GET_ADMIN_REQUEST:
            return {
                ...state,
                getiadimn:"0",
                dataAdmin: action.payload
            };
        case adminConstants.GET_ADMIN_SUCCESS:
            return {
                getiadimn:"1",
                dataAdmin: action.payload
            };
        case adminConstants.GET_ADMIN_FAILURE:
            return {
                ...state,
                getiadimn:"2",
                dataAdmin: action.payload
            };
        case adminConstants.UPDATE_ADMIN_REQUEST:
            return {
                ...state,
                updatingAdmin:"0",
                dataadm: action.payload
            };
        case adminConstants.UPDATE_ADMIN_SUCCESS:
            return {
                updatingAdmin:"1",
                dataadm: action.payload
            };
        case adminConstants.UPDATE_ADMIN_FAILURE:
            return {
                ...state,
                updatingAdmin:"2",
                dataadm: action.payload
            };
        case adminConstants.DELETE_ADMIN_REQUEST:
            return {
                ...state,
                deletingAdmin:"0",
                dataDelete: action.payload
            };
        case adminConstants.DELETE_ADMIN_SUCCESS:
            return {
                deletingAdmin:"1",
                dataDelete: action.payload
            };
        case adminConstants.DELETE_ADMIN_FAILURE:
            return {
                ...state,
                deletingAdmin:"2",
                dataDelete: action.payload
            };
        default:
            return {...state}
        }
}