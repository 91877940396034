import React from 'react'
export function NavTab(props) {
    return (
        <div className="dis-flex item-center sizeF-1em">
            {props.ElemeList.map((tab, key) => {
                return <div key={key} style={{ paddingBlock: "7px", marginInline: "8px" }} className={(tab.active ? "font-600 clr_prpl" : "") + " bdgTrans_btn sTrgPdn rad-5"} >
                    {tab.text}
                </div>
            })}
        </div>
    )
}