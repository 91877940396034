import React, { Component } from 'react'
import { FormatDate } from '../../../../../utils'
import { InlineElemnt, DownArrowSvg } from '../../../../_Common'
import { ElementTest } from './ElementTest'
class LabelTest extends Component {
    constructor(props) {
        super(props)
        this.state = {
            viewLabel: true
        }
        this.handleToggle = this.handleToggle.bind(this)
    }
    handleToggle() {
        let currentState = this.state.viewLabel
        this.setState({
            viewLabel: !currentState
        })
    }
    render() {
        return (
            <div className={this.props.className}>
                {!this.props.histo && (
                    <div className={"dis-flex sm-padding curs-pointer " + this.props.bdg_st} onClick={this.handleToggle}>

                        <InlineElemnt
                            className="font-600 curs-pointer sm-padding width-mxcn rad-5"
                            classSecend="sizeF-9em leftM-5"
                            first={<span className="dis-flex hasRotat" data-rotate={this.state.viewLabel ? 'up' : 'down'}>{DownArrowSvg}</span>}
                            secend={this.props.textLabel}
                        />
                    </div>
                )}
                <div className="  dis-flex flx-warp width-100 ToggleCntn2 OvfAtoY" toggle={this.state.viewLabel ? 'up' : 'down'} style={{ paddingBottom: '20px' }}>
                    {Array.isArray(this.props.testList) ? this.props.testList.map((Test, key) => {
                        return (<>
                            {
                                this.props.histo ?
                                    Test.status_iterop === "sended" ?
                                        <ElementTest
                                            key={key}
                                            handleJta={this.props.handleJta}
                                            idJta={Test.jta_evaluation_id}
                                            TestStatus={this.props.TestStatus}
                                            statusIterop={Test.status_iterop}
                                            GroupeName={Test.test_connais && Test.test_connais.group && Test.test_connais.group[0] && Test.test_connais.group[0].groupename}
                                            createdAt={FormatDate(Test.created_at, true)}
                                            dateTest={FormatDate(Test.date_evaluation)}
                                        />
                                        : ''
                                    : Test.status_iterop !== "sended" ?
                                        <ElementTest
                                            key={key}
                                            handleJta={this.props.handleJta}
                                            idJta={Test.jta_evaluation_id}
                                            TestStatus={this.props.TestStatus}
                                            statusIterop={Test.status_iterop}
                                            GroupeName={Test.test_connais && Test.test_connais.group && Test.test_connais.group[0] && Test.test_connais.group[0].groupename}
                                            createdAt={FormatDate(Test.created_at, true)}
                                            dateTest={FormatDate(Test.date_evaluation)}
                                        /> : ''
                            }
                        </>)
                    }) : ''}
                </div>
            </div >
        )
    }
}
export { LabelTest }