import { userConstants } from '../constants';
import { RequestService, FunctionService } from '../services';
import axios from 'axios'
import Cookies from 'js-cookie'
export const authActions = {
    Login,
    Logout,
    UpdatePassword
};
function Login(user) {
    return dispatch => {
        dispatch(FunctionService.request(userConstants.LOGIN_REQUEST))
        RequestService.PostRequest('user/login', user)
            .then(res => {
                dispatch(FunctionService.succes(res.data, userConstants.LOGIN_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err.response, userConstants.LOGIN_FAILURE))
            })
    }
}
function Logout() {
    return dispatch => {
        dispatch(FunctionService.request(userConstants.LOGOUT_REQUEST))
        RequestService.PostRequest('account/logout', { token: Cookies.get('c_user') }, true)
            .then(res => {
                dispatch(FunctionService.succes(res.data, userConstants.LOGOUT_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err.response, userConstants.LOGOUT_FAILURE))
            })
    }
}
function UpdatePassword(dataPassword, token) {
    return dispatch => {
        dispatch(FunctionService.request(userConstants.UPDATE_PASSWORD_REQUEST))
        axios.post(process.env.REACT_APP_URL_APP+"password", dataPassword, {
            headers: { "Authorization": "Bearer " + token }
        })
            .then(res => {
                dispatch(FunctionService.succes(res.data, userConstants.UPDATE_PASSWORD_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err.response, userConstants.UPDATE_PASSWORD_FAILURE))
            })
    }
}