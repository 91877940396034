import React, { Component } from 'react'
import { connect } from 'react-redux'
import {evaluatorActions,alertActions} from '../../../actions'
import {Translate} from '../../../utils'
import {PopTab,InputForm,BtnSubmit,SelectForm,Loader} from '../../_Common'
class AddEvaluator extends Component{
    constructor(props){
        super(props)
        this.state={
            firstname:props.default.firstname,
            lastname:props.default.lastname,
            email:props.default.email,
            phone:props.default.phone,
            status:props.default.evaluator_status
        }
        this.HandleChange=this.HandleChange.bind(this)
        this.handleSubmit=this.handleSubmit.bind(this)
    }
    componentWillReceiveProps(nextProps){
        const {addingevalu,dataevalu,close,getEvaluator,updatingeval}=nextProps
        if(this.props.addingevalu==="0" && addingevalu==="1" && dataevalu){
            this.props.SendAlert("Opération réussie","success")
            close(undefined)
            getEvaluator()
        }
        if(this.props.updatingeval==="0" &&  updatingeval==="1" && dataevalu){
            getEvaluator()
            close(undefined)
            this.props.SendAlert("Opération réussie","success")
        }
        if(this.props.addingevalu==="0" && addingevalu==="2" && dataevalu){
            this.props.SendAlert("Vérifier les informations de l'évaluateur ou esseyer plus tard","error")
        }
    }
    HandleChange(e){
        const {name,value}=e.target
        this.setState({
            [name]:value
        })
    }
    handleSubmit(e){
        e.preventDefault()
        let evaluator={
            email:this.state.email,
            firstname:this.state.firstname,
            lastname:this.state.lastname,
            phone:this.state.phone,
            status:this.state.status
        }
        if(this.props.active==="add"){
            this.props.AddEvaluator(evaluator)
        }else{
            evaluator.iduser=this.props.active
            this.props.UpdateEvaluator(evaluator)
        }
        
    }
    render(){
        const {addingevalu,updatingeval}=this.props
        return(
            <PopTab isActive={this.props.active} TabTitle={Translate('evaluateur','creeevalu')} close={() => this.props.close(undefined)} width="550px">
                {(addingevalu==="0" || updatingeval==="0") && <Loader/>}
                <form onSubmit={this.handleSubmit}>
                    <h4 className="clr-grey sTmr-si">{Translate('evaluateur','accountinfo')}</h4>
                    <div className="dis-flex">
                        <InputForm
                            className="rightM-8"
                            placeholder={Translate('fields','lastname')}
                            name="lastname"
                            type="text"
                            onChange={this.HandleChange}
                            value={this.state.lastname}
                        />
                        <InputForm
                            placeholder={Translate('fields','firstname')}
                            name="firstname"
                            type="text"
                            onChange={this.HandleChange}
                            value={this.state.firstname}
                        />
                    </div>
                    <div className="dis-flex topM-8">
                        <InputForm
                            className="rightM-8"
                            placeholder={Translate('fields','email')}
                            name="email"
                            type="email"
                            onChange={this.HandleChange}
                            value={this.state.email}
                        />
                        <InputForm
                            placeholder={Translate('fields','phone')}
                            name="phone"
                            type="phone"
                            onChange={this.HandleChange}
                            value={this.state.phone}
                        />
                    </div>
                    <div className="dis-flex topM-8">
                        <SelectForm 
                            className=""
                            placeholder={Translate('fields','birth')}
                            name="status"
                            onChange={this.HandleChange}
                            value={this.state.status}
                        >
                            <option value="" >Sélectionner un statut...</option>
                            <option value="Mentorat" selected={this.state.status==="Mentorat"}>Mentorat</option>
                            <option value="Formateur" selected={this.state.status==="Formateur"}>Formateur</option>
                            <option value="Mentorat et Formateur" selected={this.state.status==="Mentorat et Formateur"}>Mentorat et Formateur</option>
                        </SelectForm>
                    </div>
                    <div className="dis-flex leftM-auto sTrgPdn sTmr-si">
                        <BtnSubmit
                            TxtBtn={Translate('evaluateur','ceerlevalu')}
                            Color="width-100 bdg-onrg clr-white"
                        />
                    </div>
                </form>
            </PopTab>
        )
    }
}
function mapState(state){
    const {addingevalu,dataevalu,updatingeval}=state.evaluator
    return {addingevalu,dataevalu,updatingeval}
}
const Actions={
    AddEvaluator:evaluatorActions.AddEvaluator,
    getEvaluator:evaluatorActions.getEvaluator,
    UpdateEvaluator:evaluatorActions.UpdateEvaluator,
    SendAlert:alertActions.SendAlert
}
const connectedClass=connect(mapState,Actions)(AddEvaluator)
export {connectedClass as AddEvaluator}