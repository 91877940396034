import React, { Component, Fragment } from 'react'
import { Translate } from '../../utils'
import { DropList } from './DropList'
import { BtnAction } from './Bottuns'
import { InlineElemnt } from './InlineElemnt'
import { CloseSvg, DeletSvg, ReduceSvg, ExtendsSvg, GallerySvg } from './IconSvg'
export class QestionElem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            stateQst: true
        }
        this.DetailQst = this.DetailQst.bind(this)
    }
    prasedata(key, detail) {
        switch (key) {
            case "QCM":
            case "Class":
                return (
                    <Fragment>
                        {detail.map((detail, key1) => {
                            return <InlineElemnt
                                className="sTmr-si"
                                classSecend="leftM-auto"
                                first={<InlineElemnt first={<input onChange={this.props.onChangeOption} data-index={this.props.index} data-option={key1} data-groupe={this.props.groupe} style={{ width: "400px" }} className="sizeF-1em padding-def brd-btm" type="text" value={detail.detailtext} placeholder="Option" />} secend={<i onClick={() => this.props.onDeleteOption(this.props.index, key1, this.props.groupe)} className="dis-flex onHvr curs-pointer paddAll-5 rad-100">{CloseSvg}</i>} />}
                                secend={<div>
                                    {this.props.typeShow === "f" && <strong className="sTrgPdn">Nbr points : </strong>}
                                    <input className={this.props.typeShow === "f" ? "width-100px" : ""} placeholder={this.props.typeShow === "f" ? "Nbr points" : "le bon classement"} value={detail.detailanswer} id={"qestion_" + this.props.index + "option_" + key1} checked={detail.detailanswer === 1} onChange={this.props.onChangeAnswer} data-index={this.props.index} data-option={key1} data-groupe={this.props.groupe} type={(key === "QCM" && this.props.typeShow !== "f") ? "checkbox" : "number"} />
                                    {(this.props.data.typequestion === "QCM" && this.props.typeShow !== "f") && <label className="dis-flex" htmlFor={"qestion_" + this.props.index + "option_" + key1}>Réponse juste ?</label>}
                                </div>}
                            />
                        })}
                        <div className="dis-flex content-btwn item-center topM-8">
                            <div className="width-mxcn" onClick={() => this.props.addOption(this.props.index, this.props.groupe)}>
                                <InlineElemnt
                                    className="font-600 curs-pointer clr-grey padding-def onHvr width-mxcn rad-5"
                                    classSecend="sizeF-9em leftM-5"
                                    first={"+"}
                                    secend={"Ajouter Option"}
                                />
                            </div>
                            {this.props.typeShow !== "f" && key === "QCM" &&
                                <div>
                                    <input type="file" className="hide_input" id={"image_qst_" + this.props.index} data-index={this.props.index} onChange={this.props.handleImage} name={"photoqst_" + this.props.index} />
                                    <label htmlFor={"image_qst_" + this.props.index}>
                                        <div className="width-mxcn">
                                            <InlineElemnt
                                                className="font-600 curs-pointer clr-grey padding-def onHvr width-mxcn rad-5"
                                                classSecend="sizeF-9em leftM-5"
                                                first={<i className="clr_svg dis-flex">{GallerySvg}</i>}
                                                secend={"Associer une image"}
                                            />
                                        </div>
                                    </label>
                                </div>}
                        </div>
                    </Fragment>
                )
                break;
            case "vraifaux":
                return (
                    <div className="dis-flex">
                        {this.props.typeShow !== "f" ? <>
                            <label className="curs-pointer width-120 rightM-8" htmlFor={"qestion_" + this.props.index + "1"}>
                                <InlineElemnt
                                    className="onHvr brd-equ rad-5 pd-10-15"
                                    classSecend=""
                                    first={<input value={1} id={"qestion_" + this.props.index + "1"} onChange={this.props.onChangeAnswer} checked={detail[0].detailanswer == "1"} data-index={this.props.index} data-option={0} name={"qestion_" + this.props.index + "radio" + this.props.index} type="radio" />}
                                    secend={"Oui"}
                                />
                            </label>
                            <label className="curs-pointer width-120" htmlFor={"qestion_" + this.props.index + "0"}>
                                <InlineElemnt
                                    className="onHvr curs-pointer brd-equ rad-5 pd-10-15"
                                    classSecend=""
                                    first={<input data-index={this.props.index} data-option={0} id={"qestion_" + this.props.index + "0"} value={0} checked={detail[0].detailanswer == "0"} onChange={this.props.onChangeAnswer} name={"qestion_" + this.props.index + "radio" + this.props.index} type="radio" />}
                                    secend={"Non"}
                                />
                            </label>
                        </> : <>
                            <InlineElemnt
                                className="brd-equ pd-10-15 leRgM-15 sTmr-si width-100"
                                classSecend="leftM-auto"
                                first={<strong className="sizeF-11em">Oui</strong>}
                                secend={<><strong className="sTrgPdn">Nbr points : </strong><input className="sizeF-1em width-100px" placeholder={"Nbr points"} value={detail[0].detailanswer} id={"qestion_" + this.props.index + "option_0"} onChange={this.props.onChangeAnswer} data-index={this.props.index} data-option={0} data-groupe={this.props.groupe} type="number" /></>}
                            />
                            <InlineElemnt
                                className="brd-equ pd-10-15 leRgM-15 sTmr-si width-100"
                                classSecend="leftM-auto"
                                first={<strong className="sizeF-11em">Non</strong>}
                                secend={<><strong className="sTrgPdn">Nbr points : </strong><input className="sizeF-1em width-100px" placeholder="Nbr points" value={detail[1].detailanswer} id={"qestion_" + this.props.index + "option_1"} onChange={this.props.onChangeAnswer} data-index={this.props.index} data-option={1} data-groupe={this.props.groupe} type="number" /></>}
                            />
                        </>}
                    </div>
                )
                break;
            case "libre":
                return (
                    <>
                        <textarea disabled className="width-100 hegth-v50 " placeholder="La réponse pour cette question sera libre"></textarea>
                    </>
                )
                break;
            default:
                return (
                    <strong className="text-center leftM-auto righttM-auto sTmr-si dis-block padding-def">Veuillez préciser le type de question pour commencer</strong>
                )
                break;
        }
    }
    handleChange(value) {
        this.props.onChangeType(this.props.index, value.value, this.props.groupe)
    }
    DetailQst() {
        let current = this.state.stateQst
        this.setState({
            stateQst: !current
        })
    }
    render() {
        return (
            <div className="rad-10 brd-equ pd-14-20 width-100 brd-lft-clr sTmr-si bdg-white">
                <div className="dis-flex">
                    <input type="text" onChange={this.props.onChangeTitle} data-groupe={this.props.groupe} data-index={this.props.index} className="sizeF-11em font-600 brd-btm bottomP-5" style={{ width: "60%" }} placeholder={Translate('qestion', 'qst')} value={this.props.data.text} autoComplete="off" />
                    <DropList className="leftM-auto" default={this.props.data.typequestion ? { text: Translate('qestion', this.props.data.typequestion), value: this.props.data.typequestion } : null} onChange={this.handleChange.bind(this)} items={[{ text: Translate('qestion', 'QCM'), value: "QCM" }, ...(this.props.typeShow !== "f" ? [{ text: Translate('qestion', 'Class'), value: "Class" }] : []), { text: Translate('qestion', 'vraifaux'), value: "vraifaux" }, { text: Translate('qestion', 'libre'), value: "libre" }, ...(this.props.typeShow === "f" ? [{ text: "Commentaire JTA", value: "jtaCmnt" }, { text: "Appréciation globale", value: "appr" }] : [])]} placeholder={Translate('fields', 'selecton')} style={{ width: "295px" }} />
                </div>
                {this.props.data.image && <div className="dis-flex content-center stMrgv-tb">
                    <img src={this.props.data.image} style={{ maxHeight: "200px", maxWidth: "95%" }} />
                </div>}
                <div className="sTmr-si">{this.state.stateQst ? this.prasedata(this.props.data.typequestion, this.props.data.details) : ''}</div>
                <hr />
                <div className="dis-flex width-mxcn leftM-auto">
                    <span onClick={this.DetailQst}>
                        <BtnAction className="bdg-none onHvr" iconBtn={this.state.stateQst ? ReduceSvg : ExtendsSvg} />
                    </span>
                    <span onClick={() => this.props.onDelete(this.props.index, this.props.groupe)}>
                        <BtnAction className="leftM-8 bdg-none onHvr" iconBtn={DeletSvg} />
                    </span>
                </div>
            </div>
        )
    }
}