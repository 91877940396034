import React, { Component } from 'react'
import { connect } from 'react-redux'
import { selfGrilleActions, alertActions } from '../../../../actions'
import { Translate } from '../../../../utils'
import { InlineElemnt, PopTab, BtnClick, Loader, EmojiViewers } from '../../../_Common'
import { DragToReorderList } from './utils'
class AddSelfGrille extends Component {
    constructor(props) {
        super(props)
        this.state = {
            title: "",
            labels: [""],
            qestions: [
                { index: 0, text: '', needJustify: false }
            ]
        }
        if (this.props.isDuplicated) {
            this.props.getDetailGrille(this.props.isDuplicated)
        }
        this.handleChange = this.handleChange.bind(this)
        this.ChangeQst = this.ChangeQst.bind(this)
        this.ChangeLable = this.ChangeLable.bind(this)
        this.removeQst = this.removeQst.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.cancelChange = this.cancelChange.bind(this)
        this.setList = this.setList.bind(this)
        this.onEmojiClick = this.onEmojiClick.bind(this)
        this.needJustifyQst = this.needJustifyQst.bind(this)
    }
    componentWillReceiveProps(nextProps) {
        const { addSelf, dataSelf, Close, getSelfGrille, getDetailgri, dataDetail } = nextProps
        if (this.props.addSelf === "0" && addSelf === "1" && dataSelf && dataSelf.message === "grid created successfully") {
            this.props.SendAlert("Opération réussie", "success")
            getSelfGrille()
            Close(undefined)
        }
        if (this.props.addSelf === "0" && addSelf === "2") {
            this.props.SendAlert("Erreur lors de la création de la grille , esseyer plus tard", "error")
        }
        if (this.props.getDetailgri === "0" && getDetailgri === "1" && dataDetail && dataDetail.details) {
            this.state.title = dataDetail.details.title_auto_evaluation
            if (this.props.currentTab === "candidate") {
                this.state.labels = dataDetail.details.label_auto_test.split(";")
            }
            this.state.qestions = dataDetail.details.questions.map((qst, key) => { return { index: key, text: qst.textquestionautoeval, needJustify: qst.need_justify } })
        }
    }
    handleChange(e) {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }
    ChangeLable(e) {
        const { name, value, dataset } = e.target
        let Qst = this.state[name]
        Qst[dataset.index] = value
        this.setState({
            [name]: Qst
        })
    }
    ChangeQst(e) {
        const { name, value, dataset } = e.target
        e.target.style.height = "1px";
        e.target.style.height = (e.target.scrollHeight - 3) + "px";
        let Qst = this.state.qestions
        Qst[dataset.index].text = value
        this.setState({
            qestions: Qst
        })
    }
    removeQst(index) {
        let Qst = this.state.qestions
        Qst.splice(index, 1);
        this.setState({
            qestions: Qst
        })
    }
    needJustifyQst(e) {
        const { name, checked } = e.target
        let index = parseInt(name)
        let Qst = this.state.qestions
        console.log(checked)
        if (Qst[index]) {
            Qst[index].needJustify = checked
        }
        this.setState({
            qestions: Qst
        })
    }
    AddElemenArray(name) {
        let currentArray = this.state[name]
        currentArray.push({ index: currentArray.length, text: '', needJustify: false })
        this.setState({
            [name]: currentArray
        })
    }
    AddElemenArr(name) {
        let currentArray = this.state[name]
        currentArray.push("")
        this.setState({
            [name]: currentArray
        })
    }
    setList(list) {
        this.setState({
            qestions: list
        })
    }
    cancelChange() {
        this.setState({
            grilleTitle: "",
            labels: [""],
            qestions: [
                { index: 0, text: '', needJustify: false }
            ]
        })
    }
    handleSubmit() {
        let Qst = [];
        this.state.qestions.map(elem => {
            if (elem.text) {
                Qst.push({ "text": elem.text, "justify": elem.needJustify })
            }
        })
        let selfgrile = {
            title_auto_evaluation: this.state.title,
            label_auto_test: this.state.labels.filter(elem => elem != ""),
            questions: Qst,
            typeuser: this.props.currentTab === "candidate" ? "cand" : "eval"
        }
        this.props.addSelfGrille(selfgrile)
    }
    onEmojiClick(event, emojiObject, key) {
        console.log(event, emojiObject, key)
        let val = this.state.labels
        val[key] = val[key] + emojiObject.emoji
        this.setState({
            labels: val
        })
    }
    render() {
        const { addSelf, getDetailgri } = this.props
        return (
            <PopTab isActive={this.props.isActive} TabTitle={this.props.currentTab === "candidate" ? Translate('selfgrile', 'autoforcand') : Translate('selfgrile', 'autoforceval')} close={() => this.props.Close(undefined)} width="90%" height="90%">
                {(addSelf === "0" || getDetailgri === "0") && <Loader />}
                <div className="pd-10-15 brd-equ rad-5 box-border" style={{ height: "calc(100% - 40px)" }}>
                    <div>
                        <input type="text" name="title" className="sizeF-11em font-600 brd-bottum bottomP-5" value={this.state.title} onChange={this.handleChange} style={{ width: "60%" }} placeholder="Titre du grille" />
                    </div>
                    <div className="topM-15 OvfAtoX" style={{ height: "calc(100% - 35px)" }}>

                        {this.props.currentTab === "candidate" && <table>
                            <thead className="top-null">
                                <tr className="RglCh-cntn">
                                    <td style={{ minWidth: "600px", maxWidth: "600px" }} className="">

                                    </td>
                                    {this.state.labels.map((label, key) => {
                                        return <td className=" overflow-visible" key={key}>
                                            <div className="dis-flex item-center">
                                                <input data-index={key} name="labels" placeholder={Translate('selfgrile', 'labelname')} className="width-120 font-600" onChange={this.ChangeLable} value={label} />
                                                <EmojiViewers onEmojiClick={(event, emojiObject) => this.onEmojiClick(event, emojiObject, key)} />
                                            </div>
                                        </td>
                                    })}
                                    <td>
                                        <span onClick={() => this.AddElemenArr("labels")}>
                                            <InlineElemnt
                                                className="font-600 curs-pointer clr-grey sm-padding onHvr width-mxcn rad-5"
                                                classSecend="sizeF-7em leftM-5"
                                                first={"+"}
                                                secend={"Ajouter label"}
                                            />
                                        </span>
                                    </td>
                                </tr>
                            </thead>
                        </table>}
                        <div className="width-100 pos-sticky left-null OvfAtoY box-border" style={{ height: "calc(100% - 35px)", padding: "5px 0" }}>
                            <table className="width-100">
                                <tbody>
                                    <DragToReorderList
                                        items={this.state.qestions}
                                        handleChangeQestion={this.ChangeQst}
                                        handleDelete={this.removeQst}
                                        setList={this.setList}
                                        handleChecked={this.needJustifyQst}
                                        currentTab={this.props.currentTab}
                                    />
                                    <tr>
                                        <td style={{ width: "600px" }}>
                                            <span onClick={() => this.AddElemenArray("qestions")}>
                                                <InlineElemnt
                                                    className="font-600 curs-pointer clr-grey sm-padding onHvr width-mxcn rad-5"
                                                    classSecend="sizeF-7em leftM-5"
                                                    first={"+"}
                                                    secend={"Ajouter Question"}
                                                />
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="dis-flex width-mxcn sTrgPdn leftM-auto topM-8">
                    <BtnClick
                        onClick={this.cancelChange}
                        TxtBtn={Translate('fields', 'cnlchage')}
                        Color="bdg-onrg-op color-org"
                    />
                    <BtnClick
                        onClick={this.handleSubmit}
                        TxtBtn={Translate('fields', 'save')}
                        Color="bdg-onrg clr-white leftM-8"
                    />
                </div>
            </PopTab>
        )
    }
}
function mapState(state) {
    const { addSelf, dataSelf, getDetailgri, dataDetail } = state.grilleauto
    return { addSelf, dataSelf, getDetailgri, dataDetail }
}
const actionsClass = {
    addSelfGrille: selfGrilleActions.addSelfGrille,
    getSelfGrille: selfGrilleActions.getSelfGrille,
    SendAlert: alertActions.SendAlert,
    getDetailGrille: selfGrilleActions.getDetailGrille
}
const connectedClass = connect(mapState, actionsClass)(AddSelfGrille);
export { connectedClass as AddSelfGrille };