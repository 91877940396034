export const reporterConstants = {
    HANDLE_REPORTER_REQUEST: "ADD_OR_EDIT_REPORTER_REQUEST",
    HANDLE_REPORTER_SUCCESS: "ADD_OR_EDIT_REPORTER_SUCCESS",
    HANDLE_REPORTER_FAILURE: "ADD_OR_EDIT_REPORTER_FAILURE",

    FETCH_REPORTER_REQUEST: "FETCH_REPORTER_REQUEST",
    FETCH_REPORTER_SUCCESS: "FETCH_REPORTER_SUCCESS",
    FETCH_REPORTER_FAILURE: "FETCH_REPORTER_FAILURE",

    DELETE_REPORTER_REQUEST: "DELETE_REPORTER_REQUEST",
    DELETE_REPORTER_SUCCESS: "DELETE_REPORTER_SUCCESS",
    DELETE_REPORTER_FAILURE: "DELETE_REPORTER_FAILURE",
}