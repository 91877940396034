import React, { Component } from 'react'
import {PopTab} from '../../../_Common'
import {testActions} from '../../../../actions'
import {TopTabCheck} from './TopTabCheck'
import {Translate} from '../../../../utils'
import { connect } from 'react-redux'
import {SelectTime,SelectGrile,SelectParticipant,PreviewTest,SelectEvaluator,PreviewEvaluator} from './utils'
class AddSelfTest extends Component{
    constructor(props){
        super(props)
        this.state={

        }
    }
    handleSetup(){
        switch(this.props.stepNumber){
            case 1:
                return <SelectGrile user={this.props.userType}/>
            case 2:
                return this.props.userType==="candidate" ? <SelectParticipant/> :<SelectEvaluator/>
            case 3:
                return this.props.userType==="candidate" ? <PreviewTest close={this.props.Close} actions={this.props.getTestCandidate}/> : <PreviewEvaluator close={this.props.Close} actions={this.props.getTestEvaluateur}/>
            default:
                return <SelectTime/>  
        }
    }
    render(){
        return(
            <PopTab isActive={this.props.isActive} TabTitle={Translate('test','selfTest'+this.props.userType)} close={() => this.props.Close(undefined)} width="95%" height="92%" classChild="OvfAtoY">
                <div className="dis-flex flex-direction item-center">
                    <TopTabCheck user={this.props.userType}/>
                    {this.handleSetup()}
                </div> 
            </PopTab>
        )
    }
}
function mapState(state){
    const {stepNumber}=state.tests
    return {stepNumber}
}
const actionsClass={
    getTestEvaluateur:testActions.getTestEvaluateur,
    getTestCandidate:testActions.getTestCandidate
}
const ConnectedClass = connect(mapState,actionsClass)(AddSelfTest)
export {ConnectedClass as AddSelfTest}