import React from 'react'
import { InlineElemnt, AvaterDef, DuplicateSvg, RemoveDocSvg,PDFSvg } from '../../../_Common'
export function ElementGrille(props) {
    return (
        <div className="dis-flex item-center parent_to_hover pd-10-15 box-border width-100 hover-shadwh curs-pointer brd-btm">
            <div className="dis-flex item-center width-80" onClick={props.actionSHow}>
                <span className="rightM-8">{props.iconDoc}</span>
                <div className="width-46per space-nowarp rightM-15">
                    <span className="sizeF-1em txt-ellips HdOvrfl dis-block">{props.titleGrile}</span>
                    {props.lables &&
                        <span className="sizeF-9em clr-grey txt-ellips HdOvrfl dis-block">{"Labels: " + props.lables}</span>}
                </div>
                <div style={{ width: "20%" }} className="sizeF-9em clr-grey">
                    <span>{props.date}</span>
                </div>
                <div style={{ width: "20%" }}>
                    <InlineElemnt
                        className="font-600 curs-pointer clr-grey"
                        classSecend="sizeF-9em leftM-8"
                        classFirst="rad-100 brd-glb mm-widthg sizeF-7em"
                        first={<AvaterDef firstName={props.firstName} lastName={props.lastName} />}
                        secend={(props.firstName && props.lastName) ? props.firstName + " " + props.lastName : ""}
                    />
                </div>
            </div>
            <div className="dis-flex item-center">
                {props.exportGrile && <i className="dis-flex cntrl_show_hover stHvrscal rightM-15" onClick={props.exportGrile}>{PDFSvg}</i>}
                {props.duplicateGrille && <div className="dis-flex cntrl_show_hover stHvrscal rightM-15" onClick={props.duplicateGrille}>{DuplicateSvg}</div>}
                {props.deleteGrille && <i className="dis-flex cntrl_show_hover stHvrscal" onClick={props.deleteGrille}>{RemoveDocSvg}</i>}
            </div>
        </div>
    )
}