import { tabletConstants } from '../constants';
import { RequestService,FunctionService  } from '../services';
export const tabletActions = {
    AddTablet,
    getTablet,
    updateTablet,
    deleteTablet
};
function AddTablet(tablet){
    return dispatch => {
        dispatch(FunctionService.request(tabletConstants.ADD_TABLET_REQUEST))
        RequestService.PostRequest('tablette/create',tablet,true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,tabletConstants.ADD_TABLET_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,tabletConstants.ADD_TABLET_FAILURE))
        })
    }
}
function updateTablet(tablet){
    return dispatch => {
        dispatch(FunctionService.request(tabletConstants.UPDATE_TABLET_REQUEST))
        RequestService.PostRequest('tablette/update',tablet,true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,tabletConstants.UPDATE_TABLET_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,tabletConstants.UPDATE_TABLET_FAILURE))
        })
    }
}
function getTablet(){
    return dispatch => {
        dispatch(FunctionService.request(tabletConstants.GET_TABLET_REQUEST))
        RequestService.GetRequest('tablette/all',true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,tabletConstants.GET_TABLET_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,tabletConstants.GET_TABLET_FAILURE))
        })
    }
}
function deleteTablet(tablette){
    return dispatch => {
        dispatch(FunctionService.request(tabletConstants.DELETE_TABLET_REQUEST))
        RequestService.PostRequest('tablette/delete',tablette,true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,tabletConstants.DELETE_TABLET_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,tabletConstants.DELETE_TABLET_FAILURE))
        })
    }
}