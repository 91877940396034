import React,{Component} from 'react'
import {connect} from 'react-redux'
import {evalGrilleActions,alertActions} from '../../../../actions'
import {Translate} from '../../../../utils'
import {InlineElemnt,PopTab,BtnClick,QestionElem,Loader,NavTab,DeletSvg,BtnAction,CloseSvg} from '../../../_Common'
class AddEvaluGrille extends Component {
    constructor(props) {
        super(props)
        this.state={
            grilleTitle: "",
            sections: [
                {
                    nameSection: "Séquence accueil",groupes: [
                        {
                            nameGroupe: '',qestions: [
                                {
                                    typequestion: "",text: "",details: [
                                        {detailtext: "",detailanswer: 0}
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ],
            activeSection: 0,
            nbQestion: 1,
            type: this.props.typeGrile
        }
        if(this.props.isDuplicated) {
            this.props.getDetailGrille(this.props.isDuplicated)
        }
        this.handleChange=this.handleChange.bind(this)
        this.addQestion=this.addQestion.bind(this)
        this.ChangeTypeQst=this.ChangeTypeQst.bind(this)
        this.HandleChangeTitle=this.HandleChangeTitle.bind(this)
        this.handleDelete=this.handleDelete.bind(this)
        this.handleOption=this.handleOption.bind(this)
        this.handleAnswer=this.handleAnswer.bind(this)
        this.addOption=this.addOption.bind(this)
        this.deleteOption=this.deleteOption.bind(this)
        this.handleSubmit=this.handleSubmit.bind(this)
        this.cancelChange=this.cancelChange.bind(this)
        this.handleNameSection=this.handleNameSection.bind(this)
        this.handleAddSection=this.handleAddSection.bind(this)
        this.handleChangeGroupe=this.handleChangeGroupe.bind(this)
        this.handleAddGroupe=this.handleAddGroupe.bind(this)
        this.deleteGroupe=this.deleteGroupe.bind(this)
        this.deletSection=this.deletSection.bind(this)
    }
    componentWillReceiveProps(nextProps) {
        const {addEval,dataEval,actionGrille,Close,getDetailgri,dataDetail}=nextProps
        if(this.props.addEval==="0"&&addEval==="1"&&dataEval) {
            this.props.SendAlert("Opération réussie","success")
            actionGrille()
            Close(undefined)
        }
        if(this.props.addEval==="0"&&addEval==="2") {
            this.props.SendAlert("Erreur lors de la création de la grille , esseyer plus tard","error")
        }
        if(this.props.getDetailgri==="0"&&getDetailgri==="1"&&dataDetail&&dataDetail.grid) {
            this.state.grilleTitle=dataDetail.grid.titlegrid
            let nbqestion=0
            this.state.sections=dataDetail.grid.sections.map(section => {
                return {
                    nameSection: section.section_name,
                    groupes: section.groupes.map(groupe => {
                        nbqestion+=groupe.questions.length
                        return {
                            nameGroupe: groupe.groupe_name,
                            qestions: groupe.questions
                        }
                    })
                }
            })
            this.state.nbQestion=nbqestion
        }
    }
    handleChange(e) {
        const {value,name}=e.target
        this.setState({
            [name]: value
        })
    }
    HandleChangeTitle(e) {
        const {value,dataset: {index,groupe}}=e.target
        let sections=this.state.sections
        sections[this.state.activeSection].groupes[groupe].qestions[index].text=value
        this.setState({
            sections: sections
        })
    }
    ChangeTypeQst(index,newType,groupe) {
        let sections=this.state.sections
        if(newType==="appr"||newType==="jtaCmnt") {
            sections[this.state.activeSection].groupes[groupe].qestions[index].typequestion="libre"
        } else {
            sections[this.state.activeSection].groupes[groupe].qestions[index].typequestion=newType
        }

        if(newType==="vraifaux") {
            sections[this.state.activeSection].groupes[groupe].qestions[index].details=[{detailtext: "Oui",detailanswer: 0},{detailtext: "Non",detailanswer: 0}]
        }
        if(newType==="libre") {
            sections[this.state.activeSection].groupes[groupe].qestions[index].details=[{detailtext: "",detailanswer: null}]
        }
        if(newType==="appr") {
            sections[this.state.activeSection].groupes[groupe].qestions[index].details=[{detailtext: "appréciation",detailanswer: null}]
        }
        if(newType==="jtaCmnt") {
            sections[this.state.activeSection].groupes[groupe].qestions[index].details=[{detailtext: "comment_jta",detailanswer: null}]
        }
        this.setState({
            sections: sections
        })
    }
    addQestion(index) {
        let sections=this.state.sections
        sections[this.state.activeSection].groupes[index].qestions.push({
            typequestion: "",text: "",details:
                [{detailtext: "",detailanswer: 0}]
        })
        this.setState({
            sections: sections,
            nbQestion: this.state.nbQestion+1
        })
    }
    handleDelete(index,groupe) {
        let sections=this.state.sections
        if(sections[this.state.activeSection].groupes[groupe].qestions.length>1) {
            sections[this.state.activeSection].groupes[groupe].qestions.splice(index,1);
            this.setState({
                sections: sections,
                nbQestion: this.state.nbQestion-1
            })
        }
    }
    handleOption(e) {
        const {value,checked,dataset: {index,option,groupe}}=e.target
        let sections=this.state.sections
        sections[this.state.activeSection].groupes[groupe].qestions[index].details[option].detailtext=value
        this.setState({
            sections: sections
        })
    }
    handleAnswer(e) {
        const {value,dataset: {index,option,groupe}}=e.target
        let sections=this.state.sections
        sections[this.state.activeSection].groupes[groupe].qestions[index].details[option].detailanswer=value
        this.setState({
            sections: sections
        })
    }
    addOption(index,groupe) {
        let sections=this.state.sections
        sections[this.state.activeSection].groupes[groupe].qestions[index].details.push({detailtext: "",detailanswer: 0})
        this.setState({
            sections: sections
        })
    }
    deleteOption(index,option,groupe) {
        let sections=this.state.sections
        if(sections[this.state.activeSection].groupes[groupe].qestions[index].details.length>1) {
            sections[this.state.activeSection].groupes[groupe].qestions[index].details.splice(option,1)
            this.setState({
                sections: sections
            })
        }
    }
    handleSubmit() {
        if(this.state.grilleTitle) {
            var i=0
            var checckgroupename=false
            while(i<this.state.sections.length && !checckgroupename) {
                var j=0
                while(j<this.state.sections[i].groupes.length && !checckgroupename) {
                    if (this.state.sections[i].groupes[j].nameGroupe === '') {
                        checckgroupename=true
                    }
                    j+=1
                }
                i+=1
            }
            if(!checckgroupename) {
                let grille={
                    titlegrid: this.state.grilleTitle,
                    typeeval: "testEval",
                    bodyGrile: this.state.sections
                }
                this.props.addEvaGrille(grille)
            } else {
                this.props.SendAlert("Le titre de groupe de la grille ne peut pas étre vide","error")
            }
        } else {
            this.props.SendAlert("Le titre du formulaire de la grille ne peut pas étre vide","error")
        }
    }
    handleNameSection(e) {
        const {name,value}=e.target
        let index=parseInt(name)
        let sections=this.state.sections
        sections[index].nameSection=value
        this.setState({
            sections: sections
        })
    }
    handleChangeGroupe(e) {
        const {name,value}=e.target
        let index=parseInt(name)
        let sections=this.state.sections
        sections[this.state.activeSection].groupes[index].nameGroupe=value
        this.setState({
            sections: sections
        })
    }
    handleSection(value) {
        let crntSection=this.state.activeSection
        if(value!==crntSection) {
            this.setState({
                activeSection: value
            })
        }
    }
    handleAddSection() {
        let prevSection=this.state.sections
        prevSection.push({
            nameSection: "",groupes: [
                {
                    nameGroupe: "",qestions: [
                        {
                            typequestion: "",text: "",details: [
                                {detailtext: "",detailanswer: 0}
                            ]
                        }
                    ]
                }
            ]
        })
        this.setState({
            sections: prevSection,
            nbQestion: this.state.nbQestion+1
        })
    }
    handleAddGroupe() {
        let prevSection=this.state.sections
        prevSection[this.state.activeSection].groupes.push(
            {
                nameGroupe: "",qestions: [
                    {
                        typequestion: "",text: "",details: [
                            {detailtext: "",detailanswer: 0}
                        ]
                    }
                ]
            })
        this.setState({
            sections: prevSection,
            nbQestion: this.state.nbQestion+1
        })
    }
    cancelChange() {
        this.setState({
            grilleTitle: "",
            sections: [
                {
                    nameSection: "Séquence accueil",groupes: [
                        {
                            nameGroupe: "",qestions: [
                                {
                                    typequestion: "",text: "",details: [
                                        {detailtext: "",detailanswer: 0}
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ],
            nbQestion: 1,
        })
    }
    deleteGroupe(indexGroupe) {
        let sections=this.state.sections
        let activeSection=sections[this.state.activeSection]
        if(activeSection.groupes[indexGroupe]) {
            let nbQst=activeSection.groupes[indexGroupe].qestions.length
            activeSection.groupes.splice(indexGroupe,1)
            sections[this.state.activeSection]=activeSection
            this.setState({
                sections: sections,
                nbQestion: this.state.nbQestion-nbQst
            })
        }

    }
    deletSection(indexSection) {
        let sections=this.state.sections
        let nbQstions=0
        let sectionCrnt=sections[indexSection]
        sectionCrnt.groupes.map(grp => {
            nbQstions+=grp.qestions.length
        })
        sections.splice(indexSection,1)
        if(sections.length>0) {
            this.setState({
                activeSection: 0,
                sections: sections,
                nbQestion: this.state.nbQestion-nbQstions,
            })
        } else {
            this.setState({
                activeSection: 0,
                sections: [
                    {
                        nameSection: "Séquence accueil",groupes: [
                            {
                                nameGroupe: "",qestions: [
                                    {
                                        typequestion: "",text: "",details: [
                                            {detailtext: "",detailanswer: 0}
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ],
                nbQestion: 1
            })
        }

    }
    render() {
        const {addEval,getDetailgri}=this.props
        return (
            <PopTab isActive={this.props.isActive} TabTitle={this.props.typeGrile==="f"? Translate('grileevalu','evagrille'):Translate('grileevalu','testgrill')} close={() => this.props.Close(undefined)} width="95%" height="95%">
                {(addEval==="0"||getDetailgri==="0")&&<Loader />}
                <div className="pd-10-15 brd-equ rad-5 box-border bdg-default" style={{height: "calc(100% - 40px)"}}>
                    <div className="dis-flex item-center">
                        <input type="text" name="grilleTitle" className="sizeF-11em font-600 brd-bottum bottomP-5" value={this.state.grilleTitle} onChange={this.handleChange} style={{width: "60%"}} placeholder="Titre du Formulaire" />
                        <div className="leftM-auto"><span>{"Nbr de question(s) :"+this.state.nbQestion}</span></div>
                    </div>
                    <div className="dis-flex topM-15">
                        <NavTab
                            ElemeList={this.state.sections.map((section,key) => {
                                return {
                                    text: <div className="dis-flex item-center">
                                        <div className="curs-pointer" onClick={() => this.handleSection(key)}>
                                            <input className="sizeF-1em" name={key} placeholder="Titre de Séquence" onChange={this.handleNameSection} value={section.nameSection} />
                                        </div>
                                        <i onClick={() => this.deletSection(key)} className="dis-flex st-zmV onHvr curs-pointer paddAll-5 rad-100">{CloseSvg}</i>
                                    </div>,active: this.state.activeSection===key
                                }
                            })}
                        />
                        <div className="width-mxcn" onClick={this.handleAddSection}>
                            <InlineElemnt
                                className="font-600 topM-8 curs-pointer clr-grey sm-padding onHvr width-mxcn rad-5"
                                classSecend="sizeF-9em leftM-5"
                                first={"+"}
                                secend={"Ajouter Séquence"}
                            />
                        </div>
                    </div>
                    <div className="topM-15 OvfAtoX " style={{height: "calc(100% - 90px)"}}>
                        {this.state.sections[this.state.activeSection].groupes.map((groupe,index) => {
                            return <><div style={{width: "95%"}} className="leftM-auto righttM-auto">
                                <input type="text" name={index} className="sizeF-1em font-600" value={groupe.nameGroupe} onChange={this.handleChangeGroupe} style={{width: "60%"}} placeholder="Titre de groupe de la grille" />
                                <div className="width-70per leftM-auto righttM-auto">
                                    {groupe.qestions.map((qst,key) => {
                                        return <QestionElem groupe={index} typeShow={this.props.typeGrile} index={key} onChangeOption={this.handleOption} onChangeAnswer={this.handleAnswer} onDelete={this.handleDelete} onChangeTitle={this.HandleChangeTitle} onChangeType={this.ChangeTypeQst} addOption={this.addOption} data={qst} onDeleteOption={this.deleteOption} />
                                    })}
                                    <div className="width-mxcn" onClick={() => this.addQestion(index)}>
                                        <InlineElemnt
                                            className="font-600 topM-8 curs-pointer clr-grey sm-padding onHvr width-mxcn rad-5"
                                            classSecend="sizeF-9em leftM-5"
                                            first={"+"}
                                            secend={"Ajouter Question"}
                                        />
                                    </div>
                                </div>
                                <div className="dis-flex" style={{justifyContent: "flex-end"}}>
                                    <span onClick={() => this.deleteGroupe(index)}>
                                        <BtnAction className="leftM-8 bdg-none onHvr" iconBtn={DeletSvg} />
                                    </span>
                                </div>
                            </div><hr /></>
                        })}

                        <div className="width-mxcn" style={{marginBottom: "10px"}} onClick={this.handleAddGroupe}>
                            <InlineElemnt
                                className="font-600 topM-8 curs-pointer clr-grey sm-padding onHvr width-mxcn rad-5"
                                classSecend="sizeF-9em leftM-5"
                                first={"+"}
                                secend={"Ajouter Groupe des qestion"}
                            />
                        </div>
                    </div>
                </div>
                <div className="dis-flex width-mxcn sTrgPdn leftM-auto topM-8">
                    <BtnClick
                        onClick={this.cancelChange}
                        TxtBtn={Translate('fields','cnlchage')}
                        Color="bdg-onrg-op color-org"
                    />
                    <BtnClick
                        onClick={this.handleSubmit}
                        TxtBtn={Translate('fields','save')}
                        Color="bdg-onrg clr-white leftM-8"
                    />
                </div>
            </PopTab>
        )
    }
}
function mapState(state) {
    const {addEval,dataEval,getDetailgri,dataDetail}=state.grilleEvalu
    return {addEval,dataEval,getDetailgri,dataDetail}
}
const actionsClass={
    addEvaGrille: evalGrilleActions.addEvaGrille,
    SendAlert: alertActions.SendAlert,
    getDetailGrille: evalGrilleActions.getDetailGrille
}
const connectedClass=connect(mapState,actionsClass)(AddEvaluGrille);
export {connectedClass as AddEvaluGrille};