import React, { Component } from 'react'
import { connect } from 'react-redux'
import { evaluTestActions } from '../../../../actions'
import { Translate } from '../../../../utils'
import { ContainerPages, PlusSvg, InlineElemnt, ButtonCLick, Loader, RefreshSvg } from '../../../_Common'
import { AddEvaluTest } from './AddEvaluTest'
import { LabelTest } from './utils'
import PropTypes from "prop-types";
import {
    addUrlProps,
    UrlQueryParamTypes
} from "react-url-query";
import { DetailJta } from './DetailJta'
const urlPropsQueryConfig = {
    currentTab: { type: UrlQueryParamTypes.string },
    tab: { type: UrlQueryParamTypes.string },
    viewJta: { type: UrlQueryParamTypes.string },
};
class EvaluationTest extends Component {
    constructor(props) {
        super(props)
        this.state = {
            datagrille: [{ titleGrile: "Grile pour la list de test", lables: "", date: new Date(), firstName: "Zino", lastName: "Khedri", idgrille: "1548" }]
        }
        this.handleRefresh = this.handleRefresh.bind(this)
        this.handleJta = this.handleJta.bind(this)
        if (props.getTest !== "1") {
            props.getJta()
        }
    }
    static defaultProps = {
        currentTab: 'candidate',
        tab:null,
        viewJta:null,
    }
    static propTypes = {
        currentTab: PropTypes.string,
        tab: PropTypes.string,
        viewJta: PropTypes.string,
        /********************** */
        onChangeCurrentTab: PropTypes.func,
        onChangeTab: PropTypes.func,
        onChangeViewJta: PropTypes.func,
    };
    componentDidMount() {
        document.title = Translate('titles', 'app') + " - " + Translate('sidebar', 'testevaluation')
    }
    componentWillReceiveProps(nextProps) {
        const { handleTest, datahandleTest, getJta } = nextProps
        if (this.props.handleTest === "0" && handleTest === "1" && datahandleTest) {
            getJta()
        }
    }
    handleJta(action, test) {
        switch(action){
            case 'detail':
                this.props.onChangeViewJta(test)
                break;
            case 'edit':
                this.props.onChangeTab(test)
                break;
            case 'delete':
                this.props.DeleteJta(test)
                break;
            default:
                let update = {
                    jta_evaluation_id: test,
                    status: action
                }
                this.props.handleJta(update)
                break;
        }
    }
    handleRefresh() {
        this.props.getJta()
    }
    render() {
        const { getTest, dataGetTest, handleTest, viewJta } = this.props
        var attent = []
        var start = []
        var end = []
        if (getTest === "1" && dataGetTest && dataGetTest.jtas) {
            attent = Array.isArray(dataGetTest.jtas.attent) ? dataGetTest.jtas.attent : []
            start = Array.isArray(dataGetTest.jtas.start) ? dataGetTest.jtas.start : []
            end = Array.isArray(dataGetTest.jtas.end) ? dataGetTest.jtas.end : []
        }
        return (
            <ContainerPages titlePage={Translate('sidebar', 'testevaluation')} contentLeft={<div className="dis-flex">
                <ButtonCLick TextBottun={RefreshSvg} action={this.handleRefresh} />
                <ButtonCLick action={() => this.props.onChangeTab('add')} TextBottun={<InlineElemnt first={PlusSvg} secend={Translate('test', 'nvJta')} />} />
            </div>}>
                {(getTest === "0" || handleTest === "0") && <Loader style={{ minHeight: "300px" }} />}
                {this.props.tab && <AddEvaluTest isActive={this.props.tab} Close={this.props.onChangeTab}  idJta={this.props.tab}/>}
                <hr className="brd-none paddTB-3" style={{ borderBottom: "1px solid #e1dfdd" }} />
                <div>
                    {start.length > 0 && <LabelTest handleJta={this.handleJta} bdg_st="activ_pr" TestStatus="start" textLabel={Translate('test', 'tstencr')} testList={start} />}
                    {attent.length > 0 && <LabelTest handleJta={this.handleJta} bdg_st="bdg-pend" TestStatus="attent" className="topM-15" textLabel={Translate('test', 'pentest')} testList={attent} />}
                    {end.length > 0 && <LabelTest  handleJta={this.handleJta} bdg_st="bdg-valid" TestStatus="terminé" className="topM-15" textLabel={Translate('test', 'tremtest')} testList={end} />}
                </div>
                {viewJta && <DetailJta isActive={viewJta} Close={this.props.onChangeViewJta} />}
            </ContainerPages>
        )
    }
}
function mapState(state) {
    const { getTest, dataGetTest, handleTest, datahandleTest } = state.evaluTest
    return { getTest, dataGetTest, handleTest, datahandleTest }
}
const actionsClass = {
    getJta: evaluTestActions.getJta,
    DeleteJta:evaluTestActions.DeleteJta,
    handleJta: evaluTestActions.handleJta
}
const connectedClass = connect(mapState, actionsClass)(EvaluationTest);
const QueryProps = addUrlProps({ urlPropsQueryConfig })(connectedClass)
export { QueryProps as EvaluationTest };