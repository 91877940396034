import React, { Component } from 'react'
import { Translate } from '../../../../utils'
import { connect } from 'react-redux'
import { testActions } from '../../../../actions'
import { testsConstants } from '../../../../constants'
import { PresonSvg, TestCSvg, CheckSvg, TestSelfSvg, CheckUserSvg,AffectationEvalu } from '../../../_Common'
import { Link } from 'react-router-dom'
class TopTabCheck extends Component {
    handleStep(step) {
        if (this.props.stepNumber > step) {
            this.props.StoreStep(step, testsConstants.GO_TO_STEP)
        }
    }
    render() {
        const { stepNumber } = this.props
        return (
            <div className="pos-sticky bdg-white width-mxcn topM-8">
                <div className="dis-flex text-center tranFrAll content-btwn">
                    <hr className="brd-none hg_lin_v width-100 pos-absolute bdg-light" style={{ top: "29px", left: "10px" }} />
                    <div className="rightM-25 dis-flex flex-direction item-center index-1">
                        <div className="bdg-white sTrgPdn dis-flex pos-relative item-center width-mxcn">
                            <div onClick={() => this.handleStep(0)} className={(stepNumber === 0 ? "activ_pr" : stepNumber > 0 && "bdg-valid curs-pointer") + " prc_Wd-8 dis-flex content-center item-center rad-100 bdg-light"}>
                                <span className="dis-flex" style={{ transform: 'scale(1.2)' }}>{TestCSvg}</span>
                            </div>
                        </div>
                        <span className="paddTB-10 dis-block">{Translate('test', 'testcn')}</span>
                    </div>
                    <div className="rightM-25 dis-flex flex-direction item-center index-1">
                        <div className="bdg-white sTrgPdn dis-flex pos-relative item-center width-mxcn">
                            <div onClick={() => this.handleStep(1)} className={(stepNumber === 1 ? "activ_pr" : stepNumber > 1 && "bdg-valid curs-pointer") + " prc_Wd-8 dis-flex content-center item-center rad-100 bdg-light"}>
                                <span className="dis-flex" style={{ transform: 'scale(1.2)' }}>{AffectationEvalu}</span>
                            </div>
                        </div>
                        <span className="paddTB-10 dis-block">Affectation évaluateur</span>
                    </div>
                    <div className="rightM-25 dis-flex flex-direction item-center index-1">
                        <div className="bdg-white sTrgPdn dis-flex pos-relative item-center width-mxcn">
                            <div onClick={() => this.handleStep(2)} className={(stepNumber === 2 ? "activ_pr" : stepNumber > 2 && "bdg-valid curs-pointer") + " prc_Wd-8 dis-flex content-center item-center rad-100 bdg-light"}>
                                <span className="dis-flex" style={{ transform: 'scale(1.1)' }}>{CheckUserSvg}</span>
                            </div>
                        </div>
                        <span className="paddTB-10 dis-block">Pointage présence</span>
                    </div>
                    <div className="rightM-25 dis-flex flex-direction item-center index-1">
                        <div className="bdg-white sTrgPdn dis-flex pos-relative item-center width-mxcn">
                            <div onClick={() => this.handleStep(3)} className={(stepNumber === 3 ? "activ_pr" : stepNumber > 3 && "bdg-valid curs-pointer") + " prc_Wd-8 dis-flex content-center item-center rad-100 bdg-light"}>
                                <span className="dis-flex">{PresonSvg}</span>
                            </div>
                        </div>
                        <span className="paddTB-10 dis-block">{Translate('test', 'partiSelect')}</span>
                    </div>
                    {/* <div className="rightM-25 dis-flex flex-direction item-center index-1">
                        <div className="bdg-white sTrgPdn dis-flex pos-relative item-center width-mxcn">
                            <div onClick={() => this.handleStep(4)} className={(stepNumber === 4 ? "activ_pr" : stepNumber > 4 && "bdg-valid curs-pointer") + " prc_Wd-8 dis-flex content-center item-center rad-100 bdg-light"}>
                                <span className="dis-flex" style={{ transform: 'scale(1.3)' }}>{TestSelfSvg}</span>
                            </div>
                        </div>
                        <span className="paddTB-10 dis-block">{Translate('test', 'autotest')}</span>
                    </div> */}
                    <div className="bdg-white dis-flex flex-direction item-center index-1">
                        <div className="bdg-white sTrgPdn dis-flex pos-relative item-center width-mxcn">
                            <div onClick={() => this.handleStep(5)} className={(stepNumber === 4 ? "activ_pr" : stepNumber > 5 && "bdg-valid curs-pointer") + " prc_Wd-8 dis-flex content-center item-center rad-100 bdg-light"}>
                                <span className="st_Scal dis-flex">{CheckSvg}</span>
                            </div>
                        </div>
                        <span className="paddTB-10 dis-block">{Translate('test', 'confirmtest')}</span>
                    </div>
                </div>
                <hr className="brd-none hg_lin_v bdg-light stMrgv-tb" style={{ width: "55%" }} />
            </div>
        )
    }
}
function mapState(state) {
    const { dataGrile, stepNumber } = state.tests
    return { dataGrile, stepNumber }
}
const Actions = {
    StoreStep: testActions.StoreStep
}
const ConnectedClass = connect(mapState, Actions)(TopTabCheck)
export { ConnectedClass as TopTabCheck }