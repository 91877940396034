export const adminConstants={
    ADD_ADMIN_REQUEST:"ADD_ADMIN_REQUEST",
    ADD_ADMIN_SUCCESS:"ADD_ADMIN_SUCCESS",
    ADD_ADMIN_FAILURE:"ADD_ADMIN_FAILURE",

    GET_ADMIN_REQUEST:"GET_ADMIN_REQUEST",
    GET_ADMIN_SUCCESS:"GET_ADMIN_SUCCESS",
    GET_ADMIN_FAILURE:"GET_ADMIN_FAILURE",


    UPDATE_ADMIN_REQUEST:"UPDATE_ADMIN_REQUEST",
    UPDATE_ADMIN_SUCCESS:"UPDATE_ADMIN_SUCCESS",
    UPDATE_ADMIN_FAILURE:"UPDATE_ADMIN_FAILURE",

    DELETE_ADMIN_REQUEST:"DELETE_ADMIN_REQUEST",
    DELETE_ADMIN_SUCCESS:"DELETE_ADMIN_SUCCESS",
    DELETE_ADMIN_FAILURE:"DELETE_ADMIN_FAILURE"
}