import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate } from '../../../../../utils'
import { testActions, selfGrilleActions } from '../../../../../actions'
import { testsConstants } from '../../../../../constants'
import { BtnSubmit, SelectSearch, Loader,BtnClick } from '../../../../_Common'
class SelectTime extends Component {
    constructor(props) {
        super(props)
        this.state = {
            grileAuto: props.grilleAuto,
            grileAutoEval: props.grilleAutoEval
        }
        if (props.getSelfgrille !== "1") {
            props.getSelfGrille()
        }
    }
    HandleChange = (grileAuto) => {
        this.props.StoreStep(grileAuto, testsConstants.SET_TIME_TEST)
    }
    HandleChangeEval = (grileAutoEval) => {
        this.props.StoreStep(grileAutoEval, testsConstants.SET_TIME_TEST_EVAL)
    }
    handleNext = (e) => {
        e.preventDefault()
        if (this.props.grilleAuto && this.props.grilleAutoEval) {
            this.props.StoreStep(5, testsConstants.GO_TO_STEP)
        }
    }
    render() {
        const { getSelfgrille, dataSelfgrille } = this.props
        let griles = [], grilesEval = []
        if (getSelfgrille === "1" && dataSelfgrille && dataSelfgrille.AutoGrid) {
            griles = dataSelfgrille.AutoGrid.cand
        }
        if (getSelfgrille === "1" && dataSelfgrille && dataSelfgrille.AutoGrid) {
            grilesEval = dataSelfgrille.AutoGrid.eval
        }
        return (
            <div className="width-46per">
                {getSelfgrille === "0" && <Loader />}
                <form onSubmit={this.handleNext}>
                    <h3 className="linhgth">{Translate('test', 'grileAutoCandi')}</h3>
                    <div className="dis-flex topM-8">
                        <SelectSearch field="title_auto_evaluation" placeholder="Taper le nom de grile ici..." hideOnChose={true} value={this.props.grilleAuto} datalist={griles} handleSelect={this.HandleChange} />
                    </div>

                    <h3 className="linhgth">{Translate('test', 'grileAutoEvaluator')}</h3>
                    <div className="dis-flex topM-8">
                        <SelectSearch field="title_auto_evaluation" placeholder="Taper le nom de grile ici..." hideOnChose={true} value={this.props.grilleAutoEval} datalist={grilesEval} handleSelect={this.HandleChangeEval} />
                    </div>
                    <br />
                    <div className="dis-flex content-center topM-22">
                        <BtnSubmit
                            TxtBtn={Translate('test', 'nextstep')}
                            Color="bdg-onrg clr-white leftM-8"
                        />
                    </div>
                </form>
            </div>
        )
    }
}
function mapState(state) {
    const { getSelfgrille, dataSelfgrille } = state.grilleauto
    const { grilleAuto, grilleAutoEval } = state.tests
    return { grilleAuto, grilleAutoEval, getSelfgrille, dataSelfgrille }
}
const Actions = {
    StoreStep: testActions.StoreStep,
    getSelfGrille: selfGrilleActions.getSelfGrille
}
const ConnectedClass = connect(mapState, Actions)(SelectTime)
export { ConnectedClass as SelectTime }