import React, { Component } from 'react'
import { connect } from 'react-redux'
import { evalGrilleActions, alertActions } from '../../../../actions'
import { Translate } from '../../../../utils'
import { InlineElemnt, PopTab, BtnClick, QestionElem, Loader } from '../../../_Common'
class AddGrille extends Component {
    constructor(props) {
        super(props)
        this.state = {
            grilleTitle: "",
            qestions: [
                {
                    typequestion: "", text: "", image: null, details: [
                        { detailtext: "", detailanswer: null }
                    ]
                }],
            nbQestion: 1,
            type: this.props.typeGrile,
            pendingImage: null,
            loadingImage: false
        }
        if (this.props.isDuplicated) {
            this.props.getDetailGrille(this.props.isDuplicated)
        }
        this.handleChange = this.handleChange.bind(this)
        this.addQestion = this.addQestion.bind(this)
        this.ChangeTypeQst = this.ChangeTypeQst.bind(this)
        this.HandleChangeTitle = this.HandleChangeTitle.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.handleOption = this.handleOption.bind(this)
        this.handleAnswer = this.handleAnswer.bind(this)
        this.addOption = this.addOption.bind(this)
        this.deleteOption = this.deleteOption.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.cancelChange = this.cancelChange.bind(this)
        this.handleImageChose = this.handleImageChose.bind(this)
        this.confirmImageLoad = this.confirmImageLoad.bind(this)
    }
    componentWillReceiveProps(nextProps) {
        const { addEval, dataEval, actionGrille, Close, getDetailgri, dataDetail } = nextProps
        if (this.props.addEval === "0" && addEval === "1" && dataEval) {
            this.props.SendAlert("Opération réussie", "success")
            actionGrille()
            Close(undefined)
        }
        if (this.props.addEval === "0" && addEval === "2") {
            this.props.SendAlert("Erreur lors de la création de la grille , esseyer plus tard", "error")
        }
        if (this.props.getDetailgri === "0" && getDetailgri === "1" && dataDetail && dataDetail.grid) {
            this.state.grilleTitle = dataDetail.grid.titlegrid
            this.state.qestions = dataDetail.grid.questions
            this.state.nbQestion = dataDetail.grid.questions.length
        }
    }
    handleChange(e) {
        const { value, name } = e.target
        this.setState({
            [name]: value
        })
    }
    HandleChangeTitle(e) {
        const { value, dataset: { index } } = e.target
        let qst = this.state.qestions
        qst[index].text = value
        this.setState({
            qestions: qst
        })
    }
    ChangeTypeQst(index, newType) {
        let qst = this.state.qestions
        qst[index].typequestion = newType
        if (newType === "vraifaux" || newType === "libre") {
            qst[index].details = [{ detailtext: "", detailanswer: null }]
        }
        this.setState({
            qestions: qst
        })
    }
    addQestion() {
        let qst = this.state.qestions
        qst.push({
            typequestion: "", text: "", image: null, details:
                [{ detailtext: "", detailanswer: null }]
        })
        this.setState({
            qestions: qst,
            nbQestion: this.state.nbQestion + 1
        })
    }
    handleDelete(index) {
        let Qst = this.state.qestions
        if (Qst.length > 1) {
            Qst.splice(index, 1);
            this.setState({
                qestions: Qst,
                nbQestion: this.state.nbQestion - 1
            })
        }
    }
    handleOption(e) {
        const { value, dataset: { index, option } } = e.target
        let Qst = this.state.qestions
        Qst[index].details[option].detailtext = value
        this.setState({
            qestions: Qst
        })
    }
    handleImageChose(e) {
        const { dataset: { index }, files } = e.target
        let imageObject = {
            fileData: files[0],
            qeustion: index,
            prev: <img src={URL.createObjectURL(files[0])} className="mxHeight" />
        }
        this.setState({
            pendingImage: imageObject
        })
    }
    confirmImageLoad(typeConfirm) {
        if (typeConfirm === 0) {
            this.setState({
                pendingImage: null
            })
        } else {
            let formdata = new FormData
            formdata.append('file', this.state.pendingImage.fileData)
            this.setState({
                loadingImage: true
            })
            let prevQst = this.state.qestions
            evalGrilleActions.movePicture(formdata)
                .then(res => {
                    console.log(res)
                    if (res.data && res.data.ok) {
                        prevQst[this.state.pendingImage.qeustion].image = res.data.image
                        this.setState({
                            loadingImage: false,
                            qestions: prevQst,
                            pendingImage: null
                        })
                    } else {
                        this.setState({
                            loadingImage: false
                        })
                    }

                })
                .catch(err => {
                    this.setState({
                        loadingImage: false
                    })
                })
        }
    }
    handleAnswer(e) {
        const { checked, value, type, dataset: { index, option } } = e.target
        let Qst = this.state.qestions
        if (type === "checkbox") {
            Qst[index].details[option].detailanswer = (checked ? 1 : null)
        }
        if (type === "number") {
            if (value > 0 && value <= Qst[index].details.length) {
                Qst[index].details[option].detailanswer = value
            }
        }
        if (type === "radio") {
            Qst[index].details[option].detailanswer = value
        }
        this.setState({
            qestions: Qst
        })
    }
    addOption(index) {
        let Qst = this.state.qestions
        Qst[index].details.push({ detailtext: "", detailanswer: null })
        this.setState({
            qestions: Qst
        })
    }
    deleteOption(index, option) {
        let Qst = this.state.qestions
        if (Qst[index].details.length > 1) {
            Qst[index].details.splice(option, 1)
            this.setState({
                qestions: Qst
            })
        }
    }
    handleSubmit() {
        if (this.state.grilleTitle) {
            let grille = {
                titlegrid: this.state.grilleTitle,
                typeeval: "testConnai",
                bodyGrile: this.state.qestions

            }
            this.props.addEvaGrille(grille)
        } else {
            this.props.SendAlert("Le titre de la grille ne peut pas étre vide", "error")
        }

    }
    cancelChange() {
        this.setState({
            grilleTitle: "",
            qestions: [
                {
                    typequestion: "", text: "", details:
                        [
                            { detailtext: "", detailanswer: null }
                        ]
                }],
            nbQestion: 1,
        })
    }
    render() {
        const { addEval, getDetailgri } = this.props
        return (
            <PopTab isActive={this.props.isActive} TabTitle={this.props.typeGrile === "f" ? Translate('grileevalu', 'evagrille') : Translate('grileevalu', 'testgrill')} close={() => this.props.Close(undefined)} width="95%" height="95%">
                {(addEval === "0" || getDetailgri === "0") && <Loader />}
                <div className="pd-10-15 brd-equ rad-5 box-border bdg-default" style={{ height: "calc(100% - 40px)" }}>
                    <div className="dis-flex item-center">
                        <input type="text" name="grilleTitle" className="sizeF-11em font-600 brd-bottum bottomP-5" value={this.state.grilleTitle} onChange={this.handleChange} style={{ width: "60%" }} placeholder="Titre du Formulaire" />
                        <div className="leftM-auto"><span>{"Nbr de question(s) :" + this.state.nbQestion}</span></div>
                    </div>
                    <div className="topM-15 OvfAtoX " style={{ height: "calc(100% - 35px)" }}>
                        <div className="width-70per leftM-auto righttM-auto">
                            {this.state.qestions.map((qst, key) => {
                                return <QestionElem typeShow={this.props.typeGrile} key={key} index={key} handleImage={this.handleImageChose} onChangeOption={this.handleOption} onChangeAnswer={this.handleAnswer} onDelete={this.handleDelete} onChangeTitle={this.HandleChangeTitle} onChangeType={this.ChangeTypeQst} addOption={this.addOption} data={qst} onDeleteOption={this.deleteOption} />
                            })}
                            <div className="width-mxcn" onClick={this.addQestion}>
                                <InlineElemnt
                                    className="font-600 topM-8 curs-pointer clr-grey sm-padding onHvr width-mxcn rad-5"
                                    classSecend="sizeF-9em leftM-5"
                                    first={"+"}
                                    secend={"Ajouter Question"}
                                />
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
                <div className="dis-flex width-mxcn sTrgPdn leftM-auto topM-8">
                    <BtnClick
                        onClick={this.cancelChange}
                        TxtBtn={Translate('fields', 'cnlchage')}
                        Color="bdg-onrg-op color-org"
                    />
                    <BtnClick
                        onClick={this.handleSubmit}
                        TxtBtn={Translate('fields', 'save')}
                        Color="bdg-onrg clr-white leftM-8"
                    />
                </div>
                <PopTab isActive={this.state.pendingImage} TabTitle="Transfert d'image" close={() => this.confirmImageLoad(0)}>
                    <p>Confirmer l'image à associer à la question</p>
                    {this.state.pendingImage && this.state.pendingImage.prev}
                    {this.state.loadingImage && <Loader />}
                    <div className="dis-flex leftM-auto sTrgPdn sTmr-si">
                        <BtnClick
                            onClick={() => this.confirmImageLoad(0)}
                            TxtBtn="Annuler"
                            style={{ width: "35%" }}
                            Color="leRgM-15 bdg-onrg-op color-org"
                        />
                        <BtnClick
                            onClick={() => this.confirmImageLoad(1)}
                            style={{ width: "55%" }}
                            TxtBtn="Transférer l'image"
                            Color="leRgM-15 width-100 bdg_valid clr-white"
                        />
                    </div>
                </PopTab>
            </PopTab>
        )
    }
}
function mapState(state) {
    const { addEval, dataEval, getDetailgri, dataDetail } = state.grilleEvalu
    return { addEval, dataEval, getDetailgri, dataDetail }
}
const actionsClass = {
    addEvaGrille: evalGrilleActions.addEvaGrille,
    SendAlert: alertActions.SendAlert,
    getDetailGrille: evalGrilleActions.getDetailGrille
}
const connectedAddGrille = connect(mapState, actionsClass)(AddGrille);
export { connectedAddGrille as AddGrille };