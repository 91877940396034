import { groupeConstants } from '../constants';
import { RequestService, FunctionService } from '../services';
export const groupeActions = {
    getCandidateNoGrouped,
    getCandidateGroupe,
    confirmCandidate,
    requestAffectation,
    cancelConfirmation
}
function getCandidateNoGrouped(params) {
    return dispatch => {
        dispatch(FunctionService.request(groupeConstants.GET_CANDIDATE_GROUPE_REQUEST))
        RequestService.GetRequest('candidate/groupe', true, params)
            .then(res => {
                dispatch(FunctionService.succes(res.data, groupeConstants.GET_CANDIDATE_GROUPE_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err.response, groupeConstants.GET_CANDIDATE_GROUPE_FAILURE))
            })
    }
}
function getCandidateGroupe(params) {
    return dispatch => {
        dispatch(FunctionService.request(groupeConstants.GET_GROUPE_INVITATIONS_REQUEST))
        RequestService.GetRequest('group/invitations', true, params)
            .then(res => {
                dispatch(FunctionService.succes(res.data, groupeConstants.GET_GROUPE_INVITATIONS_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err.response, groupeConstants.GET_GROUPE_INVITATIONS_FAILURE))
            })
    }
}
function confirmCandidate(params) {
    return dispatch => {
        dispatch(FunctionService.request(groupeConstants.CONFIRM_PRESENCE_CANDI_REQUEST))
        RequestService.GetRequest('group/presence/confirm', true, params)
            .then(res => {
                dispatch(FunctionService.succes(res.data, groupeConstants.CONFIRM_PRESENCE_CANDI_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err.response, groupeConstants.CONFIRM_PRESENCE_CANDI_FAILURE))
            })
    }
}
function cancelConfirmation(params) {
    return dispatch => {
        dispatch(FunctionService.request(groupeConstants.CANCEL_PRESENCE_CANDI_REQUEST))
        RequestService.GetRequest('group/presence/cancel', true, params)
            .then(res => {
                dispatch(FunctionService.succes(res.data, groupeConstants.CANCEL_PRESENCE_CANDI_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err.response, groupeConstants.CANCEL_PRESENCE_CANDI_FAILURE))
            })
    }
}
function requestAffectation(data) {
    return dispatch => {
        dispatch(FunctionService.request(groupeConstants.AUTO_AFFECTATION_CANDIDATE_REQUEST))
        RequestService.PostRequest('group/autoaffect', data, true)
            .then(res => {
                dispatch(FunctionService.succes(res.data, groupeConstants.AUTO_AFFECTATION_CANDIDATE_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err.response, groupeConstants.AUTO_AFFECTATION_CANDIDATE_FAILURE))
            })
    }
}