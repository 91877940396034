import React from 'react'
import { Component } from 'react';
import { Link } from 'react-router-dom'
import { AvaterDef, MenuSvg } from '../../_Common'
class NavBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            handleProfile: true
        }
        this.refProfile = React.createRef()
        this.handleProfile = this.handleProfile.bind(this)
        this.handleClickoutlist = this.handleClickoutlist.bind(this)
    }
    handleProfile() {
        let crnt = !this.state.handleProfile
        if (crnt) {
            document.removeEventListener('mousedown', this.handleClickoutlist);
        } else {
            document.addEventListener('mousedown', this.handleClickoutlist);
        }
        this.setState({
            handleProfile: crnt
        })
    }
    handleClickoutlist(e) {
        if (this.refProfile && !this.refProfile.current.contains(e.target)) {
            this.handleProfile()
        }
    }
    render() {
        return (
            <div className="pos-relative dis-flex item-center nav-height  bdg-blue index-10">
                <div>
                    <h3 className="box-border" id="title_nav_page" style={{ margin: "0", paddingInline: "20px" }}></h3>
                </div>
                <div className="leftM-auto pos-relative" ref={this.refProfile}>
                    <div className="nav-height wid-50 curs-pointer onHvr dis-flex item-center content-center" onClick={this.handleProfile}>
                        <div className="rad-100 brd-glb sm-widhg">
                            <AvaterDef
                                firstName={this.props.user.firstname}
                                lastName={this.props.user.lastname}
                            />
                        </div>
                    </div>
                    <div className="bdg-white pos-absolute right-null pd-10-15 box-def ToggleCntn" toggle={this.state.handleProfile ? "down" : "up"}>
                        <div className="paddTB-3">
                            <strong className="space-nowarp sizeF-11em">{this.props.user.firstname + " " + this.props.user.lastname}</strong>
                        </div>
                        <Link onClick={this.props.logout} className="color-org Set_dicrtion">Déconnexion</Link>
                    </div>
                </div>


            </div>
        )
    }
}
export { NavBar }