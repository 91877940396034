export const evalgrilleConstants = {
    ADD_EVAL_REQUEST: "ADD_EVAL_GRILLE_REQUEST",
    ADD_EVAL_SUCCESS: "ADD_EVAL_GRILLE_SUCCESS",
    ADD_EVAL_FAILURE: "ADD_EVAL_GRILLE_FAILURE",

    DELETE_EVAL_REQUEST: "DELETE_EVAL_GRILLE_REQUEST",
    DELETE_EVAL_SUCCESS: "DELETE_EVAL_GRILLE_SUCCESS",
    DELETE_EVAL_FAILURE: "DELETE_EVAL_GRILLE_FAILURE",

    GET_EVAL_GRILLE_CANDIDATE_REQUEST: "GET_EVAL_GRILLE_CANDIDATE_REQUEST",
    GET_EVAL_GRILLE_CANDIDATE_SUCCESS: "GET_EVAL_GRILLE_CANDIDATE_SUCCESS",
    GET_EVAL_GRILLE_CANDIDATE_FAILURE: "GET_EVAL_GRILLE_CANDIDATE_FAILURE",

    GET_EVAL_GRILLE_REQUEST: "GET_EVAL_GRILLE_EVALUATOR_REQUEST",
    GET_EVAL_GRILLE_SUCCESS: "GET_EVAL_GRILLE_EVALUATOR_SUCCESS",
    GET_EVAL_GRILLE_FAILURE: "GET_EVAL_GRILLE_EVALUATOR_FAILURE",

    GET_EVAL_DETAIL_REQUEST: "GET_EVAL_DETAIL_GRILLE_REQUEST",
    GET_EVAL_DETAIL_SUCCESS: "GET_EVAL_DETAIL_GRILLE_SUCCESS",
    GET_EVAL_DETAIL_FAILURE: "GET_EVAL_DETAIL_GRILLE_FAILURE",
}