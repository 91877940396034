import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate, FormatDate } from '../../../utils'
import { ContainerPages, ButtonLink, InfoTable, Loader, DeleteSvg, EditSvg, BtnActions, ButtonCLick, RefreshSvg, PopTab, BtnClick } from '../../_Common'
import { tabletActions } from '../../../actions'
import PropTypes from "prop-types";
import {
    addUrlProps,
    UrlQueryParamTypes
} from "react-url-query";
import { AddTablet } from './AddTablet'
const urlPropsQueryConfig = {
    tab: { type: UrlQueryParamTypes.string }
};
class Tablet extends Component {
    constructor(props) {
        super(props)
        this.state = {
            handleTab: false
        }
        if (this.props.getiTablet !== "1") {
            this.props.getTablet()
        }
        this.handleDelet = this.handleDelet.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleRefresh = this.handleRefresh.bind(this)
    }
    static defaultProps = {
        tab: ''
    }
    static propTypes = {
        tab: PropTypes.string,
        /********************** */
        onChangeTab: PropTypes.func,
    };
    componentDidMount() {
        document.title = Translate('titles', 'app') + " - " + Translate('sidebar', 'tablette')
    }
    componentWillReceiveProps(nextProps) {
        const { deletingTablet, dataDeletetabl } = nextProps
        if (this.props.deletingTablet === "0" && deletingTablet === "1" && dataDeletetabl) {
            this.state.handleTab = false
            this.props.getTablet()
        }
    }
    handleDelet(idTabelt) {
        if (!this.state.handleTab) {
            this.setState({
                handleTab: idTabelt
            })
        } else {
            let tablet = {
                idtablette: this.state.handleTab
            }
            this.props.deleteTablet(tablet)
        }
    }
    handleClose() {
        this.setState({
            handleTab: false
        })
    }
    handleRefresh() {
        this.props.getTablet()
    }
    render() {
        const { getiTablet, dataTabelt, deletingTablet } = this.props
        var tablets = []
        var tablet = {}
        var todelete = {}
        var lengthData = ''
        if (getiTablet === "1" && dataTabelt && Array.isArray(dataTabelt.tablettes)) {
            tablets = dataTabelt.tablettes.map(tablet => {
                return [<div className="dis-flex "><div className="paddTB-10 rad-5 rightM-8" style={{background:tablet.color_code,paddingInline:20}}></div> {tablet.color}</div>, tablet.numTablette, FormatDate(tablet.created_at, true), FormatDate(tablet.updated_at, true), <BtnActions btns={[{ icon: EditSvg, action: () => this.props.onChangeTab(tablet.tablette_id) }, { icon: DeleteSvg, action: () => this.handleDelet(tablet.tablette_id) }]} />]
            })
            lengthData = ' (' + dataTabelt.tablettes.length + ')'
            if (this.state.handleTab) {
                todelete = dataTabelt.tablettes.find(tablt => tablt.tablette_id == this.state.handleTab)
            }
            tablet = dataTabelt.tablettes.find(tablet => tablet.tablette_id == this.props.tab)
            if (!tablet) {
                tablet = {}
            }
        }
        return (
            <ContainerPages titlePage={Translate('sidebar', 'tablette') + lengthData} contentLeft={<div className="dis-flex">
                <ButtonCLick TextBottun={RefreshSvg} action={this.handleRefresh} />
                <ButtonLink pathName="?tab=add" TextBottun={Translate('tablette', 'addnew')} />
            </div>} StyleChildren={{ height: "calc(100% - 77px)" }}>
                {getiTablet === "0" ? <Loader style={{ minHeight: "250px" }} /> : getiTablet === "1" ?
                    <InfoTable
                        classSticky="fix_elem_top"
                        styleTop={{ height: "100%" }}
                        thead={[Translate('tablette', 'color'), Translate('tablette', 'number'), Translate('fields', 'datecreat'), Translate('fields', 'datemodif'), Translate('fields', 'action')]}
                        tbody={tablets}
                    /> : ""}
                {this.props.tab && <AddTablet active={this.props.tab} default={tablet} close={this.props.onChangeTab} />}
                {this.state.handleTab && <PopTab isActive={this.state.handleTab} TabTitle={Translate('tablette', 'supeTablte')} close={this.handleClose} width="470px">
                    {deletingTablet === "0" && <Loader />}
                    <p>Vous êtes sur le point de supprimer <strong className="sizeF-12em txt-dng">{todelete.color + " / " + todelete.numTablette}</strong>. Cette action est irréversible !</p>
                    <div className="dis-flex leftM-auto sTrgPdn sTmr-si">
                        <BtnClick
                            onClick={this.handleClose}
                            TxtBtn="Annuler"
                            style={{ width: "35%" }}
                            Color="leRgM-15 bdg-onrg-op color-org"
                        />
                        <BtnClick
                            onClick={this.handleDelet}
                            style={{ width: "55%" }}
                            TxtBtn="Confirmer la suppression"
                            Color="leRgM-15 width-100 bdg-dng clr-white"
                        />
                    </div>
                </PopTab>}
            </ContainerPages>
        )
    }
}
function mapState(state) {
    const { getiTablet, dataTabelt, deletingTablet, dataDeletetabl } = state.tablet
    return { getiTablet, dataTabelt, deletingTablet, dataDeletetabl }
}
const Actions = {
    getTablet: tabletActions.getTablet,
    deleteTablet: tabletActions.deleteTablet
}
const connectedClass = connect(mapState, Actions)(Tablet);
const QueryProps = addUrlProps({ urlPropsQueryConfig })(connectedClass)
export { QueryProps as Tablet };