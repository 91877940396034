export const messagingConstants = {
    FETCH_LIST_USERS_REQESUT:"FETCH_LIST_USERS_REQESUT",
    FETCH_LIST_USERS_SUCCESS:"FETCH_LIST_USERS_SUCCESS",
    FETCH_LIST_USERS_FAILEUR:"FETCH_LIST_USERS_FAILEUR",

    FETCH_NOTIFICATION_MSGS_SUCCESS:"FETCH_NOTIFICATION_MSGS_SUCCESS",
    FETCH_NOTIFICATION_MSGS_FAILEUR:"FETCH_NOTIFICATION_MSGS_FAILEUR",


    SYNC_MESSAGES_CHANNEL_REQESUT:"SYNC_MESSAGES_CHANNEL_REQESUT",
    SYNC_MESSAGES_CHANNEL_SUCCESS:"SYNC_MESSAGES_CHANNEL_SUCCESS",
    SYNC_MESSAGES_CHANNEL_FAILEUR:"SYNC_MESSAGES_CHANNEL_FAILEUR",

    SET_MESSAGES_CHANNEL: "SET_MESSAGES_CHANNEL"
};