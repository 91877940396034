import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Translate} from '../../../../../utils'
import {testActions,tabletActions,evaluatorActions} from '../../../../../actions'
import {testsConstants} from '../../../../../constants'
import {InputForm,BtnClick,SelectSearch,InlineElemnt,ImageViewer,CloseSvg, Loader} from '../../../../_Common'
class SelectEvaluator extends Component{
    constructor(props){
        super(props)
        this.state={
            selectedUser:Array.isArray(props.dataUsers) ? props.dataUsers : [{evaluator:null,tablette:null}]
        }
        if(props.getiEvalu!=="1"){
            props.getEvaluator()
        }
        if(props.getiTablet!=="1"){
            props.getTablet()
        }
        this.HandleChange=this.HandleChange.bind(this)
        this.handleNext=this.handleNext.bind(this)
        this.handleadd=this.handleadd.bind(this)
        this.deleteInput=this.deleteInput.bind(this)
    }
    HandleChange(user,params){
        let current=this.state.selectedUser
        current[params.index][params.type]=user
        this.setState({
            selectedUser:current
        })
    }
    handleadd(){
        let current=this.state.selectedUser
        current.push({evaluator:null,tablette:null})
        this.setState({
            selectedUser:current
        })
    }
    deleteInput(index){
        let current=this.state.selectedUser
        if(current.length>1){
            current.splice(index,1)
            this.setState({
                selectedUser:current 
            })
        }
    }
    handleNext(){
        this.props.StoreStep(this.state.selectedUser,testsConstants.SET_USERS_TEST)
    }
    render(){
        const {getiEvalu,dataEvalu,getiTablet,dataTabelt}=this.props
        var tabllets=[]
        var evalu=[]
        if(getiEvalu==="1" && dataEvalu && Array.isArray(dataEvalu.evaluators)){
            evalu=dataEvalu.evaluators.filter(evale=>!this.state.selectedUser.find(elem=>(elem.evaluator ? elem.evaluator.id:null)==evale.evaluator_id)).map(evalu=>{return {fullname:evalu.firstname+" "+evalu.lastname,id:evalu.evaluator_id}})
        }
        if(getiTablet==="1" && dataTabelt && Array.isArray(dataTabelt.tablettes)){
            tabllets=dataTabelt.tablettes.filter(tablet=>!this.state.selectedUser.find(elem=>(elem.tablette ? elem.tablette.tablette_id:null)==tablet.tablette_id))
        }
        return(
            <div className="hg-100-200 leftM-auto righttM-auto" style={{width:"60%"}}>
                {(getiTablet==="0" || getiEvalu==="0") && <Loader/>}
                <div>
                    <span className="linhgth">Liste des évaluateurs/rices concerné(e)s par l’auto évaluation en cours de création.</span>
                    <div className="width-100">
                        {this.state.selectedUser.map((user,key)=>{
                            return  <InlineElemnt
                                        className="width-100 stMrgv-tb item-flex-start"
                                        classFirst="width-46per"
                                        classSecend="width-46per"
                                        first={<SelectSearch field="fullname" maxHieght="200px" placeholder="Sélectionner l’évaluateur..." hideOnChose={true} value={user.evaluator} paramsFunction={{index:key,type:"evaluator"}} datalist={evalu} handleSelect={this.HandleChange}/>}
                                        secend={<SelectSearch field="color" maxHieght="200px" placeholder="Sélectionner la tablette..." hideOnChose={true} value={user.tablette} paramsFunction={{index:key,type:"tablette"}} datalist={tabllets} handleSelect={this.HandleChange}/>}
                                        more={key!==0 && <div className="leftM-8"><i onClick={() => this.deleteInput(key)} className="dis-flex onHvr curs-pointer paddAll-5 rad-100">{CloseSvg}</i></div>}
                                    />
                        })}
                    </div>
                    <div className="width-mxcn" onClick={this.handleadd}>
                        <InlineElemnt
                            className="font-600 topM-8 curs-pointer clr-grey sm-padding onHvr width-mxcn rad-5"
                            classSecend="sizeF-7em leftM-5"
                            first={"+"}
                            secend={"Ajouter évaluateur"}
                        />
                    </div>
                </div>
                <div className="dis-flex content-center topM-22">
                    <BtnClick
                        onClick={this.handleNext}
                        TxtBtn={Translate('test','nextstep')}
                        Color="bdg-onrg clr-white leftM-8"
                    />
                </div>
            </div>
        )
    }
}
function mapState(state){
    const {dataGrile,dataUsers}=state.tests
    const {getiEvalu,dataEvalu}=state.evaluator
    const {getiTablet,dataTabelt}=state.tablet
    return {dataGrile,getiEvalu,dataEvalu,getiTablet,dataTabelt,dataUsers}
}
const Actions={
    StoreStep:testActions.StoreStep,
    getTablet:tabletActions.getTablet,
    getEvaluator:evaluatorActions.getEvaluator
}
const ConnectedClass=connect(mapState,Actions)(SelectEvaluator)
export {ConnectedClass as SelectEvaluator}