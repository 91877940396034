import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate, FormatDate } from '../../../../utils'
import { ContainerPages, ButtonCLick, InlineElemnt, PlusSvg, DocsEvaSvg, Loader, RefreshSvg } from '../../../_Common'
import { ElementGrille } from '../utils'
import { evalGrilleActions,exportsActions } from '../../../../actions'
import PropTypes from "prop-types";
import {
    addUrlProps,
    UrlQueryParamTypes
} from "react-url-query";
import { AddEvaluGrille } from './AddEvaluGrille'
import { GrilleEvalViewer } from './GrilleEvalViewer'
const urlPropsQueryConfig = {
    currentTab: { type: UrlQueryParamTypes.string },
    tab: { type: UrlQueryParamTypes.string },
    viewGrile: { type: UrlQueryParamTypes.string },
    page: { type: UrlQueryParamTypes.number }
};
class EvaluationGrille extends Component {
    constructor(props) {
        super(props)
        this.state = {
            idGrille: null
        }
        this.handleRefresh = this.handleRefresh.bind(this)
        this.duplicateGrille = this.duplicateGrille.bind(this)
        this.handleAddtest = this.handleAddtest.bind(this)
        this.deleteGrille = this.deleteGrille.bind(this)
        if (props.getEvalEva !== "1") {
            props.getEvaGrilleEva()
        }
    }
    static defaultProps = {
        currentTab: 'candidate',
        tab: '',
        viewGrile: '',
        page: 0
    }
    static propTypes = {
        currentTab: PropTypes.string,
        tab: PropTypes.string,
        viewGrile: PropTypes.string,
        page: PropTypes.number,
        /********************** */
        onChangeCurrentTab: PropTypes.func,
        onChangeTab: PropTypes.func,
        onChangeViewGrile: PropTypes.func,
        onChangePage: PropTypes.func,
    };
    componentDidMount() {
        document.title = Translate('titles', 'app') + " - " + Translate('sidebar', 'grilevaluation')
    }
    handleRefresh() {
        this.props.getEvaGrilleEva()
    }
    duplicateGrille(index) {
        const { dataEvalEva } = this.props
        var grid = dataEvalEva.grids[index]
        this.state.idGrille = grid.evaluation_grid_id
        this.props.onChangeTab('add')
    }
    handleAddtest() {
        this.state.idGrille = null
        this.props.onChangeTab('add')
    }
    deleteGrille(index) {
        const { dataEvalEva } = this.props
        let grids = dataEvalEva.grids
        let grid = grids[index]
        if (grid) {
            this.props.DeleteGrid({ idGrille: grid.evaluation_grid_id, typeGrid: "testEval" })
        }
    }
    render() {
        const { getEvalEva, dataEvalEva, deletingGrid } = this.props
        let griles = [];
        if (getEvalEva === "1" && dataEvalEva && Array.isArray(dataEvalEva.grids)) {
            griles = dataEvalEva.grids
        }
        return (
            <ContainerPages titlePage={Translate('sidebar', 'grilevaluation')} contentLeft={<div className="dis-flex">
                <ButtonCLick TextBottun={RefreshSvg} action={this.handleRefresh} />
                <ButtonCLick action={this.handleAddtest} TextBottun={<InlineElemnt first={PlusSvg} classSecend="leftM-5" secend={Translate('grileevalu', 'nvgrille')} />} />
            </div>}>
                {(getEvalEva === "0" || deletingGrid === "0") && <Loader style={{ minHeight: "250px" }} />}
                {griles.map((grille, key) => {
                    let user = {}
                    if (grille.user) {
                        user = grille.user
                    }
                    return <ElementGrille key={key} iconDoc={DocsEvaSvg} exportGrile={()=>exportsActions.ExportGrid({grid:grille.evaluation_grid_id,type:'Blank'},'evaluator')} titleGrile={grille.titlegrid} date={FormatDate(grille.created_at, true)} firstName={user.firstname} duplicateGrille={() => this.duplicateGrille(key)} actionSHow={() => this.props.onChangeViewGrile(grille.evaluation_grid_id)} lastName={user.lastname} deleteGrille={() => this.deleteGrille(key)} />
                })}
                {this.props.tab === "add" && <AddEvaluGrille isDuplicated={this.state.idGrille} isActive={this.props.tab === "add"} actionGrille={this.props.getEvaGrilleEva} typeGrile="f" Close={this.props.onChangeTab} />}
                {this.props.viewGrile && <GrilleEvalViewer isActive={this.props.viewGrile} Close={this.props.onChangeViewGrile} iconGrille={DocsEvaSvg} />}
            </ContainerPages>
        )
    }
}
function mapState(state) {
    const { getEvalEva, dataEvalEva, deletingGrid, dataDelGrid } = state.grilleEvalu
    return { getEvalEva, dataEvalEva, deletingGrid, dataDelGrid }
}
const actionsCLass = {
    getEvaGrilleEva: evalGrilleActions.getEvaGrilleEva,
    DeleteGrid: evalGrilleActions.DeleteGrid
}
const connectedEvaluationGrille = connect(mapState, actionsCLass)(EvaluationGrille);
const QueryProps = addUrlProps({ urlPropsQueryConfig })(connectedEvaluationGrille)
export { QueryProps as EvaluationGrille };