import { evaluTestConstants } from '../constants';
import { RequestService, FunctionService } from '../services';
import { toast } from 'react-toastify';
import axios from 'axios';
import { commonActions } from './common.actions';

const INSCRIPTION_URL = process.env.REACT_APP_INSC_APP_URL;

export const evaluTestActions = {
    AddJta,
    UpdateJta,
    getJta,
    editJta,
    handleJta,
    detailJta,
    remarkCondidatePresence,
    detailAswerJta,
    PostDataIterop,
    DeleteJta,
    getIteropResult,
    exportToInscription
};

function AddJta(test) {
    return dispatch => {
        dispatch(FunctionService.request(evaluTestConstants.ADD_TEST_REQUEST))
        RequestService.PostRequest('jta/create', test, true)
            .then(res => {
                dispatch(FunctionService.succes(res.data, evaluTestConstants.ADD_TEST_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err.response, evaluTestConstants.ADD_TEST_FAILURE))
            })
    }
}

function UpdateJta(test) {
    return dispatch => {
        dispatch(FunctionService.request(evaluTestConstants.ADD_TEST_REQUEST))
        RequestService.PatchRequest('jta/update', test, true)
            .then(res => {
                dispatch(FunctionService.succes(res.data, evaluTestConstants.ADD_TEST_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err.response, evaluTestConstants.ADD_TEST_FAILURE))
            })
    }
}

function DeleteJta(jta) {
    return dispatch => {
        dispatch(FunctionService.request(evaluTestConstants.HANDLE_TEST_REQUEST))
        RequestService.DeleteRequest('jta/delete/' + jta, true)
            .then(res => {
                dispatch(FunctionService.succes(res.data, evaluTestConstants.HANDLE_TEST_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err.response, evaluTestConstants.HANDLE_TEST_FAILURE))
            })
    }
}

function getJta() {
    return dispatch => {
        dispatch(FunctionService.request(evaluTestConstants.GET_TEST_REQUEST))
        RequestService.GetRequest('jta/all', true)
            .then(res => {
                dispatch(FunctionService.succes(res.data, evaluTestConstants.GET_TEST_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err.response, evaluTestConstants.GET_TEST_FAILURE))
            })
    }
}

function editJta(idJta) {
    const getLink = RequestService.GetRequest('jta/edit/' + idJta, true)
    return dispatch => {
        toast.promise(
            getLink,
            {
                pending: "Récupération des information de JTA",
                success: "Les information sont prêt à utiliser",
                error: "Une erreur s'est produite"
            }
        )
        getLink
            .then(({ data: { jta = {} } = {} }) => {
                dispatch(FunctionService.succes(jta, evaluTestConstants.SET_DATA_JTA_EDIT))
            })
            .catch(err => {
            })
    }
}

function detailJta(idJta) {
    return dispatch => {
        dispatch(FunctionService.request(evaluTestConstants.GET_TEST_DETAIL_REQUEST))
        RequestService.GetRequest('jta/detail/' + idJta, true)
            .then(res => {
                dispatch(FunctionService.succes(res.data, evaluTestConstants.GET_TEST_DETAIL_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err.response, evaluTestConstants.GET_TEST_DETAIL_FAILURE))
            })
    }
}


function remarkCondidatePresence(idCondidate, invitation_candidate_id) {
    return dispatch => {
        dispatch(FunctionService.request(evaluTestConstants.READD_CANDI_REQUEST))
        RequestService.GetRequest('jta/remark-presence/' + idCondidate + '/' + invitation_candidate_id, true)
            .then(res => {
                dispatch(FunctionService.succes(res.data, evaluTestConstants.READD_CANDI_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err.response,evaluTestConstants.READD_CANDI_FAILURE))
            })
    }
}

function handleJta(jta) {
    return dispatch => {
        dispatch(FunctionService.request(evaluTestConstants.HANDLE_TEST_REQUEST))
        RequestService.PostRequest('jta/status', jta, true)
            .then(res => {
                dispatch(FunctionService.succes(res.data, evaluTestConstants.HANDLE_TEST_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err.response, evaluTestConstants.HANDLE_TEST_FAILURE))
            })
    }
}

function detailAswerJta(dataGet) {
    return dispatch => {
        dispatch(FunctionService.request(evaluTestConstants.GET_ANSWERS_TEST_REQUEST))
        RequestService.PostRequest('jta/answer/detail', dataGet, true)
            .then(res => {
                dispatch(FunctionService.succes(res.data, evaluTestConstants.GET_ANSWERS_TEST_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err.response, evaluTestConstants.GET_ANSWERS_TEST_FAILURE))
            })
    }
}

function PostDataIterop(dataPost) {
    return dispatch => {
        dispatch(FunctionService.request(evaluTestConstants.SUBMIT_DATA_TEST_REQUEST))
        RequestService.PostRequest('jta/iterop/submit', dataPost, true)   
            .then(res => {
                dispatch(FunctionService.succes(res.data, evaluTestConstants.SUBMIT_DATA_TEST_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err.response, evaluTestConstants.SUBMIT_DATA_TEST_FAILURE))
            })
    }
}

function getIteropResult(jtaId) {
    return RequestService.GetRequest(`jta/iterop-result/${jtaId}`, true);
}

async function exportToInscription(candidates) {
    let inscApiKey = "";
    try {
        inscApiKey = (await commonActions.getInscriptionApiKey()).data.data;
    } catch (err) {
        console.error(err);
    }
    return axios.put(`${INSCRIPTION_URL}/candidates/import-from-jta`, {
        data: candidates
    }, {
        headers: {
            "insc-api-key": inscApiKey
        }
    })
}
