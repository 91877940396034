export const candidateConstants = {
    ADD_GROUPE_REQUEST: "ADD_GROUPE_CANDIDATE_REQUEST",
    ADD_GROUPE_SUCCESS: "ADD_GROUPE_CANDIDATE_SUCCESS",
    ADD_GROUPE_FAILURE: "ADD_GROUPE_CANDIDATE_FAILURE",

    EDIT_GROUPE_REQUEST: "EDIT_GROUPE_CANDIDATE_REQUEST",
    EDIT_GROUPE_SUCCESS: "EDIT_GROUPE_CANDIDATE_SUCCESS",
    EDIT_GROUPE_FAILURE: "EDIT_GROUPE_CANDIDATE_FAILURE",

    GET_GROUPE_REQUEST: "GET_GROUPE_CANDIDATE_REQUEST",
    GET_GROUPE_SUCCESS: "GET_GROUPE_CANDIDATE_SUCCESS",
    GET_GROUPE_FAILURE: "GET_GROUPE_CANDIDATE_FAILURE",

    VERIFICATION_GROUPE_REQUEST: "VERIFICATION_GROUPE_CANDIDATE_REQUEST",
    VERIFICATION_GROUPE_SUCCESS: "VERIFICATION_GROUPE_CANDIDATE_SUCCESS",
    VERIFICATION_GROUPE_FAILURE: "VERIFICATION_GROUPE``_CANDIDATE_FAILURE",
    
    RESEND_INCERTION_GROUPE_REQUEST: "RESEND_INCERTION_GROUPE_CANDIDATE_REQUEST",
    RESEND_INCERTION_GROUPE_SUCCESS: "RESEND_INCERTION_GROUPE_CANDIDATE_SUCCESS",
    RESEND_INCERTION_GROUPE_FAILURE: "RESEND_INCERTION_GROUPE_CANDIDATE_FAILURE",

    RESEND_PICTURE_GROUPE_REQUEST: "RESEND_PICTURE_GROUPE_CANDIDATE_REQUEST",
    RESEND_PICTURE_GROUPE_SUCCESS: "RESEND_PICTURE_GROUPE_CANDIDATE_SUCCESS",
    RESEND_PICTURE_GROUPE_FAILURE: "RESEND_PICTURE_GROUPE_CANDIDATE_FAILURE",

    GET_DETAIL_GROUPE_REQUEST: "GET_DETAIL_GROUPE_CANDIDATE_REQUEST",
    GET_DETAIL_GROUPE_SUCCESS: "GET_DETAIL_GROUPE_CANDIDATE_SUCCESS",
    GET_DETAIL_GROUPE_FAILURE: "GET_DETAIL_GROUPE_CANDIDATE_FAILURE",

    GET_CANDIDATE_REQUEST: "GET_CANDIDATE_REQUEST",
    GET_CANDIDATE_SUCCESS: "GET_CANDIDATE_SUCCESS",
    GET_CANDIDATE_FAILURE: "GET_CANDIDATE_FAILURE",

    IMPORT_CANDIDATE_REQUEST: "IMPORT_CANDIDATE_REQUEST",
    IMPORT_CANDIDATE_SUCCESS: "IMPORT_CANDIDATE_SUCCESS",
    IMPORT_CANDIDATE_FAILURE: "IMPORT_CANDIDATE_FAILURE",

    CONFIRM_CANDIDATE_REQUEST: "CONFIRM_CANDIDATE_REQUEST",
    CONFIRM_CANDIDATE_SUCCESS: "CONFIRM_CANDIDATE_SUCCESS",
    CONFIRM_CANDIDATE_FAILURE: "CONFIRM_CANDIDATE_FAILURE",

    DELETE_GROUPE_CANDIDATE_REQUEST: "DELETE_GROUPE_CANDIDATE_REQUEST",
    DELETE_GROUPE_CANDIDATE_SUCCESS: "DELETE_GROUPE_CANDIDATE_SUCCESS",
    DELETE_GROUPE_CANDIDATE_FAILURE: "DELETE_GROUPE_CANDIDATE_FAILURE",

    DELETE_GROUPE_CANDIDATE_REQUEST_EMPTY: "DELETE_GROUPE_CANDIDATE_REQUEST_EMPTY",
    DELETE_GROUPE_CANDIDATE_SUCCESS_EMPTY: "DELETE_GROUPE_CANDIDATE_SUCCESS_EMPTY",
    DELETE_GROUPE_CANDIDATE_FAILURE_EMPTY: "DELETE_GROUPE_CANDIDATE_FAILURE_EMPTY",
    
    GET_GALLERY_REQUEST: "GET_GALLERY_REQUEST",
    GET_GALLERY_SUCCESS: "GET_GALLERY_SUCCESS",
    GET_GALLERY_FAILURE: "GET_GALLERY_FAILURE",


    ABANDON_CANDIDATE_REQUEST: "ABANDON_CANDIDATE_REQUEST",
    ABANDON_CANDIDATE_SUCCESS: "ABANDON_CANDIDATE_SUCCESS",
    ABANDON_CANDIDATE_FAILURE: "ABANDON_CANDIDATE_FAILURE",

    REMOVE_CANDIDATE_GROUPE_REQUEST: "REMOVE_CANDIDATE_GROUPE_REQUEST",
    REMOVE_CANDIDATE_GROUPE_FAILURE: "REMOVE_CANDIDATE_GROUPE_FAILURE",
    REMOVE_CANDIDATE_GROUPE_SUCCESS:"REMOVE_CANDIDATE_GROUPE_SUCCESS"
}