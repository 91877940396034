import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify';
import { Translate, FormatDate, mapHeaders } from '../../../../utils'
import { evaluTestActions, candidateActions, exportsActions } from '../../../../actions'
import { InlineElemnt, PopTab, BtnClick, InfoTable, Loader } from '../../../_Common'
import { SendIteropData } from './utils'
import { DetailAnswerJta } from './DetailAnswerJta';

import 'react-toastify/dist/ReactToastify.css';
class DetailJta extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showDetail: false,
            showReinsertCandi: false,
            candiDataToInsert: null,
            sendToiterop: false,
            showSendToIris: false
        }
        props.detailJta(props.isActive);
        this.showResponse = this.showResponse.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.submitIterop = this.submitIterop.bind(this);
        this.exportExcel = this.exportExcel.bind(this);
        this.handleRemoveCandi = this.handleRemoveCandi.bind(this);
        this.showPopUpReinserCandi = this.showPopUpReinserCandi.bind(this);
        this.handleReInsertCandi = this.handleReInsertCandi.bind(this);
        this.sendToInscription = this.sendToInscription.bind(this);
        this.getIteropResultCheck = this.getIteropResultCheck.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        const { deleteingGrpCand, dataGrpDelte } = nextProps
        if (this.props.deleteingGrpCand === "0" && deleteingGrpCand === "1" && dataGrpDelte) {
            this.props.detailJta(this.props.isActive)
        }
    }
    showResponse(evaluteur, candidate) {
        this.setState({
            showDetail: {
                idJta: this.props.isActive,
                idCandi: candidate,
                idEvalu: evaluteur
            }
        })
    }
    handleClose() {
        this.setState({
            showDetail: null
        })
    }
    submitIterop(withAction) {
        if (withAction === "export") {
            this.getLinkExport()
        }
        let prevState = this.state.sendToiterop
        this.setState({
            sendToiterop: !prevState
        })
    }
    getLinkExport = () => {
        const getLink = exportsActions.CreateExportLink({ type: "JTA", dataTable: this.props.isActive })
        toast.promise(
            getLink,
            {
                pending: "L'exportation est en cours de traitement",
                success: "Votre fichier est prêt à utiliser",
                error: "Une erreur s'est produite lors de l'exportation"
            }
        )
        getLink
            .then(res => {
                if (res && res.data && res.data.status === "done") {
                    window.open(res.data.link)
                }
            })
            .catch(err => {

            })
    }
    exportExcel() {
        this.getLinkExport()
    }
    async getIteropResultCheck() {
        try {
            const candidates = (await evaluTestActions.getIteropResult(this.props.isActive)).data.data;
            candidates.forEach(candidate => {
                candidate["date_jta"] = this.props.dataTestDetail.detailJta.date_evaluation;
            });
            const mappedCandidates = mapHeaders(candidates).filter((el) => el && el.date_jta);
            const checkSee = mappedCandidates.length === candidates.length ? false : true
            this.setState({
                showSendToIris: checkSee
            })
        } catch (error) {

        }
    }
    handleRemoveCandi(idCandi) {
        this.props.removeGroupe({ idCandidate: idCandi })
    }
    showPopUpReinserCandi(dataCandi) {
        this.setState({ ...this.state, showReinsertCandi: true, candiDataToInsert: dataCandi })
    }
    handleReInsertCandi(idEval, idCandi) {
        this.props.remarkCondidatePresence(idCandi, idEval)
        this.setState({ ...this.state, showReinsertCandi: false, candiDataToInsert: null })
        setTimeout(() => {
            this.props.detailJta(this.props.isActive)
        }, 1000);
    }

    async sendToInscription() {
        try {
            const candidates = (await evaluTestActions.getIteropResult(this.props.isActive)).data.data;
            candidates.forEach(candidate => {
                candidate["date_jta"] = this.props.dataTestDetail.detailJta.date_evaluation;
            }); 
            const mappedCandidates = mapHeaders(candidates).filter((el) => el && el.date_jta);
            const exportPromise = evaluTestActions.exportToInscription(mappedCandidates);
            toast.promise(
                exportPromise,
                {
                    pending: "L'exportation est en cours de traitement",
                    success: "Données envoyées vers Inscription",
                    error: "Une erreur s'est produite lors de l'exportation"
                }
            )
            await exportPromise;
        } catch (err) {
            console.error(err);
        }
    }

    // componentDidMount() {
    //     this.getIteropResultCheck()
    // }

    render() {
        const { getTestDetail, dataTestDetail, deleteingGrpCand, reAddingCandi } = this.props
        let title = "Chargement de JTA ..."
        let sections = []
        let list = []
        let statusJta = ""
        let showIterop = true
        if (getTestDetail === "1" && dataTestDetail && dataTestDetail.detailJta) {
            showIterop = dataTestDetail.detailJta.status_iterop !== "sended"
            statusJta = dataTestDetail.detailJta.statusejtavaluation
            title = FormatDate(dataTestDetail.detailJta.date_evaluation)
            list = dataTestDetail.detailJta.evaluators_tablettes
            sections = Array.isArray(dataTestDetail.sections) ? dataTestDetail.sections : []
        }
        return (
            <PopTab isActive={this.props.isActive} TabTitle={<InlineElemnt first={this.props.iconGrille} secend={title} />} close={() => this.props.Close(undefined)} width="93%" height="95%">
                {(getTestDetail === "0" || deleteingGrpCand === "0" || reAddingCandi === "0") && <Loader />}
                <div className="OvfAtoY" style={{ height: "calc(100% - 27px)" }}>
                    {list.map((evalu, key) => {
                        return <div key={key}>
                            <h3 className="stMrgv-tb rad-5 padding-def" style={{ background: evalu.tablette.color_code + "20", border: "3px solid " + evalu.tablette.color_code }}>
                                L'évaluateur : {evalu.user.firstname + " " + evalu.user.lastname} / sur Tablette : {evalu.tablette.color}
                            </h3>
                            <InfoTable
                                thead={["",
                                    "ID lindustreet",
                                    "ID ITEROP",
                                    "STATUT", "Raison abandon", ...sections.map((section => {
                                        return "Score " + section.section_name
                                    })), "Moyenne score Séquences", Translate('test', 'testcn'), Translate('test', 'cmntJta'), Translate('test', 'aprecGlobal'), Translate('fields', 'action')]}
                                tbody={evalu.candidates.map((candi, key2) => {
                                    let moyn = 0, some = 0
                                    let note_test, jta_comment, apretion
                                    if (candi.noteTest) {
                                        note_test = candi.noteTest.nb_qestions > 0 ? candi.noteTest.note + " / " + candi.noteTest.nb_qestions : ""
                                        jta_comment = candi.noteTest.jta_comment
                                        apretion = candi.noteTest.appr_gloable
                                    }
                                    if (showIterop) {
                                        jta_comment = candi.comment_jta
                                        apretion = candi.apretion
                                    }
                                    return [
                                        (statusJta === "end" && showIterop) && <BtnClick onClick={() => this.handleRemoveCandi(candi.candidate_id)}
                                            TxtBtn="Réintégrer"
                                            Color="bdg-dng clr-white select-non"
                                        />,
                                        candi.candidate.lindustreet_id,
                                        candi.candidate.iditerop,
                                        statusJta !== "attent" ? (!candi.noteTest || (candi.noteTest && candi.noteTest.isAbsent)) ? "ABANDON pré JTA" : "" : "",
                                        candi.noteTest ? candi.noteTest.absent_raison : "",
                                        ...sections.map(section => {
                                            some += section.score_section ? section.score_section : 0
                                            let score = candi.scores.find(sec => sec.section_id === section.grid_section_id)
                                            if (score) {
                                                moyn += score.score_seq
                                                return score.score_seq + " points"
                                            } else {
                                                return "Pas de note"
                                            }
                                        }), moyn > 0 ? (moyn + "/" + some) : "", note_test, <strong className="space-preline dis-block" style={{ width: 350 }}>{jta_comment}</strong>, <strong className="space-preline dis-block" style={{ width: 350 }}>{apretion}</strong>,
                                        <BtnClick onClick={() => this.showResponse(evalu.evaluator_id, candi.candidate_id)}
                                            TxtBtn="Corrigé"
                                            Color="bdg-onrg-op color-org select-non"
                                        />
                                    ]
                                })}
                            />
                            <hr />
                        </div>
                    })}
                    {dataTestDetail && dataTestDetail.candidateNo && Array.isArray(dataTestDetail.candidateNo.invit_candidate) && dataTestDetail.candidateNo.invit_candidate.length > 0 && <>
                        <h3 className="txt-dng">Candidat(s) non confirmé(s)</h3>
                        <InfoTable
                            thead={["",
                                'ID lindustreet',
                                'Id iterop',
                                Translate('fields', 'firstname'), Translate('fields', 'lastname')]}
                            tbody={dataTestDetail.candidateNo.invit_candidate.map((candi, key) => {
                                return [
                                    <>
                                        {(statusJta === "end" && showIterop) && <BtnClick onClick={() => this.handleRemoveCandi(candi.candidate_id)}
                                            TxtBtn="Supprimer le candidat du groupe"
                                            Color="bdg-dng clr-white select-non mr-8"
                                        />}
                                        {((statusJta === "start" || statusJta === "attent") && showIterop) && <BtnClick onClick={() => this.showPopUpReinserCandi(candi)}
                                            TxtBtn="Ajouter"
                                            Color="bdg_success clr-white select-non px-20"
                                        />}
                                    </>,
                                    candi.candidate.lindustreet_id,
                                    candi.candidate.iditerop,
                                    candi.user.firstname,
                                    candi.user.lastname]
                            })}
                        />
                    </>}
                </div>
                {(statusJta === "end" && showIterop) ? <div className="dis-flex content-end">
                    <BtnClick onClick={this.submitIterop} TxtBtn="Compléter avant envoi dans IRIS" Color="bdg-onrg clr-white select-non" />
                </div> : (getTestDetail === "1" && statusJta === "end") && <div className="dis-flex content-end">
                    <BtnClick onClick={this.exportExcel} TxtBtn="Exporter l'excel" Color="bdg_valid clr-white select-non" />
                    {/* <BtnClick onClick={this.sendToInscription} disabled={this.state.showSendToIris} TxtBtn="Envoyer dans IRIS" Color="bdg_valid clr-white select-non" /> */}
                </div>}
                {this.state.showDetail && <DetailAnswerJta detailAnswer={this.state.showDetail} handleClose={this.handleClose} />}
                {this.state.sendToiterop && <SendIteropData showPop={this.state.sendToiterop} sendToInscription={this.sendToInscription} close={this.submitIterop} />}
                <PopTab
                    isActive={this.state.showReinsertCandi}
                    TabTitle={<InlineElemnt first={null}
                        secend={`Réajouter le candidate: ${this.state.candiDataToInsert ? this.state.candiDataToInsert.candidate.iditerop : ''}`} />}
                    close={() => this.setState({ ...this.state, showReinsertCandi: false, candiDataToInsert: null })} width="60%" height="50%">
                    <div>
                        <h4>Sélectionner l’évaluateur: </h4>
                        <div>
                            {list && list.map((evalu) => (
                                <div key={evalu.tablette_id} className='flex-bc'
                                    style={{ background: evalu.tablette.color_code + "20", border: "1px solid " + evalu.tablette.color_code, margin: '10px', padding: '10px', borderRadius: '5px' }}>
                                    <h3 className="stMrgv-tb rad-5 padding-def" >
                                        L'évaluateur : {evalu.user.firstname + " " + evalu.user.lastname} / sur Tablette : {evalu.tablette.color}
                                    </h3>
                                    <BtnClick onClick={() => this.handleReInsertCandi(evalu.evaluator_test_evaluation_id, this.state.candiDataToInsert.candidate_id)} TxtBtn="Valider" Color="bdg_valid clr-white select-non" />

                                    {/* <h3>- {el.user.full_name} / {el.tablette.color}</h3> */}
                                </div>
                            ))}
                        </div>
                    </div>
                </PopTab>
            </PopTab>
        )
    }
}
function mapState(state) {
    const { getTestDetail, dataTestDetail, reAddingCandi } = state.evaluTest
    const { deleteingGrpCand, dataGrpDelte } = state.candidate
    return { getTestDetail, dataTestDetail, deleteingGrpCand, dataGrpDelte, reAddingCandi }
}
const actionsClass = {
    detailJta: evaluTestActions.detailJta,
    remarkCondidatePresence: evaluTestActions.remarkCondidatePresence,
    removeGroupe: candidateActions.removeGroupe
}
const connectedClass = connect(mapState, actionsClass)(DetailJta);
export { connectedClass as DetailJta };