export const evaluTestConstants={
    READD_CANDI_REQUEST:"READD_CANDI_EVALU_REQUEST",
    READD_CANDI_SUCCESS:"READD_CANDI_EVALU_SUCCESS",
    READD_CANDI_FAILURE:"READD_CANDI_EVALU_FAILURE",

    ADD_TEST_REQUEST:"ADD_TEST_EVALU_REQUEST",
    ADD_TEST_SUCCESS:"ADD_TEST_EVALU_SUCCESS",
    ADD_TEST_FAILURE:"ADD_TEST_EVALU_FAILURE",

    EDIT_TEST_REQUEST:"EDIT_TEST_EVALU_REQUEST",
    EDIT_TEST_SUCCESS:"EDIT_TEST_EVALU_SUCCESS",
    EDIT_TEST_FAILURE:"EDIT_TEST_EVALU_FAILURE",

    SET_DATA_JTA_EDIT:"SET_DATA_JTA_EDIT",

    GET_TEST_REQUEST:"GET_TEST_EVALU_REQUEST",
    GET_TEST_SUCCESS:"GET_TEST_EVALU_SUCCESS",
    GET_TEST_FAILURE:"GET_TEST_EVALU_FAILURE",

    GET_TEST_DETAIL_REQUEST:"GET_TEST_DETAIL_EVALU_REQUEST",
    GET_TEST_DETAIL_SUCCESS:"GET_TEST_DETAIL_EVALU_SUCCESS",
    GET_TEST_DETAIL_FAILURE:"GET_TEST_DETAIL_EVALU_FAILURE",

    HANDLE_TEST_REQUEST:"HANDLE_TEST_EVALU_REQUEST",
    HANDLE_TEST_SUCCESS:"HANDLE_TEST_EVALU_SUCCESS",
    HANDLE_TEST_FAILURE:"HANDLE_TEST_EVALU_FAILURE",


    GET_ANSWERS_TEST_REQUEST:"GET_ANSWERS_TEST_EVALU_REQUEST",
    GET_ANSWERS_TEST_SUCCESS:"GET_ANSWERS_TEST_EVALU_SUCCESS",
    GET_ANSWERS_TEST_FAILURE:"GET_ANSWERS_TEST_EVALU_FAILURE",

    SUBMIT_DATA_TEST_REQUEST:"SUBMIT_DATA_TEST_EVALU_REQUEST",
    SUBMIT_DATA_TEST_SUCCESS:"SUBMIT_DATA_TEST_EVALU_SUCCESS",
    SUBMIT_DATA_TEST_FAILURE:"SUBMIT_DATA_TEST_EVALU_FAILURE",
    
}