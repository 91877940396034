import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Translate} from '../../../../../utils'
import {testActions,evalGrilleActions,testknowActions} from '../../../../../actions'
import {testsConstants} from '../../../../../constants'
import {InputForm,BtnClick,SelectSearch, Loader} from '../../../../_Common'
class SelectGrile extends Component{
    constructor(props){
        super(props)
        this.state={
            griletitle:this.props.dataGrile
        }
        props.getEvaGrilleCan(0)
        this.HandleChange=this.HandleChange.bind(this)
        this.handleNext=this.handleNext.bind(this)
    }
    componentWillReceiveProps(nextProps){
        const {addingTest,dataTest}=nextProps
        if(this.props.addingTest==="0" && addingTest==="1" && dataTest && dataTest.data && dataTest.data.toAdd){
            this.props.StoreStep(this.state.griletitle,testsConstants.SET_GRILE_TEST)
        }
    }
    HandleChange(grile){
        this.setState({
            griletitle:grile
        })
    }
    handleNext(){
        if(this.state.griletitle){
            let test={
                evalGridId:this.state.griletitle.id,
                data_evaluation:this.props.dataTime
            }
            this.props.AddTestKnow(test)
        } 
    }
    render(){
        const {getEvalGrilleCand,dataEvalGrilleCand,addingTest}=this.props
        let griles=[]
        if(getEvalGrilleCand==="1" && dataEvalGrilleCand && dataEvalGrilleCand.data && Array.isArray(dataEvalGrilleCand.data.list)){
            griles=dataEvalGrilleCand.data.list
        }
        return(
            <div className="width-46per leftM-auto righttM-auto">
                {(getEvalGrilleCand==="0" || addingTest==="0") && <Loader/>}
                <span className="linhgth">{Translate('test','selectGrile')}</span>
                <div className="dis-flex topM-8">
                    <SelectSearch field="title_grid" placeholder="Taper le nom de grile ici..." hideOnChose={true} value={this.state.griletitle} datalist={griles} handleSelect={this.HandleChange}/>
                </div>
                <div className="dis-flex content-center topM-22">
                    <BtnClick
                        onClick={this.handleNext}
                        TxtBtn={Translate('test','nextstep')}
                        Color="bdg-onrg clr-white leftM-8"
                    />
                </div>
            </div>
        )
    }
}
function mapState(state){
    const {dataTime,dataGrile}=state.tests
    const {getEvalGrilleCand,dataEvalGrilleCand}=state.grilleEvalu
    const {addingTest,dataTest}=state.testKnow
    return {dataTime,getEvalGrilleCand,dataEvalGrilleCand,addingTest,dataTest,dataGrile}
}
const Actions={
    StoreStep:testActions.StoreStep,
    getEvaGrilleCan:evalGrilleActions.getEvaGrilleCan,
    AddTestKnow:testknowActions.AddTestKnow
}
const ConnectedClass=connect(mapState,Actions)(SelectGrile)
export {ConnectedClass as SelectGrile}