import { testKnowConstants } from '../constants';
export function testKnow(state , action) {
    switch (action.type) {
        case testKnowConstants.ADD_TEST_REQUEST:
            return{
                ...state,
                addingTest:"0",
                dataTest:action.payload
            }
        case testKnowConstants.ADD_TEST_SUCCESS:
            return{
                ...state,
                addingTest:"1",
                dataTest:action.payload
            }
        case testKnowConstants.ADD_TEST_FAILURE:
            return{
                ...state,
                addingTest:"2",
                dataTest:action.payload
            }
        case testKnowConstants.AFFECT_TEST_REQUEST:
            return{
                ...state,
                affecTest:"0",
                dataAffect:action.payload
            }
        case testKnowConstants.AFFECT_TEST_SUCCESS:
            return{
                ...state,
                affecTest:"1",
                dataAffect:action.payload
            }
        case testKnowConstants.AFFECT_TEST_FAILURE:
            return{
                ...state,
                affecTest:"2",
                dataAffect:action.payload
            }
        
        default:
            return {...state}
        }
}