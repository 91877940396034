import { evaluTestConstants } from '../constants';
export function evaluTest(state , action) {
    switch (action.type) {
        case evaluTestConstants.ADD_TEST_REQUEST:
            return{
                ...state,
                addingTest:"0",
                dataTest:action.payload
            }
        case evaluTestConstants.ADD_TEST_SUCCESS:
            return{
                ...state,
                addingTest:"1",
                dataTest:action.payload
            }
        case evaluTestConstants.ADD_TEST_FAILURE:
            return{
                ...state,
                addingTest:"2",
                dataTest:action.payload
            }
        case evaluTestConstants.GET_TEST_REQUEST:
            return{
                ...state,
                getTest:"0",
                dataGetTest:action.payload
            }
        case evaluTestConstants.GET_TEST_SUCCESS:
            return{
                ...state,
                getTest:"1",
                dataGetTest:action.payload
            }
        case evaluTestConstants.GET_TEST_FAILURE:
            return{
                ...state,
                getTest:"2",
                dataGetTest:action.payload
            }
        case evaluTestConstants.GET_TEST_DETAIL_REQUEST:
            return{
                ...state,
                getTestDetail:"0",
                dataTestDetail:action.payload
            }
        case evaluTestConstants.GET_TEST_DETAIL_SUCCESS:
            return{
                ...state,
                getTestDetail:"1",
                dataTestDetail:action.payload
            }
        case evaluTestConstants.GET_TEST_DETAIL_FAILURE:
            return{
                ...state,
                getTestDetail:"2",
                dataTestDetail:action.payload
            }
        case evaluTestConstants.READD_CANDI_REQUEST:
            return{
                ...state,
                reAddingCandi:"0",
            }
        case evaluTestConstants.READD_CANDI_SUCCESS:
            return{
                ...state,
                reAddingCandi:"1",
            }
        case evaluTestConstants.READD_CANDI_FAILURE:
            return{
                ...state,
                reAddingCandi:"2",
            }
        case evaluTestConstants.GET_ANSWERS_TEST_REQUEST:
            return{
                ...state,
                getingAnswers:"0",
                dataAnswerJta:action.payload
            }
        case evaluTestConstants.GET_ANSWERS_TEST_SUCCESS:
            return{
                ...state,
                getingAnswers:"1",
                dataAnswerJta:action.payload
            }
        case evaluTestConstants.GET_ANSWERS_TEST_FAILURE:
            return{
                ...state,
                getingAnswers:"2",
                dataAnswerJta:action.payload
            }
        case evaluTestConstants.HANDLE_TEST_REQUEST:
            return{
                ...state,
                handleTest:"0"
            }
        case evaluTestConstants.HANDLE_TEST_SUCCESS:
            return{
                ...state,
                handleTest:"1",
                datahandleTest:action.payload
            }
        case evaluTestConstants.HANDLE_TEST_FAILURE:
            return{
                ...state,
                handleTest:"2"
            }
        case evaluTestConstants.SUBMIT_DATA_TEST_REQUEST:
            return{
                ...state,
                submitingData:"0",
                dataIterop:action.payload
            }
        case evaluTestConstants.SUBMIT_DATA_TEST_SUCCESS:
            return{
                ...state,
                submitingData:"1",
                dataIterop:action.payload
            }
        case evaluTestConstants.SUBMIT_DATA_TEST_FAILURE:
            return{
                ...state,
                submitingData:"2",
                dataIterop:action.payload
            }
        
        default:
            return {...state}
        }
}