export const groupeConstants = {
    GET_CANDIDATE_GROUPE_REQUEST: "GET_CANDIDATE_GROUPE_REQUEST",
    GET_CANDIDATE_GROUPE_SUCCESS: "GET_CANDIDATE_GROUPE_SUCCESS",
    GET_CANDIDATE_GROUPE_FAILURE: "GET_CANDIDATE_GROUPE_FAILURE",

    GET_GROUPE_INVITATIONS_REQUEST: "GET_GROUPE_INVITATIONS_REQUEST",
    GET_GROUPE_INVITATIONS_SUCCESS: "GET_GROUPE_INVITATIONS_SUCCESS",
    GET_GROUPE_INVITATIONS_FAILURE: "GET_GROUPE_INVITATIONS_FAILURE",

    CONFIRM_PRESENCE_CANDI_REQUEST: "CONFIRM_PRESENCE_CANDI_REQUEST",
    CONFIRM_PRESENCE_CANDI_SUCCESS: "CONFIRM_PRESENCE_CANDI_SUCCESS",
    CONFIRM_PRESENCE_CANDI_FAILURE: "CONFIRM_PRESENCE_CANDI_FAILURE",

    CANCEL_PRESENCE_CANDI_REQUEST: "CANCEL_PRESENCE_CANDI_REQUEST",
    CANCEL_PRESENCE_CANDI_SUCCESS: "CANCEL_PRESENCE_CANDI_SUCCESS",
    CANCEL_PRESENCE_CANDI_FAILURE: "CANCEL_PRESENCE_CANDI_FAILURE",

    AUTO_AFFECTATION_CANDIDATE_REQUEST: "AUTO_AFFECTATION_CANDIDATE_REQUEST",
    AUTO_AFFECTATION_CANDIDATE_SUCCESS: "AUTO_AFFECTATION_CANDIDATE_SUCCESS",
    AUTO_AFFECTATION_CANDIDATE_FAILURE: "AUTO_AFFECTATION_CANDIDATE_FAILURE",

}