import { evalgrilleConstants } from '../constants';
export function grilleEvalu(state, action) {
    switch (action.type) {
        case evalgrilleConstants.ADD_EVAL_REQUEST:
            return {
                ...state,
                addEval: "0",
                dataEval: action.payload
            };
        case evalgrilleConstants.ADD_EVAL_SUCCESS:
            return {
                addEval: "1",
                dataEval: action.payload
            };
        case evalgrilleConstants.ADD_EVAL_FAILURE:
            return {
                ...state,
                addEval: "2",
                dataEval: action.payload
            };
        case evalgrilleConstants.DELETE_EVAL_REQUEST:
            return {
                ...state,
                deletingGrid: "0"
            };
        case evalgrilleConstants.DELETE_EVAL_SUCCESS:
            let returnValues = {
                dataDelGrid: action.payload
            }
            if (action.payload && action.payload.data && action.payload.data.status === "done") {
                if (action.payload.params.typeGrid === "testConnai") {
                    returnValues = {
                        dataEvalGrilleCand: action.payload.data
                    }
                } else if (action.payload.params.typeGrid === "testEval") {
                    returnValues = {
                        dataEvalEva: action.payload.data
                    }
                }

            }
            return {
                ...state,
                deletingGrid: "1",
                ...returnValues
            };
        case evalgrilleConstants.DELETE_EVAL_FAILURE:
            return {
                ...state,
                deletingGrid: "1",
                dataDelGrid: action.payload
            };
        case evalgrilleConstants.GET_EVAL_GRILLE_CANDIDATE_REQUEST:
            return {
                ...state,
                getEvalGrilleCand: "0",
                dataEvalGrilleCand: action.payload
            };
        case evalgrilleConstants.GET_EVAL_GRILLE_CANDIDATE_SUCCESS:
            return {
                ...state,
                getEvalGrilleCand: "1",
                dataEvalGrilleCand: action.payload
            };
        case evalgrilleConstants.GET_EVAL_GRILLE_CANDIDATE_FAILURE:
            return {
                ...state,
                getEvalGrilleCand: "2",
                dataEvalGrilleCand: action.payload
            };
        case evalgrilleConstants.GET_EVAL_GRILLE_REQUEST:
            return {
                ...state,
                getEvalEva: "0",
                dataEvalEva: action.payload
            };
        case evalgrilleConstants.GET_EVAL_GRILLE_SUCCESS:
            return {
                ...state,
                getEvalEva: "1",
                dataEvalEva: action.payload
            };
        case evalgrilleConstants.GET_EVAL_GRILLE_FAILURE:
            return {
                ...state,
                getEvalEva: "2",
                dataEvalEva: action.payload
            };
        case evalgrilleConstants.GET_EVAL_DETAIL_REQUEST:
            return {
                ...state,
                getDetailgri: "0",
                dataDetail: action.payload
            };
        case evalgrilleConstants.GET_EVAL_DETAIL_SUCCESS:
            return {
                ...state,
                getDetailgri: "1",
                dataDetail: action.payload
            };
        case evalgrilleConstants.GET_EVAL_DETAIL_FAILURE:
            return {
                ...state,
                getDetailgri: "2",
                dataDetail: action.payload
            };
        default:
            return { ...state }
    }
}