import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Translate} from '../../../../../utils'
import {testActions,selfGrilleActions} from '../../../../../actions'
import {testsConstants} from '../../../../../constants'
import {InputForm,BtnClick,SelectSearch, Loader} from '../../../../_Common'
class SelectGrile extends Component{
    constructor(props){
        super(props)
        this.state={
            griletitle:props.dataGrille,
            type:this.props.user==="candidate"?'cand':'eval'
        }
        this.HandleChange=this.HandleChange.bind(this)
        this.handleNext=this.handleNext.bind(this)
        if(props.getSelfgrille!=="1"){
            props.getSelfGrille()
        }
    }
    HandleChange(grile){
        this.setState({
            griletitle:grile
        })
    }
    handleNext(){
        if(this.state.griletitle){
            this.props.StoreStep(this.state.griletitle,testsConstants.SET_SELF_GRILLE_TEST)
        } 
    }
    render(){
        const {getSelfgrille,dataSelfgrille}=this.props
        let griles=[]
        if(getSelfgrille==="1" && dataSelfgrille && dataSelfgrille.AutoGrid){
            griles=dataSelfgrille.AutoGrid[this.state.type]
        }
        return(
            <div className="width-46per leftM-auto righttM-auto">
                {getSelfgrille==="0" && <Loader />}
                <span className="linhgth">{Translate('test','selectGrile')}</span>
                <div className="dis-flex topM-8">
                    <SelectSearch field="title_auto_evaluation" placeholder="Taper le nom de grile ici..." hideOnChose={true} value={this.state.griletitle} datalist={griles} handleSelect={this.HandleChange}/>
                </div>
                <div className="dis-flex content-center topM-22">
                    <BtnClick
                        onClick={this.handleNext}
                        TxtBtn={Translate('test','nextstep')}
                        Color="bdg-onrg clr-white leftM-8"
                    />
                </div>
            </div>
        )
    }
}
function mapState(state){
    const {dataGrille}=state.tests
    const {getSelfgrille,dataSelfgrille}=state.grilleauto
    return {dataGrille,getSelfgrille,dataSelfgrille}
}
const Actions={
    StoreStep:testActions.StoreStep,
    getSelfGrille:selfGrilleActions.getSelfGrille
}
const ConnectedClass=connect(mapState,Actions)(SelectGrile)
export {ConnectedClass as SelectGrile}