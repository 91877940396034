import React, { Component } from 'react';
import { connect } from 'react-redux'
import Cookies from 'js-cookie'
import { authActions } from '../../../actions'
import { Translate } from '../../../utils'
import { BtnClick, InputForm, Loader } from '../../_Common'
class LoginPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            password: '',
            newPassword: '',
            confirmedPassword: ''
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handlePassword = this.handlePassword.bind(this)
    }
    componentDidMount() {
        document.title = "JTA App - " + Translate('titles', 'login')
    }
    handleChange(e) {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }
    handlePassword(e) {
        e.preventDefault()
        let data = {
            password: this.state.newPassword,
            confirm: this.state.confirmedPassword
        }
        this.props.UpdatePassword(data, this.props.dataLogin.token)
    }
    handleSubmit(e) {
        e.preventDefault()
        this.props.Login({ email: this.state.email, password: this.state.password })
    }
    render() {
        const { loging, dataLogin, updatinPassword, dataPassword } = this.props
        var isUpdate = true
        if (loging === "1" && dataLogin && dataLogin.token && (dataLogin.typeuser === "reporter" || dataLogin.typeuser === "admin" || dataLogin.typeuser === "supadmin")) {
            if (dataLogin.update) {
                isUpdate = false
            } else {
                var date = new Date();
                date.setDate(date.getDate() + 365);
                Cookies.set('auth_user', dataLogin.token, { expires: date })
                window.location.reload()
            }
        }
        if (updatinPassword === "1" && dataPassword && dataPassword.status === "done") {
            var date = new Date();
            date.setDate(date.getDate() + 365);
            Cookies.set('auth_user', dataLogin.token, { expires: date })
            window.location.reload()
        }
        return (
            <div className="bdg-white pos-relative box-def rad-5" style={{ width: "470px", padding: "45px 30px" }}>
                {(loging === "0" || updatinPassword === "0") && <Loader />}
                <img alt="logo_industreet" width="220" src="/assets/img/logo.png" />
                {isUpdate ? <form onSubmit={this.handleSubmit}>
                    <h2 className="margin-null topM-15">{Translate('auth', 'connexion')}</h2>
                    {loging === "2" && <span className="txt-dng">vérifier votre information de connexion</span>}
                    <div>
                        <InputForm
                            className="topM-15"
                            placeholder={Translate('fields', 'email')}
                            name="email"
                            type="email"
                            onChange={this.handleChange}
                            value={this.state.email}
                        />
                        <InputForm
                            className="topM-15"
                            placeholder={Translate('auth', 'password')}
                            name="password"
                            type="password"
                            onChange={this.handleChange}
                            value={this.state.password}
                        />
                    </div>
                    <div className="dis-flex content-center topM-22">
                        <BtnClick
                            onClick={this.handleSubmit}
                            TxtBtn={Translate('auth', 'connexion')}
                            Color="bdg-onrg clr-white leftM-8"
                        />
                    </div>
                </form> :
                    <form onSubmit={this.handlePassword}>
                        <h2 className="margin-null topM-15">{Translate('auth', 'chngpass')}</h2>
                        {updatinPassword === "2" && <span className="txt-dng">Les informations saisies sont incorrectes</span>}
                        <div>
                            <InputForm
                                className="topM-15"
                                placeholder={Translate('auth', 'newpassword')}
                                name="newPassword"
                                type="password"
                                onChange={this.handleChange}
                                value={this.state.newPassword}
                            />
                            <InputForm
                                className="topM-15"
                                placeholder={Translate('auth', 'confirmpassword')}
                                name="confirmedPassword"
                                type="password"
                                onChange={this.handleChange}
                                value={this.state.confirmedPassword}
                            />
                        </div>
                        <div className="dis-flex content-center topM-22">
                            <BtnClick
                                onClick={this.handlePassword}
                                TxtBtn={Translate('auth', 'savg')}
                                Color="bdg-onrg clr-white leftM-8"
                            />
                        </div>
                    </form>}
            </div>
        )
    }
}
function mapState(state) {
    const { loging, dataLogin, updatinPassword, dataPassword } = state.auth
    return { loging, dataLogin, updatinPassword, dataPassword }
}

const actionCreators = {
    Login: authActions.Login,
    UpdatePassword: authActions.UpdatePassword
};

const connectedLoginPage = connect(mapState, actionCreators)(LoginPage);
export { connectedLoginPage as LoginPage };