import { selfgrilleConstants } from '../constants';
export function grilleauto(state, action) {
    switch (action.type) {
        case selfgrilleConstants.ADD_SELF_REQUEST:
            return {
                ...state,
                addSelf: "0",
                dataSelf: action.payload
            };
        case selfgrilleConstants.ADD_SELF_SUCCESS:
            return {
                ...state,
                addSelf: "1",
                dataSelf: action.payload
            };
        case selfgrilleConstants.ADD_SELF_FAILURE:
            return {
                ...state,
                addSelf: "2",
                dataSelf: action.payload
            };
        case selfgrilleConstants.DELETE_SELF_REQUEST:
            return {
                ...state,
                deletingSelf: "0"
            };
        case selfgrilleConstants.DELETE_SELF_SUCCESS:
            let dataReturnGrille = {
                dataDeleteSelft: action.payload
            }
            if (action.payload && action.payload.status === "done") {
                dataReturnGrille = {
                    dataSelfgrille: action.payload
                }
            }
            return {
                ...state,
                deletingSelf: "1",
                ...dataReturnGrille
            };
        case selfgrilleConstants.DELETE_SELF_FAILURE:
            return {
                ...state,
                deletingSelf: "2",
                dataDeleteSelft: action.payload
            };
        case selfgrilleConstants.GET_SELF_GRILLE_REQUEST:
            return {
                ...state,
                getSelfgrille: "0",
                dataSelfgrille: action.payload
            };
        case selfgrilleConstants.GET_SELF_GRILLE_SUCCESS:
            return {
                ...state,
                getSelfgrille: "1",
                dataSelfgrille: action.payload
            };
        case selfgrilleConstants.GET_SELF_GRILLE_FAILURE:
            return {
                ...state,
                getSelfgrille: "2",
                dataSelfgrille: action.payload
            };
        case selfgrilleConstants.GET_SELF_DETAIL_REQUEST:
            return {
                ...state,
                getDetailgri: "0",
                dataDetail: action.payload
            };
        case selfgrilleConstants.GET_SELF_DETAIL_SUCCESS:
            return {
                ...state,
                getDetailgri: "1",
                dataDetail: action.payload
            };
        case selfgrilleConstants.GET_SELF_DETAIL_FAILURE:
            return {
                ...state,
                getDetailgri: "2",
                dataDetail: action.payload
            };
        default:
            return { ...state }
    }
}