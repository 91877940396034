import React, { Component } from 'react'
import { connect } from 'react-redux'
import {tabletActions,alertActions} from '../../../actions'
import {Translate} from '../../../utils'
import {PopTab,InputForm,BtnSubmit,Loader} from '../../_Common'
class AddTablet extends Component{
    constructor(props){
        super(props)
        this.state={
            color:"",
            numTablette:"",
            color_code:'#454545',
            ...props.default
        }
        this.HandleChange=this.HandleChange.bind(this)
        this.handleSubmit=this.handleSubmit.bind(this)
    }
    componentWillReceiveProps(nextProps){
        const {adingTablet,dataAddTabelt,getTablet,close,updatingTablet}=nextProps
        if(this.props.adingTablet==="0" && adingTablet==="1" && dataAddTabelt){
            this.props.SendAlert("Opération réussie","success")
            getTablet()
            close(undefined)
        }
        if(this.props.updatingTablet==="0" && updatingTablet==="1" && dataAddTabelt){
            this.props.SendAlert("Opération réussie","success")
            getTablet()
            close(undefined)
        }
        if(this.props.adingTablet==="0" && adingTablet==="2" && dataAddTabelt){
            this.props.SendAlert("Vérifier les informations de La table ou esseyer plus tard","error")
        }
    }
    HandleChange(e){
        const {name,value}=e.target
        this.setState({
            [name]:value
        })
    }
    handleCodeColor = (color) =>{
        this.setState({
            color_code:color.hex
        })
    }
    handleSubmit(e){
        e.preventDefault()
        let tablet={
            color:this.state.color,
            numTablette:this.state.numTablette,
            color_code:this.state.color_code
        }
        if(this.props.active==="add"){
            this.props.AddTablet(tablet)
        }else{
            tablet.idtablette=this.props.active
            this.props.updateTablet(tablet)
        }
        
    }
    render(){
        const {adingTablet,updatingTablet}=this.props
        return(
            <PopTab isActive={this.props.active} TabTitle={Translate('tablette','newtabllet')} close={() => this.props.close(undefined)} width="550px">
                {(adingTablet==="0" || updatingTablet==="0") && <Loader/>}
                <form onSubmit={this.handleSubmit}>
                    <h4 className="clr-grey sTmr-si">{Translate('tablette','infotablet')}</h4>
                    <div className="dis-flex">
                        <InputForm
                            className="rightM-8"
                            placeholder={Translate('tablette','color')}
                            name="color"
                            type="text"
                            onChange={this.HandleChange}
                            value={this.state.color}
                        />
                        <InputForm
                            placeholder={Translate('tablette','number')}
                            name="numTablette"
                            type="text"
                            onChange={this.HandleChange}
                            value={this.state.numTablette}
                        />
                    </div>
                    <div className="paddTB-10 ">
                        <label className="sizeF-1em">Code de couleur</label>
                        <input type="color" name="color_code" className="width-100 padding-null margin-null rad-5" style={{height:50}} value={this.state.color_code} onChange={this.HandleChange}/>
                    </div>
                    <div className="dis-flex leftM-auto sTrgPdn sTmr-si">
                        <BtnSubmit
                            TxtBtn={Translate('tablette','certablet')}
                            Color="width-100 bdg-onrg clr-white"
                        />
                    </div>
                </form>
            </PopTab>
        )
    }
}
function mapState(state){
    const {adingTablet,dataAddTabelt,updatingTablet}=state.tablet
    return {adingTablet,dataAddTabelt,updatingTablet}
}
const Actions={
    AddTablet:tabletActions.AddTablet,
    getTablet:tabletActions.getTablet,
    updateTablet:tabletActions.updateTablet,
    SendAlert:alertActions.SendAlert
}
const connectedClass=connect(mapState,Actions)(AddTablet)
export {connectedClass as AddTablet}