import { userConstants } from '../constants';
export function user(state , action) {
    switch (action.type) {
        case userConstants.GET_USER_REQUEST:
            return {
                getingUser:"0",
                dataUser: action.payload
            };
        case userConstants.GET_USER_SUCCESS:
            return {
                getingUser:"1",
                dataUser: action.payload
            };
        case userConstants.GET_USER_FAILURE:
            return {
                getingUser:"2",
                dataUser: action.payload
            };
        default:
            return {...state}
        }
}