import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormatDate, Translate } from '../../../../../utils'
import { testActions, evaluTestActions, alertActions } from '../../../../../actions'
import { testsConstants } from '../../../../../constants'
import { BtnClick, InfoTable, Loader } from '../../../../_Common'
class PreviewTest extends Component {
    constructor(props) {
        super(props)
        this.state = {
            griletitle: null,
            selectedUser: []
        }
        this.HandleChange = this.HandleChange.bind(this)
        this.handleNext = this.handleNext.bind(this)
    }
    /*componentWillReceiveProps(nextProps) {
        const { addingTest, dataTest, close, StoreStep, getJta, dataSelfTest, addSelfTest } = nextProps
        if ((this.props.addingTest === "0" || this.props.addSelfTest === "0") && (addingTest === "1" && addSelfTest === "1") && dataTest && dataSelfTest) {
            this.props.SendAlert("Opération réussie", "success")
            StoreStep("none", testsConstants.REMOVE_PROCESS_TEST)
            getJta()
            close(undefined)
        }
        if (this.props.addingTest === "0" && addingTest === "2") {
            this.props.SendAlert("Erreur lors de la création de test , esseyer plus tard", "error")
        }
    }*/
    HandleChange(user) {
        let current = this.state.selectedUser
        if (!current.find(use => use.id === user.id)) {
            current.push(user)
            this.setState({
                selectedUser: current
            })
        }

    }
    removeSelect(key) {
        let crntelem = this.state.selectedUser
        crntelem.splice(key, 1)
        this.setState({
            selectedUser: crntelem
        })
    }
    handleNext() {
        const { dataEvaluator, dataTime, dataGroupe, grilleAuto, grilleAutoEval } = this.props
        let test = {
            dateautotest: dataTime,
            auto_evaluation_id: null,
            groups: [dataGroupe.groupe_candidate_id]
        }
        if (grilleAutoEval) {
            const testAutoEval = {
                dateautotest: dataTime,
                auto_evaluation_id: grilleAutoEval.auto_evaluation_id,
                evaluators: dataEvaluator
            }
            this.props.TestEvaluateur(testAutoEval)
        }
        // this.props.TestCandidate(test)
        this.props.AddJta()
    }
    render() {
        const { dataGrille, dataTime, dataGroupe, dataEvaluator, addSelfTest } = this.props
        var selected = dataEvaluator.map(user => { return [user.evalu_name, user.table_color, user.candidates.length] })
        return (
            <div className="hg-100-200 leftM-auto righttM-auto" style={{ width: "68%" }}>
                {addSelfTest === "0" && <Loader />}
                <div>
                    <strong className="linhgth sizeF-12em lineHg-35">{Translate('test', 'ladatedejta')}</strong>
                    <div>{FormatDate(dataTime)}</div>
                    {/* <hr /> */}
                    {/* <strong className="linhgth sizeF-12em lineHg-35">{Translate('test', 'testcn')}</strong> */}
                    <hr />
                    <div>
                        {/* <strong className="linhgth sizeF-11em lineHg-35">{Translate('test', 'grileselect')}</strong> */}
                        {/* <div>{dataGrille.titlegrid}</div> */}

                        <strong className="linhgth sizeF-12em lineHg-35">Groupe sélectionné : </strong>
                        <div>{dataGroupe.groupename}</div>
                    </div>
                    <hr />
                    <strong className="linhgth sizeF-12em lineHg-35">Les évaluateurs :</strong>
                    <hr />
                    <div className="text-left width-100">
                        <div className="">
                            <InfoTable
                                thead={[Translate('test', 'nmevalu'), "Couleur de l’équipe", Translate('test', 'nbcandidate')]}
                                tbody={selected}
                            />
                        </div>
                    </div>
                </div>
                <div className="dis-flex content-center topM-22">
                    <BtnClick
                        onClick={this.handleNext}
                        TxtBtn={Translate('test', 'creerletest')}
                        Color="bdg-onrg clr-white leftM-8"
                    />
                </div>
            </div>
        )
    }
}
function mapState(state) {
    const { dataEvaluator, datagrileEval, dataTime, dataGroupe, dataGrille, grilleAuto, grilleAutoEval, addSelfTest, dataSelfTest } = state.tests
    return { dataGrille, dataEvaluator, datagrileEval, dataTime, dataGroupe, grilleAuto, grilleAutoEval, addSelfTest, dataSelfTest }
}
const Actions = {
    StoreStep: testActions.StoreStep,
    getJta: evaluTestActions.getJta,
    TestCandidate: testActions.TestCandidate,
    TestEvaluateur: testActions.TestEvaluateur,
    SendAlert: alertActions.SendAlert
}
const ConnectedClass = connect(mapState, Actions)(PreviewTest)
export { ConnectedClass as PreviewTest }