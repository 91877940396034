import React from 'react'
import { BtnClick, NoPictureSvg } from '../../../_Common'
const ElementGalerie = (props) => {
    return (
        <div className="img_candiglr rightM-8 topM-15 rad-5 HdOvrfl box-def bdg-white dis-flex flex-direction content-btwn">
            <div className="dis-flex content-center item-center">
                {props.image ? <img src={props.image} style={{ maxHeight: "180px", maxWidth: "100%" }} /> : <i className="dis-flex" style={{ marginTop: "10px" }}>{NoPictureSvg}</i>}

            </div>
            <div className="width-100 box-border pd-10-15 dis-flex flex-direction content-between height-100">
                <strong>{props.fullName}</strong>
                <strong className="linhgth">{props.iterop}</strong>
                {props.image &&
                    <BtnClick
                        onClick={() => props.downloadImage(props.iterop)}
                        Color="bdg-onrg-op color-org"
                        TxtBtn="Télécharger"
                    />
                }
            </div>
        </div>
    )
}
export { ElementGalerie }