import { reporterConstants } from '../constants';
import { RequestService,FunctionService  } from '../services';
export const reporterActions = {
    AddReporter,
    fetchReporters,
    updateReporter,
    deletReporter
};
function AddReporter(reporter){
    return dispatch => {
        dispatch(FunctionService.request(reporterConstants.HANDLE_REPORTER_REQUEST))
        RequestService.PostRequest('report/user/create',reporter,true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,reporterConstants.HANDLE_REPORTER_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,reporterConstants.HANDLE_REPORTER_FAILURE))
        })
    }
}
function fetchReporters(){
    return dispatch => {
        dispatch(FunctionService.request(reporterConstants.FETCH_REPORTER_REQUEST))
        RequestService.GetRequest('report/user/fetch',true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,reporterConstants.FETCH_REPORTER_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,reporterConstants.FETCH_REPORTER_FAILURE))
        })
    }
}
function updateReporter(reporter){
    return dispatch => {
        dispatch(FunctionService.request(reporterConstants.HANDLE_REPORTER_REQUEST))
        RequestService.PatchRequest('report/user/update',reporter,true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,reporterConstants.HANDLE_REPORTER_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,reporterConstants.HANDLE_REPORTER_FAILURE))
        })
    }
}
function deletReporter(idReporter){
    return dispatch => {
        dispatch(FunctionService.request(reporterConstants.DELETE_REPORTER_REQUEST))
        RequestService.DeleteRequest('report/user/delete/'+idReporter,true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,reporterConstants.DELETE_REPORTER_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,reporterConstants.DELETE_REPORTER_FAILURE))
        })
    }
}