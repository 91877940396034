import React, { Component } from 'react';
import { exportsActions } from '../../../../../../actions';
import { Translate } from '../../../../../../utils';
import { BtnClick, SelectSearch, InlineElemnt, TableRows,BtnActions,DeleteSvg } from '../../../../../_Common'
class SelectEvaluatorTablete extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tablette: null,
            evaluator: null,
        }
        this.handleSelect = this.handleSelect.bind(this)
    }
    handleChangeSelect(name, value) {
        this.setState({
            [name]: value
        })
    }
    handleSelect() {
        this.props.handleAffect(this.state.evaluator, this.state.tablette)
        this.state.evaluator = null
        this.state.tablette = null
    }
    render() {
        const { getiEvalu, dataEvalu, getiTablet, dataTabelt } = this.props
        var tabllets = []
        var evalu = []
        if (getiEvalu === "1" && dataEvalu && Array.isArray(dataEvalu.evaluators)) {
            evalu = dataEvalu.evaluators.filter(evale => !this.props.dataevaluation.find(elem => elem.evaluator_id == evale.evaluator_id)).map(evalu => { return { fullname: evalu.firstname + " " + evalu.lastname, id: evalu.evaluator_id } })
        }
        if (getiTablet === "1" && dataTabelt && Array.isArray(dataTabelt.tablettes)) {
            tabllets = dataTabelt.tablettes.filter(tablet => !this.props.dataevaluation.find(elem => elem.tablette_id == tablet.tablette_id))
        }
        return (
            <div className="dis-flex">
                <div className="dis-flex flex-direction item-center" style={{ width: "100%" }}>
                    <div className="text-left width-100">
                        <div className="dis-flex content-btwn item-center">
                            <h3 className="margin-null">Les évaluateurs</h3>
                        </div>
                        <div className="">
                            <TableRows
                                columns={[
                                    { name: Translate('test', 'nmevalu'), rowKey: "evalu_name" },
                                    { name: Translate('tablette', 'color'), rowKey: "table_color" },
                                    { name: Translate('fields', 'action'), style:{width:20}, rowKey: "table_color",renderKey: keyItem => <BtnActions btns={[{ icon: DeleteSvg, action: () => this.props.handleDeleteItem(keyItem) }]} />
                                     }
                                ]}
                                data={this.props.dataevaluation}
                                key_table="evaluator_id"
                            />
                        </div>
                    </div>
                    <div className="dis-flex content-btwn width-100">
                        <div className="width-49per">
                            <SelectSearch field="fullname" placeholder="Choisir l'evaluateur ..." hideOnChose={true} datalist={evalu} value={this.state.evaluator} maxHieght="250px" handleSelect={(evalu) => this.handleChangeSelect("evaluator", evalu)} />
                        </div>
                        <div className="width-49per">
                            <SelectSearch field="color" placeholder="Choisir la tablette ..." hideOnChose={true} datalist={tabllets} value={this.state.tablette} maxHieght="250px" handleSelect={(tablete) => this.handleChangeSelect("tablette", tablete)} />
                        </div>
                    </div>
                    <div className="topM-8 dis-flex width-100 content-btwn">
                        <div className="d-flex">
                            <BtnClick
                                onClick={this.handleSelect}
                                TxtBtn="Ajouter un évaluateur"
                                Color="bdg-onrg-op color-org"
                            />
                            <BtnClick
                                onClick={()=> exportsActions.ExportFicheAffectations({evaluators:this.props.dataevaluation})}
                                Color="bgd-pdf clr-white leftM-8"
                                style={{}}
                                TxtBtn="Feuille d’émargement"
                            />
                        </div>
                        <div className="d-flex">
                            <BtnClick
                                onClick={this.props.AddJta}
                                TxtBtn="Sauvegarder la JTA"
                                Color="bdg_valid clr-white leftM-8"
                            />
                            <BtnClick
                                onClick={this.props.handleNext}
                                TxtBtn={Translate('test', 'nextstep')}
                                Color="bdg-onrg clr-white leftM-8"
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export { SelectEvaluatorTablete }