import React from 'react'
export function InfoTable(props) {
    return (
        <div className="width-100 OvfAtoX pos-relative paddTB-3" style={props.styleTop}>
            <div className="width-100 rad-10" style={{ overflow: "" }}>
                <table className="text-left pad-table space-nowarp" style={{ minWidth: "100%" }}>
                    <thead className="index-1">
                        <tr className={"color-org RglCh-cntn " + (props.classSticky ? props.classSticky : "")}>
                            {Array.isArray(props.thead) && props.thead.map((head, key) => {
                                return <th className="bdg-blue" key={key}>{head}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody className={props.classBody} style={props.styleBody}>
                        {Array.isArray(props.tbody) && props.tbody.map((tr, key1) => {
                            return <tr key={key1} className="brd-btm curs-pointer hover-shadwh RglCh-cntn">
                                {Array.isArray(tr) && tr.map((td, key2) => {
                                    return <td key={key2} title={typeof td === "string" && td}>{td}</td>
                                })}
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}