import React from 'react'
import {Link} from 'react-router-dom'
export function ButtonLink(props) {
    return (
        <Link to={props.pathName}>
            <div className="color-org font-600 btn_color hvr_btn padding-def shadow-sml hover-shadow">
                <span>{props.TextBottun}</span>
            </div>
        </Link>
    )
}
export function ButtonCLick(props) {
    return (
        <button onClick={props.action}>
            <div className="color-org font-600 btn_color hvr_btn padding-def shadow-sml hover-shadow">
                <span className="dis-flex">{props.TextBottun}</span>
            </div>
        </button>
    )
}
export function BtnAction(props) {
    return (
        <button className={props.className+" dis-flex paddAll-5 bdg-trw rad-100 hover-shadow"}>
            <i className="width-mxcn height-mxcn dis-flex item-center">{props.iconBtn}</i>
        </button>
    )
}
export function BtnSubmit(props) {
    return (
        <button className={props.Color+" padding-def rad-5 sizeF-1em font-600 hover-shadow"}>
            <span>{props.TxtBtn}</span>
        </button>
    )
}
export function BtnClick(props) {
    return (
        <button type="button"
            title={props.title&&props.title}
            disabled={props.disabled}
            onClick={props.onClick}
            style={props.style}
            className={props.disabled? " padding-def leftM-8 rad-5 sizeF-85em font-600 disabled_btn":props.Color+" padding-def rad-5 sizeF-85em font-600 hover-shadow"}>
            <span>{props.TxtBtn}</span>
        </button>
    )
}