import { toast } from 'react-toastify';
import { RequestService } from '../services';
export const mailsActions = {
    FetchMailTemplate,
    SaveTemplate,
    TestTemplate
}
function FetchMailTemplate(typeTemplate) {
    return RequestService.GetRequest(`mails/${typeTemplate}`, true)
}
function SaveTemplate(template, typeTemplate) {
    return RequestService.PostRequest(`mails/${typeTemplate}`, template, true)
}
function TestTemplate(data, typeTemplate) {
    const dataPromise = RequestService.PostRequest(`mails/test/${typeTemplate}`, data, true)
    toast.promise(
        dataPromise,
        {
            pending: "L'email en cours",
            success: "Email envoyé avec succès",
            error: "Une erreur s'est produite"
        }
    )
    dataPromise
        .then(({ data = {} }) => {
        })
        .catch(err => {

        })
    return 0
}