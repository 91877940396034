import React, { useState } from 'react'
import { qestionsType } from '../../../../../constants'
import { InlineElemnt, DownArrowSvg, InfoTable } from '../../../../_Common'
export function QstEvaluView(props) {
    const initialState = {
        showDetail: false
    }
    const [state, setstate] = useState(initialState);
    function handleChange() {
        setstate({
            ...state,
            showDetail: !state.showDetail
        })
    }
    return (
        <div className="botM-25">
            <span className="curs-pointer" onClick={handleChange}>
                <InlineElemnt className="sizeF-11em" first={<strong className="dis-flex brd-equ select-non item-center content-center mm-widthg rad-100 ">{props.index}</strong>} more={<div className="leftM-auto trans-cub" style={state.showDetail ? { transform: 'scale(1.4)' } : { transform: 'rotate(-90deg) scale(1.4)' }}>{DownArrowSvg}</div>} secend={props.qst.text}
                />
            </span>
            {state.showDetail && props.qst.typequestion === qestionsType.bool &&
                <div className="leftM-40 topM-8 dis-flex">
                    <InlineElemnt
                        className={"brd-equ rad-5 pd-10-15 " + (props.qst.details[0].chosed ? "accpt_clr" : "")}
                        classSecend=""
                        first={<span>{(props.qst.details[0].chosed ? "+" : "") + props.qst.details[0].detailanswer}</span>}
                        secend={"  " + props.qst.details[0].detailtext}
                    />
                    <InlineElemnt
                        className={"leftM-8 brd-equ rad-5 pd-10-15 " + (props.qst.details[1].chosed ? "accpt_clr" : "")}
                        classSecend=""
                        first={<span>{(props.qst.details[1].chosed ? "+" : "") + props.qst.details[1].detailanswer}</span>}
                        secend={"  " + props.qst.details[1].detailtext}
                    />
                </div>}
            {state.showDetail && (props.qst.typequestion === qestionsType.qcm || props.qst.typequestion === qestionsType.rank) &&
                <div className="dis-block leftM-40 topM-8 paddTB-3">
                    <InfoTable
                        thead={["Score question", "Description", "Score du candidat"]}
                        tbody={props.qst.details.map((detail, key) => {
                            return [
                                <strong>{detail.detailanswer}</strong>,
                                <span className={detail.chosed ? "accpt_clr" : ""}>{detail.detailtext}</span>,
                                <strong className={detail.chosed ? "accpt_clr" : ""}>{detail.chosed ? "+" + detail.detailanswer : "N'est pas choisie"}</strong>
                            ]
                        })}
                    />
                </div>}
            {state.showDetail && props.qst.typequestion === qestionsType.text &&
                <div className="dis-block leftM-40 topM-8">{props.qst.details[0].answerText}</div>}
        </div>
    )
}