import React from 'react';
import { Translate } from '../../../../utils'
import { BtnClick, InlineElemnt, AvaterDef } from '../../../_Common'
export function ElementTest(props) {
    function handleSubmit() {

    }
    return (
        <div className="bdg-white padding-def rad-5 box-def leRmen-2 letT-b-15 " style={{ flexBasis: "21%" }}>
            <h3 className="margin-null topM-8 sizeF-1em"><span className="clr-grey">Date :</span> {props.dateTest}</h3>
            <hr className="brd-none paddTB-3" style={{ borderBottom: "1px solid #e1dfdd" }} />

            <div className="dis-flex content-btwn topM-15">
                <BtnClick
                    onClick={() => props.handleTest("detail", props.idTest, props.TestStatus)}
                    TxtBtn={Translate('test', 'voirdetail')}
                    Color="bdg-onrg-op color-org select-non"
                />
                {props.TestStatus === "attent" &&
                    <BtnClick
                        onClick={() => props.handleTest("start", props.idTest)}
                        TxtBtn={Translate('test', 'lnctest')}
                        Color="bdg-onrg clr-white select-non"
                    />}
                {props.TestStatus === "start" &&
                    <BtnClick
                        onClick={() => props.handleTest("end", props.idTest)}
                        TxtBtn={Translate('test', 'arettest')}
                        Color="bdg-dng clr-white select-non"
                    />
                }
            </div>
            <div className="topM-8">
                <hr className="brd-none paddTB-3" style={{ borderBottom: "1px solid #e1dfdd" }} />
                <div className="dis-flex topM-15">
                    <InlineElemnt
                        className="font-600 curs-pointer clr-grey"
                        classSecend="sizeF-9em leftM-8"
                        classFirst="rad-100 brd-glb mm-widthg sizeF-7em"
                        secend={<div><span className="font-500 sizeF-11em">{props.createdAt}</span></div>}
                    />
                </div>
            </div>

        </div>
    )
}