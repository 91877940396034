import React from 'react';
import { Translate } from '../../../../../utils'
import { BtnActions, BtnClick, DeleteSvg, EditSvg, InlineElemnt } from '../../../../_Common'
export function ElementTest(props) {
    return (
        <div className="bdg-white padding-def rad-5 box-def leRmen-2 letT-b-15 " style={{ flexBasis: "21%" }}>
            <div className="dis-flex content-btwn item-center" style={{ paddingTop: 6 }}>
                <h3 className="margin-null sizeF-1em"><span className="clr-grey">Date :</span> {props.dateTest}</h3>
                {props.TestStatus === "attent" && <BtnActions btns={[
                    { icon: EditSvg, action: () => props.handleJta("edit", props.idJta) },
                    { icon: DeleteSvg, action: () => props.handleJta("delete", props.idJta) }]} />}
            </div>
            <hr className="brd-none paddTB-3" style={{ borderBottom: "1px solid #e1dfdd" }} />
            <div>
                <div className="stMrgv-tb">
                    <strong className="clr-grey">{Translate('test', 'groupede')}</strong>
                    <span>{props.GroupeName}</span>
                </div>
            </div>
            <div className="dis-flex content-btwn topM-15">
                <div className="dis-flex content-btwn item-center" style={props.statusIterop === "sended" ? { width: "100%" } : {}}>
                    <BtnClick
                        onClick={() => props.handleJta("detail", props.idJta)}
                        TxtBtn={Translate('test', 'voirdetail')}
                        Color="bdg-onrg-op color-org select-non"
                    />
                    {props.statusIterop === "sended" && <strong className="accpt_clr" style={{ width: "140px", fontSize:'14px' }}>Envoyé dans IRIS</strong>}
                </div>

                {props.TestStatus === "attent" &&
                    <BtnClick
                        onClick={() => props.handleJta("start", props.idJta)}
                        TxtBtn={Translate('test', 'lnctest')}
                        Color="bdg-onrg clr-white select-non"
                    />}
                {props.TestStatus === "start" &&
                    <BtnClick
                        onClick={() => props.handleJta("end", props.idJta)}
                        TxtBtn={Translate('test', 'arettest')}
                        Color="bdg-dng clr-white select-non"
                    />
                }
            </div>
            <div className="topM-8">
                <hr className="brd-none paddTB-3" style={{ borderBottom: "1px solid #e1dfdd" }} />
                <div className="dis-flex content-btwn item-center topM-15" style={{ paddingBottom: '10px'}}>
                    <InlineElemnt
                        className="font-600 curs-pointer clr-grey"
                        classSecend="sizeF-9em leftM-8"
                        classFirst="rad-100 brd-glb mm-widthg sizeF-7em"
                        secend={<div><span className="font-500 sizeF-11em">{props.createdAt}</span></div>}
                    />
                    {/* {props.TestStatus === "terminé" &&
                        <BtnClick
                            onClick={() => sendToInscription()}
                            TxtBtn='Envoyer dans IRIS'
                            Color="bdg-dark clr-white select-non"
                        />
                    } */}
                </div>
            </div>

        </div>
    )
}