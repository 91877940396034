import React, { Component } from 'react'
import { connect } from 'react-redux'
import { selfGrilleActions } from '../../../../actions'
import { Translate } from '../../../../utils'
import { InlineElemnt, PopTab, Loader, DocsSvg } from '../../../_Common'
class GrilleDetail extends Component {
    constructor(props) {
        super(props)
        this.props.getDetailGrille(this.props.isActive)
    }
    render() {
        const { getDetailgri, dataDetail } = this.props
        let elemTd = [];
        var labels = [];
        var Qestions = [];
        var title = "Chargement de grille ..."
        if (getDetailgri === "1" && dataDetail && dataDetail.details) {
            if (this.props.currentTab == "candidate" && dataDetail.details.label_auto_test) {
                labels = dataDetail.details.label_auto_test.split(';').map((label, key) => {
                    elemTd.push(<td></td>)
                    return <th key={key}>{label}</th>
                })
            }
            title = dataDetail.details.title_auto_evaluation
            Qestions = dataDetail.details.questions
        }

        return (
            <PopTab isActive={this.props.isActive} TabTitle={<InlineElemnt first={DocsSvg} secend={title} />} close={() => this.props.Close(undefined)} width="90%" height="90%">
                {getDetailgri === "0" && <Loader />}
                <div className="pd-10-15 brd-equ rad-5 box-border height-100">
                    <table className="width-100 setTablw">
                        <thead>
                            <tr className="brd-btm">
                                <th className="" style={{ width: "550px" }}></th>
                                {labels}
                            </tr>
                        </thead>
                        <tbody>
                            {Qestions.map((qst, key) => {
                                return <tr key={key} className="brd-btm">
                                    <td>{qst.textquestionautoeval}<br />{qst.need_justify && < span className="sizeF-9em accpt_clr">Avec justification</span>}</td>
                                    {elemTd}
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </PopTab >
        )
    }
}
function mapState(state) {
    const { getDetailgri, dataDetail } = state.grilleauto
    return { getDetailgri, dataDetail }
}
const acionsClass = {
    getDetailGrille: selfGrilleActions.getDetailGrille
}
const connectedGrilleDetail = connect(mapState, acionsClass)(GrilleDetail);
export { connectedGrilleDetail as GrilleDetail };