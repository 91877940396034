import { groupeConstants } from '../constants';
export function groupe(state, action) {
    let prevData, absence, presence
    switch (action.type) {
        case groupeConstants.GET_CANDIDATE_GROUPE_REQUEST:
            return {
                ...state,
                getingCandNGRP: 0
            }
        case groupeConstants.GET_CANDIDATE_GROUPE_SUCCESS:
            return {
                ...state,
                getingCandNGRP: 1,
                dataCandNGRP: action.payload
            }
        case groupeConstants.GET_CANDIDATE_GROUPE_FAILURE:
            return {
                ...state,
                getingCandNGRP: 2,
                dataCandNGRP: action.payload
            }
        /*********************************** */
        case groupeConstants.GET_GROUPE_INVITATIONS_REQUEST:
            return {
                ...state,
                getingInvts: 0
            }
        case groupeConstants.GET_GROUPE_INVITATIONS_SUCCESS:
            let returnData = {
                dataInvits: [],
                absence: 0,
                presence: 0
            }
            if (action.payload && action.payload.ok) {
                returnData = {
                    dataInvits: action.payload && action.payload.ok ? action.payload.candidates : [],
                    absence: action.payload.absence,
                    presence: action.payload.presence
                }
            }
            return {
                ...state,
                getingInvts: 1,
                ...returnData
            }
        case groupeConstants.GET_GROUPE_INVITATIONS_FAILURE:
            return {
                ...state,
                getingInvts: 2,
                dataInvits: action.payload
            }
        /************************************** */
        case groupeConstants.AUTO_AFFECTATION_CANDIDATE_REQUEST:
            return {
                ...state,
                affectingGrp: 0
            }
        case groupeConstants.AUTO_AFFECTATION_CANDIDATE_SUCCESS:
            return {
                ...state,
                affectingGrp: 1,
                dataAffectation: action.payload
            }
        case groupeConstants.AUTO_AFFECTATION_CANDIDATE_FAILURE:
            return {
                ...state,
                affectingGrp: 2,
                dataAffectation: action.payload
            }
        /************************************** */
        case groupeConstants.CONFIRM_PRESENCE_CANDI_REQUEST:
            return {
                ...state,
                asyncPresence: 0
            }
        case groupeConstants.CONFIRM_PRESENCE_CANDI_SUCCESS:
            prevData = state.dataInvits
            absence = state.absence
            presence = state.presence
            if (action.payload.ok && action.payload.async) {
                let indexInvit = prevData.map(invit => { return invit.invitation_candidate_id }).indexOf(parseInt(action.payload.async))
                if (indexInvit > -1) {
                    prevData[indexInvit].status_invitation = "joined"
                    prevData[indexInvit].is_present = true
                    presence = presence + 1
                    absence = absence - 1
                }
            }
            return {
                ...state,
                asyncPresence: 1,
                dataInvits: prevData,
                absence: absence,
                presence: presence
            }
        case groupeConstants.CONFIRM_PRESENCE_CANDI_FAILURE:
            return {
                ...state,
                asyncPresence: 2,
            }

        /************************************** */
        case groupeConstants.CANCEL_PRESENCE_CANDI_REQUEST:
            return {
                ...state,
                asyncPresence: 0
            }
        case groupeConstants.CANCEL_PRESENCE_CANDI_SUCCESS:
            prevData = state.dataInvits
            absence = state.absence
            presence = state.presence
            if (action.payload.ok && action.payload.async) {
                let indexInvit = prevData.map(invit => { return invit.invitation_candidate_id }).indexOf(parseInt(action.payload.async))
                if (indexInvit > -1) {
                    prevData[indexInvit].status_invitation = "pending"
                    prevData[indexInvit].is_present = false
                    presence = presence - 1
                    absence = absence + 1
                }
            }
            return {
                ...state,
                asyncPresence: 1,
                dataInvits: prevData,
                absence: absence,
                presence: presence
            }
        case groupeConstants.CANCEL_PRESENCE_CANDI_FAILURE:
            return {
                ...state,
                asyncPresence: 2,
            }
        default: return { ...state }
    }
}