import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate } from '../../../../utils'
import { ContainerPages, ButtonCLick, InlineElemnt, PlusSvg } from '../../../_Common'
import PropTypes from "prop-types";
import {
    addUrlProps,
    UrlQueryParamTypes
} from "react-url-query";
import { AddKnowTest } from './AddKnowTest'
const urlPropsQueryConfig = {
    tab: { type: UrlQueryParamTypes.string },
    viewGrile: { type: UrlQueryParamTypes.string }
};
class TestKnowledge extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    static defaultProps = {
        tab: '',
        viewGrile: ''
    }
    static propTypes = {
        tab: PropTypes.string,
        viewGrile: PropTypes.string,
        /********************** */
        onChangeTab: PropTypes.func,
        onChangeViewGrile: PropTypes.func,
    };
    componentDidMount() {
        document.title = Translate('titles', 'app') + " - " + Translate('sidebar', 'testconaise')
    }
    render() {
        return (
            <ContainerPages titlePage={Translate('sidebar', 'testconaise')} contentLeft={<ButtonCLick action={() => this.props.onChangeTab('add')} TextBottun={<InlineElemnt first={PlusSvg} secend={Translate('test', 'testcn')} />} />}>
                {this.props.tab === "add" && <AddKnowTest isActive={this.props.tab === "add"} Close={this.props.onChangeTab} />}
            </ContainerPages>
        )
    }
}
function mapState(state) {
    return {}
}
const actionsClass = {

}
const connectedClass = connect(mapState)(TestKnowledge);
const QueryProps = addUrlProps({ urlPropsQueryConfig })(connectedClass)
export { QueryProps as TestKnowledge };