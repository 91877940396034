import React, { Component } from 'react'
import { InlineElemnt } from './InlineElemnt'
import { DownArrowSvg } from './IconSvg'
class SelectSearch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectSearch: ""
        }
        this.RefList = React.createRef();
        this.RefDrop = React.createRef();
        this.handleClick = this.handleClick.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSelect = this.handleSelect.bind(this)
        this.handleOutside = this.handleOutside.bind(this)
    }
    handleChange(e) {
        const { name, value } = e.target
        if (this.props.value) {
            this.props.handleSelect(null, this.props.paramsFunction)
        }
        let list = this.RefDrop.current
        if (list.getAttribute("toggle") === "down") {
            list.setAttribute("toggle", "up")
        }
        this.setState({
            [name]: value
        })
    }
    handleSelect(option) {
        if (this.props.hideOnChose) {
            this.handleClick()
        }
        this.props.handleSelect(option, this.props.paramsFunction)
    }
    handleClick() {
        let list = this.RefDrop.current
        if (list.getAttribute("toggle") === "down") {
            list.setAttribute("toggle", "up")
            document.addEventListener("mousedown", this.handleOutside)
        } else {
            list.setAttribute("toggle", "down")
            document.removeEventListener("mousedown", this.handleOutside)
        }
    }
    handleOutside(e) {
        if (this.RefList && this.RefList.current && !this.RefList.current.contains(e.target)) {
            this.handleClick()
        }
    }
    render() {
        let showDate = []
        if (Array.isArray(this.props.datalist)) {
            if (this.state.selectSearch.length >= 2) {
                showDate = this.props.datalist.filter(option => option[this.props.field].indexOf(this.state.selectSearch) != -1)
            } else {
                showDate = this.props.datalist.slice(0, 15)
            }
        }
        return (
            <div ref={this.RefList} className={"width-100 pos-relative brd-equ rad-5  " + (this.props.className ? this.props.className : "")}>
                <div className="pd-10-15 brd-btm" onClick={this.handleClick}>
                    <InlineElemnt
                        classFirst="width-100"
                        first={<input autoComplete="off" type="text" className="width-100" name="selectSearch" value={this.props.value ? this.props.value[this.props.field] : this.state.selectSearch} onChange={this.handleChange} placeholder={this.props.placeholder} />}
                        more={<i className="leftM-auto dis-flex">{DownArrowSvg}</i>}
                        className="curs-pointer"
                    />
                </div>
                <div ref={this.RefDrop} className="ToggleCntn OvfAtoY bdg-white box-def brd-equ pos-absolute width-100" toggle="down" style={{ maxHeight: this.props.maxHieght, zIndex: 5 }}>
                    {showDate.map((option, key) => {
                        return <div className="pd-10-15 curs-pointer stMrgv-tb onHvr" key={key} onClick={() => this.handleSelect(option)}>{option[this.props.field]}</div>
                    })}
                </div>
            </div>
        )
    }
}
export { SelectSearch }