import { combineReducers } from 'redux';
import { common } from './common.reducer'
import { alert } from './alert.reducer'
import { auth } from './auth.reducer'
import { tests } from './tests.reducer'
import { evaluator } from './evaluator.reducer'
import { candidate } from './candidate.reducer'
import { admin } from './admin.reducer'
import { tablet } from './tablet.reducer'
import { grilleauto } from './selfGrille.reducer'
import { grilleEvalu } from './evalGrille.reducer'
import { testKnow } from './testKnow.reducer'
import { evaluTest } from './evaluTest.reducer'
import { user } from './user.reducer'
import { groupe } from './groupe.reducer'
import {reporter} from './reporter.reducer'
import {messaging} from './messaging.reducer'
const rootReducer = combineReducers({
  common,
  alert,
  auth,
  tests,
  evaluator,
  candidate,
  admin,
  tablet,
  grilleauto,
  grilleEvalu,
  testKnow,
  evaluTest,
  user,
  groupe,
  reporter,
  messaging
});
export default rootReducer;