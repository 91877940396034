import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Translate} from '../../../../../utils'
import {testActions,candidateActions} from '../../../../../actions'
import {testsConstants} from '../../../../../constants'
import {InputForm,BtnClick,SelectSearch,InlineElemnt,ImageViewer,CloseSvg, Loader} from '../../../../_Common'
class SelectParticipant extends Component{
    constructor(props){
        super(props)
        this.state={
            dataGroupe:props.dataUsers,
            selectedUser:[]
        }
        this.HandleChange=this.HandleChange.bind(this)
        this.handleNext=this.handleNext.bind(this)
        if(props.getingGroupe!=="1"){
            props.getGroupe()
        }
    }
    HandleChange(user){
        this.setState({
            dataGroupe:user
        })
    }
    handleNext(){
        if(this.state.dataGroupe){
            this.props.StoreStep(this.state.dataGroupe,testsConstants.SET_USERS_TEST)
        } 
    }
    render(){
        const {getingGroupe,dataGroupes}=this.props
        let groupes=[]
        if(getingGroupe==="1" && dataGroupes && dataGroupes.groupes){
            groupes=Array.isArray(dataGroupes.groupes.new)?dataGroupes.groupes.new:[]
        }
        return(
            <div className="hg-100-200 leftM-auto righttM-auto" style={{width:"43%"}}>
                {getingGroupe==="0" && <Loader/>}
                <span className="linhgth">{Translate('test','serchuser')}</span>
                <div className="dis-flex topM-8">
                    <SelectSearch field="groupename" maxHieght="100%" placeholder="Rechercher le groupe des candidate ..." hideOnChose={true} value={this.state.dataGroupe} datalist={groupes} handleSelect={this.HandleChange}/>
                </div>
                <div className="dis-flex content-center topM-22">
                    <BtnClick
                        onClick={this.handleNext}
                        TxtBtn={Translate('test','nextstep')}
                        Color="bdg-onrg clr-white leftM-8"
                    />
                </div>
            </div>
        )
    }
}
function mapState(state){
    const {dataUsers}=state.tests
    const {getingGroupe,dataGroupes}=state.candidate
    return {dataUsers,getingGroupe,dataGroupes}
}
const Actions={
    StoreStep:testActions.StoreStep,
    getGroupe:candidateActions.getGroupe
}
const ConnectedClass=connect(mapState,Actions)(SelectParticipant)
export {ConnectedClass as SelectParticipant}