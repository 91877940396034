import { exportsConstants } from '../constants';
import { RequestService, FunctionService } from '../services';
import { toast } from 'react-toastify';
export const exportsActions = {
    CreateExportLink,
    ExportCandidateAnswer,
    ExportEvaluatorAnswer,
    PresenceFiche,
    ExportGrid,
    ExportAffectations,
    ExportFicheAffectations
}
function CreateExportLink(linkData) {
    return RequestService.PostRequest('jta/export/link', linkData, true)
}
function ExportCandidateAnswer(linkData) {
    return RequestService.PostRequest('jta/answer/candidate/export/link', linkData, true)
}
function ExportEvaluatorAnswer(linkData) {
    return RequestService.PostRequest('jta/answer/evaluator/export/link', linkData, true)
}
function PresenceFiche(groupe) {
    const getLink = RequestService.PostRequest('group/fiche-presence/link', groupe, true)
    toast.promise(
        getLink,
        {
            pending: "L'exportation est en cours de traitement",
            success: "Votre fichier est prêt à utiliser",
            error: "Une erreur s'est produite lors de l'exportation"
        }
    )
    getLink
        .then(res => {
            if (res && res.data && res.data.status === "done") {
                window.open(res.data.link)
            }
        })
        .catch(err => {

        })
    return 0
}
function ExportGrid(grid, type) {
    const getLink = RequestService.PostRequest(`grid/test/${type}/link`, grid, true)
    toast.promise(
        getLink,
        {
            pending: "L'exportation est en cours de traitement",
            success: "Votre fichier est prêt à utiliser",
            error: "Une erreur s'est produite lors de l'exportation"
        }
    )
    getLink
        .then(res => {
            if (res && res.data && res.data.status === "done") {
                window.open(res.data.link)
            }
        })
        .catch(err => {

        })
    return 0
}
function ExportAffectations(colomns) {
    const getLink = RequestService.PostRequest(`jta/export/affectations`, colomns, true)
    toast.promise(
        getLink,
        {
            pending: "L'exportation est en cours de traitement",
            success: "Votre fichier sera télécharger dans un moment",
            error: "Une erreur s'est produite lors de l'exportation"
        }
    )
    getLink
        .then(({data:{status,file}={}}={}) => {
            if(status==='done' && file){
                window.open(file)
            }
        })
        .catch(err => {

        })
    return 0
}
function ExportFicheAffectations(evaluators) {
    const getLink = RequestService.PostRequest(`jta/affectation/fiche/export`, evaluators, true)
    toast.promise(
        getLink,
        {
            pending: "L'exportation est en cours de traitement",
            success: "Votre fichier sera télécharger dans un moment",
            error: "Une erreur s'est produite lors de l'exportation"
        }
    )
    getLink
        .then(({data:{status,file}={}}={}) => {
            if(status==='done' && file){
                window.open(file)
            }
        })
        .catch(err => {

        })
    return 0
}