import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import {
    Route,
    Switch,
    Redirect
} from 'react-router-dom';
import Cookies from 'js-cookie'
import { userActions } from '../../actions'
import { NavBar } from './NavBar'
import { Admin } from './AdminsPage'
import { Reporter } from './ReportersPage'
import { ToastContainer } from 'react-toastify';
import { Evaluator } from './EvaluatorPages'
import { Candidate, GroupeCandidate, GalleryPage } from './CandidatePages'
import { Tablet } from './TabletPages'
import { TestGrille, SelfGrille, EvaluationGrille } from './Grilles'
import { EvaluationTest, SelfTest, TestKnowledge } from './Tests'
import { CandidateRapport } from './CandidateRapportPages'
import { AbandonCandidate } from './AbandonCandidate'
import { SettingsPage } from './Settings'
import { AlertPage } from './Alerts'
import { Loader } from '../_Common';
import { Messaging } from './Messaging';
import Echo from "laravel-echo"
// import { SideBar } from './SideBar'
import { SideBarV2 } from './SideBar/SideBarV2';
import { EvaluationTestEnd } from './Tests/EvaluationTest/EvaluationTestEnd';
class IndexApp extends Component {
    constructor(props) {
        super(props)
        if (props.getingUser !== "1") {
            props.GetUser()
        }
        this.logout = this.logout.bind(this)
    }
    componentWillReceiveProps(nextProps) {
        const { getingUser, dataUser } = nextProps
        if (this.props.getingUser === "0" && getingUser === "2" && dataUser) {
            this.logout()
        }else{
            window.Pusher = require('pusher-js');
            window.Echo = new Echo({
                broadcaster: 'pusher',
                key: process.env.REACT_APP_PUSHER_APP_KEY,
                wsHost: process.env.REACT_APP_SOCKET_HOST,
                wsPort: process.env.REACT_APP_SOCKET_PORT,
                forceTLS: process.env.REACT_APP_SSL_SOCKET,
                disableStats: process.env.REACT_APP_STATE_SOCKET,
            });
        }
    }
    logout() {
        Cookies.remove('auth_user')
        window.location.reload()
    }
    render() {
        const { getingUser, dataUser } = this.props
        return (
            <div className="pos-fixed width-100 height-100 top-0 left-0 bdg-default dis-flex">
                {getingUser === "0" ? <Loader /> : getingUser === "1" && dataUser && dataUser.user ? <>
                    <SideBarV2 user={dataUser.user} />
                    {/* <SideBar user={dataUser.user} /> */}
                    <div className="height-100 container-width">
                        <NavBar user={dataUser.user} logout={this.logout} />
                        <div className="content-height">
                            <Switch>
                                <Route path="/admin" component={Admin} />
                                <Route path="/reporters" component={Reporter} />
                                <Route path="/evaluator" component={Evaluator} />
                                <Route path="/candidate/groupe" component={GroupeCandidate} />
                                <Route path="/candidate/pictures" component={GalleryPage} />
                                <Route path="/candidate/abandon" component={AbandonCandidate} />
                                <Route path="/candidate/reportes" component={CandidateRapport} />
                                <Route path="/candidate/chat" component={Messaging} />
                                <Route path="/candidate" component={Candidate} />
                                <Route path="/tablet" component={Tablet} />
                                <Route path="/settings" component={SettingsPage} />
                                <Route path="/grille/self" component={SelfGrille} />
                                <Route path="/grille/evaluation" component={EvaluationGrille} />
                                <Route path="/grille/test" component={TestGrille} />
                                <Route path="/test/self" component={SelfTest} />
                                <Route path="/test/evaluation" component={EvaluationTest} />
                                <Route path="/test/historique" component={EvaluationTestEnd} />
                                <Route path="/test/knowledge" component={TestKnowledge} />
                                <Redirect from="*" to={dataUser.user.role !== "reporter" ? `/candidate` : '/candidate/reportes'} />
                            </Switch>
                        </div>
                        <AlertPage />
                        <ToastContainer 
                            position="bottom-left"
                            theme={'dark'}
                            className={'w-300px'}
                        />
                    </div></> : ""}

            </div>
        )
    }
}
function mapState(state) {
    const { getingUser, dataUser } = state.user
    return { getingUser, dataUser }
}
const actionCreators = {
    GetUser: userActions.GetUser
};

const connectedIndexApp = connect(mapState, actionCreators)(IndexApp);
export { connectedIndexApp as IndexApp };