import React, { Component } from 'react'
import {connect} from 'react-redux'
import {alertActions} from '../../../actions'
import Alert from '@material-ui/lab/Alert';
class AlertPage extends Component{
    constructor(props){
        super(props)
        this.Enterval=null
        this.ClearAlert=this.ClearAlert.bind(this)
    }
    componentWillReceiveProps(nextProps){
        const {alertCode,alertData}=nextProps
        if(alertData && alertCode){
           this.Enterval=setInterval(this.ClearAlert,5000);
        }
    }
    ClearAlert() {
        clearInterval(this.Enterval)
        this.props.SendAlert(null,null)        
    }
    render(){
        const {alertCode,alertData}=this.props
        return(
            alertData ? <div className="pos-fixed " style={{zIndex:"10000",left:"10px",bottom:"10px"}}>
                <Alert variant="filled" severity={alertCode} onClose={this.ClearAlert}>{alertData}</Alert>
            </div>:""
        )
    }
}
function mapState(state) {
    const {alertCode,alertData}=state.alert
    return {alertCode,alertData}
}
const actionCreators = {
   SendAlert:alertActions.SendAlert
};
const connectedAlertPage = connect(mapState,actionCreators)(AlertPage);
export { connectedAlertPage as AlertPage };