import React, { useState } from 'react'
import { Translate, FormatDate } from '../../../utils'
import { PopTab, Loader } from '../../_Common'
import { ElementRapport } from './utils'
import { rapportCandidateActions } from '../../../actions/rapport_candidates.actions'
export function ListRapport(props) {
    const [list, setList] = useState(null)
    const [loading, setLoading] = useState(false)
    if (!list) {
        rapportCandidateActions.fetchRapports(props.activeTab)
            .then(res => {
                if (res && res.data && res.data.ok) {
                    setList(res.data.list)
                } else {
                    setList([])
                }
            })
            .catch(err => {
                setList([])
            })
    }
    const handleDownload = (fileUrl, nameFile) => {
        setLoading(true)
        rapportCandidateActions.downloadRapport(props.activeTab, {
            filePath: fileUrl
        })
            .then(res => {
                if (res && res.data) {
                    try {
                        const blob = new Blob([res.data], { type: "application/pdf" })
                        var fileName = nameFile + ".pdf"
                        var url = window.URL;
                        var link = url.createObjectURL(blob);
                        var element = document.createElement('a');
                        element.setAttribute('href', link);
                        element.setAttribute('download', fileName);
                        element.click();
                    } catch (e) {
                        console.log(e)
                    }
                }
            })
            .catch(err => {

            })
            .finally(e => {
                setLoading(false)
            })
    }
    return (
        <>
            <PopTab isActive={props.candidate} TabTitle={props.candidate && `${Translate('raportCandi', 'listRapport')} de : ${props.candidate.lastname} ${props.candidate.firstname}`} close={() => props.close(undefined)} height="90%" width="85%">
                {(list === null || loading) && <Loader />}
                <div className="topM-15 OvfAtoX" style={{ height: "calc(100% - 10px)" }}>
                    {Array.isArray(list) && list.map((item, key) => {
                        return <ElementRapport key={key} nameRapport={item.name} downloadFile={handleDownload} urlFile={item.pdf} date={FormatDate(item.created_at, true)} />
                    })}
                </div>
            </PopTab>
        </>
    )
}