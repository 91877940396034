import React from 'react'
import {BtnAction,CloseSvg} from '../_Common'
export function PopTab(props){
    return (
        <div className={(props.isActive ? "width-100 height-100" : "size-0D toppr-50 lefpr-50" )+" trans-es pos-fixed top-null left-null index-60 bdgTra-whi HdOvrfl"}>
            {props.isActive && 
                <div className="dis-flex width-100 height-100 item-center content-center">
                    <div className="bdg-white pos-relative pd-10-15 rad-10 box-def"  style={{minWidth:"450px",width:props.width,height:props.height}}>
                        <div className="dis-flex item-center content-btwn brd-btm bottomP-8">
                            <h2 className="margin-null sizeF-12em">{props.TabTitle}</h2>
                            {props.centerTop && <div>{props.centerTop}</div>}
                            {props.close && <div className="" onClick={props.close}>
                                <BtnAction iconBtn={CloseSvg}/>
                            </div>}
                        </div>
                        <div className={(props.classChild?props.classChild:"" )+" width-100 topM-8"} style={{height:"calc(100% - 65px)"}}>
                            {props.children}
                        </div>
                    </div>
                </div>}
        </div>
    )
}