import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate } from '../../../utils'
import { ContainerPages,NavTab } from '../../_Common'
import {MailEditor} from './Emails'
import PropTypes from "prop-types";
import {
    addUrlProps,
    UrlQueryParamTypes
} from "react-url-query";
const urlPropsQueryConfig = {
    emailType: { type: UrlQueryParamTypes.string }
};
class SettingsPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    componentDidMount() {
        document.title = Translate('titles', 'app') + " - " + Translate('setting', 'title')
    }
    static defaultProps = {
        emailType: 'invitation'
    }
    static propTypes = {
        emailType: PropTypes.string,
        /********************** */
        onChangeEmailType: PropTypes.func,
    };
    render(){
        return(
            <ContainerPages
                titlePage={Translate('setting', 'title')} 
                contentTop={<NavTab
                    ElemeList={[
                        { text: <div className="curs-pointer" onClick={() => this.props.onChangeEmailType("invitation")}>{Translate('setting', 'invitation')}</div>, active: this.props.emailType === "invitation" }, 
                        { text: <div className="curs-pointer" onClick={() => this.props.onChangeEmailType("confirmation")}>{Translate('setting', 'confirmation')}</div>, active: this.props.emailType === "confirmation" },
                        { text: <div className="curs-pointer" onClick={() => this.props.onChangeEmailType("picture")}>{Translate('setting', 'picture')}</div>, active: this.props.emailType === "picture" },
                        { text: <div className="curs-pointer" onClick={() => this.props.onChangeEmailType("relanceenat")}>{Translate('setting', 'relanceenat')}</div>, active: this.props.emailType === "relanceenat" },
                        { text: <div className="curs-pointer" onClick={() => this.props.onChangeEmailType("relanceinc")}>{Translate('setting', 'relanceinc')}</div>, active: this.props.emailType === "relanceinc" }
                    ]}
                />}
                StyleChildren={{ height: "calc(100% - 77px)" }}
            >
                <div className="bdg-blue pd-14-20 pos-relative">
                    <MailEditor typeMail={this.props.emailType}/>
                </div>
            </ContainerPages>
        )
    }
}
function mapState(state) {
    return {  }
}
const Actions = {
}
const connectedClass = connect(mapState, Actions)(SettingsPage);
const QueryProps = addUrlProps({ urlPropsQueryConfig })(connectedClass)
export { QueryProps as SettingsPage };