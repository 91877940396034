import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate } from '../../../../../utils'
import { testActions, evalGrilleActions, candidateActions } from '../../../../../actions'
import { testsConstants } from '../../../../../constants'
import { InputForm, BtnSubmit, SelectSearch, Loader ,BtnClick} from '../../../../_Common'
class TestKnow extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
        props.getEvaGrilleCan()
        props.getGroupe()
    }
    HandleChange = (groupe) => {
        this.props.StoreStep({ dataGroupe: groupe, dataGrille: this.props.dataGrille, dataTime: groupe && groupe.date_test_groupe.split(' ')[0] }, testsConstants.SET_GRILE_TEST)
    }
    HandleChangeGride = (grile) => {
        this.props.StoreStep({ dataGroupe: this.props.dataGroupe, dataGrille: grile, dataTime: this.props.dataTime }, testsConstants.SET_GRILE_TEST)
    }
    HandleChangeTime = (e) => {
        const { value } = e.target
        this.props.StoreStep({ dataGroupe: this.props.dataGroupe, dataGrille: this.props.dataGrille, dataTime: value }, testsConstants.SET_GRILE_TEST)
    }
    handleNext = (e) => {
        e.preventDefault()
        // if (this.props.dataGrille && this.props.dataGroupe) {
        if (this.props.dataGroupe) {
            this.props.StoreStep(1, testsConstants.GO_TO_STEP)
        }
    }
    render() {
        const { getEvalGrilleCand, dataEvalGrilleCand, getingGroupe, dataGroupes } = this.props
        let grilles = []
        if (getEvalGrilleCand === "1" && dataEvalGrilleCand && Array.isArray(dataEvalGrilleCand.grids)) {
            grilles = dataEvalGrilleCand.grids
        }
        let groupes = []
        if (getingGroupe === "1" && dataGroupes && dataGroupes.groupes && Array.isArray(dataGroupes.groupes.new)) {
            groupes = dataGroupes.groupes.new
        }
        return (
            <div className="width-46per leftM-auto righttM-auto">
                {(getEvalGrilleCand === "0" || getingGroupe === "0") && <Loader />}
                <form onSubmit={this.handleNext}>
                    <input type="hidden" />
                    <h3 className="linhgth" style={{ marginTop: "0" }}>{Translate('test', 'groupeTest')}</h3>
                    <div className="dis-flex">
                        <SelectSearch field="groupename" placeholder="Choisir le groupe de test ..." hideOnChose={true} value={this.props.dataGroupe} datalist={groupes} maxHieght="250px" handleSelect={this.HandleChange} />
                    </div>
                    <h3 className="linhgth">{Translate('test', 'selectGrile')}</h3>
                    <div className="dis-flex">
                        <SelectSearch field="titlegrid" placeholder="Choisir la grille de test ..." hideOnChose={true} value={this.props.dataGrille} datalist={grilles} maxHieght="250px" handleSelect={this.HandleChangeGride} />
                    </div>
                    {this.props.dataTime &&
                        <div className="topM-8">
                            <h3 className="linhgth" style={{ marginTop: "0" }}>{Translate('test', 'slDtTim')}</h3>
                            <InputForm
                                className="rightM-8 topM-8"
                                name="testDate"
                                type="date"
                                onChange={this.HandleChangeTime}
                                value={this.props.dataTime}
                            />
                        </div>}
                    <div className="dis-flex content-center topM-22">
                        <BtnClick
                            onClick={this.props.AddJta}
                            TxtBtn="Sauvegarder la JTA"
                            Color="bdg_valid clr-white leftM-8"
                        />
                        <BtnSubmit
                            TxtBtn={Translate('test', 'nextstep')}
                            Color="bdg-onrg clr-white leftM-8"
                        />
                    </div>
                </form>
            </div>
        )
    }
}
function mapState(state) {
    const { dataTime, dataGroupe, dataGrille } = state.tests
    const { getEvalGrilleCand, dataEvalGrilleCand } = state.grilleEvalu
    const { getingGroupe, dataGroupes } = state.candidate
    return { dataTime, getingGroupe, dataGroupes, dataGroupe, dataGrille, getEvalGrilleCand, dataEvalGrilleCand }
}
const Actions = {
    StoreStep: testActions.StoreStep,
    getEvaGrilleCan: evalGrilleActions.getEvaGrilleCan,
    getGroupe: candidateActions.getGroupe
}
const ConnectedClass = connect(mapState, Actions)(TestKnow)
export { ConnectedClass as TestKnow }