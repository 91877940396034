import { evaluatorConstants } from '../constants';
import { RequestService,FunctionService  } from '../services';
export const evaluatorActions = {
    AddEvaluator,
    getEvaluator,
    UpdateEvaluator,
    deleteEvalu
};
function AddEvaluator(evaluator){
    return dispatch => {
        dispatch(FunctionService.request(evaluatorConstants.ADD_EVALUATOR_REQUEST))
        RequestService.PostRequest('evaluator/create',evaluator,true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,evaluatorConstants.ADD_EVALUATOR_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,evaluatorConstants.ADD_EVALUATOR_FAILURE))
        })
    }
}
function UpdateEvaluator(evaluator){
    return dispatch => {
        dispatch(FunctionService.request(evaluatorConstants.UPDATE_EVALUATOR_REQUEST))
        RequestService.PostRequest('evaluator/update',evaluator,true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,evaluatorConstants.UPDATE_EVALUATOR_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,evaluatorConstants.UPDATE_EVALUATOR_FAILURE))
        })
    }
}
function getEvaluator(){
    return dispatch => {
        dispatch(FunctionService.request(evaluatorConstants.GET_EVALUATOR_REQUEST))
        RequestService.GetRequest('evaluator/all',true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,evaluatorConstants.GET_EVALUATOR_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,evaluatorConstants.GET_EVALUATOR_FAILURE))
        })
    }
}
function deleteEvalu(evalu){
    return dispatch => {
        dispatch(FunctionService.request(evaluatorConstants.DELETE_EVALUATOR_REQUEST))
        RequestService.PostRequest('user/delete',evalu,true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,evaluatorConstants.DELETE_EVALUATOR_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,evaluatorConstants.DELETE_EVALUATOR_FAILURE))
        })
    }
}