import React, { Component } from 'react'
import {NavTab} from '../../../../_Common'
import {QstEvaluView} from './QstEvaluView'
class ViewerEvaluation extends Component{
    constructor(props){
        super(props)
        this.state={
            currentSection:0
        }
        this.handleSection=this.handleSection.bind(this)
    }
    handleSection(index){
        this.setState({
            currentSection:index
        })
    }
    render(){
        const {dataSection}=this.props

        return(
            <div>
                <NavTab 
                    ElemeList={dataSection.map((section,key)=>{
                        return {text:<div className="curs-pointer" onClick={() => this.handleSection(key)}>{section.section_name}</div>,active:this.state.currentSection===key}
                    })}
                />
                <div className="padding-def">
                    {dataSection[this.state.currentSection] && Array.isArray(dataSection[this.state.currentSection].groupes) &&dataSection[this.state.currentSection].groupes.map((groupe,key2)=>{
                        let qestion=Array.isArray(groupe.questions)?groupe.questions:[]
                        return <div>
                            <h3 className="sTmr-si">{groupe.groupe_name}</h3>
                            <div className="leftM-8">{qestion.map((qst,key)=>{
                                return <QstEvaluView qst={qst} index={key+1}/>
                            })}</div>
                            <hr/>
                        </div>
                    })} 
                </div>
            </div>
        )
    }
}
export {ViewerEvaluation}