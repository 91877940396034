export const selfgrilleConstants = {
    ADD_SELF_REQUEST: "ADD_SELF_GRILLE_REQUEST",
    ADD_SELF_SUCCESS: "ADD_SELF_GRILLE_SUCCESS",
    ADD_SELF_FAILURE: "ADD_SELF_GRILLE_FAILURE",

    DELETE_SELF_REQUEST: "DELETE_SELF_GRILLE_REQUEST",
    DELETE_SELF_SUCCESS: "DELETE_SELF_GRILLE_SUCCESS",
    DELETE_SELF_FAILURE: "DELETE_SELF_GRILLE_FAILURE",

    GET_SELF_GRILLE_REQUEST: "GET_SELF_GRILLE_REQUEST",
    GET_SELF_GRILLE_SUCCESS: "GET_SELF_GRILLE_SUCCESS",
    GET_SELF_GRILLE_FAILURE: "GET_SELF_GRILLE_FAILURE",

    GET_SELF_DETAIL_REQUEST: "GET_SELF_DETAIL_GRILLE_REQUEST",
    GET_SELF_DETAIL_SUCCESS: "GET_SELF_DETAIL_GRILLE_SUCCESS",
    GET_SELF_DETAIL_FAILURE: "GET_SELF_DETAIL_GRILLE_FAILURE"
}