import React , {Component} from 'react'
import {connect} from 'react-redux'
import {evalGrilleActions,exportsActions} from '../../../../actions'
import {Qestionview} from '../utils'
import {InlineElemnt,PopTab,NavTab, Loader,BtnClick} from '../../../_Common'
class GrilleEvalViewer extends Component{
    constructor(props){
        super(props)
        this.state={
            activesection:0
        }
        this.props.getDetailGrille(this.props.isActive)
    }
    handleSection(index){
        this.setState({
            activesection:index
        })
    }
    render(){
        const {getDetailgri,dataDetail}=this.props
        let title="Chargement de grille ..."
        let sections=[]
        let idGrid=null
        if(getDetailgri==="1" && dataDetail && dataDetail.grid){
            title=dataDetail.grid.titlegrid
            idGrid=dataDetail.grid.evaluation_grid_id
            sections=Array.isArray(dataDetail.grid.sections)?dataDetail.grid.sections:[]
        }
        return(
                <PopTab isActive={this.props.isActive} TabTitle={<InlineElemnt first={this.props.iconGrille} secend={title}/>} close={() => this.props.Close(undefined)} width="90%" height="90%">
                    {getDetailgri==="0" && <Loader/>}
                    <NavTab 
                        ElemeList={sections.map((section,key)=>{
                            return {text:<div className="curs-pointer" onClick={() => this.handleSection(key)}>{section.section_name}</div>,active:this.state.activesection===key}
                        })}
                    />
                    <div className="pd-10-15 brd-equ rad-5 box-border OvfAtoY" style={{height:"calc(100% - 30px)"}}>
                        {sections[this.state.activesection] && Array.isArray(sections[this.state.activesection].groupes) && sections[this.state.activesection].groupes.map((groupe,key2)=>{
                            let qestion=Array.isArray(groupe.questions)?groupe.questions:[]
                            return <div>
                                <h3 className="sTmr-si">{groupe.groupe_name}</h3>
                                <div className="leftM-8">{qestion.map((qst,key)=>{
                                    return <Qestionview key={key} qst={qst} index={key+1} aspoint={true}/>
                                })}</div>
                                <hr/>
                            </div>
                        })} 
                    </div>
                    <div className="pos-absolute bdg-white dis-flex width-mxcn leftM-auto" style={{zIndex:2,paddingTop:5,bottom:8,right:10}}>
                        <BtnClick onClick={()=>exportsActions.ExportGrid({grid:idGrid,type:'Answer'},'evaluator')} TxtBtn="Exporter la grille" Color="bgd-pdf clr-white select-non leftM-8" />
                    </div>
                </PopTab>
        )
    }
}
function mapState(state) {
    const {getDetailgri,dataDetail}=state.grilleEvalu
    return {getDetailgri,dataDetail}
}
const actionsClass={
    getDetailGrille:evalGrilleActions.getDetailGrille
}
const connectedGrilleViewer = connect(mapState,actionsClass)(GrilleEvalViewer);
export { connectedGrilleViewer as GrilleEvalViewer };