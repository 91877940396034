import { selfgrilleConstants } from '../constants';
import { RequestService, FunctionService } from '../services';
export const selfGrilleActions = {
    addSelfGrille,
    getSelfGrille,
    getDetailGrille,
    DeleteSelfGrille
};
function addSelfGrille(selfGrille) {
    return dispatch => {
        dispatch(FunctionService.request(selfgrilleConstants.ADD_SELF_REQUEST))
        RequestService.PostRequest('gridAuto/create', selfGrille, true)
            .then(res => {
                dispatch(FunctionService.succes(res.data, selfgrilleConstants.ADD_SELF_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err.response, selfgrilleConstants.ADD_SELF_FAILURE))
            })
    }
}
function DeleteSelfGrille(selfGrille) {
    return dispatch => {
        dispatch(FunctionService.request(selfgrilleConstants.DELETE_SELF_REQUEST))
        RequestService.PostRequest('gridAuto/delete', selfGrille, true)
            .then(res => {
                dispatch(FunctionService.succes(res.data, selfgrilleConstants.DELETE_SELF_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err.response, selfgrilleConstants.DELETE_SELF_FAILURE))
            })
    }
}
function getSelfGrille() {
    return dispatch => {
        dispatch(FunctionService.request(selfgrilleConstants.GET_SELF_GRILLE_REQUEST))
        RequestService.GetRequest('gridAuto/all', true)
            .then(res => {
                dispatch(FunctionService.succes(res.data, selfgrilleConstants.GET_SELF_GRILLE_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err.response, selfgrilleConstants.GET_SELF_GRILLE_FAILURE))
            })
    }
}
function getDetailGrille(idGrile) {
    return dispatch => {
        dispatch(FunctionService.request(selfgrilleConstants.GET_SELF_DETAIL_REQUEST))
        RequestService.GetRequest('gridAuto/grid/' + idGrile, true)
            .then(res => {
                dispatch(FunctionService.succes(res.data, selfgrilleConstants.GET_SELF_DETAIL_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err.response, selfgrilleConstants.GET_SELF_DETAIL_FAILURE))
            })
    }
}