import React, { Component } from 'react'
import {connect} from 'react-redux'
import {Translate} from '../../../../../utils'
import {evaluTestActions} from '../../../../../actions'
import {PopTab,InfoTable,BtnClick, Loader} from '../../../../_Common'
class SendIteropData extends Component{
    constructor(props){
        super(props)
        const {getTestDetail,dataTestDetail}=props
        let state={
            jta_id:null,
            candidates:[],
            noConfirmer:[],
            sections:[],
            disabledSend: true,
        }
        this.candidateSend=[]
        if(getTestDetail==="1" && dataTestDetail){
            if(dataTestDetail.detailJta){
                let jtaInfo=dataTestDetail.detailJta
                let countIndex=0
                state.jta_id=jtaInfo.jta_evaluation_id
                let checkJtaComment = true
                if (jtaInfo && jtaInfo.evaluators_tablettes && jtaInfo.evaluators_tablettes) {
                    jtaInfo.evaluators_tablettes.forEach((tab) => (
                        tab.candidates.forEach((el) => {
                            if (el.comment_jta) {
                                checkJtaComment = checkJtaComment & true
                            } else {
                                checkJtaComment = checkJtaComment & false
                            }
                        })                    
                    ))
                }
                state.disabledSend = checkJtaComment === 0 ? true: false 
                state.sections=Array.isArray(dataTestDetail.sections)?dataTestDetail.sections:[]
                const aa = dataTestDetail.candidateNo && dataTestDetail.candidateNo[0] && Array.isArray(dataTestDetail.candidateNo[0].invit_candidate)?dataTestDetail.candidateNo[0].invit_candidate:[]
                const bb = dataTestDetail.candidateNo && Array.isArray(dataTestDetail.candidateNo.invit_candidate)? dataTestDetail.candidateNo.invit_candidate : []

                state.noConfirmer = aa.length ? aa : bb.length ? bb : []
                Array.isArray(jtaInfo.evaluators_tablettes) && jtaInfo.evaluators_tablettes.map((evaluTabl,key)=>{
                    Array.isArray(evaluTabl.candidates) && evaluTabl.candidates.map((candidat,key2)=>{
                        let testInfo={}
                        if(candidat.noteTest){
                            testInfo=candidat.noteTest
                        }
                        let moyn=0,some=0
                        this.candidateSend.push({
                            candidate_id:candidat.candidate_id,
                            jta_comment:candidat.comment_jta?candidat.comment_jta:"",
                            raison_abandon:"",
                            candidat:candidat,
                            apretion:candidat.apretion?candidat.apretion:""
                        })
                        state.candidates.push([candidat.candidate.lindustreet_id, candidat.candidate.iditerop,
                            !candidat.noteTest? "" : "",
                            !candidat.noteTest ?
                             <textarea name="raison_abandon" data-index={countIndex} onChange={this.onChangeTexts.bind(this)} cols="10" rows="2"
                             style={{width:"240px"}} placeholder="Raison abandon">{testInfo.absent_raison}</textarea>
                            :"",
                            ...state.sections.map(section=>{
                            some+=1
                            let score=candidat.scores.find(sec=>sec.section_id === section.grid_section_id)
                            if(score){
                                moyn+=score.score_seq
                                return score.score_seq+" points"
                            }else{
                                return "Pas de note"
                            }
                        }),some>0?(moyn/some).toFixed(2) :0,testInfo.note? testInfo.note+" / "+testInfo.nb_qestions:"",
                    //    <strong className="space-preline dis-block" style={{width:"240px"}}>{candidat.comment_jta}</strong>,
                       <textarea name="jta_comment" data-index={countIndex} onChange={this.onChangeTexts.bind(this)} cols="10" rows="2" style={{width:"240px"}} placeholder="Commentaire JTA">{candidat.comment_jta}</textarea>,
                       <textarea name="apretion" data-index={countIndex} onChange={this.onChangeTexts.bind(this)} cols="10" rows="2" style={{width:"240px"}} placeholder="Appréciation globale">{candidat.apretion!=0?candidat.apretion:""}</textarea>])
                        countIndex+=1
                    })
                })
            }
        }else{
            this.props.close()
        }
        this.state=state
        this.submitIterop=this.submitIterop.bind(this) 
    }
    componentWillReceiveProps(nextProps){
        const {submitingData,dataIterop,dataTestDetail}=nextProps
        if(this.props.submitingData==="0" && submitingData==="1" && dataIterop && Array.isArray(dataIterop.dataIteropexcel)){
            this.props.detailJta(dataTestDetail.detailJta.jta_evaluation_id)
            this.props.close("export")
            this.props.sendToInscription()
        }
    }
    submitIterop(){
        let dataPost={
            jta_id:this.state.jta_id,
            dataCandidate:this.candidateSend
        }
        this.props.PostDataIterop(dataPost)
    }
    onChangeTexts(e){
        const {value,name,dataset:{index}}=e.target 
        if(this.candidateSend[parseInt(index)]){
            this.candidateSend[parseInt(index)][name]=value
        }
        let check = true
        this.candidateSend.forEach((el) => {
            if (el.jta_comment) {
                check = check & true
            } else {
                check = check & false
            }
        })
        this.setState({
            ...this.state,
            disabledSend: check === 0 ? true: false
        })
    }
    render(){
        const {submitingData}=this.props
        return (
            <PopTab isActive={this.props.showPop} TabTitle="Envoyer résultat de JTA vers IRIS" close={this.props.close} width="90%" height="90%">
                {submitingData==="0" && <Loader/>}
                <div className="OvfAtoY box-border sTrgPdn" style={{height:"calc(100% - 27px)"}}>
                    <InfoTable
                        classSticky="fix_elem_top"
                        styleTop={{height:"calc(100% - 10px)"}}
                        thead={["ID lindustreet", "Id iterop / Candidate","STATUT","Raison abandon",...this.state.sections.map((section=>{
                            return "Score "+section.section_name
                        })),"Moyenne score Séquences",Translate('test','testcn'),Translate('test','cmntJta'),Translate('test','aprecGlobal')]}
                        tbody={this.state.candidates}
                    />
                    {this.state.noConfirmer.length>0 && <>
                        <h3 className="txt-dng">Candidate non confirmé</h3>
                        <InfoTable
                            thead={['ID lindustreet', 'Id iterop',"STATUT","Raison abandon",Translate('fields','firstname'),Translate('fields','lastname')]}
                            tbody={this.state.noConfirmer.map((candi,key)=>{
                                return [candi.candidate.lindustreet_id, candi.candidate.iditerop,"",'Présence non confirmé',candi.user.firstname,candi.user.lastname]
                            })}
                        />
                    </>}
                </div>
                <div className="dis-flex content-end">
                    {this.state.disabledSend ?
                        <BtnClick onClick={this.submitIterop}
                        title="Tous les commentaires JTA doivent être renseignés pour permettre l'envoi dans IRIS. Ne concerne que les candidats présents."
                        disabled={true} TxtBtn="Confirmer et Envoyer dans IRIS" Color="bdg_valid clr-white select-non"/>
                    :
                     <BtnClick onClick={this.submitIterop} TxtBtn="Confirmer et Envoyer dans IRIS" Color="bdg_valid clr-white select-non"/>
                    }
                </div>
            </PopTab>
        )
    }
}
function mapState(state) {
    const {getTestDetail,dataTestDetail,submitingData,dataIterop}=state.evaluTest
    return {getTestDetail,dataTestDetail,submitingData,dataIterop}
}
const actionsClass={
    PostDataIterop:evaluTestActions.PostDataIterop,
    detailJta:evaluTestActions.detailJta
}
const connectedClass = connect(mapState,actionsClass)(SendIteropData);
export { connectedClass as SendIteropData };