import React, { Component } from 'react'
import {Translate,FormatDate} from '../../../../utils'
import {InlineElemnt,DownArrowSvg} from '../../../_Common'
import {ElementTest} from './ElementTest'
class ElementLabel extends Component{
    constructor(props){
        super(props)
        this.state={
            viewLabel:true
        }
        this.handleToggle=this.handleToggle.bind(this)
    }
    handleToggle(){
        let currentState=this.state.viewLabel
        this.setState({
            viewLabel:!currentState
        })
    }
    render(){
        return(
            <div className={this.props.className}>
                <div className={"dis-flex sm-padding curs-pointer "+this.props.bdg_st} onClick={this.handleToggle}>
                    <InlineElemnt
                        className="font-600 curs-pointer sm-padding width-mxcn rad-5"
                        classSecend="sizeF-9em leftM-5"
                        first={<span className="dis-flex hasRotat" data-rotate={this.state.viewLabel?'up':'down'}>{DownArrowSvg}</span>}
                        secend={this.props.textLabel}
                    />
                </div>
                <div className="dis-flex flx-warp width-100 ToggleCntn OvfAtoY" toggle={this.state.viewLabel?'up':'down'}>
                    {Array.isArray(this.props.testList) ? this.props.testList.map((Test,key)=>{
                        return <ElementTest
                                    key={key}
                                    TestStatus={this.props.TestStatus}
                                    handleTest={this.props.updateStatus}
                                    GroupeName={Test.GroupeName}
                                    idTest={Test.test_auto_id}
                                    createdAt={FormatDate(Test.created_at,true)}
                                    dateTest={FormatDate(Test.dateautotest)}
                                />
                    }) :''}
                </div>
            </div>
        )
    }
}
export {ElementLabel}