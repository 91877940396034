import React, { Component } from 'react'
import { Translate } from '../../../utils'
import { TableRows, BtnClick } from '../../_Common'
class EditGroupe extends Component {
    render() {
        return (
            <>
                <TableRows
                    classSticky="fix_elem_top"
                    styleTop={{ height: "calc(100% - 32px)" }}
                    columns={[
                        { name: Translate('fields', 'lastname'), rowKey: "lastname" },
                        { name: Translate('fields', 'firstname'), rowKey: "firstname" },
                        { name: Translate('fields', 'phone'), rowKey: "phone" },
                        { name: Translate('fields', 'email'), rowKey: "email" },
                        { name: Translate('fields', 'username'), rowKey: "usename" },
                        { name: Translate('fields', 'initpassw'), rowKey: "initpassword", render: initpassword => initpassword ? initpassword : <strong>Fait lui connexion</strong> },
                        { name: Translate('candidate', 'civility'), rowKey: "civility" },
                        { name: Translate('candidate', 'city'), rowKey: "city" },
                        { name: Translate('candidate', 'age'), rowKey: "age" },
                        { name: Translate('candidate', 'codepostale'), rowKey: "codepostale" },
                        { name: Translate('candidate', 'fitgeneral'), rowKey: "fitgenerale" }
                    ]}
                    data={this.props.dataGroupe}
                    rowSelect={{
                        onChange: this.props.onChange
                    }}
                    key_table="candidate_id"
                />
                <div className="dis-flex">
                    <BtnClick
                        onClick={this.props.handleEdit}
                        Color="leftM-auto width-100 bdg-onrg clr-white"
                        style={{ maxWidth: "200px" }}
                        TxtBtn="Confirmer et inviter"
                    />
                </div>
            </>
        )
    }
}
export { EditGroupe }