import React from 'react'
import {Translate} from '../../../utils'
import {exportsActions} from '../../../actions/exports.actions'
import {PopTab,TableRowsG,Loader,BtnClick,InlineElemnt,BtnActions,SortExitSvg} from '../../_Common'
export function DetailGroupeG(props) {
    const {loading,dataGroupe,showData}=props
    var showGroupe=false
    let idGroupe=null
    let titre="Chargement ..."
    let imageNbr=0,confirmNbr=0,total=0,Incertain=0,refused=0,pending=0, imageNbryes=0
    if(showData&&dataGroupe&&dataGroupe.groupes) {
        showGroupe=(dataGroupe.groupes.groupe_status==="new")
        idGroupe=dataGroupe.groupes.groupe_candidate_id
        let linkMeet=dataGroupe.groupes.meet_link
        imageNbr=dataGroupe.droitImage
        imageNbryes=dataGroupe.yesdroitImage
        confirmNbr=dataGroupe.stateConf.joined
        total=dataGroupe.stateConf.joined+dataGroupe.stateConf.pending+dataGroupe.stateConf.unknown+dataGroupe.stateConf.refused
        Incertain=dataGroupe.stateConf.unknown
        pending=dataGroupe.stateConf.pending
        refused=dataGroupe.stateConf.refused
        titre=<div>
            <span>{dataGroupe.groupes.groupename}</span>
            {linkMeet&&<a target="_blank" rel='noreferrer' style={{padding: "0 10px"}} className="font-500 color-org" href={linkMeet}>Lien vers le meet</a>}
        </div>
    }
    const getStatusColor=(statusInv) => {
        switch(statusInv) {
            case 'joined':
                return 'accpt_clr'
            case 'unknown':
                return 'color-org'
            case 'refused':
                return 'txt-dng'
            default:
                return 'pnding_clr'
        }
    }
    return (
        <PopTab isActive={true} TabTitle={titre} close={() => props.close(undefined)} width="95%" height="90%">
            {props.loaderAction&&<Loader />}
            {loading&&<Loader />}
            <div className="OvfAtoY" style={{height: "calc(100% - 60px)"}}>
                <TableRowsG
                    classSticky="fix_elem_top"
                    styleTop={{height: "calc(100% - 20px)"}}
                    columns={[
                        {name: Translate('fields','lastname'),srt: 'lastname',rowKey: "candidate",render: candidate => candidate.user.lastname,sorter: true},
                        {name: Translate('fields','firstname'),srt: 'firstname',rowKey: "candidate",render: candidate => candidate.user.firstname,sorter: true},
                        {name: Translate('fields','phone'),rowKey: "candidate",render: candidate => candidate.user.phone,sorter: false},
                        {name: Translate('fields','email'),rowKey: "candidate",render: candidate => candidate.user.email,sorter: false},
                        {name: Translate('fields','username'),rowKey: "candidate",render: candidate => candidate.user.usename,sorter: false},
                        {name: Translate('fields','initpassw'),rowKey: "candidate",render: candidate => candidate.user.initpassword? candidate.user.initpassword:<strong>Fait lui connexion</strong>,sorter: false},
                        {name: Translate('candidate','civility'),rowKey: "candidate",render: candidate => candidate.civility,sorter: false},
                        {name: Translate('candidate','city'),rowKey: "candidate",render: candidate => candidate.city,sorter: false},
                        {name: Translate('candidate','age'),rowKey: "candidate",render: candidate => candidate.age,sorter: false},
                        {name: Translate('candidate','codepostale'),rowKey: "candidate",render: candidate => candidate.codepostale,sorter: false},
                        {name: Translate('candidate','fitgeneral'),rowKey: "candidate",render: candidate => candidate.fitgenerale,sorter: false},
                        {name: "Droit d'image",rowKey: "candidate",srt: 'image',sorter: true,render: candidate => candidate.accept_condition? <strong className="accpt_clr">Accepté</strong>:<strong className="txt-dng">Non accepté</strong>},
                        {
                            name: Translate('candidate','statusInvit'),srt: 'statusInvit',sorter: true,rowKey: "status_invitation",rowParams: 'invitation_candidate_id',render: (status_invitation,invitation_candidate_id,item) =>
                                <InlineElemnt
                                    first={<strong className={getStatusColor(status_invitation)}>{Translate('status',status_invitation)}</strong>}
                                    secend={!item.deleted_at&&<BtnActions btns={[
                                        {icon: SortExitSvg,action: () => props.removeMember(invitation_candidate_id)}
                                    ]} />}
                                />
                        }
                    ]}
                    data={dataGroupe&&dataGroupe.groupes.invitations}
                    key_table="candidate_id"
                />
            </div>
            <div className="">
                <div className="sizeF-9em right">
                    <div className="dis-inline-block rightM-15"><strong className="color-org">Nbr Accepté les droits : </strong><span> {imageNbr}</span></div>
                    <div className="dis-inline-block rightM-15"><strong className="color-org">Nbr Incertains : </strong><span> {Incertain}</span></div>
                    <div className="dis-inline-block rightM-15"><strong className="color-org">Nbr refus : </strong><span> {refused}</span></div>
                    <div className="dis-inline-block rightM-15"><strong className="color-org">Nbr En attentes : </strong><span> {pending}</span></div>
                    <div className="dis-inline-block rightM-15"><strong className="color-org">Nbr confirmations : </strong><span> {confirmNbr}</span></div>
                    <div className="dis-inline-block"><u><strong className="color-org">Total : </strong><span> {total}</span></u></div>
                </div>
                <div className="dis-flex topM-15">
                    <BtnClick
                        onClick={() => exportsActions.PresenceFiche({groupe: idGroupe})}
                        Color="bgd-pdf clr-white"
                        style={{}}
                        TxtBtn="Feuille d’émargement"
                    />
                    {showGroupe&&<>
                        <BtnClick
                            onClick={props.relancePicture}
                            disabled={imageNbryes>0 && confirmNbr<=0 }
                            Color="bdg-onrg-op color-org leftM-8"
                            style={{}}
                            title='relance pour non acc et conf'
                            TxtBtn="Relance photo"
                        />

                        <BtnClick
                            onClick={props.startMail}
                            Color="bdg-onrg-op color-org leftM-8"
                            style={{}}
                            disabled={pending<=0}
                            title='relance pour en attente'
                            TxtBtn="Relance attent"
                            />

                        <BtnClick
                            onClick={props.startMailIncertain}
                            Color="bdg-onrg-op color-org leftM-8"
                            style={{}}
                            disabled={Incertain<=0}
                            title='relance pour incertain'
                            TxtBtn="Relance incertain"
                        />
                        <BtnClick
                            onClick={props.addMembers}
                            Color="bdg-onrg clr-white leftM-8"
                            style={{}}
                            TxtBtn="Ajouter des candidats"
                        />
                    </>}
                </div>
            </div>
        </PopTab>
    )
}