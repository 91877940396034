import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate, FormatDate } from '../../../../utils'
import { testActions } from '../../../../actions'
import { InlineElemnt, PopTab, BtnClick, InfoTable, Loader, CheckClrSvg, MissingSvg } from '../../../_Common'
class DetailTest extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showDetail: false
        }
        props.getSelftTest(props.isActive)
        this.showResponse = this.showResponse.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }
    showResponse(idtest, idCandidate, user) {
        this.state.showDetail = user
        this.props.detailResponse(idtest, idCandidate)
    }
    handleClose() {
        this.setState({
            showDetail: null
        })
    }
    render() {
        const { getingDetail, dataDetailselft, getingAnswers, dataAnswers } = this.props
        let title = "Chargement du test ..."
        let list = []
        let labels = []
        let qestions = []
        let titleresponse = "Chargement de reponse ..."
        let titleGrille = ""
        let noAnswer = true
        if (getingDetail === "1" && dataDetailselft && dataDetailselft.test) {
            if (dataDetailselft.test.group_test && dataDetailselft.test.group_test[0] && dataDetailselft.test.group_test[0].groupe) {
                title = "Evaluation pour: " + dataDetailselft.test.group_test[0].groupe.groupename + " Le: " + FormatDate(dataDetailselft.test.dateautotest)
                list = Array.isArray(dataDetailselft.test.group_test[0].users) ? dataDetailselft.test.group_test[0].users.map(user => {
                    return [user.lastname, user.firstname, this.props.showDetail ? <BtnClick onClick={() => this.showResponse(dataDetailselft.test.test_auto_id, user.id, user)}
                        TxtBtn="Voir la réponse"
                        Color="bdg-onrg-op color-org select-non" /> : "En attent"]
                }) : []
            }
        }
        if (this.state.showDetail && getingAnswers === "1" && dataAnswers && dataAnswers.answers) {
            labels = dataAnswers.answers.label_auto_test ? dataAnswers.answers.label_auto_test.split(";") : []
            qestions = Array.isArray(dataAnswers.answers.questions) ? dataAnswers.answers.questions : []
            titleresponse = "Réponse de candidate: " + (this.state.showDetail.firstname + " " + this.state.showDetail.lastname)
            titleGrille = dataAnswers.answers.title_auto_evaluation
            noAnswer = (qestions.length !== 0)
        }
        return (
            <PopTab isActive={this.props.isActive} TabTitle={title} close={() => this.props.Close(undefined)} width="92%" height="92%">
                {getingDetail === "0" && <Loader />}
                <div className="height-100 OvfAtoY">
                    <InfoTable
                        classSticky="fix_elem_top"
                        styleTop={{ height: "calc(100% - 10px)" }}
                        thead={[Translate('fields', 'lastname'), Translate('fields', 'firstname'), Translate('fields', 'action')]}
                        tbody={list}
                    />
                </div>
                {this.state.showDetail &&
                    <PopTab isActive={this.state.showDetail} TabTitle={titleresponse} close={this.handleClose} width="90%" height="90%">
                        {getingAnswers === "0" && <Loader />}
                        <h3>{titleGrille}</h3>
                        {noAnswer ?
                            <table className="width-100 setTablw">
                                <thead>
                                    <tr className="brd-btm">
                                        <th className="" style={{ width: "550px" }}></th>
                                        {labels.map((elem, key) => {
                                            return <th key={key}>{elem}</th>
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {qestions.map((qst, key) => {
                                        return <tr key={key} className="brd-btm">
                                            <td>
                                                <strong>{(key + 1) + "/ "}</strong>{qst.textquestionautoeval}
                                                {qst.justification &&
                                                    <div className="dis-flex" style={{ marginTop: "10px" }}>
                                                        <strong className="accpt_clr">Justification : </strong>
                                                        <span style={{ padding: "0 7px" }}>{qst.justification}</span>
                                                    </div>}
                                            </td>
                                            {labels.map((elem, key2) => {
                                                return <th key={key2}>{elem === qst.answer ? CheckClrSvg : ""}</th>
                                            })}
                                        </tr>
                                    })}
                                </tbody>
                            </table> :
                            <div className="dis-flex flex-direction content-center item-center width-100 height-100">
                                {MissingSvg}
                                <h2>Accune réponse de ce candidate</h2>
                            </div>}
                    </PopTab>}
            </PopTab>
        )
    }
}
function mapState(state) {
    const { getingDetail, dataDetailselft, getingAnswers, dataAnswers } = state.tests
    return { getingDetail, dataDetailselft, getingAnswers, dataAnswers }
}
const actionsClass = {
    getSelftTest: testActions.getSelftTest,
    detailResponse: testActions.detailResponse
}
const connectedClass = connect(mapState, actionsClass)(DetailTest);
export { connectedClass as DetailTest };