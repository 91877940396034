import React from 'react'
import { Link } from 'react-router-dom'
export default function ElementSide(props) {
    return (
        <Link to={props.pathName} title={props.TextSvg} onClick={props.onClick}>
            <div className={" pl-side dis-flex item-center elemS-height width-100 font-500 trans-cub sizeF-9em topM- notificationp " + (props.active ? "active_side" : "onHvr")}>
                <div className="dis-flex item-center leftP-12 height-100 brd-leri" >
                    <i className="dis-flex">{props.icon}</i>
                    <div className={"select-non leftM-8 font-space txt-ellips space-nowarp HdOvrfl mxwidth-140 " + (props.active ? "clr-white" : "color_elem")}>{props.TextSvg}</div>
                    {props.notification ? <span className='notificationc'>{props.notification}</span> : null}
                </div>
            </div>
        </Link>
    )
}