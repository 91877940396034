import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reporterActions } from '../../../actions'
import { Translate, FormatDate } from '../../../utils'
import { ContainerPages, ButtonLink, InfoTable, Loader, BtnActions, EditSvg, DeleteSvg, ButtonCLick, RefreshSvg, PopTab, BtnClick, TableRows } from '../../_Common'
import PropTypes from "prop-types";
import {
    addUrlProps,
    UrlQueryParamTypes
} from "react-url-query";
import { AddReporter } from './AddReporter'
const urlPropsQueryConfig = {
    tab: { type: UrlQueryParamTypes.string }
};
class Reporter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            handleTab: false
        }
        if (this.props.fetching !== 1) {
            this.props.fetchReporters()
        }
        this.handleDelet = this.handleDelet.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleRefresh = this.handleRefresh.bind(this)
    }
    static defaultProps = {
        tab: ''
    }
    static propTypes = {
        tab: PropTypes.string,
        /********************** */
        onChangeTab: PropTypes.func,
    };
    componentDidMount() {
        document.title = Translate('titles', 'app') + " - " + Translate('sidebar', 'reports')
    }
    componentWillReceiveProps(nextProps) {
        const { deletingReporter } = nextProps
        if (this.props.deletingReporter === 0 && (deletingReporter === 1 || deletingReporter === 2)) {
            this.props.fetchReporters()
            this.state.handleTab = false
        }
    }
    handleDelet(idRepo) {
        if (!this.state.handleTab) {
            let todelete = this.props.list.reporters.find(reporter => reporter.id == idRepo)
            this.setState({
                handleTab: todelete
            })
        } else {
            this.props.deletReporter(this.state.handleTab.reporter_id)
        }
    }
    handleClose() {
        this.setState({
            handleTab: false
        })
    }
    handleRefresh() {
        this.props.fetchReporters()
    }
    render() {
        const { fetching, list, deletingReporter } = this.props
        var reporter = {}
        var lengthData = ''
        if (fetching === 1 && list && Array.isArray(list.reporters)) {
            lengthData = ' (' + list.reporters.length + ')'
            if (this.props.tab !== "add") {
                reporter = list.reporters.find(reporter => reporter.id == this.props.tab)
                if (!reporter) {
                    reporter = {}
                }
            }
        }
        return (
            <ContainerPages titlePage={Translate('report', 'titlePage') + lengthData} className="admin_bdg" contentLeft={<div className="dis-flex">
                <ButtonCLick TextBottun={RefreshSvg} action={this.handleRefresh} />
                <ButtonLink TextBottun={Translate('report', 'addRapporter')} pathName="?tab=add" />
            </div>} StyleChildren={{ height: "calc(100% - 75px)" }}>
                {fetching === 0 && <Loader style={{ height: "200px" }} />}
                <TableRows
                    classSticky="fix_elem_top"
                    styleTop={{ height: "100%" }}
                    columns={[
                        { name: Translate('fields', 'lastname'), rowKey: "lastname" },
                        { name: Translate('fields', 'firstname'), rowKey: "firstname" },
                        { name: Translate('fields', 'phone'), rowKey: "phone" },
                        { name: Translate('fields', 'email'), rowKey: "email" },
                        { name: Translate('fields', 'username'), rowKey: "usename" },
                        { name: Translate('fields', 'initpassw'), rowKey: "initpassword", render: initpassword => initpassword ? initpassword : <strong>Fait lui connexion</strong> },
                        { name: Translate('fields', 'datecreat'), rowKey: "created_at", render: created_at => FormatDate(created_at, true) },
                        { name: Translate('fields', 'datemodif'), rowKey: "updated_at", render: updated_at => FormatDate(updated_at, true) },
                        { name: Translate('fields', 'action'), rowKey: "id", render: id => <BtnActions btns={[{ icon: EditSvg, action: () => this.props.onChangeTab(id) }, { icon: DeleteSvg, action: () => this.handleDelet(id) }]} /> }
                    ]}
                    data={list && list.reporters}
                    key_table="id"
                />
                {this.props.tab && <AddReporter active={this.props.tab} default={reporter} close={this.props.onChangeTab} />}
                {this.state.handleTab && <PopTab isActive={this.state.handleTab} TabTitle={Translate('report', 'deleteReporter')} close={this.handleClose} width="470px">
                    {deletingReporter === 0 && <Loader />}
                    <p>Vous êtes sur le point de supprimer <strong className="sizeF-12em txt-dng">{this.state.handleTab.firstname + " " + this.state.handleTab.lastname}</strong>. Cette action est irréversible !</p>
                    <div className="dis-flex leftM-auto sTrgPdn sTmr-si">
                        <BtnClick
                            onClick={this.handleClose}
                            TxtBtn="Annuler"
                            style={{ width: "35%" }}
                            Color="leRgM-15 bdg-onrg-op color-org"
                        />
                        <BtnClick
                            onClick={this.handleDelet}
                            style={{ width: "55%" }}
                            TxtBtn="Confirmer la suppression"
                            Color="leRgM-15 width-100 bdg-dng clr-white"
                        />
                    </div>
                </PopTab>}
            </ContainerPages>
        )
    }
}
function mapState(state) {
    const { errorsDelete, dataDelete, deletingReporter, reportersList: list, fetchingReporters: fetching, fetchErrors: errors } = state.reporter
    return { errorsDelete, dataDelete, deletingReporter, list, fetching, errors }
}
const Actions = {
    fetchReporters: reporterActions.fetchReporters,
    deletReporter: reporterActions.deletReporter
}
const connectedClass = connect(mapState, Actions)(Reporter);
const QueryProps = addUrlProps({ urlPropsQueryConfig })(connectedClass)
export { QueryProps as Reporter };