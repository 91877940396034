export const evaluatorConstants={
    ADD_EVALUATOR_REQUEST:"ADD_EVALUATOR_REQUEST",
    ADD_EVALUATOR_SUCCESS:"ADD_EVALUATOR_SUCCESS",
    ADD_EVALUATOR_FAILURE:"ADD_EVALUATOR_FAILURE",

    UPDATE_EVALUATOR_REQUEST:"UPDATE_EVALUATOR_REQUEST",
    UPDATE_EVALUATOR_SUCCESS:"UPDATE_EVALUATOR_SUCCESS",
    UPDATE_EVALUATOR_FAILURE:"UPDATE_EVALUATOR_FAILURE",

    GET_EVALUATOR_REQUEST:"GET_EVALUATOR_REQUEST",
    GET_EVALUATOR_SUCCESS:"GET_EVALUATOR_SUCCESS",
    GET_EVALUATOR_FAILURE:"GET_EVALUATOR_FAILURE",

    DELETE_EVALUATOR_REQUEST:"DELETE_EVALUATOR_REQUEST",
    DELETE_EVALUATOR_SUCCESS:"DELETE_EVALUATOR_SUCCESS",
    DELETE_EVALUATOR_FAILURE:"DELETE_EVALUATOR_FAILURE"
}