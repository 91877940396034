import { adminConstants } from '../constants';
import { RequestService,FunctionService  } from '../services';
export const adminActions = {
    AddAdmin,
    getAdmin,
    updateAdmin,
    deletAdmin
};
function AddAdmin(admin){
    return dispatch => {
        dispatch(FunctionService.request(adminConstants.ADD_ADMIN_REQUEST))
        RequestService.PostRequest('admins/create',admin,true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,adminConstants.ADD_ADMIN_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,adminConstants.ADD_ADMIN_FAILURE))
        })
    }
}
function getAdmin(){
    return dispatch => {
        dispatch(FunctionService.request(adminConstants.GET_ADMIN_REQUEST))
        RequestService.GetRequest('admins/all',true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,adminConstants.GET_ADMIN_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,adminConstants.GET_ADMIN_FAILURE))
        })
    }
}
function updateAdmin(admin){
    return dispatch => {
        dispatch(FunctionService.request(adminConstants.UPDATE_ADMIN_REQUEST))
        RequestService.PostRequest('admins/update',admin,true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,adminConstants.UPDATE_ADMIN_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,adminConstants.UPDATE_ADMIN_FAILURE))
        })
    }
}
function deletAdmin(admin){
    return dispatch => {
        dispatch(FunctionService.request(adminConstants.DELETE_ADMIN_REQUEST))
        RequestService.PostRequest('admins/delete',admin,true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,adminConstants.DELETE_ADMIN_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,adminConstants.DELETE_ADMIN_FAILURE))
        })
    }
}