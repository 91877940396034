export const selfTestCanstants={
    ADD_TEST_REQUEST:"ADD_SELF_TEST_REQUEST",
    ADD_TEST_SUCCESS:"ADD_SELF_TEST_SUCCESS",
    ADD_TEST_FAILURE:"ADD_SELF_TEST_FAILURE",

    ADD_TEST_EVAL_REQUEST:"ADD_SELF_TEST_EVAL_REQUEST",
    ADD_TEST_EVAL_SUCCESS:"ADD_SELF_TEST_EVAL_SUCCESS",
    ADD_TEST_EVAL_FAILURE:"ADD_SELF_TEST_EVAL_FAILURE",

    GET_TEST_EVAL_REQUEST:"GET_SELF_TEST_EVAL_REQUEST",
    GET_TEST_EVAL_SUCCESS:"GET_SELF_TEST_EVAL_SUCCESS",
    GET_TEST_EVAL_FAILURE:"GET_SELF_TEST_EVAL_FAILURE",

    GET_TEST_CANDI_REQUEST:"GET_SELF_TEST_CANDI_REQUEST",
    GET_TEST_CANDI_SUCCESS:"GET_SELF_TEST_CANDI_SUCCESS",
    GET_TEST_CANDI_FAILURE:"GET_SELF_TEST_CANDI_FAILURE",

    UPDATE_AUTO_TEST_REQUEST:"UPDATE_AUTO_TEST_REQUEST",
    UPDATE_AUTO_TEST_SUCCESS:"UPDATE_AUTO_TEST_SUCCESS",
    UPDATE_AUTO_TEST_FAILURE:"UPDATE_AUTO_TEST_FAILURE",

    GET_DETAIL_TEST_REQUEST:" GET_DETAIL_TEST_REQUEST",
    GET_DETAIL_TEST_SUCCESS:" GET_DETAIL_TEST_SUCCESS",
    GET_DETAIL_TEST_FAILURE:" GET_DETAIL_TEST_FAILURE",


    GET_ANSWER_TEST_REQUEST:" GET_ANSWER_TEST_REQUEST",
    GET_ANSWER_TEST_SUCCESS:" GET_ANSWER_TEST_SUCCESS",
    GET_ANSWER_TEST_FAILURE:" GET_ANSWER_TEST_FAILURE",

    GET_DETAIL_TEST_EVALU_REQUEST:" GET_DETAIL_TEST_EVALU_REQUEST",
    GET_DETAIL_TEST_EVALU_SUCCESS:" GET_DETAIL_TEST_EVALU_SUCCESS",
    GET_DETAIL_TEST_EVALU_FAILURE:" GET_DETAIL_TEST_EVALU_FAILURE",


    GET_ANSWER_TEST_EVALU_REQUEST:" GET_ANSWER_TEST_EVALU_REQUEST",
    GET_ANSWER_TEST_EVALU_SUCCESS:" GET_ANSWER_TEST_EVALU_SUCCESS",
    GET_ANSWER_TEST_EVALU_FAILURE:" GET_ANSWER_TEST_EVALU_FAILURE",

}