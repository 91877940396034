import React, { useState } from 'react'
import { qestionsType } from '../../../../constants'
import { InlineElemnt, DownArrowSvg } from '../../../_Common'
export function Qestionview(props) {
    const initialState = {
        showDetail: false
    }
    const [state, setstate] = useState(initialState);
    function handleChange() {
        setstate({
            ...state,
            showDetail: !state.showDetail
        })
    }
    return (
        <div className="botM-25">
            <span className="curs-pointer" onClick={handleChange}>
                <InlineElemnt className="sizeF-11em" first={<strong className="dis-flex brd-equ select-non item-center content-center mm-widthg bdg-default rad-100">{props.index}</strong>} more={<div className="leftM-auto trans-cub" style={state.showDetail ? { transform: 'scale(1.4)' } : { transform: 'rotate(-90deg) scale(1.4)' }}>{DownArrowSvg}</div>} classSecend="" secend={props.qst.text}
                />
            </span>
            {state.showDetail && props.qst.typequestion === qestionsType.bool &&
                <div className="leftM-40 topM-8 dis-flex">
                    <InlineElemnt
                        className="brd-equ rad-5 pd-10-15"
                        classSecend=""
                        first={props.aspoint ? <strong>{"( " + props.qst.details[0].detailanswer + " points)"}</strong> : <input disabled={true} checked={props.qst.details[0].detailanswer === 1} type="radio" />}
                        secend={"Oui"}
                    />
                    <InlineElemnt
                        className="leftM-8 brd-equ rad-5 pd-10-15"
                        classSecend=""
                        first={props.aspoint ? <strong>{"( " + props.qst.details[1].detailanswer + " points)"}</strong> : <input disabled={true} checked={props.qst.details[0].detailanswer === 0} type="radio" />}
                        secend={"Non"}
                    />
                </div>}
            {state.showDetail && (props.qst.typequestion === qestionsType.qcm || (props.qst.typequestion === qestionsType.rank && props.aspoint)) &&
                <div className="dis-block leftM-40 topM-8 paddTB-3">
                    {props.qst.image && <div className="dis-flex stMrgv-tb">
                        <img src={props.qst.image} style={{ maxHeight: "200px", maxWidth: "95%" }} />
                    </div>}
                    {props.qst.details.map((detail, key) => {
                        return <InlineElemnt
                            key={key}
                            className="topM-8"
                            classSecend=""
                            first={props.aspoint ? <strong>{"( " + detail.detailanswer + " points)"}</strong> : <><input id="" disabled={true} checked={detail.detailanswer === 1} type="checkbox" /><label></label></>}
                            secend={detail.detailtext}
                        />
                    })}
                </div>}
            {state.showDetail && (props.qst.typequestion === qestionsType.rank && !props.aspoint) &&
                <div className="dis-block leftM-40">
                    <table className="width-100 setTablw text-center ">
                        <thead>
                            <tr className="brd-btm">
                                <td></td>
                                {Array.isArray(props.qst.details) && props.qst.details.map((detail, key) => {
                                    return <td key={key}><strong className="dis-flex leftM-auto righttM-auto brd-equ select-non item-center content-center mm-widthg rad-100">{key + 1}</strong></td>
                                })}

                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(props.qst.details) && props.qst.details.map((detail, key) => {
                                return <tr className="brd-btm" key={key}>
                                    <td>{detail.detailtext}</td>
                                    {props.qst.details.map((elm, key1) => {
                                        return <td key={key1}><input disabled={true} checked={detail.detailanswer === (key1 + 1)} type="radio" /></td>
                                    })}
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>}
            {state.showDetail && props.qst.typequestion === qestionsType.text &&
                <div className="dis-block leftM-40">
                    Ce question est une text libre
            </div>}
        </div>
    )
}