import React from 'react'
export const EmptySelect = (props) =>{
    window.activeChannel="none"
    return(
        <div className="dis-flex flex-direction height-100 item-center content-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="130" height="130" viewBox="0 0 171 171" >
                <g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none">
                    <path d="M0,171.99504v-171.99504h171.99504v171.99504z" fill="none"></path>
                    <g>
                        <path d="M35.625,24.9375h99.75v24.9375h-99.75z" fill="#f1c40f"></path>
                        <path d="M35.625,74.8125h-14.25v-28.5l14.25,-21.375zM135.375,74.8125h14.25v-28.5l-14.25,-21.375z" fill="#8a5100"></path>
                        <path d="M142.5,146.0625h-114c-3.91875,0 -7.125,-3.20625 -7.125,-7.125v-92.625h128.25v92.625c0,3.91875 -3.20625,7.125 -7.125,7.125z" fill="#d8b62b"></path>
                        <path d="M97.96875,71.25h-24.9375c-2.85,0 -5.34375,-2.49375 -5.34375,-5.34375v0c0,-2.85 2.49375,-5.34375 5.34375,-5.34375h24.9375c2.85,0 5.34375,2.49375 5.34375,5.34375v0c0,2.85 -2.49375,5.34375 -5.34375,5.34375z" fill="#8a5100"></path>
                    </g>
                </g>
            </svg>
            <h2 className="margin-null">Sélectionner un candidat pour voir les questions</h2>
        </div>
    )
}