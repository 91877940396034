import React, { Component } from 'react'
import { connect } from 'react-redux'
import { selfGrilleActions } from '../../../../actions'
import { Translate } from '../../../../utils'
import { ContainerPages, NavTab, ButtonCLick, PlusSvg, InlineElemnt, Loader, RefreshSvg } from '../../../_Common'
import { ListGrille } from './ListGrille'
import { AddSelfGrille } from './AddSelfGrille'
import { GrilleDetail } from './GrilleDetail'
import PropTypes from "prop-types";
import {
    addUrlProps,
    UrlQueryParamTypes
} from "react-url-query";
const urlPropsQueryConfig = {
    currentTab: { type: UrlQueryParamTypes.string },
    tab: { type: UrlQueryParamTypes.string },
    viewGrile: { type: UrlQueryParamTypes.string },
    pageEv: { type: UrlQueryParamTypes.number },
    pageCa: { type: UrlQueryParamTypes.number }
};
class SelfGrille extends Component {
    constructor(props) {
        super(props)
        this.state = {
            idGrille: null
        }
        props.getSelfGrille()
        this.handleRefresh = this.handleRefresh.bind(this)
        this.duplicateGrille = this.duplicateGrille.bind(this)
        this.handleAddtest = this.handleAddtest.bind(this)
        this.deleteGrille = this.deleteGrille.bind(this)
    }
    componentDidMount() {
        document.title = Translate('titles', 'app') + " - " + Translate('sidebar', 'grileauto')
    }
    static defaultProps = {
        currentTab: 'candidate',
        tab: '',
        viewGrile: '',
        pageEv: 0,
        pageCa: 0
    }
    static propTypes = {
        currentTab: PropTypes.string,
        tab: PropTypes.string,
        viewGrile: PropTypes.string,
        pageEv: PropTypes.number,
        pageCa: PropTypes.number,
        /********************** */
        onChangeCurrentTab: PropTypes.func,
        onChangeTab: PropTypes.func,
        onChangeViewGrile: PropTypes.func,
        onChangePageEv: PropTypes.func,
        onChangePageCa: PropTypes.func,
    };
    handleUser(user) {
    }
    handleRefresh() {
        this.props.getSelfGrille()
    }
    duplicateGrille(index) {
        const { dataSelfgrille } = this.props
        var grid = dataSelfgrille.AutoGrid[this.props.currentTab === "candidate" ? "cand" : "eval"][index]
        this.state.idGrille = grid.auto_evaluation_id
        this.props.onChangeTab('add')
    }
    handleAddtest() {
        this.state.idGrille = null
        this.props.onChangeTab('add')
    }
    deleteGrille(index) {
        const { dataSelfgrille, currentTab } = this.props
        let listGrille = currentTab === "candidate" ? dataSelfgrille.AutoGrid.cand : currentTab === "evaluator" ? dataSelfgrille.AutoGrid.eval : []
        let grille = listGrille[index]
        if (grille) {
            this.props.DeleteSelfGrille({ auto_evaluation_id: grille.auto_evaluation_id })
        }
    }
    render() {
        const { getSelfgrille, dataSelfgrille, currentTab, deletingSelf } = this.props
        let ActionDo = ""
        var dataGriles = []
        if (getSelfgrille === "1" && dataSelfgrille && dataSelfgrille.AutoGrid) {
            if (currentTab === "candidate" && Array.isArray(dataSelfgrille.AutoGrid.cand)) {
                dataGriles = dataSelfgrille.AutoGrid.cand
            } else if (currentTab === "evaluator" && Array.isArray(dataSelfgrille.AutoGrid.eval)) {
                dataGriles = dataSelfgrille.AutoGrid.eval
            }
        }

        switch (this.props.currentTab) {
            case "candidate":
                ActionDo = <div className="dis-flex">
                    <ButtonCLick TextBottun={RefreshSvg} action={this.handleRefresh} />
                    <ButtonCLick action={this.handleAddtest} TextBottun={<InlineElemnt
                        first={PlusSvg}
                        secend={Translate('selfgrile', 'grilcandi')}
                    />} />
                </div>
                break;
            case "evaluator":
                ActionDo = <div className="dis-flex">
                    <ButtonCLick TextBottun={RefreshSvg} action={this.handleRefresh} />
                    <ButtonCLick action={this.handleAddtest} TextBottun={<InlineElemnt
                        first={PlusSvg}
                        secend={Translate('selfgrile', 'grilevalu')}
                    />}
                    />
                </div>
                break;
            default:
                break;
        }
        return (
            <ContainerPages titlePage={Translate('sidebar', 'grileauto')} contentTop={<NavTab
                    ElemeList={[{ text: <div className="curs-pointer" onClick={() => this.props.onChangeCurrentTab("candidate")}>{Translate('selfgrile', 'forcandidat')}</div>, active: this.props.currentTab === "candidate" }, { text: <div className="curs-pointer" onClick={() => this.props.onChangeCurrentTab("evaluator")}>{Translate('selfgrile', 'forevaluat')}</div>, active: this.props.currentTab === "evaluator" }]}
                />} contentLeft={ActionDo}>
                
                {(getSelfgrille === "0" || deletingSelf === "0") && <Loader style={{ minHeight: "250px" }} />}
                <ListGrille onViewDetail={this.props.onChangeViewGrile} dateGrilles={dataGriles} deleteGrille={this.deleteGrille} duplicateGrille={this.duplicateGrille} />
                {this.props.tab === "add" && <AddSelfGrille isDuplicated={this.state.idGrille} isActive={this.props.tab === "add"} currentTab={this.props.currentTab} Close={this.props.onChangeTab} />}
                {this.props.viewGrile && <GrilleDetail currentTab={this.props.currentTab} isActive={this.props.viewGrile} Close={this.props.onChangeViewGrile} />}
            </ContainerPages>
        )
    }
}
function mapState(state) {
    const { getSelfgrille, dataSelfgrille, deletingSelf, dataDeleteSelft } = state.grilleauto
    return { getSelfgrille, dataSelfgrille, deletingSelf, dataDeleteSelft }
}
const actionsCLass = {
    getSelfGrille: selfGrilleActions.getSelfGrille,
    DeleteSelfGrille: selfGrilleActions.DeleteSelfGrille
}
const connectedSelfGrille = connect(mapState, actionsCLass)(SelfGrille);
const QueryProps = addUrlProps({ urlPropsQueryConfig })(connectedSelfGrille)
export { QueryProps as SelfGrille };