import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Translate,FormatDate} from '../../../../../utils'
import {testActions,alertActions} from '../../../../../actions'
import {testsConstants} from '../../../../../constants'
import {BtnClick,Loader} from '../../../../_Common'
class PreviewTest extends Component{
    constructor(props){
        super(props)
        this.handleNext=this.handleNext.bind(this)
    }
    componentWillReceiveProps(nextProps){
        const {StoreStep,addSelfTest,dataSelfTest,close,actions}=nextProps
        if(this.props.addSelfTest==="0" && addSelfTest==="1" && dataSelfTest){
            this.props.SendAlert("Opération réussie","success")
            StoreStep("none",testsConstants.REMOVE_PROCESS_TEST)
            actions()
            close(undefined)
        }
        if(this.props.addSelfTest==="0" && addSelfTest==="2"){
            this.props.SendAlert("Erreur lors de la création de test , esseyer plus tard","error")
        }
    }
    handleNext(){
        const {dataGrille,dataTime,dataUsers}=this.props
        if(dataGrille && dataTime && dataUsers){
            let test={
                dateautotest:dataTime,
                auto_evaluation_id:dataGrille.auto_evaluation_id,
                groups:[dataUsers.groupe_candidate_id]
            }
            this.props.TestCandidate(test)
        } 
    }
    render(){
        const {dataGrille,dataTime,dataUsers,addSelfTest,dataSelfTest}=this.props
        return(
            <div className="hg-100-200 leftM-auto righttM-auto" style={{width:"68%"}}>
                {addSelfTest==="0" && <Loader/>}
                <div>
                    <strong className="linhgth sizeF-12em lineHg-35">{Translate('test','ladatedetest')}</strong>
                    <div>{FormatDate(dataTime)}</div>
                    <hr/>
                    <strong className="linhgth sizeF-12em lineHg-35">{Translate('test','grileselect')}</strong>
                    <div>{dataGrille.title_auto_evaluation}</div>
                    <hr/>
                    <strong className="linhgth sizeF-12em lineHg-35">{Translate('test','groupede')}</strong>
                    <div>{dataUsers.groupename}</div> 
                </div>
                <div className="dis-flex content-center topM-22">
                    <BtnClick
                        onClick={this.handleNext}
                        TxtBtn={Translate('test','creerletest')}
                        Color="bdg-onrg clr-white leftM-8"
                    />
                </div>
            </div>
        )
    }
}
function mapState(state){
    const {dataGrille,dataTime,dataUsers,addSelfTest,dataSelfTest}=state.tests
    return {dataGrille,dataTime,dataUsers,addSelfTest,dataSelfTest}
}
const Actions={
    StoreStep:testActions.StoreStep,
    TestCandidate:testActions.TestCandidate,
    SendAlert:alertActions.SendAlert
}
const ConnectedClass=connect(mapState,Actions)(PreviewTest)
export {ConnectedClass as PreviewTest}