const headers = [
    { label: "Appréciation globale", key: "appreciation_global" },
    { label: "Commentaire JTA", key: "comment_jta" },
    { label: "ID ITEROP", key: "form_ref" },
    { label: "Moyenne Score séq", key: "score_seq_avg" },
    { label: "Raison abandon", key: "abandonment_reason" },
    // { label: "STATUT", key: "status" },
    { label: "Score séq 1", key: "score_seq1" },
    { label: "Score séq 2", key: "score_seq2" },
    { label: "Score séq 3", key: "score_seq3" },
    { label: "Score séq 4", key: "score_seq4" },
    { label: "Test connaissance", key: "knowledge_test" },
    { label: "date_jta", key: "date_jta" },
];

export const mapHeaders = (candidates) => {
    const mapped = candidates.map(candidate => {
            const temp = {};
            temp['status'] = candidate['Commentaire JTA'] ? '' : 'abandon_preJta'
            headers.forEach(header => {
                if (candidate[header.label]) {
                    temp[header.key] = String(candidate[header.label]) ?? "";
                }
            });
            return temp;
        });
    return mapped;
}