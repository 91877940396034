import {evalgrilleConstants} from '../constants';
import {RequestService,FunctionService} from '../services';
export const evalGrilleActions={
    addEvaGrille,
    getEvaGrilleCan,
    getEvaGrilleEva,
    getDetailGrille,
    DeleteGrid,
    movePicture
};
function addEvaGrille(EvaGrille) {
    return dispatch => {
        dispatch(FunctionService.request(evalgrilleConstants.ADD_EVAL_REQUEST))
        RequestService.PostRequest('grid/create',EvaGrille,true)
            .then(res => {
                dispatch(FunctionService.succes(res.data,evalgrilleConstants.ADD_EVAL_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err.response,evalgrilleConstants.ADD_EVAL_FAILURE))
            })
    }
}
function DeleteGrid(EvaGrille) {
    return dispatch => {
        dispatch(FunctionService.request(evalgrilleConstants.DELETE_EVAL_REQUEST))
        RequestService.PostRequest('grid/delete',EvaGrille,true)
            .then(res => {
                dispatch(FunctionService.succes({data: res.data,params: EvaGrille},evalgrilleConstants.DELETE_EVAL_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err.response,evalgrilleConstants.DELETE_EVAL_FAILURE))
            })
    }
}
function getEvaGrilleCan() {
    return dispatch => {
        dispatch(FunctionService.request(evalgrilleConstants.GET_EVAL_GRILLE_CANDIDATE_REQUEST))
        RequestService.GetRequest("grid/all/testConnai",true)
            .then(res => {
                dispatch(FunctionService.succes(res.data,evalgrilleConstants.GET_EVAL_GRILLE_CANDIDATE_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err.response,evalgrilleConstants.GET_EVAL_GRILLE_CANDIDATE_FAILURE))
            })
    }
}
function getEvaGrilleEva() {
    return dispatch => {
        dispatch(FunctionService.request(evalgrilleConstants.GET_EVAL_GRILLE_REQUEST))
        RequestService.GetRequest('grid/all/testEval',true)
            .then(res => {
                dispatch(FunctionService.succes(res.data,evalgrilleConstants.GET_EVAL_GRILLE_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err.response,evalgrilleConstants.GET_EVAL_GRILLE_FAILURE))
            })
    }
}
function getDetailGrille(idGrile) {
    return dispatch => {
        dispatch(FunctionService.request(evalgrilleConstants.GET_EVAL_DETAIL_REQUEST))
        RequestService.GetRequest('grid/'+idGrile,true)
            .then(res => {
                dispatch(FunctionService.succes(res.data,evalgrilleConstants.GET_EVAL_DETAIL_SUCCESS))
            })
            .catch(err => {
                dispatch(FunctionService.failure(err.response,evalgrilleConstants.GET_EVAL_DETAIL_FAILURE))
            })
    }
}
function movePicture(picture) {
    return RequestService.PostRequest('qestion/picture',picture,true)
}