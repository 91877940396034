import React, { Component } from 'react'
import { ImSearch } from 'react-icons/im'
import { connect } from 'react-redux'
import { Route,Switch } from 'react-router-dom'
import { messagingActions } from '../../../actions'
import { Translate } from '../../../utils'
import { ContainerPages, Loader } from '../../_Common'
import { CandidateList } from './CandidateList'
import { EmptySelect } from './EmptySelect'
import {MessageContent} from './MessageContent'
class Messaging extends Component {
    constructor(props){
        super(props)
        this.state={
            activeChannel:null,
            channels:[]
        }
        this.handleClick = this.handleClick.bind(this);
        this.setqctivechannel = this.setqctivechannel.bind(this);
        this.fetchListUsers()
    }
    fetchListUsers = () => {
        this.props.GetNotificationall()
        this.props.GetListUsers()
    }
    
    setqctivechannel = (activeChannelhere) => {
        // console.log('9999999999999999999999 setqctivechannel------',activeChannelhere)
        this.setState({
            activeChannel:activeChannelhere
        })
        this.props.GetListUsers()
    }
    handleClick = () => {        
        setTimeout(() => {
            this.props.GetNotificationall()
        }, 1200);
    }
    componentDidMount() {
        document.title = Translate('titles', 'app') + " - " + Translate('chat', 'title');        
        window.Echo.channel(`messaging-adminsJta`)
        .listen('RecordChannelEvent', (e) => {
            if (e.asyncList==="async") {
                this.props.GetListAsync()                
                this.props.GetNotificationall()
            }
        })
    }
    render(){
        const {getingUsers,dataUsersList,notifcationchannelsone}=this.props
        return(
            <ContainerPages titlePage={Translate('chat', 'title')} hideSubHeader={true} StyleChildren={{ height: "calc(100% - 25px)" }}>
                
                <div className="dis-flex topM-22 pos-relative height-100">
                    <div className="paddTB-10 bdg-white height-100 box-border pos-relative" style={{width:370}}>
                        {getingUsers===0 && <Loader/>}
                        <div className="dis-flex item-center brd-equ rad-5 padding-def mx-8" style={{borderWidth:2}}>
                            <ImSearch className="icon_color mr-2 curs-pointer" />
                            <input type="text" autoComplete="off" name="search" onChange={this.handleSearchCandi} placeholder="Rechercher un candidat" value={this.state.search} className="sizeF-1em leftM-5 width-100" />
                        </div>
                        <div className="pt-10" style={{width:370}}>
                            <CandidateList candidates={dataUsersList} notifcationchannelsone={notifcationchannelsone} oncllick={this.handleClick} setqctivechannel={this.setqctivechannel} />
                        </div>
                    </div>
                    <div className="pd-10-15 bdg-white width-100 box-border height-100 pos-relative" style={{marginLeft:20}}>
                        <Switch>
                            <Route path="/candidate/chat/:idChannel" component={MessageContent} />
                            <Route path="/candidate/chat" component={EmptySelect} />
                        </Switch>
                    </div>
                </div>
                
            </ContainerPages>
        )
    }
}
function mapState(state) {
    const {getingUsers,dataUsersList,notifcationchannelsone}=state.messaging
    return {getingUsers,dataUsersList,notifcationchannelsone}
}
const connectedClass = connect(mapState, messagingActions)(Messaging);
export { connectedClass as Messaging };