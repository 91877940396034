import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Translate} from '../../../../../utils'
import {testActions} from '../../../../../actions'
import {testsConstants} from '../../../../../constants'
import {InputForm,BtnClick} from '../../../../_Common'
class SelectTime extends Component{
    constructor(props){
        super(props)
        this.state={
            testDate:props.dataTime
        }
        this.HandleChange=this.HandleChange.bind(this)
        this.handleNext=this.handleNext.bind(this)
    }
    HandleChange(e){
        const {name,value}=e.target 
        this.setState({
            [name]:value
        })
    }
    handleNext(){
        if(this.state.testDate){
            this.props.StoreStep(this.state.testDate,testsConstants.SET_SELF_TIME_TEST)
        } 
    }
    render(){
        return(
            <div className="width-46per leftM-auto righttM-auto">
                    <span className="linhgth">Date d'auto-évaluation</span>
                    <div className="dis-flex topM-8">
                        <InputForm
                            className="rightM-8"
                            name="testDate"
                            type="date"
                            onChange={this.HandleChange}
                            value={this.state.testDate}
                        />
                    </div>
                    <div className="dis-flex content-center topM-22">
                        <BtnClick
                            onClick={this.handleNext}
                            TxtBtn={Translate('test','nextstep')}
                            Color="bdg-onrg clr-white leftM-8"
                        />
                    </div>
                </div>
        )
    }
}
function mapState(state){
    const {dataTime}=state.tests
    return {dataTime}
}
const Actions={
    StoreStep:testActions.StoreStep
}
const ConnectedClass=connect(mapState,Actions)(SelectTime)
export {ConnectedClass as SelectTime}