import { alertConstants } from '../constants';

export function alert(state={},action) {
  switch (action.type) {
    case alertConstants.SEND_ALERT_DATA:
      return{
        alertCode:action.payload.code,
        alertData:action.payload.text
      }
    default:
        return state
    }
}