import {selfTestCanstants} from '../constants'
import { RequestService,FunctionService  } from '../services';
export const testActions = {
    StoreStep,
    TestCandidate,
    TestEvaluateur,
    getTestEvaluateur,
    getTestCandidate,
    updateStatusAuto,
    getSelftTest,
    detailResponse,
    getSelftTestEvalu,
    detailResponseEvalu
};
function StoreStep(data,constant){
    return dispatch => {
        dispatch(FunctionService.succes(data,constant))
    }
}
function TestCandidate(test){
    return dispatch => {
        dispatch(FunctionService.request(selfTestCanstants.ADD_TEST_REQUEST))
        RequestService.PostRequest('autoTestCand/create',test,true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,selfTestCanstants.ADD_TEST_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,selfTestCanstants.ADD_TEST_FAILURE))
        })
    }
}
function TestEvaluateur(test){
    return dispatch => {
        dispatch(FunctionService.request(selfTestCanstants.ADD_TEST_EVAL_REQUEST))
        RequestService.PostRequest('autoTestEval/create',test,true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,selfTestCanstants.ADD_TEST_EVAL_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,selfTestCanstants.ADD_TEST_EVAL_FAILURE))
        })
    }
}
function getTestEvaluateur(){
    return dispatch => {
        dispatch(FunctionService.request(selfTestCanstants.GET_TEST_EVAL_REQUEST))
        RequestService.GetRequest('autoTestEval/all',true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,selfTestCanstants.GET_TEST_EVAL_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,selfTestCanstants.GET_TEST_EVAL_FAILURE))
        })
    }
}
function getTestCandidate(){
    return dispatch => {
        dispatch(FunctionService.request(selfTestCanstants.GET_TEST_CANDI_REQUEST))
        RequestService.GetRequest('autoTestCand/all',true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,selfTestCanstants.GET_TEST_CANDI_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,selfTestCanstants.GET_TEST_CANDI_FAILURE))
        })
    }
}
function updateStatusAuto(type,dataTest){
    return dispatch => {
        dispatch(FunctionService.request(selfTestCanstants.UPDATE_AUTO_TEST_REQUEST))
        RequestService.PostRequest(type+'/status/update',dataTest,true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,selfTestCanstants.UPDATE_AUTO_TEST_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,selfTestCanstants.UPDATE_AUTO_TEST_FAILURE))
        })
    }
}
function getSelftTest(idTest){
    return dispatch => {
        dispatch(FunctionService.request(selfTestCanstants.GET_DETAIL_TEST_REQUEST))
        RequestService.GetRequest("autoTestCand/detail/"+idTest,true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,selfTestCanstants.GET_DETAIL_TEST_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,selfTestCanstants.GET_DETAIL_TEST_FAILURE))
        })
    }
}
function detailResponse(idTest,idcandi){
    return dispatch => {
        dispatch(FunctionService.request(selfTestCanstants.GET_ANSWER_TEST_REQUEST))
        RequestService.GetRequest("autoTestCand/detail/"+idcandi+"/"+idTest,true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,selfTestCanstants.GET_ANSWER_TEST_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,selfTestCanstants.GET_ANSWER_TEST_FAILURE))
        })
    }
}
function getSelftTestEvalu(idTest){
    return dispatch => {
        dispatch(FunctionService.request(selfTestCanstants.GET_DETAIL_TEST_EVALU_REQUEST))
        RequestService.GetRequest("autoTestEval/detail/"+idTest,true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,selfTestCanstants.GET_DETAIL_TEST_EVALU_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,selfTestCanstants.GET_DETAIL_TEST_EVALU_FAILURE))
        })
    }
}
function detailResponseEvalu(idTest,idEvalu){
    return dispatch => {
        dispatch(FunctionService.request(selfTestCanstants.GET_ANSWER_TEST_EVALU_REQUEST))
        RequestService.GetRequest("autoTestEval/detail/"+idEvalu+"/"+idTest,true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,selfTestCanstants.GET_ANSWER_TEST_EVALU_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,selfTestCanstants.GET_ANSWER_TEST_EVALU_FAILURE))
        })
    }
}

